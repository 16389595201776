import { Action, createReducer, on } from '@ngrx/store';
import * as DurationActions from '../actions/duration.actions';

export interface DurationState {
  startDate: Date;
  endDate: Date;
  startHour: string;
  endHour: string;
  timezone: string;
  durationInDays: number;
  costPerDay: number;
  durationCost: number;
}

export const initialState: DurationState = {
  startDate: null,
  endDate: null,
  startHour: null,
  endHour: null,
  timezone: null,
  durationInDays: null,
  costPerDay: null,
  durationCost: null,
};

const durationReducer = createReducer(
  initialState,
  on(DurationActions.saveDuration, (state, { engagementDuration }) => ({
    ...state,
    startDate: engagementDuration.startDate,
    endDate: engagementDuration.endDate,
    startHour: engagementDuration.startHour,
    endHour: engagementDuration.endHour,
    timezone: engagementDuration.timezone,
    durationInDays: engagementDuration.durationInDays,
    costPerDay: engagementDuration.costPerDay,
    durationCost: engagementDuration.durationCost,
  })),
);

export function reducer(state = initialState, action: Action): DurationState {
  return durationReducer(state, action);
}
