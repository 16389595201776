import { ActionReducer, ActionReducerMap, MetaReducer } from '@ngrx/store';
import { LogUtils } from '../utils/log-utils';
import { BuyTargetState } from './reducers/buy-target.reducer';
import * as fromBuyTarget from './reducers/buy-target.reducer';
import * as fromDuration from './reducers/duration.reducer';
import { DurationState } from './reducers/duration.reducer';
import { FilterState } from './reducers/filter.reducer';
import * as fromFilter from './reducers/filter.reducer';
import * as fromGeolocation from './reducers/geolocation.reducer';
import { GeolocationState } from './reducers/geolocation.reducer';
import { InformationTargetPageState } from './reducers/information-target-page.reducer';
import * as fromInformationTargetPage from './reducers/information-target-page.reducer';
import { SavedTargetPagesState } from './reducers/saved-target-pages.reducer';
import * as fromSavedTargetPages from './reducers/saved-target-pages.reducer';
import * as fromWizard from './reducers/wizard.reducer';
import { WizardState } from './reducers/wizard.reducer';
import {dropdownReducer, DropdownState} from "./reducers/chartType.reducer";

/* Global state and store */
export interface State {
  wizard: WizardState;
  geolocation: GeolocationState;
  duration: DurationState;
  savedTargetPages: SavedTargetPagesState;
  buyTarget: BuyTargetState;
  informationTargetPage: InformationTargetPageState;
  filterState: FilterState;
  dropdown: DropdownState;
}

export const reducers: ActionReducerMap<State> = {
  wizard: fromWizard.reducer,
  geolocation: fromGeolocation.reducer,
  duration: fromDuration.reducer,
  savedTargetPages: fromSavedTargetPages.reducer,
  buyTarget: fromBuyTarget.reducer,
  informationTargetPage: fromInformationTargetPage.reducer,
  filterState: fromFilter.reducer,
  dropdown: dropdownReducer,
};

/* Meta store */
export function logger(reducer: ActionReducer<State>): ActionReducer<State> {
  return (state: State, action: any): State => {
    LogUtils.action(action);
    return reducer(state, action);
  };
}

export function metaReducers(production: boolean): MetaReducer<State>[] {
  return !production ? [logger] : [];
}

export * from './selectors/wizard.selectors';
export * from './selectors/geolocation.selectors';
export * from './selectors/duration.selectors';
export * from './selectors/saved-target-pages.selectors';
export * from './selectors/buy-target.selectors';
export * from './selectors/information-target-page.selectors';
export * from './selectors/filter.selectors';
