import { Component, Input, OnInit } from '@angular/core';
import { MatLegacyDialogRef } from '@angular/material/legacy-dialog';
import { SelectionOption } from '@core/models/selection-option';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { BasePageBuilderConfiguration } from '../base-page-builder-configuration';

@Component({
  selector: 'app-selection-customisation-popup',
  templateUrl: './selection-customisation-popup.component.html',
  styleUrls: ['./selection-customisation-popup.component.scss'],
})
export class SelectionCustomisationPopupComponent implements OnInit {
  @Input() configuration: BasePageBuilderConfiguration;

  hasEmptyOption: boolean;
  labelProvided: boolean;
  optionPlaceholder: string;
  optionValueChanged$: Subject<string>;

  constructor(public dialogRef: MatLegacyDialogRef<SelectionCustomisationPopupComponent>) {
    this.optionValueChanged$ = new Subject<string>();
    this.optionPlaceholder = '';
  }

  ngOnInit(): void {
    if (this.configuration.data.selectionOptions.length === 0) {
      this.configuration.data.selectionOptions.push(this.getNewSelectionOption());
    }

    this.hasEmptyOption = this.checkForEmptySelectionOption();
    this.labelProvided = this.configuration.data.text ? true : false;

    this.optionValueChanged$.pipe(debounceTime(250)).subscribe(() => {
      this.hasEmptyOption = this.checkForEmptySelectionOption();
    });
  }

  validateLabel(): void {
    this.labelProvided = this.configuration.data.text.trim().length > 0;
  }

  onOptionValueChange(value: string) {
    if (!value) {
      this.hasEmptyOption = true;
      return;
    }

    this.optionValueChanged$.next(value);
  }

  addNewOption(index: number): void {
    this.configuration.data.selectionOptions.splice(index, 0, this.getNewSelectionOption());
    this.hasEmptyOption = true;
  }

  deleteOption(index: number): void {
    this.configuration.data.selectionOptions.splice(index, 1);
    this.hasEmptyOption = this.checkForEmptySelectionOption();
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  checkForEmptySelectionOption(): boolean {
    return this.configuration.data.selectionOptions.some((o) => o.description.trim().length === 0);
  }

  getNewSelectionOption(): SelectionOption<number> {
    return {
      value: this.configuration.data.selectionOptions.length + 1,
      description: '',
    };
  }
}
