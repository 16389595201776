import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  EngagementCreationWizardStepType,
} from '@core/constants/EngagementCreationWizardSteps';
import { BaseComponent } from '@shared/components/base-component/base-component';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-wizard-step',
  templateUrl: './wizard-step.component.html',
  styleUrls: ['./wizard-step.component.scss'],
})
export class WizardStepComponent extends BaseComponent implements OnInit {
  @Input() engagementCreationWizardStep: EngagementCreationWizardStepType;
  @Input() totalSteps: number;
  @Input() isHomePage:boolean;
  @Output() activeStepNumber: EventEmitter<string> = new EventEmitter();
  isVisited = false;
  isActiveStep = false;
  stepText = 'NOT_DONE_YET';

  constructor(private activatedRoute: ActivatedRoute) {
    super();
  }
  ngOnInit(): void {
    this.isVisited = false;
    this.isActiveStep = false;
    this.activatedRoute.url.pipe(takeUntil(this.destroy$)).subscribe(() => {
      if (
        this.engagementCreationWizardStep.allowedRoutes.includes(this.activatedRoute.firstChild.routeConfig.path)
      ) {
        this.isActiveStep = true;
        this.isVisited = true;
        this.activeStepNumber.emit(this.engagementCreationWizardStep.stepNumber);
      } else {
        this.isActiveStep = false;
      }
    });
  }
}
