import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatLegacyDialogRef } from '@angular/material/legacy-dialog';
import { DateTimePickerComponent } from '@shared/components/date-time-picker/date-time-picker.component';
import { DateTimePickerConfig } from '@shared/components/date-time-picker/date-time-picker.configuration';
import * as moment from 'moment';
import { BasePageBuilderConfiguration } from '../base-page-builder-configuration';

@Component({
  selector: 'app-date-field-customisation-popup',
  templateUrl: './date-field-customisation-popup.component.html',
  styleUrls: ['./date-field-customisation-popup.component.scss'],
})
export class DateFieldCustomisationPopupComponent implements OnInit {
  @Input() configuration: BasePageBuilderConfiguration;
  startDateConfig: DateTimePickerConfig = {
    showTime: false,
    hourFormat: '24',
    inline: false,
    showButtonBar: true,
    showIcon: true,
  };
  endDateConfig: DateTimePickerConfig = {
    showTime: false,
    hourFormat: '24',
    inline: false,
    showButtonBar: true,
    showIcon: true,
  };
  labelProvided: boolean;
  @ViewChild('startDateInputId') startDate!: DateTimePickerComponent;
  @ViewChild('endDateInputId') endDate!: DateTimePickerComponent;

  constructor(public dialogRef: MatLegacyDialogRef<DateFieldCustomisationPopupComponent>) {}

  ngOnInit(): void {
    this.labelProvided = !!this.configuration.data.text;
  }

  checkIfDateIsInPastStart(date: Date) {
    this.configuration.data.startDateLimit = date;
  }

  checkIfDateIsInPastEnd(date: Date) {
    this.configuration.data.endDateLimit = date;
  }

  validateLabel(): void {
    this.labelProvided = this.configuration.data.text.trim().length > 0;
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  onlyFutureDates() {
    if (this.configuration.data.onlyShowDaysInFuture === true) {
      this.startDateConfig.minDate = new Date();
      this.endDateConfig.minDate = new Date();
    } else {
      const minDate = moment.utc('0001-01-01');
      this.startDateConfig.minDate = minDate.toDate();
      this.endDateConfig.minDate = minDate.toDate();
    }
  }
}
