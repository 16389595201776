import { InstantButtonOption } from '@core/models/instant-button-option';

export enum WidthEnum {
  Wide = 0,
  Narrow = 1,
  Custom = 2,
}

export const WidthWide: InstantButtonOption = {
  name: WidthEnum[WidthEnum.Wide],
  value: WidthEnum.Wide,
  optionValue: 'wide',
  svgIcon: 'width-wide',
};

export const WidthNarrow: InstantButtonOption = {
  name: WidthEnum[WidthEnum.Narrow],
  value: WidthEnum.Narrow,
  optionValue: 'narrow',
  svgIcon: 'width-narrow',
};

export const WidthCustom: InstantButtonOption = {
  name: WidthEnum[WidthEnum.Custom],
  value: WidthEnum.Custom,
  optionValue: '',
  svgIcon: 'width-custom',
};

export const WidthList: Array<InstantButtonOption> = [WidthWide, WidthNarrow, WidthCustom];
