import { Component, Input, OnInit } from '@angular/core';
import { MatLegacyDialogRef } from '@angular/material/legacy-dialog';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { FeatureManagementEnum } from '@core/constants/FeatureManagement';
import { RegularExpressions } from '@core/constants/RegularExpressions';
import { AppSettingService } from '@core/services/app-setting.service';
import { VideoUrlEmbedder } from '@core/utils/VideoUrlEmbedder';
import { BasePageBuilderConfiguration } from '../base-page-builder-configuration';

const youtubeLink = 'www.youtube.com';
const vimeoLink = 'player.vimeo.com';
let apiLoaded = false;
const youtubeNotLoadedState = -1;
const autoplay = '?autoplay=1';

@Component({
  selector: 'app-video-customisation-popup',
  templateUrl: './video-customisation-popup.component.html',
  styleUrls: ['./video-customisation-popup.component.scss'],
})
export class VideoCustomisationPopupComponent implements OnInit {
  @Input() configuration: BasePageBuilderConfiguration;

  urlRegex = RegularExpressions.URL;
  trustedUrl: SafeResourceUrl;
  youtubeId = null;
  vimeoId = null;
  checkBox: boolean;
  isEnabledAutoplay: boolean;

  constructor(
    public dialogRef: MatLegacyDialogRef<VideoCustomisationPopupComponent>,
    readonly sanitizer: DomSanitizer,
    readonly appSettingService: AppSettingService,
  ) {}

  ngOnInit(): void {
    if (this.configuration.data.url) {
      this.checkBox = this.configuration.data.url.includes(autoplay);
      this.validateVideoUrl();
    }
    if (!apiLoaded) {
      const tag = document.createElement('script');
      tag.src = 'https://www.youtube.com/iframe_api';
      document.body.appendChild(tag);
      apiLoaded = true;
    }
    const appSettingsFeatureFlagForAutoplay = this.appSettingService.isFeatureEnabled(
      FeatureManagementEnum.PageBuilderAutoplay,
    );
    this.isEnabledAutoplay = appSettingsFeatureFlagForAutoplay === undefined || appSettingsFeatureFlagForAutoplay;
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  validateVideoUrl(): void {
    const url = this.configuration.data.url;
    const embededUrl = url.includes(youtubeLink)
      ? VideoUrlEmbedder.getEmbededYouTubeUrl(url)
      : VideoUrlEmbedder.getEmbededVimeoUrl(url);
    if (embededUrl) {
      this.trustedUrl = this.sanitizer.bypassSecurityTrustResourceUrl(embededUrl);
      this.configuration.data.url = embededUrl;
      if (embededUrl.includes(youtubeLink)) {
        this.youtubeId = this.getYoutubeId(embededUrl);
        this.vimeoId = null;
      } else if (embededUrl.includes(vimeoLink)) {
        this.vimeoId = this.getVimeoId(embededUrl);
        this.youtubeId = null;
      }
    } else {
      this.trustedUrl = null;
    }
  }

  onAutoplay(): void {
    const url = this.configuration.data.url;
    this.configuration.data.url = this.checkBox ? `${url}${autoplay}` : url.replace(autoplay, '');
    if (url.includes(autoplay) && this.checkBox) {
      this.configuration.data.url = url;
    }
  }

  getYoutubeId(url: string): string {
    const firstIndexOf = url.indexOf('/embed/') + 7;
    const lastIndexOf = url.lastIndexOf('?autoplay=1');
    return url.substring(firstIndexOf);
  }

  getVimeoId(url: string): string {
    const firstIndexOf = url.indexOf('/video/') + 7;
    const lastIndexOf = url.lastIndexOf('?autoplay=1');
    return url.substring(firstIndexOf);
  }

  onResourceLoadingError(): void {
    this.trustedUrl = null;
  }

  onYoutubeReady(event: YT.PlayerEvent): void {
    const playerState = event.target.getPlayerState();
    if (playerState === youtubeNotLoadedState) {
      this.trustedUrl = null;
      apiLoaded = false;
    }
  }
}
