// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header-container {
  padding: 0 6px;
  margin: 25px 0;
  width: 100%;
}
.header-container h2 {
  color: white;
  padding: 8px 0 8px 14px;
  margin: 0;
}

.base-card {
  min-width: 100px;
  max-width: 370px;
  flex: 1 1 100%;
}

.card-container {
  justify-content: center;
  gap: 10px;
}

@media screen and (max-width: 1279px) {
  .header-container {
    padding: 0;
    margin: 10px 0;
  }
  .base-card {
    max-width: unset;
  }
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/base-card-container/base-card-container.component.scss"],"names":[],"mappings":"AAGA;EACE,cAAA;EACA,cAAA;EACA,WAAA;AAFF;AAIE;EACE,YAAA;EACA,uBAAA;EACA,SAAA;AAFJ;;AAMA;EACE,gBAAA;EACA,gBAAA;EACA,cAAA;AAHF;;AAMA;EACE,uBAAA;EACA,SAAA;AAHF;;AAMA;EACE;IACE,UAAA;IACA,cAAA;EAHF;EAMA;IACE,gBAAA;EAJF;AACF","sourcesContent":["@import '@angular/material/theming';\n@import 'variables';\n\n.header-container {\n  padding: 0 6px;\n  margin: 25px 0;\n  width: 100%;\n\n  h2 {\n    color: white;\n    padding: 8px 0 8px 14px;\n    margin: 0;\n  }\n}\n\n.base-card {\n  min-width: 100px;\n  max-width: 370px;\n  flex: 1 1 100%;\n}\n\n.card-container {\n  justify-content: center;\n  gap: 10px;\n}\n\n@media screen and (max-width: 1279px) {\n  .header-container {\n    padding: 0;\n    margin: 10px 0;\n  }\n\n  .base-card {\n    max-width: unset;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
