import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { AUTH_SERVICE } from '@core/core.module';
import { AuthServiceInterface } from '@core/services/auth/auth-service-inteface';
import { Observable, of } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard  {
  constructor(@Inject(AUTH_SERVICE) private auth: AuthServiceInterface) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.auth.isAuthenticated$.pipe(
      switchMap((loggedIn) => {
        if (!loggedIn) {
          return this.auth.login$(state.url, next.queryParams).pipe(map((_) => false));
        }
        return of(true);
      }),
    );
  }
}
