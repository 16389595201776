import { Guid } from '@core/utils/Guid';
import { CategoryLink } from '../product/CreateProductRequest';
import { MediaType } from '../product/UploadProductMediaRequest';
import { ProductCategory } from './product-category';

export interface MediaGalleryEntry {
  id: number;
  url: string;
  mediaType: string;
  types: MediaType[];
}

export class RceProduct {
  enabled: boolean;
  imageThumbUrl: string;
  merchantId: string;
  name: string;
  partnerId: string;
  price: number;
  productCategories: ProductCategory[] = [];
  productCategoryLinks: CategoryLink[] = [];
  mediaGalleryEntries: MediaGalleryEntry[] = [];
  quantity: number;
  sku: string;
  shortDescription: string;
  description: string;
  weight: number;
  id: number;
  isExternal: boolean;
  externalId?: string;

  constructor(product: RceProduct = null) {
    if (product != null) {
      this.enabled = product.enabled;
      this.imageThumbUrl = product.imageThumbUrl;
      this.merchantId = product.merchantId;
      this.name = product.name;
      this.partnerId = product.partnerId;
      this.price = product.price;
      this.productCategories = product.productCategories;
      this.quantity = product.quantity;
      this.sku = product.sku;
      this.weight = product.weight;
      this.description = product.description;
      this.shortDescription = product.shortDescription;
      this.productCategoryLinks = product.productCategoryLinks;
      this.productCategories = product.productCategories;
      this.id = product.id;
      this.mediaGalleryEntries = product.mediaGalleryEntries;
      this.externalId = product.externalId;
      this.isExternal = product.isExternal;
    } else {
      this.weight = 1;
      this.sku = Guid.newGuid();
    }
  }
}
