import { MAX_SUBTITLE_LENGTH, MAX_TITLE_LENGTH} from '@core/constants/Limits';
import { ThumbnailImage } from '@core/models/thumbnail-image';
import { ActTypeEnum } from '../enums/act-type-enum';

abstract class BaseTargetPage {
  images: unknown[];
  dataFields: unknown[];
  id: string;
  title: string;
  subtitle: string;
  pageName: string;
  description: string;
  type: ActTypeEnum;
  thumbnailImage: ThumbnailImage;
  thumbnailUrl: string;

  abstract getTargetPageInfo();

  generateCloneTitle() {
    this.title = `${this.title} 2`;
    this.pageName = `${this.pageName} 2`;
  }

  truncateTitle(titleLength = MAX_TITLE_LENGTH, subtitleLength = MAX_SUBTITLE_LENGTH): void {
    if (this.title?.length >= titleLength) {
      this.title = this.title.substr(0, titleLength - 3) + '...';
    }
    if (this.subtitle?.length >= subtitleLength) {
      this.subtitle = this.subtitle.substr(0, subtitleLength - 3) + '...';
    }
  }
}
export default BaseTargetPage;
