import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { AudioFilePreviewModule } from './audio-file-preview/audio-file-preview.module';
import { ImageFilePreviewModule } from './image-file-preview/image-file-preview.module';
import { ProductImagePreviewModule } from './product-image-preview/product-image-preview.module';

@NgModule({
  declarations: [],
  imports: [CommonModule, ImageFilePreviewModule, AudioFilePreviewModule, ProductImagePreviewModule],
  exports: [ImageFilePreviewModule, AudioFilePreviewModule, ProductImagePreviewModule],
})
export class FilePreviewModule {}
