import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';
import { TranslateModule } from '@ngx-translate/core';
import { GenericCreationContainerComponent } from './generic-creation-container.component';

@NgModule({
  declarations: [GenericCreationContainerComponent],
  imports: [CommonModule, MatIconModule, MatLegacyButtonModule, ScrollingModule, TranslateModule.forChild()],
  exports: [GenericCreationContainerComponent],
})
export class GenericCreationContainerModule {}
