import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TargetPagesComponent } from './target-pages.component';

const routes = [{ path: '', component: TargetPagesComponent }];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class TargetPagesRoutingModule {}
