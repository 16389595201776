import { Action, createReducer, on } from '@ngrx/store';
import { Connection, PaymentType } from '../../payment-settings/payment-connection-models';
import { selectPaymentProvider } from '../actions/select-payment-provider.action';

export interface SelectedPaymentProviderState {
  providerName: string;
  providerLogoUrl: string;
  selectedPaymentPlatform: { [type in PaymentType]: Connection };
}

export const initialState: SelectedPaymentProviderState = {
  providerName: '',
  providerLogoUrl: '',
  selectedPaymentPlatform: null,
};

const selectPaymentProviderReducer = createReducer(
  initialState,
  on(selectPaymentProvider, (state, { paymentDto }) => ({
    ...state,
    ...paymentDto,
  })),
);

export function reducer(state = initialState, action: Action): SelectedPaymentProviderState {
  return selectPaymentProviderReducer(state, action);
}
