import { ActDTO } from '@core/models/dtos/act-dto';
import { Product } from '@core/models/dtos/product';
import { BuyTargetLinkOptionsEnum } from '@core/models/enums/buy-target-link-options-enum';
import { Action, createReducer, on } from '@ngrx/store';
import * as BuyTargetActions from '../actions/buy-target.actions';

export interface BuyTargetState {
  targetId: string;
  isEditMode: boolean;
  isAccountLinkedToRCE: boolean;
  showFields: boolean;
  selectedLinkOption: BuyTargetLinkOptionsEnum;
  selectedProduct: Product;
  showSelectedProduct: boolean;
  autoCompleteProductOptions: Product[];
  productsAreLoading: boolean;
  buyTargetData: ActDTO;
  savedBuyTarget: ActDTO;
  productNotSelectedError: boolean;
}

export const initialState: BuyTargetState = {
  targetId: null,
  isEditMode: false,
  isAccountLinkedToRCE: false,
  showFields: false,
  selectedProduct: null,
  showSelectedProduct: false,
  autoCompleteProductOptions: [],
  productsAreLoading: false,
  selectedLinkOption: BuyTargetLinkOptionsEnum.PRODUCT,
  buyTargetData: null,
  savedBuyTarget: null,
  productNotSelectedError: false,
};

const buyTargetPageReducer = createReducer(
  initialState,
  on(BuyTargetActions.provideTargetIdFromRoute, (state, { targetId }) => ({
    ...state,
    targetId,
    isEditMode: true,
  })),
  on(BuyTargetActions.accountLinkedToRceResult, (state, { response }) => ({
    ...state,
    isAccountLinkedToRCE: response,
  })),
  on(BuyTargetActions.buyTargetDataReceived, (state, { response }) => ({
    ...state,
    buyTargetData: response,
    selectedLinkOption: response.product ? BuyTargetLinkOptionsEnum.PRODUCT : BuyTargetLinkOptionsEnum.CATEGORY,
    selectedProduct: response.product,
    showSelectedProduct: response.product != null,
  })),
  on(BuyTargetActions.showFields, (state) => ({
    ...state,
    showFields: true,
  })),
  on(BuyTargetActions.hideFields, (state) => ({
    ...state,
    showFields: false,
  })),
  on(BuyTargetActions.selectProduct, (state, { selectedProduct }) => ({
    ...state,
    selectedProduct,
    showSelectedProduct: true,
    productNotSelectedError: false,
  })),
  on(BuyTargetActions.closeProductSearch, (state) => ({
    ...state,
    autoCompleteProductOptions: [],
  })),
  on(BuyTargetActions.enableSearchProductsSpinner, (state) => ({
    ...state,
    productsAreLoading: true,
  })),
  on(BuyTargetActions.disableSearchProductsSpinner, (state) => ({
    ...state,
    productsAreLoading: false,
  })),
  on(BuyTargetActions.clearSelectedProduct, (state) => ({
    ...state,
    selectedProduct: null,
    showSelectedProduct: false,
  })),
  on(BuyTargetActions.loadAutoCompleteProductOptions, (state, { products }) => ({
    ...state,
    autoCompleteProductOptions: products,
  })),
  on(BuyTargetActions.changeLinkTargetOption, (state, { option }) => ({
    ...state,
    selectedLinkOption: option,
  })),
  on(BuyTargetActions.saveBuyTarget, (state, { data }) => ({
    ...state,
    savedBuyTarget: data,
  })),
  on(BuyTargetActions.showProductNotSelectedError, (state) => ({
    ...state,
    productNotSelectedError:
      state.isAccountLinkedToRCE &&
      state.selectedLinkOption === BuyTargetLinkOptionsEnum.PRODUCT &&
      !state.selectedProduct,
  })),
  on(BuyTargetActions.clearBuyTargetState, (state) => ({
    ...state,
    targetId: null,
    isEditMode: false,
    isAccountLinkedToRCE: false,
    showFields: false,
    selectedProduct: null,
    showSelectedProduct: false,
    autoCompleteProductOptions: [],
    productsAreLoading: false,
    selectedLinkOption: BuyTargetLinkOptionsEnum.PRODUCT,
    saveButtonEnabled: false,
    buyTargetData: null,
    productNotSelectedError: false,
  })),
);

export function reducer(state = initialState, action: Action): BuyTargetState {
  return buyTargetPageReducer(state, action);
}
