import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-confirm-button',
  templateUrl: './confirm-button.component.html',
  styleUrls: ['./confirm-button.component.scss'],
  // tslint:disable-next-line:use-component-view-encapsulation
  encapsulation: ViewEncapsulation.None,
})
export class ConfirmButtonComponent implements OnInit {
  @Input() disabled: boolean;
  @Input() width?: number;
  @Input() height?: number;
  @Input() confirmation = false;
  @Input() isStroked?: boolean;
  @Input() borderRadius?: boolean;
  @Input() raisedBtn = false;
  @Input() fullWidth?: boolean;
  @Input() type? = 'submit';

  ngOnInit(): void {}
}
