// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.container .drop-zone {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #f0f5fb;
  border: 2px dotted #c1cbd7;
  border-radius: 4px;
  padding: 1em;
}
.container .drop-zone.hovering {
  border: 2px solid #c1cbd7;
  color: #dadada !important;
}
.container .drop-zone .drag-icon-container {
  min-width: 108px;
  align-items: center;
  justify-content: center;
  padding: 1.5em;
  border: 2px dotted #c1cbd7;
  border-radius: 3px;
}
.container .drop-zone .drag-icon-container .icon {
  font-size: 60px;
  color: rgb(16, 39, 118);
}
.container .drop-zone .margin-bottom {
  margin-bottom: 1.5em;
}
.container .drop-zone .text {
  color: rgb(16, 39, 118);
  font-weight: 600;
  font-size: 16px;
}
.container .drop-zone .text.hovering {
  color: #dadada;
}
.container .drop-zone .browse-btn {
  color: white;
}
.container .drop-zone .browse-btn.hovering {
  background-color: #dadada;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/file-upload/product-image-file-upload/product-image-file-upload.component.scss"],"names":[],"mappings":"AAGA;EACE,WAAA;EACA,YAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,sBAAA;AAFF;AAIE;EACE,WAAA;EACA,YAAA;EACA,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,uBAAA;EACA,yBAAA;EACA,0BAAA;EACA,kBAAA;EACA,YAAA;AAFJ;AAII;EACE,yBAAA;EACA,yBAAA;AAFN;AAKI;EACE,gBAAA;EACA,mBAAA;EACA,uBAAA;EACA,cAAA;EACA,0BAAA;EACA,kBAAA;AAHN;AAKM;EACE,eAAA;EACA,uBAAA;AAHR;AAOI;EACE,oBAAA;AALN;AAQI;EACE,uBAAA;EACA,gBAAA;EACA,eAAA;AANN;AAOM;EACE,cAAA;AALR;AASI;EACE,YAAA;AAPN;AASM;EACE,yBAAA;AAPR","sourcesContent":["@import 'node_modules/@angular/material/theming';\n@import 'variables';\n\n.container {\n  width: 100%;\n  height: 100%;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  flex-direction: column;\n\n  .drop-zone {\n    width: 100%;\n    height: 100%;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    background-color: #f0f5fb;\n    border: 2px dotted #c1cbd7;\n    border-radius: 4px;\n    padding: 1em;\n\n    &.hovering {\n      border: 2px solid #c1cbd7;\n      color: #dadada !important;\n    }\n\n    .drag-icon-container {\n      min-width: 108px;\n      align-items: center;\n      justify-content: center;\n      padding: 1.5em;\n      border: 2px dotted #c1cbd7;\n      border-radius: 3px;\n\n      .icon {\n        font-size: 60px;\n        color: rgb(16 39 118);\n      }\n    }\n\n    .margin-bottom {\n      margin-bottom: 1.5em;\n    }\n\n    .text {\n      color: rgb(16 39 118);\n      font-weight: 600;\n      font-size: 16px;\n      &.hovering {\n        color: #dadada;\n      }\n    }\n\n    .browse-btn {\n      color: white;\n\n      &.hovering {\n        background-color: #dadada;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
