import { Component, Input, OnInit } from '@angular/core';
import { MatLegacyDialogRef } from '@angular/material/legacy-dialog';
import { SimpleConfirmPopupConfiguration } from './simple-confirm-popup.configuration';

@Component({
  selector: 'app-simple-confirm-popup',
  templateUrl: './simple-confirm-popup.component.html',
  styleUrls: ['./simple-confirm-popup.component.scss'],
})
export class SimpleConfirmPopupComponent implements OnInit {
  @Input() configuration: SimpleConfirmPopupConfiguration;

  constructor(public matDialogRef: MatLegacyDialogRef<SimpleConfirmPopupComponent>) {}

  ngOnInit(): void {}

  onCancel(): void {
    this.matDialogRef.close(false);
  }
}
