import { Component, Input } from '@angular/core';

@Component({
  selector: 'provider-not-listed',
  templateUrl: './provider-not-listed.component.html',
  styleUrls: ['./provider-not-listed.component.scss'],
})
export class ProviderNotListedComponent {
  @Input() merchantNotListedUrl = '';
}
