import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { AUTH_SERVICE } from '@core/core.module';
import { AppSettingService } from '@core/services/app-setting.service';
import { AuthServiceInterface } from '@core/services/auth/auth-service-inteface';
import { LoaderService } from '@core/services/loader.service';
import { getUrlWithoutQueryParams } from '@core/utils/url-helper';
import { environment } from '@env/environment';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { combineLatest, Observable } from 'rxjs';
import { filter, switchMap, takeUntil } from 'rxjs/operators';
import { BaseComponent } from '../base-component/base-component';
import { PopupService } from '../popup/popup.service';

type LanguageIso = 'en' | 'de' | 'es' | 'zh-CN' | 'zh-TW';
interface Header {
  name: string;
}
interface Language {
  iso: LanguageIso;
  display: string;
  icon: string;
  lanTag: string;
}
const dictionary: { [key: string]: Header } = {
  '/dashboard': { name: 'MY_DASHBOARD' },
  '/analytics': { name: 'ANALYTICS' },
  '/target-pages': { name: 'MY_TARGET_PAGES' },
  '/help': { name: 'HELP' },
  '/engagement-creation': { name: 'CREATE_AN_ENGAGEMENT' },
  '/engagement-creation/make-payment': { name: 'CREATE_AN_ENGAGEMENT' },
  '/settings/self-on-boarding': { name: 'SETTINGS' },
  '/settings/self-on-boarding/payment-provider-details': { name: 'SETTINGS' },
  '/settings/your-details': { name: 'SETTINGS' },
  '/products': { name: 'PRODUCTS' },
  '/products/dashboard': { name: 'PRODUCTS' },
  '/products/create': { name: 'CREATE_A_NEW_PRODUCT' },
  'products/connect-to-store': { name: 'PRODUCTS' },
  '/locations': { name: 'LOCATIONS' },
  '/metrics': { name: 'METRICS' },
  'e-commerce': { name: 'ECOMMERCE.TITLE' },
};
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent extends BaseComponent implements OnInit, OnDestroy {
  englishFlagClass = environment['englishFlagClass'] ? environment['englishFlagClass'] : 'fi-gb';
  languages: Language[] = [
    { iso: 'en', lanTag: 'en_US', display: 'English', icon: `fi ${this.englishFlagClass}` },
    { iso: 'de', lanTag: 'de', display: 'Deutsch', icon: 'fi fi-de' },
    { iso: 'es', lanTag: 'es', display: 'Español', icon: 'fi fi-es' },
    { iso: 'zh-CN', lanTag: 'zh', display: '中文 (中国)', icon: 'fi fi-cn' },
  ];
  selectedLanguage: Language;
  header: Header = {} as Header;
  defaultHeader: Header = {} as Header;
  email: string;
  loading$: Observable<boolean>;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    @Inject(AUTH_SERVICE) public authService: AuthServiceInterface,
    private popupService: PopupService,
    private translateService: TranslateService,
    public loadingService: LoaderService,
    readonly appSettingService: AppSettingService,
  ) {
    super();

    if (environment.showTraditionalZh) {
      this.languages.push({ iso: 'zh-TW', lanTag: 'zhtw', display: '繁體中文', icon: 'fi fi-tw' });
    }

    this.loading$ = this.loadingService.isLoading('auth');
  }

  ngOnInit(): void {
    this.languageSetup();
    this.loadingService.show('auth', true);
    combineLatest([
      this.authService.isAuthenticated$,
      this.router.events.pipe(filter((e) => e instanceof NavigationEnd)),
    ])
      .pipe(takeUntil(this.destroy$))
      .subscribe(([isAuthenticated, event]: [boolean, NavigationEnd]) => {
        this.header = this.defaultHeader;
        const url = getUrlWithoutQueryParams(event.urlAfterRedirects);
        const header = dictionary[url];
        this.header = header || this.defaultHeader;

        // for nested routing, if for all nested route will use same header
        if (!this.header.name) {
          const initialUrl = url.split('/').filter((x) => !!x)[0];
          this.header = dictionary[initialUrl] || this.defaultHeader;
        }
        this.loadingService.hide('auth');
      });

    // init user profile
    this.authService.userProfile$.pipe(takeUntil(this.destroy$)).subscribe((profile) => {
      if (profile) {
        this.email = profile.email || this.email;
      }
    });
  }

  onSelected(event) {
    this.selectLanguage(event.value);
  }

  selectLanguage(iso: string) {
    this.translateService
      .use(iso)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (_) => {
          this.selectedLanguage = this.languages.find((language) => language.iso === iso);
        },
        () => {
          this.showStatusPopup(false, `FAILED_TO_SWITCH_THE_LANGUAGE_IT_IS_NOT_SUPPORTED_YET`);
        },
      );
  }

  redirectToLogin() {
    this.authService.login('/', { partner: environment.partner });
  }

  private languageSetup() {
    this.selectLanguage(this.appSettingService.language);

    this.translateService.onLangChange.pipe(takeUntil(this.destroy$)).subscribe((langEvent: LangChangeEvent) => {
      this.appSettingService.language = langEvent.lang;
    });

    this.route.queryParams.pipe(takeUntil(this.destroy$)).subscribe((params) => {
      console.log('query params', params, params['lang']);
      const lang = params['lang'];
      if (lang) {
        this.selectLanguage(lang);
      }
    });

    this.appSettingService.isLoaded$.pipe(takeUntil(this.destroy$)).subscribe((_) => {
      this.selectLanguage(this.appSettingService.language);
    });
  }

  private showStatusPopup(isSuccess: boolean, message: string, failureReason?: string): void {
    this.translateService
      .get(message)
      .pipe(
        switchMap((translated: string) => {
          return this.popupService.successStatusPopup({
            componentConfiguration: { isSuccess, message: translated, failureReason },
            height: 'auto',
            width: 'auto',
            maxWidth: '430px',
            backdropClass: 'blurred-backdrop',
          });
        }),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }
}
