import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';
import { MatLegacyDialogModule } from '@angular/material/legacy-dialog';
import { CustomDirectivesModule } from '@core/directives/custom-directives.module';
import { TranslateModule } from '@ngx-translate/core';
import { PageBuildingBlocksPopupComponent } from './page-building-blocks-popup.component';

@NgModule({
  declarations: [PageBuildingBlocksPopupComponent],
  imports: [
    CommonModule,
    FlexLayoutModule,
    MatLegacyButtonModule,
    MatLegacyDialogModule,
    MatIconModule,
    TranslateModule,
    CustomDirectivesModule,
  ],
  exports: [PageBuildingBlocksPopupComponent],
})
export class PageBuildingBlocksPopupModule {}
