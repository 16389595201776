import { Pipe, PipeTransform } from '@angular/core';
@Pipe({ name: 'NumberDivider' })
export class NumberDividerPipe implements PipeTransform {
  constructor() {}

  transform(value: number, divider: number): any {
    if (divider > 0) {
      return value / divider;
    } else {
      return value;
    }
  }
}
