import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexModule } from '@angular/flex-layout';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';
import { MatLegacyDialogModule } from '@angular/material/legacy-dialog';
import { MatLegacySliderModule } from '@angular/material/legacy-slider';
import { MatLegacyTooltipModule } from '@angular/material/legacy-tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { BaseCardContainerModule } from '@shared/components/base-card-container/base-card-container.module';
import { NavigationCardsModule } from '@shared/components/navigation-cards/navigation-cards.module';
import { SharedModule } from '@shared/shared.module';
import { ImageCropperModule } from 'ngx-image-cropper';
import { SavedTargetPagesModule } from './saved-target-pages/saved-target-pages.module';
import { ImageCropperModalComponent } from './shared/image-cropper-modal/image-cropper-modal.component';
import { ThumbnailUploadComponent } from './shared/thumbnail-upload/thumbnail-upload.component';
import { TargetPagesRoutingModule } from './target-pages-routing.module';
import { TargetPagesComponent } from './target-pages.component';
import { MatLegacyProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { ConfirmButtonModule } from '@shared/components/confirm-button/confirm-button.module';
import { MatLegacyFormFieldModule } from '@angular/material/legacy-form-field';

@NgModule({
  declarations: [TargetPagesComponent, ThumbnailUploadComponent, ImageCropperModalComponent],
  imports: [
    CommonModule,
    BaseCardContainerModule,
    FlexModule,
    TargetPagesRoutingModule,
    SavedTargetPagesModule,
    MatIconModule,
    TranslateModule.forChild(),
    MatLegacyButtonModule,
    NavigationCardsModule,
    ImageCropperModule,
    MatLegacyDialogModule,
    MatLegacySliderModule,
    MatLegacyTooltipModule,
    MatLegacyProgressSpinnerModule,
    ConfirmButtonModule,
    SharedModule,
    MatLegacyFormFieldModule,
  ],
  exports: [TargetPagesComponent, ThumbnailUploadComponent, ImageCropperModalComponent],
})
export class TargetPagesModule {}
