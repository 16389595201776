import { ActDTO } from '@core/models/dtos/act-dto';
import { EngagementDTO } from '@core/models/dtos/engagement-dto';
import { EngagementResponsesDTO } from '@core/models/dtos/engagement-response-dto';
import { EngagementValidityPeriodDTO } from '@core/models/dtos/engagement-validiti-period-dto';
import { PaymentInformationDTO } from '@core/models/dtos/payment-information-dto';
import { RelatedItemDTO } from '@core/models/dtos/related-item-dto';
import { DateSelectionTypeEnum } from '@core/models/enums/data-selection-type-enum';
import { EngagementStatusEnum } from '@core/models/enums/engagement-status-enum';
import { EngagementTypeEnum } from '@core/models/enums/engagement-type-enum';
import { EngagementUserStatusEnum } from '@core/models/enums/engagement-user-status-enum';
import { OverallStatusEnum } from '@core/models/enums/overal-status-enum';
import { PaymentStatusEnum } from '@core/models/enums/payment-status-enum';
import { UserEngagementsActions } from '@core/models/enums/user-engagements-actions';
import { Filters } from '@core/models/filters';
import { EnvironmentVariableHelper } from '@core/utils/environment-variable-helper';
import { environment } from '@env/environment';
import * as moment from 'moment';
import { ActTypeEnum } from '../enums/act-type-enum';

export class EngagementViewModel {
  id: string;
  shortId: string;
  name: string;
  paymentStatus: PaymentStatusEnum;
  clientReferenceId: string;
  type: EngagementTypeEnum;
  createdDate: Date;
  purchaseDate: Date;
  status: EngagementStatusEnum;
  dateSelectionType: DateSelectionTypeEnum;
  validityPeriods: EngagementValidityPeriodDTO[] = [];
  responses: EngagementResponsesDTO[] = [];
  relatedItem: RelatedItemDTO;
  relatedItems: RelatedItemDTO[];
  act: ActDTO;
  paymentInformation: PaymentInformationDTO;
  qRCodeEncodedImage: string;
  price: number;
  userStatus: EngagementUserStatusEnum;
  overallStatus: OverallStatusEnum;
  isWatermarkeable: boolean;
  locationReference: any;
  typeLabel: string;
  statusLabel: string;
  overallStatusLabel: string;
  availableActions: UserEngagementsActions[];
  selected: boolean;
  overallStatusColor: string;
  url: string;
  shortUrl: string;
  svgIcon: string;
  getIconName: string;

  static sortingDataAccessor = (item, property) => {
    switch (property) {
      case 'name':
        return item.name;
      case 'trigger':
        return item.type;
      case 'status':
        return item.status;
      case 'purchased':
        return item.purchaseDate.toString();
      case 'start':
        return item.validityPeriods[0].from.toString();
      case 'end':
        return item.validityPeriods[0].to.toString();
      // case 'target': return item.target
      // case 'activity': return item.activity
      default:
        return item[property];
    }
  };

  static getFieldNamesBySort(sortName: string) {
    switch (sortName) {
      case 'name':
        return 'name';
      case 'trigger':
        return 'type';
      case 'status':
        return 'status';
      case 'target':
        return "'relatedItems.0.title'";
      case 'purchased':
        return 'createdDate';
      default:
        return '';
    }
  }

  static filterPredicate = (data: EngagementViewModel, filterString: string): boolean => {
    const { dateRange, typesSelection = [], searchTerm = '' }: Filters = JSON.parse(filterString);
    if (dateRange && moment(data.purchaseDate).isBefore(dateRange)) {
      return false;
    }

    if (typesSelection.length > 0) {
      const selectedTypes = typesSelection.filter((type) => Object.keys(EngagementTypeEnum).includes(type));
      const selectedStatuses = typesSelection.filter((status) => Object.keys(OverallStatusEnum).includes(status));

      if (selectedTypes.length > 0 && selectedStatuses.length > 0) {
        return (
          selectedTypes.includes(EngagementTypeEnum[data.type]) &&
          selectedStatuses.includes(OverallStatusEnum[data.overallStatus])
        );
      } else if (selectedTypes.length > 0) {
        return selectedTypes.includes(EngagementTypeEnum[data.type]);
      } else if (selectedStatuses.length > 0) {
        return selectedStatuses.includes(OverallStatusEnum[data.overallStatus]);
      }
    }

    return data.name.toLowerCase().includes(searchTerm.toLowerCase());
  };

  static getSwitchOverTargetInfo(relatedItems: RelatedItemDTO[]): { title: string; date: string } {
    if (relatedItems.length != 2) {
      return;
    }

    return { title: relatedItems[1].title, date: moment(relatedItems[1].startDate).format('DD-MM-YYYY') };
  }

  constructor(dto: EngagementDTO) {
    this.id = dto.id;
    this.shortId = dto.shortId;
    this.name = dto.name;
    this.clientReferenceId = dto.clientReferenceId;
    this.type = dto.type;
    this.createdDate = dto.createdDate;
    this.purchaseDate = dto.purchaseDate;
    this.status = dto.status;
    this.dateSelectionType = dto.dateSelectionType;
    this.validityPeriods = dto.validityPeriods;
    this.responses = dto.responses;
    this.relatedItem = dto.relatedItem;
    this.act = dto.act;
    this.paymentInformation = dto.paymentInformation;
    this.qRCodeEncodedImage = dto.qRCodeEncodedImage;
    this.price = dto.price;
    this.paymentStatus = dto.paymentStatus;
    this.userStatus = dto.userStatus;
    this.isWatermarkeable = dto.isWatermarkeable;
    this.locationReference = dto.locationReference;
    this.typeLabel = EngagementTypeEnum[dto.type].toUpperCase();
    this.statusLabel = EngagementStatusEnum[dto.status];
    this.selected = false;
    this.availableActions = [UserEngagementsActions.Delete];
    this.overallStatusColor = '';
    this.overallStatus = dto.overallStatus;
    this.relatedItems = dto.relatedItems;
    this.shortUrl = dto.shortUrl;
    this.svgIcon = getIconName(this.type);

    if (this.overallStatus === OverallStatusEnum.Active) {
      if (moment(this.validityPeriods[0].to).isBefore(moment())) {
        this.overallStatus = OverallStatusEnum.Expired;
      } else if (moment(this.validityPeriods[0].from).isAfter(moment())) {
        this.overallStatus = OverallStatusEnum.Scheduled;
      }
    }

    if (this.type === EngagementTypeEnum.Link) {
      this.url = dto.url;
    }

    function getIconName(type): string {
      switch (type) {
        case EngagementTypeEnum.Link:
          return 'engagement-link';
        case EngagementTypeEnum.Image:
          return 'engagement-image';
        case EngagementTypeEnum.Audio:
          return 'engagement-audio';
        case EngagementTypeEnum.Geolocation:
          return 'engagement-geolocation';
        case EngagementTypeEnum.SmartCode:
          return 'engagement-smartcode';
        case EngagementTypeEnum.InstantWeb:
          return 'engagement-instantweb';
        default:
          return 'engagement-link';
      }
    }

    const getQrDownload = () =>
      this.act.actType !== ActTypeEnum.Url &&
      (this.act.actType !== ActTypeEnum.Buy ||
        !!this.act.product ||
        !!this.act.customUrl.match(/(path=)(.+)\/(.+)\/.+/g));

    this.overallStatusLabel = OverallStatusEnum[this.overallStatus].toUpperCase();

    switch (this.overallStatus) {
      case OverallStatusEnum.Active:
        this.overallStatusColor = 'green';
        switch (this.type) {
          case EngagementTypeEnum.Audio:
            this.availableActions.push(UserEngagementsActions.Download);
            break;
          case EngagementTypeEnum.Image:
            this.availableActions.push(UserEngagementsActions.Download);
            break;
          case EngagementTypeEnum.SmartCode:
            this.availableActions.push(UserEngagementsActions.Download);

            if (environment.enableWeChatQRCode && getQrDownload()) {
              this.availableActions.push(UserEngagementsActions.WeChatDownload);
            }
            break;
          case EngagementTypeEnum.Link:
            this.availableActions.push(UserEngagementsActions.CopyURL);
            break;
          case EngagementTypeEnum.InstantWeb:
            this.availableActions.push(UserEngagementsActions.GenerateCodeSnippet);
            break;
          case EngagementTypeEnum.Geolocation:
            this.availableActions.push(UserEngagementsActions.Pause);
        }
        break;
      case OverallStatusEnum.Scheduled:
        this.overallStatusColor = 'yellow';
        break;
      case OverallStatusEnum.Paused:
        this.overallStatusColor = 'gray';
        switch (this.type) {
          case EngagementTypeEnum.Audio:
          case EngagementTypeEnum.Image:
            this.availableActions.push(UserEngagementsActions.Download);
            break;
          case EngagementTypeEnum.SmartCode:
            this.availableActions.push(UserEngagementsActions.Download);
            if (environment.enableWeChatQRCode && getQrDownload()) {
              this.availableActions.push(UserEngagementsActions.WeChatDownload);
            }
            break;
          case EngagementTypeEnum.Link:
            this.availableActions.push(UserEngagementsActions.CopyURL);
            break;
          case EngagementTypeEnum.InstantWeb:
            this.availableActions.push(UserEngagementsActions.GenerateCodeSnippet);
            break;
          case EngagementTypeEnum.Geolocation:
            this.availableActions.push(UserEngagementsActions.Resume);
        }
        break;
      case OverallStatusEnum.Incomplete:
        this.overallStatusColor = 'red';
        this.availableActions.push(UserEngagementsActions.Refresh);
        break;
      case OverallStatusEnum.Expired:
        this.overallStatusColor = 'gray';
        break;
      case OverallStatusEnum.PendingPayment:
        this.availableActions.push(UserEngagementsActions.Pay);
        this.overallStatusColor = 'red';
        break;
      default:
        this.overallStatusColor = 'gray';
        this.overallStatusLabel = OverallStatusEnum[dto.overallStatus];
    }

    this.availableActions = EnvironmentVariableHelper.removeHiddenEngagementActions(this.availableActions);
  }
}
