import { Component, Inject, OnInit } from '@angular/core';
import { AUTH_SERVICE } from '@core/core.module';
import { AuthServiceInterface } from '@core/services/auth/auth-service-inteface';
import { BaseComponent } from '@shared/components/base-component/base-component';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-callback',
  templateUrl: './callback.component.html',
  styleUrls: ['./callback.component.scss'],
})
export class CallbackComponent extends BaseComponent implements OnInit {
  constructor(@Inject(AUTH_SERVICE) private authService: AuthServiceInterface) {
    super();
  }

  ngOnInit(): void {
    this.authService.handleRedirectCallback$.pipe(takeUntil(this.destroy$)).subscribe((a) => console.log(a));
  }
}
