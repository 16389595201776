import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule, FlexModule } from '@angular/flex-layout';
import { RouterModule } from '@angular/router';
import { CustomDirectivesModule } from '@core/directives/custom-directives.module';
import { TranslateModule } from '@ngx-translate/core';
import { LinksContainerComponent } from './links-container.component';

@NgModule({
  declarations: [LinksContainerComponent],
  imports: [
    CommonModule,
    FlexLayoutModule,
    RouterModule,
    FlexModule,
    CustomDirectivesModule,
    TranslateModule.forChild(),
  ],
  exports: [LinksContainerComponent],
})
export class LinksContainerModule {}
