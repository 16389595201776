import { Component, Input, OnChanges, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { DomSanitizer, SafeHtml, SafeResourceUrl, SafeUrl } from '@angular/platform-browser';
import { PageBuildingBlockTypeEnum } from '@core/models/enums/page-building-block-type-enum';
import { PageBuildingBlock } from '@core/models/page-building-block';
import { ActAPIService } from '@core/services/act-api.service';
import { ServicingService } from '@core/services/servicing.service';
import { BaseComponent } from '@shared/components/base-component/base-component';
import { mergeMap, takeUntil } from 'rxjs/operators';
import { LogUtils } from 'src/app/utils/log-utils';
import { PageBuildingBlockPreviewConfiguration } from './page-building-block-preview.configuration';

@Component({
  selector: 'app-page-building-block-preview',
  templateUrl: './page-building-block-preview.component.html',
  styleUrls: ['./page-building-block-preview.component.scss'],
  // tslint:disable-next-line:use-component-view-encapsulation
  encapsulation: ViewEncapsulation.None,
})
export class PageBuildingBlockPreviewComponent extends BaseComponent implements OnChanges {
  @Input() config: PageBuildingBlockPreviewConfiguration;

  PageBuildingBlockType = PageBuildingBlockTypeEnum;
  spinnerLoading: boolean;
  imageUrl: SafeUrl;
  trustedVideoUrl: SafeResourceUrl;
  safeHtmlContent: SafeHtml;
  selectedActThumbnail: string = null;
  selectedActTitle: string;
  selectedActSubtitle: string;

  constructor(
    readonly sanitizer: DomSanitizer,
    readonly servicingService: ServicingService,
    protected actAPIService: ActAPIService,
  ) {
    super();
    this.imageUrl = null;
    this.trustedVideoUrl = null;
    this.selectedActThumbnail = null;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.config) {
      const pageBuildingBlock: PageBuildingBlock = changes.config.currentValue.details;
      if (!pageBuildingBlock) {
        console.error('Page building block data is not provided', pageBuildingBlock);
        return;
      }

      switch (pageBuildingBlock.type) {
        case PageBuildingBlockTypeEnum.Header:
        case PageBuildingBlockTypeEnum.Paragraph:
          this.safeHtmlContent = this.sanitizer.bypassSecurityTrustHtml(pageBuildingBlock.content);
          break;
        case PageBuildingBlockTypeEnum.Image:
        case PageBuildingBlockTypeEnum.Coupon:
          this.loadImage(pageBuildingBlock);
          break;
        case PageBuildingBlockTypeEnum.Video:
          if (pageBuildingBlock.url) {
            this.trustedVideoUrl = this.getTrustedVideoUrl(pageBuildingBlock.url);
          }
          break;
        case PageBuildingBlockTypeEnum.Link:
          this.loadLinkToPage(pageBuildingBlock.content);
          break;
        default:
          break;
      }
    }
  }

  loadLinkToPage(actId: string) {
    if (actId) {
      this.spinnerLoading = true;
      this.actAPIService
        .getAct(actId)
        .pipe(takeUntil(this.destroy$))
        .subscribe((data) => {
          this.selectedActThumbnail = data.thumbnailUrl;
          this.selectedActTitle = data.title;
          this.selectedActSubtitle = data.subtitle;
          this.spinnerLoading = false;
        });
    }
  }

  loadImage(pageBuildingBlock: PageBuildingBlock) {
    if (pageBuildingBlock.filePath) {
      this.loadImageByFilePath(pageBuildingBlock.filePath);
    } else if (pageBuildingBlock.url) {
      this.imageUrl = this.sanitizer.bypassSecurityTrustUrl(pageBuildingBlock.url);
    } else {
      LogUtils.error('Could not load Image because image Url and filePath are missing!');
    }
  }

  loadImageByFilePath(filePath: string): void {
    this.spinnerLoading = true;
    this.servicingService
      .getImage(encodeURIComponent(filePath))
      .pipe(
        takeUntil(this.destroy$),
        mergeMap((image) => {
          const reader = new FileReader();
          reader.readAsDataURL(image);
          return new Promise((resolve) => {
            reader.onloadend = () => {
              resolve(this.sanitizer.bypassSecurityTrustUrl(reader.result.toString()));
            };
          });
        }),
      )
      .subscribe(
        (imageUrl) => {
          this.imageUrl = imageUrl;
          this.spinnerLoading = false;
        },
        (error) => {
          LogUtils.error('Error while loading image!');
          LogUtils.error(error);
          this.spinnerLoading = false;
        },
      );
  }

  getTrustedVideoUrl(embededUrl: string) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(embededUrl);
  }

  onVideoLoadingError(): void {
    this.trustedVideoUrl = null;
  }
}
