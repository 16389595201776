import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule } from '@angular/forms';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';
import { MatLegacyDialogModule } from '@angular/material/legacy-dialog';
import { MatLegacyTooltipModule } from '@angular/material/legacy-tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { ChipFiltersModule } from '@shared/components/chip-filters/chip-filters.module';
import { ConfirmButtonModule } from '@shared/components/confirm-button/confirm-button.module';
import { PaginationModule } from '@shared/components/pagination/pagination.module';
import { SearchInputModule } from '@shared/components/search-input/search-input.module';
import { TableDropdownGroupsModule } from '@shared/components/table-dropdown-groups/table-dropdown-groups.module';
import { FileUploadModule } from 'primeng/fileupload';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { TargetPagesCardModule } from '../../../../../features/target-pages-dashboard/target-pages-card/target-pages-card.module';
import { LinkToPageCustomisationPopupComponent } from './link-to-page-customisation-popup.component';

@NgModule({
  declarations: [LinkToPageCustomisationPopupComponent],
  imports: [
    CommonModule,
    MatLegacyDialogModule,
    MatLegacyButtonModule,
    FormsModule,
    MatDividerModule,
    FlexLayoutModule,
    FileUploadModule,
    ProgressSpinnerModule,
    TranslateModule.forChild(),
    MatLegacyTooltipModule,
    MatIconModule,
    PaginationModule,
    SearchInputModule,
    ChipFiltersModule,
    TableDropdownGroupsModule,
    ConfirmButtonModule,
    TargetPagesCardModule,
  ],
  exports: [LinkToPageCustomisationPopupComponent],
})
export class LinkToPageCustomisationPopupModule {}
