import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Endpoints } from '@core/constants/Endpoints';
import { Observable } from 'rxjs';
import { BaseAPIServiceService } from './http/base-apiservice.service';

@Injectable({
  providedIn: 'root',
})
export class ServicingService extends BaseAPIServiceService {
  constructor(protected http: HttpClient) {
    super(http);
  }

  getImage(path: string): Observable<Blob> {
    return this.http.get(Endpoints.GET_IMAGE + path, {
      responseType: 'blob',
    });
  }
}
