import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';
import { MatLegacyCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatLegacyDialogModule } from '@angular/material/legacy-dialog';
import { TranslateModule } from '@ngx-translate/core';
import { ConfirmButtonModule } from '@shared/components/confirm-button/confirm-button.module';
import { DateTimePickerModule } from '@shared/components/date-time-picker/date-time-picker.module';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { CouponCustomisationPopupComponent } from './coupon-customisation-popup.component';

@NgModule({
  declarations: [CouponCustomisationPopupComponent],
  imports: [
    CommonModule,
    FlexLayoutModule,
    MatLegacyDialogModule,
    MatLegacyButtonModule,
    FormsModule,
    MatLegacyCheckboxModule,
    MatIconModule,
    ProgressSpinnerModule,
    DateTimePickerModule,
    TranslateModule.forChild(),
    ConfirmButtonModule,
  ],
  exports: [CouponCustomisationPopupComponent],
})
export class CouponCustomisationPopupModule {}
