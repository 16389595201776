import { AbstractControl, ValidatorFn, Validators } from '@angular/forms';
import { CardProviderEnum } from '@core/constants/CardProviders';
import { ShopType } from '@core/models/update-merchant-product-config-request';

interface GeneralSettings {
  store_id: string;
  store_url: string;
  cart_id: string;
}

export let urlValidator: ValidatorFn = (control: AbstractControl) => {
  let validUrl = true;

  try {
    new URL(control.value);
  } catch {
    validUrl = false;
  }

  return validUrl ? null : { invalidUrl: 'PLEASE_PROVIDE_A_VALID_URL' };
}

export let ShopifyConnection: ConnectionFormField[] = [
  { labelName: 'API_ACCESS_TOKEN', formControlName: 'shopifyAccessToken', type: 'password', isRequired: true },
];

export let BigCommerceConnection: ConnectionFormField[] = [
  { labelName: 'CLIENT_ID', formControlName: 'bigCommerceApiClientId', type: 'password', isRequired: true },
  { labelName: 'ACCESS_TOKEN', formControlName: 'bigCommerceApiAccessToken', type: 'password', isRequired: true },
  { labelName: 'CONTEXT', formControlName: 'bigCommerceApiContext', type: 'password', isRequired: true },
];

let magentoAuthenticationOptions: IOption[] = [
  {
    label: "bearer_token",
    value: "bearer_token"
  },
  {
    label: "partial_oauth",
    value: "partial_oauth"
  },
]
export let MagentoConnection: ConnectionFormField[] = [
  { labelName: 'BASE_URL', formControlName: 'baseUrl', type: 'text', isRequired: true, validations: [urlValidator] },
  { labelName: 'API_VERSION', formControlName: 'apiVersion', type: 'text', isRequired: true },
  { labelName: 'ROOT_CATEGORY_ID', formControlName: 'rootCategoryId', type: 'password', isRequired: true },
  { labelName: 'API_AUTHENTICATION_METHOD', formControlName: 'apiAuthenticationMethod', type: 'dropDown', isRequired: true, options: magentoAuthenticationOptions},
];

export let MagentoPaymentFields: ConnectionFormField[] = [
  { labelName: 'REMOTE_PAYMENT_METHOD', formControlName: 'remotePaymentMethod', type: 'text', isRequired: true, defaultValue: 'MAGENTO_FORM_FIELDS.PAYPAL_BILLING_AGREEMENT' },
]

export let MagentoStateFields: ConnectionFormField[] = [
  { labelName: 'REMOTE_STATE_COMPLETE', formControlName: 'remoteStateComplete', type: 'text', isRequired: true, defaultValue: 'MAGENTO_FORM_FIELDS.COMPLETE' },
  { labelName: 'REMOTE_STATE_CANCELLED', formControlName: 'remoteStateCancelled', type: 'text', isRequired: true, defaultValue: 'MAGENTO_FORM_FIELDS.CANCELLED' },
  { labelName: 'REMOTE_STATE_CLOSED', formControlName: 'remoteStateClosed', type: 'text', isRequired: true, defaultValue: 'MAGENTO_FORM_FIELDS.CLOSED' },
]

export let MagentoShippingFields: ConnectionFormField[] = [
  { labelName: 'REMOTE_SHIPPING_METHOD', formControlName: 'remoteShippingMethod', type: 'text', isRequired: true, defaultValue: 'MAGENTO_FORM_FIELDS.FLATRATE_FLATRATE' },
]

export let WooCommerceConnection: ConnectionFormField[] = [
  { labelName: 'API_DOMAIN', formControlName: 'apiDomain', type: 'text', isRequired: true, validations: [urlValidator] },
  { labelName: 'PUBLIC_KEY', formControlName: 'publicKey', type: 'password', isRequired: true },
  { labelName: 'SECRET_KEY', formControlName: 'secretKey', type: 'password', isRequired: true },
];

export let BearerToken: ConnectionFormField[] = [
  { labelName: 'ADMIN_USER', formControlName: 'adminUser', type: 'text', isRequired: true},
  { labelName: 'ADMIN_PASSWORD', formControlName: 'adminPassword', type: 'password', isRequired: true },
];

export let PartialOAuth: ConnectionFormField[] = [
  { labelName: 'ACCESS_TOKEN', formControlName: 'accessToken', type: 'password', isRequired: true},
  { labelName: 'ACCESS_TOKEN_SECRET', formControlName: 'accessTokenSecret', type: 'password', isRequired: true },
  { labelName: 'CUSTOMER_KEY', formControlName: 'customerKey', type: 'password', isRequired: true },
  { labelName: 'CUSTOMER_SECRET', formControlName: 'customerSecret', type: 'password', isRequired: true },
];

export let DemandwareConnection: ConnectionFormField[] = [
  { labelName: 'SHORT_CODE', formControlName: 'shortCode', type: 'text', isRequired: true },
  { labelName: 'ORGANIZATION_ID', formControlName: 'organizationId', type: 'text', isRequired: true },
  { labelName: 'REALM_ID', formControlName: 'realmId', type: 'text', isRequired: true },
  { labelName: 'INSTANCE_ID', formControlName: 'instanceId', type: 'text', isRequired: true },
  { labelName: 'SITE_ID', formControlName: 'siteId', type: 'text', isRequired: true },
  { labelName: 'OAUTH_CLIENT_ID', formControlName: 'oauthClientId', type: 'text', isRequired: true },
  { labelName: 'OAUTH_CLIENT_SECRET', formControlName: 'oauthClientSecret', type: 'password', isRequired: true },
  { labelName: 'SLAS_CLIENT_ID', formControlName: 'slasClientId', type: 'text', isRequired: true },
  { labelName: 'SLAS_CLIENT_SECRET', formControlName: 'slasClientSecret', type: 'password', isRequired: true },
  { labelName: 'CATALOG_ID', formControlName: 'catalogId', type: 'text', isRequired: true },
]

export const ShopConnectionDic: { [type in ShopType]: Connection } = {
  'BigcommerceApi': {
    formFields: BigCommerceConnection,
    link: 'https://support.bigcommerce.com/s/article/Store-API-Accounts',
    apiUrl: 'big-commerce',
    logo: 'bigcommerce.png',
    name: 'BigCommerce',
  },
  'Shopify': {
    formFields: ShopifyConnection,
    link: 'https://www.shopify.com/partners/blog/17056443-how-to-generate-a-shopify-api-token',
    apiUrl: 'shopify',
    logo: 'shopify.png',
    name: 'Shopify',
  },
  '3DCartApi': {
    apiUrl: '3d-cart',
    logo: '3dcart.png',
  },
  'AceShop': {
    apiUrl: 'sceShop',
    logo: 'aceshop.png',
  },
  'Amazon': {
    apiUrl: 'amazon',
    logo: 'amazon.png',
  },
  'AspDotNetStorefront': {
    apiUrl: 'aspDotNetStorefront',
    logo: 'aspdotnetstorefront.png',
  },
  'Cscart': {
    apiUrl: 'cs-cart',
    logo: 'cs-cart.png',
  },
  'CommerceHQ': {
    apiUrl: 'commercehq',
    logo: 'commercehq.png',
  },
  'Cubecart': {
    apiUrl: 'cubeCart',
    logo: 'cubecart.png',
  },
  'Demandware': {
    formFields: DemandwareConnection,
    apiUrl: 'demandware',
    logo: 'demandware.png',
    name: 'SalesForce Commerce Cloud',
  },
  'EBay': {
    apiUrl: 'ebay',
    logo: 'ebay.png',
  },
  'ECWid': {
    apiUrl: 'eCWid',
    logo: 'ecwid.png',
  },
  'Etsy': {
    apiUrl: 'etsy',
    logo: 'etsy.png',
  },
  'Gambio': {
    apiUrl: 'gambio',
    logo: 'gambio.png',
  },
  'Hybris': {
    apiUrl: 'hybris',
    logo: 'hybris.png',
  },
  'Interspire': {
    apiUrl: 'interspire',
    logo: 'interspire.png',
  },
  'JooCart': {
    apiUrl: 'jooCart',
    logo: 'joocart.png',
  },
  'Lightspeed Ecommerce': {
    apiUrl: 'lightspeed-ecommerce',
    logo: 'lightspeed.png',
  },
  'Loaded Commerce': {
    apiUrl: 'loaded-commerce',
    logo: 'loaded-commerce.png',
  },
  'Magento1212': {
    formFields: MagentoConnection,
    partialOAuthFields: PartialOAuth,
    bearerAuthFields: BearerToken,
    stateFields: MagentoStateFields,
    paymentMethodFields: MagentoPaymentFields,
    shippingFields: MagentoShippingFields,
    apiUrl: 'magento',
    logo: 'magento.png',
    name: 'Magento',
  },
  'MijoShop': {
    apiUrl: 'mijoshop',
    logo: 'mijoshop.png',
  },
  'Neto': {
    apiUrl: 'neto',
    logo: 'neto.png',
  },
  'Opencart14': {
    apiUrl: 'openCart',
    logo: 'opencart.png',
  },
  'Oscmax2': {
    apiUrl: 'osCMax',
    logo: 'oscmax.png',
  },
  'Oscommerce22ms2': {
    apiUrl: 'osCommerce',
    logo: 'oscommerce.png',
  },
  'Oxid': {
    apiUrl: 'oxid',
    logo: 'oxid.png',
  },
  'Pinnacle': {
    apiUrl: 'pinnacle-cart',
    logo: 'pinnacle-cart.png',
  },
  'PrestaShop': {
    apiUrl: 'prestaShop',
    logo: 'prestashop.png',
  },
  'Shop-Script Premium': {
    apiUrl: 'shop-script-premium',
    logo: 'shop-script.png',
  },
  'Shopware': {
    apiUrl: 'shopware',
    logo: 'shopware.png',
  },
  'Squarespace': {
    apiUrl: 'squarespace',
    logo: 'squarespace.png',
  },
  'Tomatocart': {
    apiUrl: 'tomatoCart',
    logo: 'tomatocart.png',
  },
  'Ubercart': {
    apiUrl: 'ubercart',
    logo: 'ubercart.png',
  },
  'Virtuemart': {
    apiUrl: 'virtueMart',
    logo: 'virtuemart.png',
  },
  'Volusion': {
    apiUrl: 'volusion',
    logo: 'volusion.png',
  },
  'WPecommerce': {
    apiUrl: 'wPEcommerce',
    logo: 'wpe-commerce.png',
  },
  'Walmart': {
    apiUrl: 'walmart',
    logo: 'walmart.png',
  },
  'WebAsyst': {
    apiUrl: 'webAsyst-shop-script',
    logo: 'webasyst.png',
  },
  'Woocommerce': {
    formFields: WooCommerceConnection,
    apiUrl: 'wooCommerce',
    logo: 'woocommerce.png',
    name: 'Woo Commerce',
  },
  'Xcart': {
    apiUrl: 'xCart',
    logo: 'x-cart.png',
  },
  'Xtcommerce': {
    apiUrl: 'xtcommerce',
    logo: 'xt-commerce.png',
  },
  'XtcommerceVeyton': {
    apiUrl: 'xtcommerce-veyton',
    logo: 'xtCommerce Veyton logo.png',
  },
  'Zencart137': {
    apiUrl: 'zencart',
    logo: 'zen-cart.png',
  },
};

export const aci3DSecureConfigurationsDict: { [type in CardProviderEnum]: Aci3DSecureFormConfiguration } = {
  [CardProviderEnum.Visa] : { formFields: getBasic3DSecureFormFields('visa') },
  [CardProviderEnum.MasterCard]: { formFields: getBasic3DSecureFormFields('master') },
  [CardProviderEnum.AmericanExpress]: { formFields: getCommon3DSecureFormFields('amex', false) }
};

export const aci3DSecureCommonConfiguration: Aci3DSecureFormField[] = [
  { labelName: '3D_SECURE.MERCHANT_3D_NAME', formControlName: 'merchant3DName', type: 'text', isRequired: true },
  { labelName: '3D_SECURE.MERCHANT_3D_URL', formControlName: 'merchant3DUrl', type: 'text', isRequired: true },
  { labelName: '3D_SECURE.MERCHANT_ISO_COUNTRY_CODE', formControlName: 'merchantIsoCountryCode', type: 'text', isRequired: true, validations: [ Validators.pattern('^[0-9]{3}$') ] },
  { labelName: '3D_SECURE.MERCHANT_3D_ID', formControlName: 'merchant3DId', type: 'text', isRequired: true },
  { labelName: '3D_SECURE.MERCHANT_3D_CATEGORY', formControlName: 'merchant3DCategory', type: 'text', isRequired: true },
]

export interface ConnectionFormField extends FormField {
  defaultValue?: string;
}
interface FormField {
  labelName: string;
  formControlName: string;
  type: 'text' | 'password' | 'dropDown';
  validations?: ValidatorFn[];
  isRequired?: boolean;
  options?: Array<IOption>;
}

export interface IOption{
  value: string;
  label: string;
}
export interface Connection {
  formFields?: ConnectionFormField[];
  link?: string;
  apiUrl: string;
  logo?: string;
  name?: string;
  bearerAuthFields?: ConnectionFormField[];
  partialOAuthFields?: ConnectionFormField[];
  paymentMethodFields?: ConnectionFormField[];
  stateFields?: ConnectionFormField[];
  shippingFields?: ConnectionFormField[];
}
export interface Aci3DSecureFormField extends FormField {
}
export interface Aci3DSecureFormConfiguration {
  formFields?: Aci3DSecureFormField[];
}

export function getBasic3DSecureFormFields(prefix: string, isRequired = true): Aci3DSecureFormField[] {
  return [
    { labelName: '3D_SECURE.MERCHANT_ACQUIRER_BIN_V2', formControlName: prefix + 'MerchantAcquirerBin_v2', type: 'text', isRequired },
    { labelName: '3D_SECURE.REQUESTOR_ID', formControlName: prefix + 'RequestorId_v2', type: 'text', isRequired },
    { labelName: '3D_SECURE.REQUESTOR_NAME', formControlName: prefix + 'RequestorName', type: 'text', isRequired }
  ];
}

export function getCommon3DSecureFormFields(prefix: string, isRequired = true): Aci3DSecureFormField[] {
  return [
    ...getBasic3DSecureFormFields(prefix, isRequired),
    { labelName: '3D_SECURE.MERCHANT_ID', formControlName: prefix + 'MerchantId', type: 'text', isRequired }
  ];
}

