import { ActDTO } from '@core/models/dtos/act-dto';
import { Product } from '@core/models/dtos/product';
import { BuyTargetLinkOptionsEnum } from '@core/models/enums/buy-target-link-options-enum';
import { createAction, props } from '@ngrx/store';

export const provideTargetIdFromRoute = createAction(
  '[BuyTarget] Provide targetId from Route',
  props<{ targetId: string }>(),
);

export const accountLinkedToRceResult = createAction(
  '[BuyTarget] Account Linked To RCE Result',
  props<{ response: boolean }>(),
);

export const buyTargetDataReceived = createAction(
  '[BuyTarget] Buy Target data is received',
  props<{ response: ActDTO }>(),
);

export const loadAutoCompleteProductOptions = createAction(
  '[BuyTarget] Loading AutoComplete Products Options',
  props<{ products: Product[] }>(),
);

export const changeLinkTargetOption = createAction(
  '[BuyTarget] Changing the Buy Target Link Option',
  props<{ option: BuyTargetLinkOptionsEnum }>(),
);

export const updateSelectedProduct = createAction(
  '[BuyTarget] Update Selected Product',
  props<{ newProduct: Product }>(),
);

export const saveBuyTarget = createAction('[BuyTarget] Saving Buy Target', props<{ data: ActDTO }>());

export const selectProduct = createAction('[BuyTarget] Select Product', props<{ selectedProduct: Product }>());

export const showFields = createAction('[BuyTarget] Showing input fields');

export const hideFields = createAction('[BuyTarget] Hide all input fields');

export const closeProductSearch = createAction('[BuyTarget] Closing Product Search');

export const enableSearchProductsSpinner = createAction('[BuyTarget] Enable Search Products Spinner');

export const disableSearchProductsSpinner = createAction('[BuyTarget] Disable Search Products Spinner');

export const showSelectedProduct = createAction('[BuyTarget] Show Selected Product');

export const hideSelectedProduct = createAction('[BuyTarget] Hide Selected Product');

export const clearSelectedProduct = createAction('[BuyTarget] Clear Selected Product');

export const clearBuyTargetState = createAction('[BuyTarget] Clearing the Buy Target component state');

export const showProductNotSelectedError = createAction(`[BuyTarget] Showing error that Product is not selected`);
