// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.layout {
  padding: 1em 4em;
  height: auto;
}

@media screen and (max-width: 1279px) {
  .layout {
    padding: 1em 2em;
  }
}
@media screen and (max-width: 599px) {
  .layout {
    padding: 6px 10px;
  }
}`, "",{"version":3,"sources":["webpack://./src/app/features/target-page-creation/target-page-creation.component.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,YAAA;AACF;;AAEA;EACE;IACE,gBAAA;EACF;AACF;AAEA;EACE;IACE,iBAAA;EAAF;AACF","sourcesContent":[".layout {\n  padding: 1em 4em;\n  height: auto;\n}\n\n@media screen and (max-width: 1279px) {\n  .layout {\n    padding: 1em 2em;\n  }\n}\n\n@media screen and (max-width: 599px) {\n  .layout {\n    padding: 6px 10px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
