import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { LoaderService } from '@core/services/loader.service';
import { TranslateService } from '@ngx-translate/core';
import {
  FileInfo,
  ProductFileUploadState,
} from '@shared/components/file-upload/product-image-file-upload/product-image-file-upload.component';
import { Observable } from 'rxjs';
import { MediaType } from '@core/models/product/UploadProductMediaRequest';

interface ImagePreviewInfo {
  imageUrl: string;
  tempId: string;
  state: ProductFileUploadState;
  mediaTypes: MediaType[];
}
@Component({
  selector: 'app-product-image-preview',
  templateUrl: './product-image-preview.component.html',
  styleUrls: ['./product-image-preview.component.scss'],
})
export class ProductImagePreviewComponent implements OnChanges, OnInit {
  @Input() files: FileInfo[];
  @Output() loadingCompleted: EventEmitter<boolean> = new EventEmitter();
  @Output() fileUploaded: EventEmitter<FileList> = new EventEmitter();
  @Output() fileDeleted: EventEmitter<string> = new EventEmitter();
  @Output() fileUpdated: EventEmitter<ImagePreviewInfo> = new EventEmitter();

  imageInfos: ImagePreviewInfo[] = [];
  dimensions: string;
  loading$: Observable<boolean>;
  imageTypes: MediaType[] = ['thumbnail', 'image', 'small_image'];

  constructor(public loaderService: LoaderService, protected translateService: TranslateService) {
    this.loading$ = this.loaderService.isLoading();
  }

  ngOnInit() {
    // this.loadFile();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.files && this.files) {
      this.loadFile();
    }
  }

  changeFile(files: FileList) {
    this.fileUploaded.emit(files);
  }

  imageMediaTypeUpdate(imageMediaTypes: MediaType[], imageInfo: ImagePreviewInfo) {
    this.fileUpdated.emit({ ...imageInfo, mediaTypes: imageMediaTypes });
  }

  loadFile(): void {
    // this.loaderService.show();
    const files = this.files.filter((file) => file.state !== 'removed');
    this.imageInfos = [];

    for (let { file, id } of files.filter((x) => !!x.file)) {
      const reader = new FileReader();

      reader.onloadend = () => {
        const imageUrl = reader.result as string;
        this.imageInfos.push({
          imageUrl,
          tempId: id,
          mediaTypes: this.files.find((x) => x.id == id)?.mediaTypes || ['image'],
          state: 'new',
        });
        const image = new Image();
        image.src = imageUrl;
        image.onload = () => {
          this.dimensions = `${300}x${300}`;
          // this.emitLoadingCompleted();
        };
      };
      reader.readAsDataURL(file);
    }
    const additionalImageInfos: ImagePreviewInfo[] = files
      .filter((x) => !!x.url)
      .map((file) => ({ imageUrl: file.url, tempId: file.id, mediaTypes: file.mediaTypes, state: file.state }));
    this.imageInfos = [...this.imageInfos, ...additionalImageInfos];
  }

  removeFile(event: any, id: string) {
    event.preventDefault();
    this.fileDeleted.next(id);
  }

  private emitLoadingCompleted(): void {
    this.loaderService.hide();
    this.loadingCompleted.emit(true);
  }
}
