import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ImagePreviewPopupComponent } from './image-preview-popup.component';

@NgModule({
  declarations: [ImagePreviewPopupComponent],
  imports: [CommonModule],
  exports: [ImagePreviewPopupComponent],
})
export class ImagePreviewPopupModule {}
