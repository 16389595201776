import { Directive, Input, ViewContainerRef } from '@angular/core';

@Directive({
  selector: '[data]',
})
export class NgTemplateDataStoreDirective {
  @Input() data: any;

  constructor(public vcRef: ViewContainerRef) {}
}
