import Route from '@core/constants/route';
import GenericEngagement from '@core/models/engagement/generic-engagement';
import { EngagementTypeEnum } from '@core/models/enums/engagement-type-enum';
import { PricingTargetTypeEnum } from '@core/models/enums/pricing-target-type.enum';
import { PriceInfoModel } from '@core/models/price-info.model';
import { AppSettingService } from '@core/services/app-setting.service';
import { PaymentApiService } from '@core/services/payment-api.service';
import { Observable } from 'rxjs';

export default class SmartCodeEngagement extends GenericEngagement {
  constructor(paymentApiService: PaymentApiService, appSettingService: AppSettingService) {
    super(
      paymentApiService,
      appSettingService,
      EngagementTypeEnum.SmartCode,
      PricingTargetTypeEnum.SmartCodeEngagement,
      [Route.TRIGGERS, Route.SMARTCODE_TRIGGER, Route.TARGETS, Route.CHECKOUT, Route.MAKE_PAYMENT],
    );
  }

  getPayload$() {
    return super.getPayload$();
  }

  getDuration$() {
    return super.getDuration$();
  }

  getPrice(): Observable<PriceInfoModel> {
    return super.getPrice();
  }

  getTrigger() {
    return {
      imageUrl: 'assets/images/confirmation-smartcode-icon.png',
      name: EngagementTypeEnum[EngagementTypeEnum.SmartCode].toUpperCase(),
      type: EngagementTypeEnum.SmartCode,
      svgIcon: 'smartcode',
    };
  }
}
