import { Input } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { MatLegacyDialogRef } from '@angular/material/legacy-dialog';
import { SuccessStatusPopupConfiguration } from './success-status-popup.configuration';

@Component({
  selector: 'app-success-status-popup',
  templateUrl: './success-status-popup.component.html',
  styleUrls: ['./success-status-popup.component.scss'],
})
export class SuccessStatusPopupComponent implements OnInit {
  @Input() configuration: SuccessStatusPopupConfiguration;

  constructor(public matDialogRef: MatLegacyDialogRef<SuccessStatusPopupComponent>) {}

  ngOnInit(): void {}
}
