import { Injectable } from '@angular/core';
import { MatLegacySnackBar, MatLegacySnackBarConfig } from '@angular/material/legacy-snack-bar';
import { SnackBarComponent } from '@shared/components/snack-bar/snack-bar/snack-bar.component';

@Injectable({
  providedIn: 'root',
})
export class SnackBarService {
  constructor(private snackBar: MatLegacySnackBar) {}

  showSuccessMessage(message: string) {
    const configSuccess: MatLegacySnackBarConfig = {
      data: message,
      duration: 5000,
      horizontalPosition: 'right',
      verticalPosition: 'top',
    };
    this.snackBar.openFromComponent(SnackBarComponent, configSuccess);
  }
}
