import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacySnackBarModule } from '@angular/material/legacy-snack-bar';
import { TranslateModule } from '@ngx-translate/core';
import { SnackBarService } from '@shared/components/snack-bar/snack-bar.service';
import { SnackBarComponent } from '@shared/components/snack-bar/snack-bar/snack-bar.component';

@NgModule({
  declarations: [SnackBarComponent],
  imports: [CommonModule, MatLegacySnackBarModule, MatIconModule, TranslateModule.forChild()],
  providers: [SnackBarService],
})
export class SnackBarModule {}
