import { createAction, props } from '@ngrx/store';

interface Aci3DSecureDetailsDto {
  merchant3DName: string,
  merchant3DUrl: string,
  merchantIsoCountryCode: string,
  merchant3DId: string,
  merchant3DCategory: string,
  visaMerchantAcquirerBin_v2: string,
  visaRequestorId_v2: string,
  visaRequestorName: string,
  masterMerchantAcquirerBin_v2: string,
  masterRequestorId_v2: string,
  masterRequestorName: string,
  amexMerchantAcquirerBin_v2: string,
  amexRequestorId_v2: string,
  amexRequestorName: string,
  amexMerchantId: string
}

export const createAci3DSecureDetails = createAction(
  '[CreateAci3DSecureDetails] Create ACI 3D Secure Details',
  props<Aci3DSecureDetailsDto>(),
);

export const skipAci3DSecureDetails = createAction(
  '[SkipAci3DSecureDetails] Skip ACI 3D Secure Details'
);
