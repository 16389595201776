import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@shared/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { ShopSettingsFormComponent } from '../shop-settings-form/shop-settings-form.component';
import { ConnectProductsStoreComponent } from './connect-products-store.component';
import { GenericCreationContainerModule } from '@shared/components/generic-creation-container/generic-creation-container.module';
import { ConnectProductsStoreRoutingModule } from './connect-products-store-routing.module';
import { MatDividerModule } from '@angular/material/divider';

@NgModule({
  declarations: [ConnectProductsStoreComponent, ShopSettingsFormComponent],
  imports: [
    CommonModule,
    SharedModule,
    ConnectProductsStoreRoutingModule,
    GenericCreationContainerModule,
    MatDividerModule,
    TranslateModule.forChild(),
  ],
  exports: [ConnectProductsStoreComponent],
})
export class ConnectProductsStoreModule {}
