export class ChipFilterOptions {
  filters: FilterItem[] = [];
  link?: string;
  linkText?: string;
}

export interface FilterItem {
  name: string;
  value: any;
  icon?: string;
  selected?: boolean;
  elementId?: string;
}
