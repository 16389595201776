import { Component, OnInit } from '@angular/core';
import { GetMerchantInfoResponse } from '@core/models/get-merchant-info-response';
import { MerchantAPIService } from '@core/services/merchant-api.service';
import { BaseComponent } from '@shared/components/base-component/base-component';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'api2-cart-settings',
  templateUrl: './api2-cart-settings.component.html',
  styleUrls: ['./api2-cart-settings.component.scss'],
})
export class Api2CartSettingsComponent extends BaseComponent implements OnInit {
  merchant: GetMerchantInfoResponse;

  constructor(private merchantService: MerchantAPIService) {
    super();
  }

  ngOnInit(): void {
    this.merchantService
      .getMerchantInfo()
      .pipe(takeUntil(this.destroy$))
      .subscribe((merchant) => {
        this.merchant = merchant;
      });
  }
}
