import { Injectable } from '@angular/core';
import { ActDTO } from '../dtos/act-dto';
import { LocationDto } from '../dtos/location-dto';
import { ActViewModel } from '../view-models/act-view-model';
import { LocationViewModel } from '../view-models/location-view-model';
import { Adapter } from './adapter';

@Injectable({
  providedIn: 'root',
})
export class LocationAdapter implements Adapter<LocationViewModel, LocationDto> {
  fromDTO(dto: LocationDto): LocationViewModel {
    return new LocationViewModel(dto);
  }

  toDTO(viewModel: LocationViewModel): LocationDto {
    return viewModel.locationDto;
  }
}
