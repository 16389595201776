import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatLegacyDialogRef } from '@angular/material/legacy-dialog';
import { ActivatedRoute } from '@angular/router';
import { TargetChipFilters, TargetPagesFilters } from '@core/constants/Filters';
import { ActTypeEnum } from '@core/models/enums/act-type-enum';
import { ActViewModel } from '@core/models/view-models/act-view-model';
import { ActAPIService } from '@core/services/act-api.service';
import { LoaderService } from '@core/services/loader.service';
import { Store } from '@ngrx/store';
import { BaseTargetsDashboardComponent } from '@shared/components/base-targets-dashboard-component/base-targets-dashboard-component';
import { ChipFilterOptions } from '@shared/components/chip-filters/chip-filter-options';
import { PaginationComponent } from '@shared/components/pagination/pagination.component';
import { SearchInputComponent } from '@shared/components/search-input/search-input.component';
import { takeUntil } from 'rxjs/operators';
import { clearFilter, updateFilter } from '../../../../../store/actions/filter.actions';
import { getFilterState } from '../../../../../store/selectors/filter.selectors';
import { State } from '../../../../../store/store';
import { BasePageBuilderConfiguration } from '../base-page-builder-configuration';

@Component({
  selector: 'app-link-to-page-customisation-popup',
  templateUrl: './link-to-page-customisation-popup.component.html',
  styleUrls: ['./link-to-page-customisation-popup.component.scss'],
})
export class LinkToPageCustomisationPopupComponent extends BaseTargetsDashboardComponent implements OnInit {
  @Input() configuration: BasePageBuilderConfiguration;
  @ViewChild(PaginationComponent, { static: true }) paginator: PaginationComponent;
  @ViewChild(SearchInputComponent) searchComponent: SearchInputComponent;
  targetPagesFilters = TargetPagesFilters;
  enumTargetTypeFilter = 'enumTypesSelection';
  searchTermTtargetTypeFilter = 'searchTerm';
  filterOptions: ChipFilterOptions = new ChipFilterOptions();
  currentTargetPage: ActViewModel = null;
  chosenTargetPage: ActViewModel;
  searchedEnumValues = null;
  searchedTermValues: any = '';
  defaultOptions: [];

  constructor(
    private readonly store: Store<State>,

    public matDialogRef: MatLegacyDialogRef<LinkToPageCustomisationPopupComponent>,
    readonly actAPIService: ActAPIService,
    readonly loaderService: LoaderService,
    readonly route: ActivatedRoute,
  ) {
    super(actAPIService, loaderService);
    this.configureFilterOptions();
  }

  ngOnInit(): void {
    this.store
      .select(getFilterState)
      .pipe(takeUntil(this.destroy$))
      .subscribe((filterState) => {
        filterState.filterData.forEach((filter) => {
          this.filters[filter.filterId] = filter.data;
          if (filter.filterId === this.enumTargetTypeFilter) {
            this.searchedEnumValues = filter.data;
          }
          if (filter.filterId === this.searchTermTtargetTypeFilter) {
            this.searchedTermValues = filter.data ?? '';
          }
        });
        this.refresh(0, 5);
      });

    this.dataSource
      .connect()
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        this.route.queryParams.pipe(takeUntil(this.destroy$)).subscribe((params) => {
          this.data = data?.filter((act) => act.id !== params.id);
        });
        this.chosenTargetPage = data?.filter((act) => act.id === this.configuration.data.content)[0];
      });
    this.refresh(this.pageIndex, this.pageSize);
  }

  refreshWithFirstPageNavigation = () => this.refresh(this.pageIndex, this.pageSize, true);

  applyEnumFilter(value: string[]) {
    this.filters.typesSelection = value;
    this.store.dispatch(updateFilter({ filterId: this.enumTargetTypeFilter, data: value }));
  }

  applySearchTermFilter(value: string) {
    this.filters.searchTerm = value;
    this.store.dispatch(updateFilter({ filterId: this.searchTermTtargetTypeFilter, data: value }));
  }

  resetFilters() {
    this.searchComponent.searchElement.nativeElement.value = null;
    this.defaultOptions = [];
    this.filters = {};
    this.searchedEnumValues = null;
    this.searchedTermValues = '';
    this.store.dispatch(clearFilter());
    this.applyEnumFilter(undefined);
    this.applySearchTermFilter(undefined);
  }

  onSelectTargetPage(targetPage: ActViewModel): void {
    if (targetPage.id !== this.currentTargetPage?.id) {
      this.chosenTargetPage = targetPage;
    }
  }

  onSubmit(): void {
    this.configuration.data.content = this.chosenTargetPage.id;
    this.configuration.data.text = this.chosenTargetPage.pageName;
    this.matDialogRef.close(this.configuration.data);
  }

  private configureFilterOptions(): void {
    this.filterOptions.filters = TargetChipFilters;
    const selectedItems = this.filterOptions.filters.filter((item) => item.selected);
    this.filters.enumTypesSelection =
      !selectedItems.length || selectedItems[0].value === ActTypeEnum.All ? [] : [selectedItems[0].value];
  }
}
