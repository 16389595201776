export class Merchant {
  id: number;
  region: string;
  userId: string;
  name: string;
  rceMerchantId: string;
  rcePartnerId: string;

  constructor(merchant) {
    this.id = merchant.id;
    this.region = merchant.userId;
    this.name = merchant.name;
    this.rceMerchantId = merchant.rceMerchantId;
    this.rcePartnerId = merchant.rcePartnerId;
  }

  isRceMerchant() {
    return !!this.rceMerchantId && !!this.rceMerchantId;
  }
}
