import Route from '@core/constants/route';
import { AudioFile } from '@core/models/audio-file';
import DurationData from '@core/models/duration-data';
import GenericEngagement from '@core/models/engagement/generic-engagement';
import { EngagementTypeEnum } from '@core/models/enums/engagement-type-enum';
import { PricingTargetTypeEnum } from '@core/models/enums/pricing-target-type.enum';
import { PriceInfoModel } from '@core/models/price-info.model';
import { AppSettingService } from '@core/services/app-setting.service';
import { PaymentApiService } from '@core/services/payment-api.service';
import { Guid } from '@core/utils/Guid';
import * as moment from 'moment';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { AudioEngagementData } from '../audio-engagement-data';

export default class AudioEngagement extends GenericEngagement {
  audios: AudioFile[];
  watermarkStrength: number;
  file: File;

  constructor(paymentApiService: PaymentApiService, appSettingService: AppSettingService) {
    super(paymentApiService, appSettingService, EngagementTypeEnum.Audio, PricingTargetTypeEnum.AudioEngagement, [
      Route.TRIGGERS,
      Route.AUDIO_UPLOAD,
      Route.TARGETS,
      Route.CHECKOUT,
      Route.MAKE_PAYMENT,
    ]);
    this.audios = [];
    const duration = new DurationData();
    duration.endDate = moment().add(1, 'year').toDate();
    this.duration$ = new BehaviorSubject(duration);
  }

  getTrigger() {
    return {
      svgIcon: 'engagement-audio-no-background',
      name: this.file?.name,
      imageUrl: 'assets/images/confirmation-audio-icon.png',
      type: EngagementTypeEnum.Audio,
    };
  }

  getPayload$() {
    return of({
      id: Guid.newGuid(),
      actInformation: this.actInformation,
      activeTrigger: this.activeTrigger,
      activePricingTargetType: this.activePricingTargetType,
      deferredPayment: false,
      watermarkedAudioTrigger: {
        audios: this.audios,
        watermarkStrength: this.watermarkStrength,
      },
    });
  }

  getDuration$() {
    return this.duration$;
  }

  getPrice(): Observable<PriceInfoModel> {
    return super.getPrice();
  }

  updateAudioEngagement(data: AudioEngagementData): void {
    this.audios = data.audios;
    this.watermarkStrength = data.watermarkStrength;
    this.file = data.file;
  }
}
