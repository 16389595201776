import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';
import { MatLegacyMenuModule } from '@angular/material/legacy-menu';
import { MatLegacyTableModule } from '@angular/material/legacy-table';
import { MatLegacyTooltipModule } from '@angular/material/legacy-tooltip';
import { MatSortModule } from '@angular/material/sort';
import { CustomDirectivesModule } from '@core/directives/custom-directives.module';
import { TranslateModule } from '@ngx-translate/core';
import { CustomPipeModule } from '@shared/pipes/custom-pipe.module';
import { DashboardTableComponent } from './dashboard-table.component';

@NgModule({
  declarations: [DashboardTableComponent],
  imports: [
    CommonModule,
    MatLegacyTableModule,
    MatIconModule,
    FlexLayoutModule,
    MatSortModule,
    MatLegacyButtonModule,
    MatLegacyMenuModule,
    MatButtonToggleModule,
    CustomDirectivesModule,
    CustomPipeModule,
    MatLegacyTooltipModule,
    TranslateModule.forChild(),
  ],
  exports: [DashboardTableComponent],
})
export class DashboardTableModule {}
