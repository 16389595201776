import { createSelector } from '@ngrx/store';
import { selectSelfOnboardingState, SelfOnboardingState } from '../index';
import { SelectedPaymentProviderState } from '../reducers/select-payment-provider.reducer';

export const getSelectedPaymentProvider = createSelector(
  selectSelfOnboardingState,
  (state: SelfOnboardingState) => state.selectedPaymentProvider,
);

export const getPaymentProvider = createSelector(
  getSelectedPaymentProvider,
  (state: SelectedPaymentProviderState) => state.selectedPaymentPlatform,
);

export const getSelectedPaymentGateway = createSelector(
  selectSelfOnboardingState,
  (state: SelfOnboardingState) => state.selectedPaymentGatewayProvider,
);

export const getPaymentProviderDetails = createSelector(
  selectSelfOnboardingState,
  (state: SelfOnboardingState) => state.paymentProviderDetails,
);
