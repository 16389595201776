import { createReducer, on } from '@ngrx/store';
import * as DropdownActions from '../actions/chartType.actions';

export interface DropdownState {
  selectedChartTypes: { [key: number]: string };
}

export const initialState: DropdownState = {
  selectedChartTypes: {
    0: 'Donut',
    1: 'Radar',
    2: 'Bar',
    3: 'Polar',
    4: 'Line',
    5: 'Pie',
  },
};

export const dropdownReducer = createReducer(
  initialState,
  on(DropdownActions.setSelectedChartType, (state, { filterId, chartType }) => {
    return { ...state, selectedChartTypes: { ...state.selectedChartTypes, [filterId]: chartType } };
  }),
);
