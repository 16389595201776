import { PaymentProvider } from './PaymentProvider';

export const PaymentProvidersList: PaymentProvider[] = [
  {
    name: 'Computop',
    logoUrl: 'computop-logo.png',
  },
  {
    name: 'American Express',
    logoUrl: 'amex.png',
  },
  {
    name: 'Barclaycard',
    logoUrl: 'barclaycard.png',
  },
  {
    name: 'BNP Paribas',
    logoUrl: 'bnp-paribas.png',
  },
  {
    name: 'BSPayOne',
    logoUrl: 'BS-Payone.png',
  },
  {
    name: 'Concardis',
    logoUrl: 'concardis.png',
  },
  {
    name: 'Card Complete Service Bank AG',
    logoUrl: 'card-complete.png',
  },
  {
    name: 'ECP',
    logoUrl: 'ecp.png',
  },
  {
    name: 'Elavon',
    logoUrl: 'Elavon_Logo.png',
  },
  {
    name: 'EMS',
    logoUrl: 'Europ-Merchant-Services_Logo.png',
  },
  {
    name: 'ePay',
    logoUrl: 'epay.png',
  },
  {
    name: 'Evo',
    logoUrl: 'EVO_Logo.png',
  },
  {
    name: 'First Cash Solution',
    logoUrl: 'firstcash_solution.png',
  },
  {
    name: 'Ingenico',
    logoUrl: 'ingenico.png',
  },
  {
    name: 'Intercard',
    logoUrl: 'Intercard_Logo.png',
  },
  {
    name: 'Icard',
    logoUrl: 'icard.png',
  },
  {
    name: 'IkanoBank',
    logoUrl: 'ikanobank.png',
  },
  {
    name: 'Global Payments',
    logoUrl: 'Global-Payments_Logo.png',
  },
  {
    name: 'BSPayOne',
    logoUrl: 'payone.png',
  },
  {
    name: 'PayPal',
    logoUrl: 'paypal-logo-seow.png',
  },
  {
    name: 'PaySquare',
    logoUrl: 'Paysquare_Logo.png',
  },
  {
    name: 'PayU',
    logoUrl: 'PayU.png',
  },
  {
    name: 'PBS/Nets',
    logoUrl: 'pbs-nets.png',
  },
  {
    name: 'Raiffeisen Bank International',
    logoUrl: 'Raiffeisen.png',
  },
  {
    name: 'SIX',
    logoUrl: 'SIX_Logo.png',
  },
  {
    name: 'Vantiv',
    logoUrl: 'Vantiv_Logo.png',
  },
  {
    name: 'VR Payment',
    logoUrl: 'VR_Payment.png',
  },
  {
    name: 'Worldpay',
    logoUrl: 'worldpay.png',
  },
  {
    name: 'Worldline',
    logoUrl: 'worldline-logo-seow.png',
  },
];
