import { Injectable } from '@angular/core';
import { ActDTO } from '../dtos/act-dto';
import { RceProduct } from '../dtos/rce-product';
import { ActViewModel } from '../view-models/act-view-model';
import { ProductViewModel } from '../view-models/product-view-model';
import { Adapter } from './adapter';

@Injectable({
  providedIn: 'root',
})
export class RceProductAdapter implements Adapter<ProductViewModel, RceProduct> {
  fromDTO(dto: RceProduct): ProductViewModel {
    return new ProductViewModel(dto);
  }

  toDTO(viewModel: ProductViewModel): RceProduct {
    let product = new RceProduct();
    product.enabled = viewModel.enabled;
    product.price = viewModel.price;
    product.quantity = viewModel.quantity;
    product.imageThumbUrl = viewModel.imageThumbUrl;
    product.name = viewModel.name;
    product.sku = viewModel.sku;
    product.productCategories = viewModel.productCategories;
    product.merchantId = viewModel.merchantId;
    product.partnerId = viewModel.partnerId;
    product.description = viewModel.description;
    product.shortDescription = viewModel.shortDescription;
    product.productCategoryLinks = viewModel.productCategoryLinks;
    return product;
  }
}
