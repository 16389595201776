import { Component, OnInit } from '@angular/core';
import { SETTINGS_CARD_PROVIDERS } from '@core/constants/CardProviders';
import { DataViewField } from '@core/models/data-view-field';
import { DataViewTypeEnum } from '@core/models/enums/data-view-type-enum';
import { PaymentGatewayEnum } from '@core/models/enums/payment-gateway-enum';
import { ShopType } from '@core/models/update-merchant-product-config-request';
import { LoaderService } from '@core/services/loader.service';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { BaseComponent } from '@shared/components/base-component/base-component';
import { PopupService } from '@shared/components/popup/popup.service';
import { Observable, of } from 'rxjs';
import { map, switchMap, takeUntil } from 'rxjs/operators';
import { MenuWizardService } from '../menu-wizard/menu-wizard-service/menu-wizard.service';
import { GetPaymentConnectionDictionary, PaymentType } from '../payment-settings/payment-connection-models';
import { selectSelfOnboardingState, SelfOnboardingState } from '../store';
import { createEcommercePlatformConnectionRequest } from '../store/actions/confirmation.actions';
import { OnboardingProgressEnum } from '../store/models/aci-onboarding.models';
import { AciPaymentProviderDetailsState } from '../store/reducers/aci-payment-provider-details.reducer';
import { EcommercePlatformState } from '../store/reducers/ecommerce-platform.reducer';
import { PaymentGatewayDetailsState } from '../store/reducers/payment-gateway-details.reducer';
import { PaymentProviderDetailsState } from '../store/reducers/payment-provider-details.reducer';
import { PlatformDetailsState } from '../store/reducers/platform-details.reducers';
import { SelectedPaymentProviderState } from '../store/reducers/select-payment-provider.reducer';
import { getAciOnboardingProgress } from '../store/selectors/aci-onboarding-progress.select';

interface DataViewFieldGroup {
  fields: DataViewField[];
}

@Component({
  selector: 'app-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.scss'],
})
export class ConfirmationComponent extends BaseComponent implements OnInit {
  DataViewTypeEnum = DataViewTypeEnum;
  confirmationDetailGroups$: Observable<DataViewFieldGroup[]>;

  constructor(
    private readonly store: Store<SelfOnboardingState>,
    private readonly menuWizardService: MenuWizardService,
    private readonly translateService: TranslateService,
    private readonly popupService: PopupService,
    public readonly loaderService: LoaderService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.confirmationDetailGroups$ = this.store.select(selectSelfOnboardingState).pipe(
      map((state) => {
        const paymentDetails =
          state.selectedPaymentProvider.providerName === 'Computop'
            ? ConfirmationComponent.loadPaymentProviderDetails(
                state.selectedPaymentProvider,
                state.paymentProviderDetails,
              )
            : ConfirmationComponent.loadAciPaymentProviderDetails(
                state.selectedPaymentProvider,
                state.aciPaymentProviderDetails,
              );

        const platformDetails: DataViewFieldGroup = {
          fields: ConfirmationComponent.loadPlatformDetails(state.ecommercePlatformDetails, state.platformDetails),
        };

        return [...paymentDetails, platformDetails];
      }),
    );
    this.menuWizardService.nextStepRequest$.pipe(takeUntil(this.destroy$)).subscribe((_) => {
      this.onSubmit();
    });
    this.handleSubmitRequest();
  }

  private handleSubmitRequest(): void {
    this.store
      .select(getAciOnboardingProgress)
      .pipe(takeUntil(this.destroy$))
      .subscribe(({ confirmationStep }) => {
        switch (confirmationStep.state) {
          case OnboardingProgressEnum.Idle:
            this.loaderService.hide();
            break;
          case OnboardingProgressEnum.Success:
            this.menuWizardService.goNext();
            this.loaderService.hide();
            break;

          case OnboardingProgressEnum.Failed:
            this.showWizardSubmitFailed();
            this.loaderService.hide();
            break;

          case OnboardingProgressEnum.Pending:
            this.loaderService.show();
            break;

          default:
            break;
        }
      });
  }

  private showWizardSubmitFailed(): void {
    this.translateService
      .get('FAILED_TO_COMPLETE_ONBOARDING_PROCESS')
      .pipe(
        switchMap((translated) =>
          this.popupService.successStatusPopup({
            componentConfiguration: { isSuccess: false, message: translated },
            height: 'auto',
            width: 'auto',
            maxWidth: '430px',
            backdropClass: 'blurred-backdrop',
          }),
        ),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  onSubmit() {
    this.store.dispatch(createEcommercePlatformConnectionRequest());
  }

  private static loadPlatformDetails(
    selectedPlatformConnection: EcommercePlatformState,
    platformDetails: PlatformDetailsState,
  ): DataViewField[] {
    const details: DataViewField[] = [];
    const selectedPlatform = selectedPlatformConnection?.selectedEcommercePlatform;
    if (!selectedPlatform) {
      return details;
    }

    const platformName = Object.keys(selectedPlatform)[0] as ShopType;
    ConfirmationComponent.addDataViewFieldToList(details, platformName, 'ECOMMERCE_PLATFORM');
    ConfirmationComponent.addDataViewFieldToList(details, platformDetails.storeId, 'NAME_OF_STORE');
    ConfirmationComponent.addDataViewFieldToList(details, platformDetails.storeURL, 'STORE_URL');

    const connection = selectedPlatform[platformName];
    if (connection.formFields) {
      for (const formField of connection.formFields) {
        const value = platformDetails[formField.formControlName];
        ConfirmationComponent.addDataViewFieldToList(
          details,
          value,
          formField.labelName,
          formField.type === 'password' ? DataViewTypeEnum.MASKED_TEXT : DataViewTypeEnum.TEXT,
        );
      }
    }
    if (connection.name == 'Magento') {
      if(platformDetails["apiAuthenticationMethod"] == 'bearer_token'){
        for (const formField of connection.bearerAuthFields) {
          const value = platformDetails[formField.formControlName];
          ConfirmationComponent.addDataViewFieldToList(
            details,
            value,
            formField.labelName,
            formField.type === 'password' ? DataViewTypeEnum.MASKED_TEXT : DataViewTypeEnum.TEXT,
          );
        }  
      }
      else if(platformDetails["apiAuthenticationMethod"] == 'partial_oauth'){
        for (const formField of connection.partialOAuthFields) {
          const value = platformDetails[formField.formControlName];
          ConfirmationComponent.addDataViewFieldToList(
            details,
            value,
            formField.labelName,
            formField.type === 'password' ? DataViewTypeEnum.MASKED_TEXT : DataViewTypeEnum.TEXT,
          );
        }
      }
      for (const formField of connection.stateFields) {
        const value = platformDetails[formField.formControlName];
        ConfirmationComponent.addDataViewFieldToList(
          details,
          value,
          formField.labelName,
          formField.type === 'password' ? DataViewTypeEnum.MASKED_TEXT : DataViewTypeEnum.TEXT,
        );
      }

      for (const formField of connection.paymentMethodFields) {
        const value = platformDetails[formField.formControlName];
        ConfirmationComponent.addDataViewFieldToList(
          details,
          value,
          formField.labelName,
          formField.type === 'password' ? DataViewTypeEnum.MASKED_TEXT : DataViewTypeEnum.TEXT,
        );
      }

      for (const formField of connection.shippingFields) {
        const value = platformDetails[formField.formControlName];
        ConfirmationComponent.addDataViewFieldToList(
          details,
          value,
          formField.labelName,
          formField.type === 'password' ? DataViewTypeEnum.MASKED_TEXT : DataViewTypeEnum.TEXT,
        );
      }
    }
    return details;
  }

  private static loadAciPaymentProviderDetails(
    selectedPaymentProvider: SelectedPaymentProviderState,
    paymentProviderDetails: AciPaymentProviderDetailsState,
  ): DataViewFieldGroup[] {
    const firstGroup: DataViewFieldGroup = { fields: [] };
    const dictionary = GetPaymentConnectionDictionary();

    const connection = dictionary[selectedPaymentProvider.providerName as PaymentType];

    ConfirmationComponent.addDataViewFieldToList(
      firstGroup.fields,
      selectedPaymentProvider.providerName,
      'PAYMENT_PROVIDER',
    );

    (connection.formFields || []).forEach((formField) => {
      const value =
        paymentProviderDetails.paymentProviderDetails &&
        paymentProviderDetails.paymentProviderDetails[formField.formControlName];

      if (value) {
        ConfirmationComponent.addDataViewFieldToList(firstGroup.fields, value, formField.labelName);
      }
    });

    const secondGroup: DataViewFieldGroup = { fields: [] };
    let cardBrands = this.getSelectedBrands(paymentProviderDetails);

    ConfirmationComponent.addDataViewFieldToList(
      secondGroup.fields,
      paymentProviderDetails.currencyForPayment,
      'CURRENCY_FOR_PAYMENTS',
    );

    ConfirmationComponent.addDataViewFieldToList(
      secondGroup.fields,
      cardBrands.join(", "),
      'CARD_PROVIDER',
    );

    ConfirmationComponent.addDataViewFieldToList(secondGroup.fields, paymentProviderDetails.timezone, 'YOUR_TIMEZONE');
    ConfirmationComponent.addDataViewFieldToList(
      secondGroup.fields,
      paymentProviderDetails.phone,
      'YOUR_CUSTOMER_SERVICE_PHONE_NUMBER',
    );
    return [firstGroup, secondGroup];
  }

  private static loadPaymentProviderDetails(
    selectedPaymentProvider: SelectedPaymentProviderState,
    paymentProviderDetails: PaymentProviderDetailsState,
  ): DataViewFieldGroup[] {
    const firstGroup: DataViewFieldGroup = { fields: [] };

    ConfirmationComponent.addDataViewFieldToList(
      firstGroup.fields,
      selectedPaymentProvider.providerName,
      'PAYMENT_PROVIDER',
    );
    ConfirmationComponent.addDataViewFieldToList(
      firstGroup.fields,
      paymentProviderDetails.computopMerchantId,
      'COMPUTOP_MERCHANT_ID',
    );
    ConfirmationComponent.addDataViewFieldToList(
      firstGroup.fields,
      paymentProviderDetails.computopHmacKey,
      'COMPUTOP_HMAC_KEY',
      DataViewTypeEnum.MASKED_TEXT,
    );
    ConfirmationComponent.addDataViewFieldToList(
      firstGroup.fields,
      paymentProviderDetails.computopBlowfishKey,
      'COMPUTOP_BLOWFISH_KEY',
      DataViewTypeEnum.MASKED_TEXT,
    );

    const secondGroup: DataViewFieldGroup = { fields: [] };
    ConfirmationComponent.addDataViewFieldToList(
      secondGroup.fields,
      paymentProviderDetails.currencyForPayment,
      'CURRENCY_FOR_PAYMENTS',
    );
    ConfirmationComponent.addDataViewFieldToList(secondGroup.fields, paymentProviderDetails.timezone, 'YOUR_TIMEZONE');
    ConfirmationComponent.addDataViewFieldToList(
      secondGroup.fields,
      paymentProviderDetails.phone,
      'YOUR_CUSTOMER_SERVICE_PHONE_NUMBER',
    );
    return [firstGroup, secondGroup];
  }

  private static getSelectedBrands(paymentProviderDetails): Array<string>{
    let cardBrands = [];
    for(let elem in paymentProviderDetails){
      if(paymentProviderDetails[elem]){
        for(let card of SETTINGS_CARD_PROVIDERS){
          if(elem === card.key)
            cardBrands.push(card.value);
        }
      }
    }
    return cardBrands;
  }

  private static loadPaymentGatewayDetails(
    selectedPaymentProvider: SelectedPaymentProviderState,
    gatewayDetails: PaymentGatewayDetailsState,
  ): DataViewFieldGroup[] {
    const firstGroup: DataViewFieldGroup = { fields: [] };
    ConfirmationComponent.addDataViewFieldToList(
      firstGroup.fields,
      selectedPaymentProvider.providerName,
      'MERCHANT_ACQUIRER',
    );
    ConfirmationComponent.addDataViewFieldToList(firstGroup.fields, gatewayDetails.merchantCategoryCode, 'MCC');
    ConfirmationComponent.addDataViewFieldToList(
      firstGroup.fields,
      gatewayDetails.merchantIdVisa,
      'MERCHANT_ID_FOR_VISA',
    );
    ConfirmationComponent.addDataViewFieldToList(
      firstGroup.fields,
      gatewayDetails.merchantIdMasterCard,
      'MERCHANT_ID_FOR_MASTERCARD',
    );
    ConfirmationComponent.addDataViewFieldToList(
      firstGroup.fields,
      gatewayDetails.merchantIdAmex,
      'MERCHANT_ID_FOR_AMEX',
    );
    ConfirmationComponent.addDataViewFieldToList(firstGroup.fields, gatewayDetails.contactName, 'CONTACT_NAME');
    ConfirmationComponent.addDataViewFieldToList(firstGroup.fields, gatewayDetails.contactNumber, 'CONTACT_NUMBER');
    ConfirmationComponent.addDataViewFieldToList(firstGroup.fields, gatewayDetails.contactEmail, 'CONTACT_EMAIL');
    ConfirmationComponent.addDataViewFieldToList(firstGroup.fields, gatewayDetails.contactAddress, 'CONTACT_ADDRESS');

    const secondGroup: DataViewFieldGroup = { fields: [] };
    ConfirmationComponent.addDataViewFieldToList(
      secondGroup.fields,
      gatewayDetails.currencyForPayment,
      'CURRENCY_FOR_PAYMENTS',
    );
    ConfirmationComponent.addDataViewFieldToList(secondGroup.fields, gatewayDetails.timezone, 'TIMEZONE');
    ConfirmationComponent.addDataViewFieldToList(
      secondGroup.fields,
      gatewayDetails.phone,
      'CUSTOMER_SERVICE_PHONE_NUMBER',
    );
    return [firstGroup, secondGroup];
  }

  private static addDataViewFieldToList(
    list: DataViewField[],
    value: any,
    label: string,
    viewType: DataViewTypeEnum = DataViewTypeEnum.TEXT,
  ) {
    if (!value) {
      return;
    }

    list.push({ value, label, type: viewType });
  }
}
