import { VideoPlayerResources } from '@core/constants/VideoPlayerResources';

export class VideoUrlEmbedder {
  static getEmbededUrl(url: string): string {
    let result: string = null;

    result = this.getEmbededYouTubeUrl(url);
    if (result) {
      return result;
    }

    result = this.getEmbededVimeoUrl(url);
    if (result) {
      return result;
    }

    result = this.getEmbededYoukuUrl(url);
    if (result) {
      return result;
    }

    return null;
  }

  static getYouTubeId(url: string): string {
    const match = url.match(/(?:(?:v|vi|be|videos|embed)\/(?!videoseries)|(?:v|ci)=)([\w-]{11})/i);
    return match ? match[1] : undefined;
  }

  static getEmbededYouTubeUrl(url: string): string {
    const youtubeId = this.getYouTubeId(url);
    const autoPlay = '?autoplay=1';
    const addAutoPlaySuffix = url.includes(autoPlay) ? autoPlay : '';
    if (youtubeId) {
      return VideoPlayerResources.YOUTUBE + youtubeId + addAutoPlaySuffix;
    }
    return null;
  }

  static getVimeoId(url: string): string {
    const match = url.match(/(?:\/(?:channels\/[\w]+|(?:(?:album\/\d+|groups\/[\w]+)\/)?videos?))?\/(\d+)/i);
    return match ? match[1] : undefined;
  }

  static getEmbededVimeoUrl(url: string): string {
    const vimeoId = this.getVimeoId(url);
    const autoPlay = '?autoplay=1';
    const addAutoPlaySuffix = url.includes(autoPlay) ? autoPlay : '';
    if (vimeoId) {
      return VideoPlayerResources.VIMEO + vimeoId + addAutoPlaySuffix;
    }
    return null;
  }

  static getYoukuId(url: string) {
    const match = url.match(/(?:(?:embed|sid)\/|v_show\/id_|VideoIDS=)([a-zA-Z0-9]+)/);
    return match ? match[1] : undefined;
  }

  static getEmbededYoukuUrl(url: string): string {
    const youkuId = this.getYoukuId(url);
    if (youkuId) {
      return VideoPlayerResources.YOUKU + youkuId;
    }
    return null;
  }
}
