import { TranslateService } from '@ngx-translate/core';

export const DateFormats = {
  EN_DE_CALENDAR_DATE_FORMAT: 'dd.mm.yy',
  ES_CALENDAR_DATE_FORMAT: 'dd/mm/yy',
  CN_TW_CALENDAR_DATE_FORMAT: 'yy-mm-dd',

};

export function getDateFormat(translateService: TranslateService): string {
  const language = translateService.currentLang;
  switch (language) {
    case 'en' :
      return DateFormats.EN_DE_CALENDAR_DATE_FORMAT;
      case 'de':
        return DateFormats.EN_DE_CALENDAR_DATE_FORMAT;
      case 'es':
      return DateFormats.ES_CALENDAR_DATE_FORMAT;
    case 'zh-CN' :
      return DateFormats.CN_TW_CALENDAR_DATE_FORMAT;
      case 'zh-TW':
      return DateFormats.CN_TW_CALENDAR_DATE_FORMAT;
    default:
      return DateFormats.EN_DE_CALENDAR_DATE_FORMAT;
  }
}
