import BaseEngagement from '@core/models/engagement/base-engagement';
import { EngagementTypeEnum } from '@core/models/enums/engagement-type-enum';
import { PricingTargetTypeEnum } from '@core/models/enums/pricing-target-type.enum';
import { PriceInfoModel } from '@core/models/price-info.model';
import { AppSettingService } from '@core/services/app-setting.service';
import { PaymentApiService } from '@core/services/payment-api.service';
import { Guid } from '@core/utils/Guid';
import * as moment from 'moment';
import { BehaviorSubject, Observable, of } from 'rxjs';
import DurationData from '../duration-data';

export default class GenericEngagement extends BaseEngagement {
  constructor(
    private paymentApiService: PaymentApiService,
    appSettingService: AppSettingService,
    triggerType: EngagementTypeEnum,
    activePricingTargetType: PricingTargetTypeEnum,
    creationSteps: string[],
  ) {
    super(appSettingService, triggerType, activePricingTargetType, creationSteps);
    const tempDuration = new DurationData();
    tempDuration.endDate = moment().add(1, 'year').toDate();
    this.duration$ = new BehaviorSubject(tempDuration);
  }

  getDuration$(): Observable<any> {
    return this.duration$;
  }

  getPayload$(): Observable<any> {
    return of({
      id: Guid.newGuid(),
      actInformation: this.actInformation,
      activeTrigger: this.activeTrigger,
      activePricingTargetType: this.activePricingTargetType,
      deferredPayment: false,
    });
  }

  getPrice(): Observable<PriceInfoModel> {
    return this.paymentApiService.getPrice(this.activePricingTargetType);
  }

  getTrigger() {}
}
