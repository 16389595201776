// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container {
  display: flex;
  justify-content: center;
  padding-top: 64px;
}

.main-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  gap: 24px;
}

.text-container {
  text-align: center;
}
.text-container h1 {
  font-size: 26px;
  line-height: 32px;
}
.text-container p {
  font-size: 16px;
  line-height: 24px;
}

.action-btn {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  align-items: center;
  gap: 16px;
}

.img-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

img {
  width: 100%;
  max-width: 400px;
}

@media screen and (max-width: 425px) {
  img {
    width: 70%;
  }
}`, "",{"version":3,"sources":["webpack://./src/app/features/welcome-onboarder/welcome-onboarder.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,uBAAA;EACA,iBAAA;AACF;;AAEA;EACE,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,qBAAA;EACA,SAAA;AACF;;AAEA;EACE,kBAAA;AACF;AAAE;EACE,eAAA;EACA,iBAAA;AAEJ;AAAE;EACE,eAAA;EACA,iBAAA;AAEJ;;AAEA;EACE,aAAA;EACA,sBAAA;EACA,iBAAA;EACA,qBAAA;EACA,mBAAA;EACA,SAAA;AACF;;AAEA;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;AACF;;AAEA;EACE,WAAA;EACA,gBAAA;AACF;;AAEA;EACE;IACE,UAAA;EACF;AACF","sourcesContent":[".container {\n  display: flex;\n  justify-content: center;\n  padding-top: 64px;\n}\n\n.main-content {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  align-content: center;\n  gap: 24px\n}\n\n.text-container {\n  text-align: center;\n  h1 {\n    font-size: 26px;\n    line-height: 32px;\n  }\n  p {\n    font-size: 16px;\n    line-height: 24px;\n  }\n}\n\n.action-btn {\n  display: flex;\n  flex-direction: column;\n  flex-wrap: nowrap;\n  align-content: center;\n  align-items: center;\n  gap: 16px;\n}\n\n.img-container {\n  display: flex;\n  flex-direction: row;\n  justify-content: center;\n}\n\nimg {\n  width: 100%;\n  max-width: 400px;\n}\n\n@media screen and (max-width: 425px) {\n  img {\n    width: 70%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
