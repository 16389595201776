import { Injectable } from '@angular/core';
import { ActDTO } from '../dtos/act-dto';
import { ActViewModel } from '../view-models/act-view-model';
import { Adapter } from './adapter';

@Injectable({
  providedIn: 'root',
})
export class ActAdapter implements Adapter<ActViewModel, ActDTO> {
  fromDTO(dto: ActDTO): ActViewModel {
    return new ActViewModel(dto);
  }

  toDTO(viewModel: ActViewModel): ActDTO {
    return viewModel.ActDTO;
  }
}
