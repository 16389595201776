import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { Store } from '@ngrx/store';
import { BaseComponent } from '@shared/components/base-component/base-component';
import { takeUntil } from 'rxjs/operators';
import { getFilterState, State } from 'src/app/store/store';

@Component({
  selector: 'app-table-dropdown-groups',
  templateUrl: './table-dropdown-groups.component.html',
  styleUrls: ['./table-dropdown-groups.component.scss'],
  // tslint:disable-next-line:use-component-view-encapsulation
  encapsulation: ViewEncapsulation.None,
})
export class TableDropdownGroupsComponent extends BaseComponent implements OnInit {
  items: { filterId: string; data: [] };

  @Input() multiple: boolean = true;
  @Input() filterId: string;
  @Input() icon: string;
  @Input() options: any[];
  @Input() placeholder: string;
  @Input() defaultSelectionLabel?: string;
  @Input() defaultOptions: [] = [];
  @Output() selected: EventEmitter<any> = new EventEmitter();

  constructor(private readonly store: Store<State>) {
    super();
  }
  ngOnInit(): void {
    this.store
      .select(getFilterState)
      .pipe(takeUntil(this.destroy$))
      .subscribe((filterState) => {
        this.items = filterState.filterData.find((f) => f.filterId === this.filterId);
        this.defaultOptions = this.items?.data || [];
      });
  }

  search() {
    this.selected.emit(this.defaultOptions);
  }
}
