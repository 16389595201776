import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ExtendedModule, FlexLayoutModule, FlexModule } from '@angular/flex-layout';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';
import { MatLegacyListModule } from '@angular/material/legacy-list';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { CustomDirectivesModule } from '@core/directives/custom-directives.module';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from '@shared/shared.module';
import { NavbarComponent } from './navbar.component';

@NgModule({
  declarations: [NavbarComponent],
  exports: [NavbarComponent],
  imports: [
    CommonModule,
    BrowserAnimationsModule,
    SharedModule,
    ExtendedModule,
    MatToolbarModule,
    MatIconModule,
    MatSidenavModule,
    MatLegacyListModule,
    RouterModule,
    FlexModule,
    FlexLayoutModule,
    MatLegacyButtonModule,
    CustomDirectivesModule,
    TranslateModule.forChild(),
  ],
})
export class NavbarModule {}
