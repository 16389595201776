import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatLegacyTableDataSource } from '@angular/material/legacy-table';
import { MatSort } from '@angular/material/sort';
import { BatchActions, DateRangeFilter, EngagementsFilters } from '@core/constants/Filters';
import { EngagementTypeEnum } from '@core/models/enums/engagement-type-enum';
import { OverallStatusEnum } from '@core/models/enums/overal-status-enum';
import { UserEngagementsActions } from '@core/models/enums/user-engagements-actions';
import { Filters } from '@core/models/filters';
import { GetMerchantInfoResponse } from '@core/models/get-merchant-info-response';
import { EngagementViewModel } from '@core/models/view-models/engagement-view-model';
import { EngagementAPIService } from '@core/services/engagement-api.service';
import { LoaderService } from '@core/services/loader.service';
import { MerchantAPIService } from '@core/services/merchant-api.service';
import { PaginationHelper } from '@core/utils/pagination-helper';
import { Store } from '@ngrx/store';
import { BaseComponent } from '@shared/components/base-component/base-component';
import { PaginationComponent } from '@shared/components/pagination/pagination.component';
import { SearchInputComponent } from '@shared/components/search-input/search-input.component';
import { BehaviorSubject, Observable } from 'rxjs';
import { finalize, map, takeUntil } from 'rxjs/operators';
import { clearFilter, updateFilter } from '../../store/actions/filter.actions';
import { getFilterState, State } from '../../store/store';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent extends BaseComponent implements OnInit, AfterViewInit {
  loading$: Observable<boolean>;
  allSelected: Observable<boolean>;
  someSelected: Observable<boolean>;
  availableActions: Observable<any>;
  merchant$: Observable<GetMerchantInfoResponse>;

  dataSource: MatLegacyTableDataSource<EngagementViewModel> = new MatLegacyTableDataSource([]);
  showDashboard: boolean = false;
  engagements: BehaviorSubject<EngagementViewModel[]>;
  actions: Observable<any>;
  filters: Filters = {};
  dashboardTypeFilter = 'typesSelection';
  dashboardDateRangeFilter = 'dashboardDateRange';
  batchActions = 'batchActions';
  @ViewChild(PaginationComponent, { static: true }) paginator: PaginationComponent;
  sort: MatSort;
  @ViewChild(MatSort) set matSort(matSort: MatSort) {
    this.sort = matSort;
  }
  @ViewChild(SearchInputComponent) searchComponent: SearchInputComponent;

  dateRangeFilter = DateRangeFilter;
  engagementsFilters = EngagementsFilters;
  pageSizeOptions: number[] = [];
  defaultOptions: [] = [];
  items: { filterId: string; data: [] };
  searchedValues = null;
  batchActionsFilters: [];
  constructor(
    private engagementService: EngagementAPIService,
    public loaderService: LoaderService,
    private readonly store: Store<State>,
    private merchantApiService: MerchantAPIService,
  ) {
    super();
    this.engagements = this.dataSource.connect();
    this.merchant$ = this.merchantApiService.getMerchantInfo();
    this.allSelected = this.engagements.pipe(map((d) => d.length && d.every((e) => e.selected)));
    this.someSelected = this.engagements.pipe(map((d) => !d.every((e) => e.selected) && d.some((e) => e.selected)));
    this.loading$ = this.loaderService.isLoading();
    this.availableActions = this.engagements.pipe(
      map((d) =>
        BatchActions.filter((a) => d.filter((e) => e.selected).every((e) => e.availableActions.includes(a.value))),
      ),
    );
    this.availableActions.forEach((action) => (this.batchActionsFilters = action));
  }

  ngAfterViewInit(): void {
    this.paginator.matPaginator.page.pipe(takeUntil(this.destroy$)).subscribe((page) => {
      this.refresh(page.pageIndex, page.pageSize);
    });
    this.sort.sortChange.pipe(takeUntil(this.destroy$)).subscribe((sort) => {
      this.refresh(this.paginator.matPaginator.pageIndex, this.paginator.matPaginator.pageSize);
    });
  }

  ngOnInit() {
    this.store
      .select(getFilterState)
      .pipe(takeUntil(this.destroy$))
      .subscribe((filterState) => {
        filterState.filterData.forEach((filter) => {
          this.filters[filter.filterId] = filter.data;
          if (filter.filterId === this.dashboardTypeFilter || filter.filterId === this.dashboardDateRangeFilter) {
            this.searchedValues = filter.data;
            this.showDashboard = true;
          }
        });
        this.refresh(0, 10);
      });
  }

  refresh(pageIndex: number, pageSize: number) {
    this.loaderService.show();
    const typesSelection = (this.filters.typesSelection || []).filter(
      (x) => Object.keys(EngagementTypeEnum).indexOf(x) !== -1,
    );
    const statuses = (this.filters.typesSelection || []).filter(
      (x) => Object.keys(OverallStatusEnum).indexOf(x) !== -1,
    );

    this.engagementService
      .getUserEngagements(
        pageIndex * pageSize,
        pageSize,
        typesSelection.map((type) => EngagementTypeEnum[type]),
        statuses,
        this.filters.dateRange,
        this.filters.searchTerm,
        this.sort && this.sort.active && this.sort.start
          ? {
              fieldName: EngagementViewModel.getFieldNamesBySort(this.sort.active),
              ascending: this.sort.direction === 'asc',
            }
          : null,
      )
      .pipe(
        takeUntil(this.destroy$),
        finalize(() => this.loaderService.hide()),
      )
      .subscribe((response) => {
        this.dataSource.data = response.data;
        this.dataSource.filterPredicate = EngagementViewModel.filterPredicate;
        this.dataSource.sortingDataAccessor = EngagementViewModel.sortingDataAccessor;
        this.pageSizeOptions = PaginationHelper.getPageSizeOptions(response.total);
        this.paginator.length = response.total;
        this.showDashboard = this.showDashboard || this.dataSource.data.length > 0;
      });
  }

  applyFilter(value: string[], type: string) {
    switch (type) {
      case (type = 'typesSelection'):
        if (value.length === 0) {
          this.searchedValues = null;
          this.store.dispatch(updateFilter({ filterId: this.dashboardTypeFilter, data: null }));
        } else {
          this.filters.typesSelection = value;
          this.store.dispatch(updateFilter({ filterId: this.dashboardTypeFilter, data: value }));
        }
        break;
      case (type = 'dashboardDateRange'):
        if (value === undefined) {
          this.searchedValues = null;
          this.filters.dateRange = undefined;
          this.store.dispatch(updateFilter({ filterId: this.dashboardDateRangeFilter, data: null }));
        } else {
          this.filters.dateRange = value.toString();
          this.store.dispatch(updateFilter({ filterId: this.dashboardDateRangeFilter, data: value }));
        }
        break;
      case (type = 'searchTerm'):
        this.filters.searchTerm = value.toString();
        break;
    }
    console.log(this.paginator);
    this.refresh(this.paginator.matPaginator.pageIndex, this.paginator.matPaginator.pageSize);
  }

  resetFilters() {
    this.searchComponent.searchElement.nativeElement.value = null;
    this.defaultOptions = null;
    this.filters = {};
    this.searchedValues = null;
    this.store.dispatch(clearFilter());
    this.applyFilter(undefined, 'dateRange');
  }

  selectAll(value: boolean) {
    const updatedData = Array.from(this.engagements.value).map((e) => ({ ...e, selected: value }));
    this.engagements.next([...updatedData]);
  }

  selectOne(engagement: EngagementViewModel) {
    this.engagements.next(this.updateData(engagement, this.engagements.value));
  }

  updateEngagement(engagement: EngagementViewModel) {
    this.dataSource.data = this.updateData(engagement, this.dataSource.data);
  }

  refreshEngagements() {
    this.refresh(this.paginator.matPaginator.pageIndex, this.paginator.matPaginator.pageSize);
  }

  batchAction(action: UserEngagementsActions) {
    const selectedEngagements = this.engagements.value.filter((e) => e.selected).map((x) => x.id);
    switch (action) {
      case UserEngagementsActions.Delete:
        this.engagementService
          .batchDeleteEngagement(selectedEngagements)
          .pipe(takeUntil(this.destroy$))
          .subscribe(() => this.refreshEngagements());
        break;
      case UserEngagementsActions.Resume:
        this.engagementService
          .batchUpdateEngagement(selectedEngagements, '0')
          .pipe(takeUntil(this.destroy$))
          .subscribe(() => this.refreshEngagements());
        break;
      case UserEngagementsActions.Pause:
        this.engagementService
          .batchUpdateEngagement(selectedEngagements, '1')
          .pipe(takeUntil(this.destroy$))
          .subscribe(() => this.refreshEngagements());
        break;
      case UserEngagementsActions.Download:
        this.engagements.value
          .filter((e) => e.selected)
          .forEach((e) => {
            this.engagementService
              .downloadWatermarkedFile(e.id, e.type)
              .pipe(takeUntil(this.destroy$))
              .subscribe((url) => {
                window.open(url);
              });
          });
        break;
    }
  }

  private updateData = (engagement: EngagementViewModel, currentData: EngagementViewModel[]) => {
    const updatedData = Array.from(currentData);
    const objIndex = updatedData.findIndex((obj) => obj.id === engagement.id);
    updatedData[objIndex] = engagement;
    return updatedData;
  };
}
