import { Component, OnInit } from '@angular/core';
import { DateFormats } from '@core/constants/DateFormats';
import { LoaderService } from '@core/services/loader.service';
import { Observable } from 'rxjs';
import { Data, MultilineOption } from './ChartConfig';

@Component({
  selector: 'app-metrics',
  templateUrl: './metrics.component.html',
  styleUrls: ['./metrics.component.scss'],
})
export class MetricsComponent implements OnInit {
  loading$: Observable<boolean>;

  multilineOptions: Partial<MultilineOption>;
  multilineOptionsResult: Partial<MultilineOption>;
  dateFormat = DateFormats.EN_DE_CALENDAR_DATE_FORMAT;
  selectedDateRange: Date[];
  engagements: Data;
  targets: Data;
  status: Data;
  generatedNotifications: Data;
  tappedNotifications: Data;
  pageViews: Data;
  isDropdownVisible = false;
  chartDataArray: { id: string; label: string; identifier: number; data: Data }[];

  constructor(public loaderService: LoaderService) {
    this.loading$ = this.loaderService.isLoading();

    this.engagements = {
      series: [121, 34, 3, 37, 87, 56],
      labels: ['Location', 'Image', 'Audio', 'SmartLink', 'SmartCode', 'Instant Checkout'],
    };

    this.targets = {
      series: [124, 56, 78, 4],
      labels: ['Info', 'Act', 'Buy', 'Link'],
    };

    this.status = {
      series: [154, 128, 83, 64],
      labels: ['Sent', 'Delivered', 'Opened', 'Followed'],
    };

    this.generatedNotifications = {
      series: [130, 72, 100, 170, 140, 85, 110, 150, 140, 130, 100, 135, 90, 250, 60],
      labels: 'Notifications Generated',
    };

    this.tappedNotifications = {
      series: [10, 96, 21, 156, 221, 73, 198, 241, 84, 175, 227, 279, 10, 69, 164],
      labels: 'Notifications Tapped',
    };

    this.pageViews = {
      series: [654, 345, 581, 736, 617, 582, 710, 432, 846, 683, 723, 595, 323, 743, 799],
      labels: 'Page Views',
    };

    this.chartDataArray = [
      { id: 'donut', label: 'ENGAGEMENT', identifier: 0, data: this.engagements },
      { id: 'radar', label: 'TARGET', identifier: 1, data: this.targets },
      { id: 'bar', label: 'STATUS', identifier: 2, data: this.status },
      { id: 'polar', label: 'ENGAGEMENT', identifier: 3, data: this.engagements },
      { id: 'line', label: 'TARGET', identifier: 4, data: this.targets },
      { id: 'pie', label: 'STATUS', identifier: 5, data: this.status },
    ];

    this.multilineOptions = {
      series: [
        {
          name: this.generatedNotifications.labels.toString(),
          data: this.generatedNotifications.series,
        },
        {
          name: this.tappedNotifications.labels.toString(),
          data: this.tappedNotifications.series,
        },
        {
          name: this.pageViews.labels.toString(),
          data: this.pageViews.series,
        },
      ],
      chart: {
        type: 'area',
        height: 350,
        width: '100%',
        stacked: false,
        toolbar: {
          show: false,
        },
      },
      colors: ['#008FFB', '#00E396', '#bd0b0b'],
      xaxis: {
        type: 'datetime',
      },
      stroke: {
        curve: 'straight',
      },
      dataLabels: {
        enabled: false,
      },
      legend: {
        show: true,
        position: 'bottom',
        horizontalAlign: 'left',
        offsetX: 16,
        offsetY: 5,
      },
    };
  }

  ngOnInit(): void {
    this.getDefaultRangeValue();
  }

  getDefaultRangeValue() {
    this.selectedDateRange = [new Date('2023-09-01'), new Date('2023-09-15')];
    this.updateMultilineOptions();
  }

  onSelectedDateRangeChange(selectedDateRange: Date[]) {
    this.selectedDateRange = selectedDateRange;
    if (this.selectedDateRange[1] && this.selectedDateRange[0].getDate() !== this.selectedDateRange[1].getDate()) {
      this.updateMultilineOptions();
    }
  }

  updateMultilineOptions() {
    const startDate = new Date(this.selectedDateRange[0]);
    const endDate = this.selectedDateRange[1];
    const updatedSeries = [];

    for (const series of this.multilineOptions.series) {
      const updatedData = [];
      for (let i = 0, date = new Date(startDate); date <= endDate; i++, date.setDate(date.getDate() + 1)) {
        const dataPoint: any = [date.getTime(), series.data[i]];
        updatedData.push(dataPoint);
      }

      const updatedSeriesItem = { ...series, data: updatedData };
      updatedSeries.push(updatedSeriesItem);
    }

    this.multilineOptionsResult = {
      ...this.multilineOptions,
      series: updatedSeries,
    };
  }

  toggleElement() {
    this.isDropdownVisible = !this.isDropdownVisible;
  }
}
