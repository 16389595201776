import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Event as NavigationEvent, NavigationStart, Router } from '@angular/router';
import { EngagementCreationWizardSteps } from '@core/constants/EngagementCreationWizardSteps';
import Route from '@core/constants/route';
import BaseEngagement from '@core/models/engagement/base-engagement';
import { EngagementTypeEnum } from '@core/models/enums/engagement-type-enum';
import { EngagementViewModel } from '@core/models/view-models/engagement-view-model';
import { AppSettingService } from '@core/services/app-setting.service';
import { Observable, Subscription } from 'rxjs';
import {filter, shareReplay} from 'rxjs/operators';
import { EngagementCreationService } from '../../../features/engagement-creation/engagement-creation.service';
import {PriceInfoModel} from "@core/models/price-info.model";

@Component({
  selector: 'app-creation-container',
  templateUrl: './creation-container.component.html',
  styleUrls: ['./creation-container.component.scss'],
})
export class CreationContainerComponent implements OnInit, OnDestroy {
  @Input() header = 'Sample header';
  @Input() totalArea: number = null;
  @Input() numberOfGeoFences: number = null;
  @Input() nextDisabled: boolean;
  @Input() hideBottomBarDetails = false;
  @Output() clickNext: EventEmitter<EngagementViewModel> = new EventEmitter();
  @Output() clickClearGeoFences: EventEmitter<null> = new EventEmitter();
  engagement: BaseEngagement;
  target: any;
  paymentCurrency: string;
  nextButtonLabel: string;
  currentStep: string;
  trigger: any;
  Route = Route;
  showGuidelineCostPerDay: boolean;
  navigationSubscription: Subscription;
  price$: Observable<PriceInfoModel>;

  constructor(
    public engagementCreationService: EngagementCreationService,
    readonly appSettingService: AppSettingService,
    private readonly router: Router,
  ) {}

  ngOnInit(): void {
    this.engagement = this.engagementCreationService.engagement;
    this.price$ = this.engagement?.getPrice().pipe(shareReplay(1));
    this.target = this.engagement?.getTargetPage();
    this.trigger = this.engagement?.getTrigger();
    this.paymentCurrency = this.appSettingService.paymentCurrency;
    this.nextButtonLabel = this.getNextButtonLabel();
    if (this.engagement) {
      this.currentStep = this.engagement.creationSteps[this.engagement.currentStep];
      this.showGuidelineCostPerDay =
        (this.currentStep === Route.TARGET_PAGE_CREATION ||
          this.currentStep === Route.TARGETS ||
          this.currentStep === Route.LOCATION) &&
        this.engagement.activeTrigger === EngagementTypeEnum.Geolocation;

      this.subscribeToNavigation();
    }
  }

  ngOnDestroy() {
    this.navigationSubscription?.unsubscribe();
  }

  async navigateBack() {
    this.engagementCreationService.setPreviousStep();
  }

  isMapControlVisible() {
    return this.totalArea !== null && this.numberOfGeoFences !== null;
  }

  clearGeoFences() {
    this.clickClearGeoFences.emit();
  }

  navigateNext() {
    this.clickNext.emit();
  }

  private subscribeToNavigation() {
    this.navigationSubscription = this.router.events
      .pipe(filter((event: NavigationEvent) => CreationContainerComponent.isBrowserNavigationButtonEvent(event)))
      .subscribe((event: NavigationStart) => {
        const navigatedStep = this.router
          .parseUrl(event.url)
          .root.children.primary.segments.map((x) => x.path)
          .pop();

        if (this.navigatedToPreviousStep(navigatedStep)) {
          this.engagementCreationService.setPreviousStep();
        } else if (this.navigatedToNextStep(navigatedStep)) {
          this.engagementCreationService.setNextStep();
        }
      });
  }

  private navigatedToNextStep(step: string) {
    return this.getNextStep() === step;
  }

  private navigatedToPreviousStep(step: string) {
    return this.getPreviousStep() === step;
  }

  private static isBrowserNavigationButtonEvent(event: NavigationEvent): boolean {
    return event instanceof NavigationStart && event.navigationTrigger === 'popstate';
  }

  private getPreviousStep() {
    if (this.engagement.currentStep === 0) {
      return '';
    }
    return this.engagement.creationSteps[this.engagement.currentStep - 1];
  }

  private getNextStep() {
    if (this.engagement.currentStep === this.engagement.creationSteps.length - 1) {
      return '';
    }
    return this.engagement.creationSteps[this.engagement.currentStep + 1];
  }

  private getNextButtonLabel(): string {
    if (this.engagement) {
      if (this.engagement.creationSteps[this.engagement.currentStep] === Route.CHECKOUT) {
        return 'CREATE_ENGAGEMENT';
      }

      const nextStepRoute = this.engagement.creationSteps[this.engagement.currentStep + 1];
      const creationWizardStepType = EngagementCreationWizardSteps.find((step) =>
        step.allowedRoutes.includes(nextStepRoute),
      );
      return creationWizardStepType ? creationWizardStepType.title : null;
    }

    return null;
  }
}
