import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-merchant-portal-connection',
  templateUrl: './merchant-portal-connection.component.html',
  styleUrls: ['./merchant-portal-connection.component.scss'],
  host: {'class': 'settings-component'}
})
export class MerchantPortalConnectionComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
