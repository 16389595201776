import { Component, Inject, OnInit } from '@angular/core';
import { AUTH_SERVICE } from '@core/core.module';
import { GetMerchantInfoResponse } from '@core/models/get-merchant-info-response';
import { AppSettingService } from '@core/services/app-setting.service';
import { AuthServiceInterface } from '@core/services/auth/auth-service-inteface';
import { MerchantAPIService } from '@core/services/merchant-api.service';
import { environment } from '@env/environment';
import { BaseComponent } from '@shared/components/base-component/base-component';
import { LinkData } from '@shared/components/links-container/links-container.component';
import { Observable, of } from 'rxjs';
import { mergeMap, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent extends BaseComponent implements OnInit {
  helpLink?: LinkData = { id: 'navbar-item-help', name: 'HELP', value: 'HELP_URL', type: 'url' };
  tenant: string;
  themeClass: boolean;
  seowCompleted = false;

  constructor(
    @Inject(AUTH_SERVICE) public authService: AuthServiceInterface,
    private readonly appSettingService: AppSettingService,
    private merchantService: MerchantAPIService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.appSettingService.isLoaded$.pipe(takeUntil(this.destroy$)).subscribe((out) => {
      if (out) {
        this.helpLink.tenant = this.appSettingService.tenant.toUpperCase();
      }
    });
    this.tenant = this.appSettingService.tenant.toUpperCase();
    this.themeClass = this.appSettingService.styleLogo();

    this.authService.isLoggedIn$
      .pipe(
        takeUntil(this.destroy$),
        mergeMap<boolean, Observable<GetMerchantInfoResponse | null>>((isLoggedIn) => {
          if (isLoggedIn) {
            return this.merchantService.getMerchantInfo();
          }
          return of(null);
        }),
      )
      .subscribe((merchant) => {
        this.seowCompleted = !!merchant?.rceToken;
      });
  }

  getLinkValue(link: LinkData): string {
    const result = link.tenant ? `${link.value}_${link.tenant}` : `${link.value}_${this.tenant}`;
    return result.toString();
  }

  redirectToLogin() {
    this.authService.login('/', { partner: environment.partner });
  }

  logout() {
    this.authService.logout();
  }
}
