import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { TargetPagesFilters } from '@core/constants/Filters';
import Route from '@core/constants/route';
import { TargetPageCards } from '@core/constants/TargetPageCards';
import { CardInfo } from '@core/models/card-info';
import { Filters } from '@core/models/filters';
import { ActViewModel } from '@core/models/view-models/act-view-model';
import { ActAPIService } from '@core/services/act-api.service';
import { EngagementAPIService } from '@core/services/engagement-api.service';
import { LoaderService } from '@core/services/loader.service';
import { UserTierService } from '@core/services/user-tier.service';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { BaseTargetsDashboardComponent } from '@shared/components/base-targets-dashboard-component/base-targets-dashboard-component';
import { PopupService } from '@shared/components/popup/popup.service';
import { SearchInputComponent } from '@shared/components/search-input/search-input.component';
import { BehaviorSubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { clearFilter, updateFilter } from '../../store/actions/filter.actions';
import { getFilterState, State } from '../../store/store';

@Component({
  selector: 'app-target-pages-dashboard',
  templateUrl: './target-pages-dashboard.component.html',
  styleUrls: ['./target-pages-dashboard.component.scss'],
})
export class TargetPagesDashboardComponent extends BaseTargetsDashboardComponent implements OnInit, AfterViewInit {
  targetPagesFilters = TargetPagesFilters;
  targetPages: CardInfo[] = TargetPageCards.NEW_TARGET_PAGES;
  navigationRoute = `${Route.TARGET_PAGE_CREATION}`;
  searchedValues = null;
  filters: Filters = {};
  targetTypeFilter = 'enumTypesSelection';
  targetDateRangeFilter = 'targetDateRange';
  targets: BehaviorSubject<ActViewModel[]>;
  sort: MatSort;
  @ViewChild(MatSort) set matSort(matSort: MatSort) {
    this.sort = matSort;
  }
  @ViewChild(SearchInputComponent) searchComponent: SearchInputComponent;

  constructor(
    private readonly actService: ActAPIService,
    loaderService: LoaderService,
    private readonly popupService: PopupService,
    private readonly translateService: TranslateService,
    private readonly engagementService: EngagementAPIService,
    private readonly store: Store<State>,
    private readonly tierService: UserTierService,
  ) {
    super(actService, loaderService);
  }

  ngOnInit(): void {
    this.targets = this.dataSource.connect();
    this.store
      .select(getFilterState)
      .pipe(takeUntil(this.destroy$))
      .subscribe((filterState) => {
        filterState.filterData.forEach((filter) => {
          this.filters[filter.filterId] = filter.data;
          if (filter.filterId === this.targetTypeFilter || filter.filterId === this.targetDateRangeFilter) {
            this.searchedValues = filter.data;
          }
        });
        this.refresh(this.paginator.matPaginator?.pageIndex || 0, this.paginator.matPaginator?.pageSize || 5);
      });

    this.tierService.getActsUsageForUser().subscribe((tierUsage) => {
      this.targetPages.forEach((triggerTypeCard) => {
        const currentTier = tierUsage.find((x) => x.type === triggerTypeCard.type);
        triggerTypeCard.limit = currentTier?.limit;
        triggerTypeCard.hasPassedLimit = currentTier?.hasPassedLimit;
      });
    });
  }

  ngAfterViewInit(): void {
    this.paginator.matPaginator.page.pipe(takeUntil(this.destroy$)).subscribe((page) => {
      this.refresh(page.pageIndex, page.pageSize);
    });
    this.sort.sortChange.pipe(takeUntil(this.destroy$)).subscribe((sort) => {
      this.refresh(this.paginator.matPaginator.pageIndex, this.paginator.matPaginator.pageSize);
    });
  }

  applyFilter(value: string[], type: string) {
    switch (type) {
      case (type = 'enumTypesSelection'):
        if (value.length === 0) {
          this.searchedValues = null;
          this.store.dispatch(updateFilter({ filterId: this.targetTypeFilter, data: null }));
        } else {
          this.filters.typesSelection = value;
          this.store.dispatch(updateFilter({ filterId: this.targetTypeFilter, data: value }));
        }
        break;
      case (type = 'targetDateRange'):
        if (value === undefined) {
          this.searchedValues = null;
          this.store.dispatch(updateFilter({ filterId: this.targetDateRangeFilter, data: null }));
        } else {
          this.filters.dateRange = value.toString();
          this.store.dispatch(updateFilter({ filterId: this.targetDateRangeFilter, data: value }));
        }
        break;
      case (type = 'searchTerm'):
        this.filters.searchTerm = value.toString();
        this.store.dispatch(updateFilter({ filterId: "searchTerm", data: value }));
        break;
    }
  }

  resetFilters() {
    this.searchComponent.searchElement.nativeElement.value = null;
    this.filters = {};
    this.searchedValues = null;
    this.store.dispatch(clearFilter());
    this.applyFilter(undefined, 'dateRange');
  }

  deleteTarget() {
    this.refresh(this.paginator.matPaginator.pageIndex, this.paginator.matPaginator.pageSize);
  }
}
