import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacySelectModule } from '@angular/material/legacy-select';
import { CustomDirectivesModule } from '@core/directives/custom-directives.module';
import { TranslateModule } from '@ngx-translate/core';
import { TableDropdownGroupsComponent } from './table-dropdown-groups.component';

@NgModule({
  declarations: [TableDropdownGroupsComponent],
  imports: [
    CommonModule,
    CustomDirectivesModule,
    MatIconModule,
    MatLegacyFormFieldModule,
    MatLegacySelectModule,
    FlexLayoutModule,
    TranslateModule.forChild(),
    FormsModule,
  ],
  exports: [TableDropdownGroupsComponent],
})
export class TableDropdownGroupsModule {}
