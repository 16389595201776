import { Component, OnInit } from '@angular/core';
import { MatLegacyTabChangeEvent } from '@angular/material/legacy-tabs';
import { PaymentGatewayEnum } from '@core/models/enums/payment-gateway-enum';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { SelfOnboardingState } from '../store';
import { resetSelectPaymentGatewayDetails } from '../store/actions/payment-gateway-details.actions';
import { resetPaymentProviderDetails } from '../store/actions/payment-provider-details.actions';
import { selectPaymentForm } from '../store/actions/select-payment-form.action';
import { SelectedPaymentGatewayState } from '../store/reducers/select-payment-form.reducer';
import { SelectedPaymentProviderState } from '../store/reducers/select-payment-provider.reducer';
import {
  getSelectedPaymentGateway,
  getSelectedPaymentProvider,
} from '../store/selectors/payment-provider-details.select';

@Component({
  selector: 'app-settings-payment-provider-other-details',
  templateUrl: './payment-provider-other-details.component.html',
  styleUrls: ['./payment-provider-other-details.component.scss'],
})
export class PaymentProviderOtherDetailsComponent implements OnInit {
  selectedPaymentProvider: Observable<SelectedPaymentProviderState>;
  selectedPaymentGateway: Observable<SelectedPaymentGatewayState>;

  constructor(private readonly store: Store<SelfOnboardingState>) {}

  ngOnInit(): void {
    this.selectedPaymentProvider = this.store.select(getSelectedPaymentProvider);
    this.selectedPaymentGateway = this.store.select(getSelectedPaymentGateway);
  }

  tabChanged(event: MatLegacyTabChangeEvent) {
    const paymentProviderForm = event.index as PaymentGatewayEnum;
    this.store.dispatch(selectPaymentForm({ paymentProviderForm }));
    this.store.dispatch(resetSelectPaymentGatewayDetails());
    this.store.dispatch(resetPaymentProviderDetails());
  }
}
