import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ConvertZeroPipe } from '@shared/pipes/convert-zero.pipe';
import { DateFormatPipe } from '@shared/pipes/date-format.pipe';
import { NumberDividerPipe } from '@shared/pipes/number-divider.pipe';
import { OrderByPipe } from '@shared/pipes/order-by.pipe';
import { SafeHtmlPipe } from '@shared/pipes/safe-html.pipe';
import { MaskTextPipe } from './mask-text.pipe';
import { TextWrapPipe } from './text-wrap.pipe';
import { DisplayTriggerTypePipe } from './trigger-type-display.pipe';

@NgModule({
  declarations: [
    DisplayTriggerTypePipe,
    TextWrapPipe,
    MaskTextPipe,
    OrderByPipe,
    NumberDividerPipe,
    SafeHtmlPipe,
    ConvertZeroPipe,
    DateFormatPipe,
  ],
  imports: [CommonModule],
  exports: [
    DisplayTriggerTypePipe,
    TextWrapPipe,
    MaskTextPipe,
    OrderByPipe,
    NumberDividerPipe,
    SafeHtmlPipe,
    ConvertZeroPipe,
    DateFormatPipe,
  ],
})
export class CustomPipeModule {}
