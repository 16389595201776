import { MatLegacyDialogRef } from '@angular/material/legacy-dialog';

export abstract class ColorPickingBlockAbstractComponent<T> {
  private disableClose = false;

  constructor(public dialogRef: MatLegacyDialogRef<T>) {}

  onColorPickerBlur(): void {
    this.disableClose = false;
    setTimeout(() => {
      this.dialogRef.disableClose = this.disableClose;
    }, 150);
  }

  onColorPickerFocus(): void {
    this.disableClose = true;
    this.dialogRef.disableClose = this.disableClose;
  }
}
