import { Injectable } from '@angular/core';
import { Adapter } from '@core/models/adapters/adapter';
import { EngagementDTO } from '@core/models/dtos/engagement-dto';
import { EngagementViewModel } from '@core/models/view-models/engagement-view-model';

@Injectable({
  providedIn: 'root',
})
export class EngagementAdapter implements Adapter<EngagementViewModel, EngagementDTO> {
  fromDTO(dto: EngagementDTO): EngagementViewModel {
    return new EngagementViewModel(dto);
  }

  toDTO(viewModel: EngagementViewModel): EngagementDTO {
    return {
      id: viewModel.id,
      shortId: viewModel.shortId,
      name: viewModel.name,
      clientReferenceId: viewModel.clientReferenceId,
      type: viewModel.type,
      createdDate: viewModel.createdDate,
      purchaseDate: viewModel.purchaseDate,
      status: viewModel.status,
      dateSelectionType: viewModel.dateSelectionType,
      validityPeriods: viewModel.validityPeriods,
      responses: viewModel.responses,
      relatedItem: viewModel.relatedItem,
      act: viewModel.act,
      paymentInformation: viewModel.paymentInformation,
      qRCodeEncodedImage: viewModel.qRCodeEncodedImage,
      price: viewModel.price,
      paymentStatus: viewModel.paymentStatus,
      userStatus: viewModel.userStatus,
      overallStatus: viewModel.overallStatus,
      isWatermarkeable: viewModel.isWatermarkeable,
      locationReference: viewModel.locationReference,
      relatedItems: viewModel.relatedItems,
      shortUrl: viewModel.shortUrl,
      url: viewModel.url,
    };
  }
}
