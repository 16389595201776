import { AfterViewInit, Component, Input, OnChanges, SimpleChanges, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatLegacyPaginator } from '@angular/material/legacy-paginator';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss'],
  // tslint:disable-next-line:use-component-view-encapsulation
  encapsulation: ViewEncapsulation.None,
})
export class PaginationComponent implements OnChanges, AfterViewInit {
  @Input() length = 0;
  @Input() pageSize: number;
  @Input() pageSizeOptions: number[] = [];
  @ViewChild(MatLegacyPaginator) matPaginator: MatLegacyPaginator;

  constructor(private translateService: TranslateService) {}

  ngOnChanges(changes: SimpleChanges): void {
    const pageSizeOptions = changes.pageSizeOptions?.currentValue as number[];
  }

  ngAfterViewInit(): void {
    this.matPaginator._intl.itemsPerPageLabel = this.translateService.instant('ITEMS_PER_PAGE');
  }

  ngOnInit(): void {
    this.translateService.onLangChange.subscribe((translationChange) => {
      const newLabel = translationChange.translations.ITEMS_PER_PAGE;
      if (newLabel) {
        this.matPaginator._intl.itemsPerPageLabel = newLabel;
      }
    });
  }
}
