import { Directive } from '@angular/core';
import { FormControl, NG_VALIDATORS, Validator } from '@angular/forms';

@Directive({
  selector: '[whiteSpaceValidator]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useClass: WhiteSpaceValidatorDirective,
      multi: true,
    },
  ],
})
export class WhiteSpaceValidatorDirective implements Validator {
  validate(formControl: FormControl) {
    if (formControl && formControl.value && !formControl.value.replace(/\s/g, '').length) {
      formControl.setValue('');
    }
    return null;
  }
}
