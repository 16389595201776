import { Product } from '../dtos/product';
import { RceProduct } from '../dtos/rce-product';
import { Filters } from '../filters';

export class ProductViewModel extends RceProduct {
  selected?: boolean;
  editMode?: boolean;
  availableActions?: any[];
  associatedCategories: string[];
  id: number;
  isSelectable: boolean;

  constructor(rceProduct: RceProduct = null) {
    super(rceProduct);
    this.selected = false;
    this.editMode = false;
    this.availableActions = [];
    this.associatedCategories =
      this.productCategories.length > 0 ? this.productCategories.map((category) => category.name) : [];
    this.isSelectable = this.enabled;
  }

  static sortingDataAccessor = (item: ProductViewModel, property) => {
    switch (property) {
      case 'name':
        return item.name;
      case 'price':
        return item.price;
      case 'quantity':
        return item.quantity;
      case 'enabled':
        return item.enabled;
      default:
        return item[property];
    }
  };

  static filterPredicate = (data: ProductViewModel, filterString: string): boolean => {
    const { typesSelection = [], searchTerm = '' }: Filters = JSON.parse(filterString);
    let resultChecks: boolean[] = [];

    if (searchTerm.length > 0) {
      resultChecks.push(data.name.toLowerCase().includes(searchTerm.toLowerCase()));
    }

    if (typesSelection.length > 0) {
      const selectedStatuses = typesSelection.filter((status) => typeof status === 'boolean').toString();

      if (selectedStatuses.length > 0) {
        resultChecks.push(selectedStatuses.toString().includes(String(data.enabled)));
      }

      const selectedCategories = typesSelection.filter((category) => typeof category === 'string');
      if (selectedCategories.length > 0) {
        const categoryIntersection = data.associatedCategories.filter((value) => selectedCategories.includes(value));
        resultChecks.push(categoryIntersection.length > 0);
      }
    }

    return resultChecks.length > 0 ? resultChecks.every((result) => result === true) : true;
  };

  static getTargetPageProduct = (data: ProductViewModel): Product => {
    if (!data) {
      return null;
    }

    return {
      id: data.id,
      partnerId: data.partnerId ? data.partnerId : undefined,
      merchantId: data.merchantId ? data.merchantId : undefined,
      categoryId: data.productCategories.length > 0 ? data.productCategories[0].id.toString() : undefined,
      categoryName: data.productCategories.length > 0 ? data.productCategories[0].name : null,
      imageThumbUrl: data.imageThumbUrl,
      name: data.name,
    };
  };
}
