import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule } from '@angular/forms';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';
import { TranslateModule } from '@ngx-translate/core';
import { CalendarModule } from 'primeng/calendar';
import { RippleModule } from 'primeng/ripple';
import { DateTimePickerComponent } from './date-time-picker.component';

@NgModule({
  declarations: [DateTimePickerComponent],
  imports: [
    CommonModule,
    CalendarModule,
    FormsModule,
    FlexLayoutModule,
    TranslateModule.forChild(),
    MatLegacyButtonModule,
    RippleModule,
  ],
  exports: [DateTimePickerComponent],
})
export class DateTimePickerModule {}
