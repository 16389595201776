import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Country } from '@core/models/country';
import { Observable } from 'rxjs';
import { BaseAPIServiceService } from './http/base-apiservice.service';

@Injectable({
  providedIn: 'root',
})
export class CountryApiService extends BaseAPIServiceService {
  constructor(protected http: HttpClient) {
    super(http);
  }

  getCountries(): Observable<Country[]> {
    return this.http.get<any>('assets/countries/countries.json');
  }
}
