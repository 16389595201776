import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';

@Component({
  selector: 'app-search-input',
  templateUrl: './search-input.component.html',
  styleUrls: ['./search-input.component.scss'],
  // tslint:disable-next-line:use-component-view-encapsulation
  encapsulation: ViewEncapsulation.None,
})
export class SearchInputComponent implements OnInit {
  @Input() initialValue = '';
  @Output() searched: EventEmitter<any> = new EventEmitter();
  @Input() caseSensitive: boolean = false;
  @ViewChild('searchElement') searchElement: ElementRef;

  ngOnInit(): void {}
  search(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    if (this.caseSensitive) {
      this.searched.emit(filterValue);
    } else {
      this.searched.emit(filterValue.trim().toLowerCase());
    }
  }
}
