import { Component, Inject, OnInit } from '@angular/core';
import { AUTH_SERVICE } from '@core/core.module';
import { AuthServiceInterface } from '@core/services/auth/auth-service-inteface';
import { BaseComponent } from '@shared/components/base-component/base-component';
import { takeUntil } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import Tenants from '@core/constants/Tenants';
import Partners from '@core/constants/Partners';
import { environment } from '@env/environment';

@Component({
  selector: 'login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent extends BaseComponent implements OnInit {
  constructor(
    @Inject(AUTH_SERVICE) private readonly authService: AuthServiceInterface,
    private readonly route: ActivatedRoute,
  ) {
    super();
  }

  ngOnInit(): void {
    const tenant = this.route.snapshot.queryParamMap.get('tenant');
    const partner = this.route.snapshot.queryParamMap.get('partner');

    const query = {
      rezolve_tenant: tenant || Tenants.DEFAULT,
      partner: partner || environment.partner,
    };

    this.authService.login$('/', query).pipe(takeUntil(this.destroy$)).subscribe();
  }
}
