import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { GenericCreationContainerService } from '@shared/components/generic-creation-container/generic-creation-container.service';
import { ProductMediaUploadModule } from '../product-creation-popup/product-media-upload/product-media-upload.module';
import { ProductEditPopupComponent } from './product-edit-popup.component';
import { ProductEditPopupService } from './product-edit-popup.service';
import { ProductEditModule } from './product-edit/product-edit.module';

@NgModule({
  declarations: [ProductEditPopupComponent],
  imports: [CommonModule, ProductMediaUploadModule, ProductEditModule, TranslateModule.forChild()],
  exports: [],
  providers: [ProductEditPopupService, GenericCreationContainerService],
})
export class ProductEditPopupModule {}
