import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatLegacyDialog } from '@angular/material/legacy-dialog';
import { Router } from '@angular/router';
import { FeatureManagementEnum } from '@core/constants/FeatureManagement';
import { DefaultBuyButtonValue, LearnMoreLink, ModalHeight, ModalWidth } from '@core/constants/InstantButton';
import Route from '@core/constants/route';
import { AciOnboardingProgressStatusEnum } from '@core/models/get-aci-onboarding-status-response';
import {
  GetMerchantInfoResponse,
  OnboardingProcess,
  OnboardingProcessStatusEnum,
} from '@core/models/get-merchant-info-response';
import { AppSettingService } from '@core/services/app-setting.service';
import { LoaderService } from '@core/services/loader.service';
import { MerchantAPIService } from '@core/services/merchant-api.service';
import { TranslateService } from '@ngx-translate/core';
import { BaseComponent } from '@shared/components/base-component/base-component';
import { PopupService } from '@shared/components/popup/popup.service';
import { combineLatest } from 'rxjs';
import { switchMap, takeUntil, tap } from 'rxjs/operators';
import { LogUtils } from '../../../utils/log-utils';
import { CreateInstantBuyButtonModalComponent } from '../../create-instant-buy-button/create-instant-buy-button-modal.component';
import { ShopConnectionDic } from '../../products/shop-connection-models';
import { PaymentProvidersList } from '../self-onboarding/payment-settings/PaymentProvidersList';

interface MerchantDetailsDto {
  onBoardingFinished: boolean;
  url: string;
  logo: string;
  paymentProviderLogo: string;
}
@Component({
  selector: 'sell-with-rezolve',
  templateUrl: './sell-with-rezolve.component.html',
  styleUrls: ['./sell-with-rezolve.component.scss'],
})
export class SellWithRezolveComponent extends BaseComponent implements OnInit {
  getMerchantInfoResponse: GetMerchantInfoResponse;
  merchantDetails: MerchantDetailsDto;
  isIBFWEnabled: boolean;

  constructor(
    public loaderService: LoaderService,
    private popupService: PopupService,
    private readonly merchantService: MerchantAPIService,
    private translateService: TranslateService,
    private readonly router: Router,
    private appSettingService: AppSettingService,
    public dialog: MatLegacyDialog,
  ) {
    super();
    this.isIBFWEnabled = appSettingService.isFeatureEnabled(FeatureManagementEnum.IBFW);
  }

  async ngOnInit(): Promise<void> {
    combineLatest([this.merchantService.getMerchantInfo(), this.merchantService.getAciOnBoardingProgressStatus()])
      .pipe(takeUntil(this.destroy$))
      .subscribe(async ([merchantInfo, aciOnBoarding]) => {
        await this.checkMerchantDetails(merchantInfo, aciOnBoarding?.aciOnboardingProgressStatus);
      });
  }

  async startAciOnBoarding() {
    await this.merchantService
      .startAciOnBoarding()
      .then(() => {
        LogUtils.info('ACI On-boarding started successfully.');
        this.router.navigate([Route.SETTINGS, Route.SELF_ON_BOARDING]);
      })
      .catch((error: HttpErrorResponse) => {
        LogUtils.error(`Error while starting aci on-boarding: ` + error.message);
        return this.showStatusPopup(false, 'ACI_ONBORDING_FAILED', error && error.error && error.error.Message);
      });
  }

  setMerchantDetails() {
    const merchantDetails = {
      onBoardingFinished: this.isOnBoardingFinished(this.getMerchantInfoResponse.onboardingProcess),
      url: this.getMerchantInfoResponse.rceHost,
      logo:
        this.getMerchantInfoResponse.productsConfig &&
        this.getMerchantInfoResponse.productsConfig.ecommerceConfig &&
        this.getEcommercePlatformLogo(this.getMerchantInfoResponse.productsConfig.ecommerceConfig?.cartId),
      paymentProviderLogo: this.getPaymentProviderLogo(this.getMerchantInfoResponse.paymentProviderInfo?.name),
    };

    this.merchantDetails = merchantDetails;
  }

  isOnBoardingFinished(onBoarding: OnboardingProcess) {
    return (
      onBoarding &&
      onBoarding.boMerchantSetup &&
      onBoarding.boMerchantSetup.status === OnboardingProcessStatusEnum.Done &&
      onBoarding.pspSetup &&
      onBoarding.pspSetup.status === OnboardingProcessStatusEnum.Done &&
      onBoarding.rceEcommerceSetup &&
      onBoarding.rceEcommerceSetup.status === OnboardingProcessStatusEnum.Done &&
      onBoarding.rceMerchantSetup &&
      onBoarding.rceMerchantSetup.status === OnboardingProcessStatusEnum.Done
    );
  }

  getEcommercePlatformLogo(shopName: string) {
    return shopName && ShopConnectionDic[shopName] && `assets/ecomm-logos/${ShopConnectionDic[shopName].logo}`;
  }

  getPaymentProviderLogo(providerName: string) {
    const provider = PaymentProvidersList.find((p) => p.name?.toUpperCase() === providerName?.toUpperCase());
    return provider && `/assets/payment-providers/${provider.logoUrl}`;
  }

  openCreateInstantBuyButton() {
    this.dialog.open(CreateInstantBuyButtonModalComponent, {
      data: {
        title: 'CREATE_INSTANT_BUY_BUTTON',
        learnMoreLink: LearnMoreLink.COPY_BUY_BUTTON_CODE,
        merchantId: this.getMerchantInfoResponse?.rceMerchantId,
        buttonText: DefaultBuyButtonValue,
        platform: this.getMerchantInfoResponse?.productsConfig?.ecommerceConfig?.cartId,
      },
      width: ModalWidth.lg,
      height: ModalHeight,
      panelClass: ['full-screen-modal'],
    });
  }

  private async checkMerchantDetails(
    merchantInfo: GetMerchantInfoResponse,
    aciOnBoardingStatus: AciOnboardingProgressStatusEnum,
  ) {
    this.getMerchantInfoResponse = merchantInfo;
    const merchantDetailsUrl = {
      termsAndConditionUrl: this.getMerchantInfoResponse.paymentCustomerInfo?.termsAndConditionsUrl,
      privacyPolicyUrl: this.getMerchantInfoResponse.paymentCustomerInfo?.privacyPolicyUrl,
      companyInfoUrl: this.getMerchantInfoResponse.paymentCustomerInfo?.companyInformationUrl,
    };

    const merchantDetailUrlIsEmptyOrNull = Object.values(merchantDetailsUrl).every((value) => {
      if (!value) {
        return true;
      }
      return false;
    });

    if (!this.getMerchantInfoResponse.paymentCustomerInfo || merchantDetailUrlIsEmptyOrNull) {
      this.showFillDetailsPopup();
    } else if (this.isOnBoardingFinished(this.getMerchantInfoResponse.onboardingProcess)) {
      this.setMerchantDetails();
    } else if (aciOnBoardingStatus === AciOnboardingProgressStatusEnum.NotStarted) {
      await this.startAciOnBoarding();
    } else {
      this.router.navigate([Route.SETTINGS, Route.SELF_ON_BOARDING]);
    }
  }

  private showStatusPopup(isSuccess: boolean, message: string, failureReason?: string): void {
    this.translateService
      .get(message)
      .pipe(
        switchMap((translated: string) => {
          return this.popupService.successStatusPopup({
            componentConfiguration: { isSuccess, message: translated, failureReason },
            height: 'auto',
            width: 'auto',
            maxWidth: '430px',
            backdropClass: 'blurred-backdrop',
          });
        }),
        takeUntil(this.destroy$),
      )
      .subscribe(() => {});
  }

  private showFillDetailsPopup(): void {
    this.popupService.goToMyDetailsPopup().subscribe(() => {
      this.router.navigate([Route.SETTINGS, Route.YOUR_DETAILS_SETTINGS]);
    });
  }
}
