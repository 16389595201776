import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { EngagementCreationService } from '../../features/engagement-creation/engagement-creation.service';

@Injectable({
  providedIn: 'root',
})
export class WizardGuard  {
  constructor(private engagementCreationService: EngagementCreationService) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (!this.engagementCreationService.engagement) {
      this.engagementCreationService.navigateToFirstStep();
      return false;
    }
    return true;
  }
}
