export const SETTINGS_PAYMENT_PROVIDER_TIMEZONES = [
  'Europe/London',
  'Europe/Berlin',
  'Asia/Taipe',
  'Asia/Shanghai',
  'Asia/Seoul',
  'America/Los_Angeles',
];

export const TimezonesDictionary = {
  'Europe/London': 'Europe/London',
  'Europe/Berlin': 'Europe/Berlin',
  'Asia/Taipe': 'Asia/Taipei',
  'Asia/Shanghai': 'Asia/Shanghai',
  'Asia/Seoul': 'Asia/Seoul',
  'America/Los_Angeles': 'America/Los Angeles',
};
