import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import Route from '@core/constants/route';
import { AuthGuard } from '@core/guards/auth.guard';
import { HideGuard } from '@core/guards/hide.guard';
import { Api2CartSettingsComponent } from './api2-cart-settings/api2-cart-settings.component';
import { LocationFormComponent } from './locations/location-form/location-form.component';
import { Aci3DSecureDetailsComponent } from './self-onboarding/aci-3DSecure-details/aci-3DSecure-details.component';
import { ConfirmationMessageComponent } from './self-onboarding/confirmation-message/confirmation-message.component';
import { ConfirmationComponent } from './self-onboarding/confirmation/confirmation.component';
import { PaymentProviderBaseComponent } from './self-onboarding/payment-provider-base/payment-provider-base.component';
import { PaymentSelectorComponent } from './self-onboarding/payment-settings/payment-selector/payment-selector.component';
import { PlatformDetailComponent } from './self-onboarding/platform-detail/platform-detail.component';
import { SelectEcommercePlatformComponent } from './self-onboarding/select-ecommerce-platform/select-ecommerce-platform.component';
import { SelfOnboardingComponent } from './self-onboarding/self-onboarding.component';
import { SellWithRezolveComponent } from './sell-with-rezolve/sell-with-rezolve.component';
import { SettingsComponent } from './settings.component';
import { YourBrandComponent } from './your-brand/your-brand.component';
import { YourDetailsComponent } from './your-details/your-details.component';

const settingsRoutes: Routes = [
  {
    path: Route.SETTINGS,
    canActivate: [AuthGuard],
    component: SettingsComponent,
    children: [
      {
        path: '',
        redirectTo: Route.YOUR_DETAILS_SETTINGS,
        pathMatch: 'full',
      },
      {
        path: Route.YOUR_DETAILS_SETTINGS,
        component: YourDetailsComponent,
      },
      {
        path: Route.YOUR_BRAND,
        component: YourBrandComponent,
        canActivate: [HideGuard],
      },
      {
        path: Route.SELL_WITH_REZOLVE,
        component: SellWithRezolveComponent,
        data: { shouldReuseRoute: false },
        canActivate: [HideGuard],
      },
      {
        path: Route.LOCATION_CREATION,
        component: LocationFormComponent,
      },
      {
        path: Route.ECOMMERCE,
        component: Api2CartSettingsComponent,
        canActivate: [HideGuard],
      },
      {
        path: Route.SELF_ON_BOARDING,
        component: SelfOnboardingComponent,
        canActivate: [HideGuard],
        data: { shouldReuseRoute: false },
        children: [
          { path: '', redirectTo: Route.SELECT_PAYMENT_PROVIDER, pathMatch: 'full' },
          {
            path: Route.SELECT_ECOMMERCE_PLATFORM,
            component: SelectEcommercePlatformComponent,
            data: { shouldReuseRoute: false },
          },
          {
            path: Route.PROVIDER_DETAILS,
            component: PaymentProviderBaseComponent,
            data: { shouldReuseRoute: false },
          },
          {
            path: Route.SECURE_SETUP,
            component: Aci3DSecureDetailsComponent,
            data: { shouldReuseRoute: false },
          },
          {
            path: Route.CONFIRMATION_MESSAGE,
            component: ConfirmationMessageComponent,
            data: { shouldReuseRoute: false },
          },
          {
            path: Route.SELECT_PAYMENT_PROVIDER,
            component: PaymentSelectorComponent,
            data: { shouldReuseRoute: false },
          },
          { path: Route.PLATFORM_DETAIL, component: PlatformDetailComponent, data: { shouldReuseRoute: false } },
          {
            path: Route.CONFIRMATION,
            component: ConfirmationComponent,
          },
        ],
      },
      { path: '**', redirectTo: `/${Route.ENGAGEMENT_CREATION}`, pathMatch: 'full' },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(settingsRoutes, {})],
  exports: [RouterModule],
})
export class SettingsRoutingModule {}
