import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, FormGroupDirective, ValidationErrors, Validators } from '@angular/forms';
import { SETTINGS_CARD_PROVIDERS } from '@core/constants/CardProviders';
import { SETTINGS_PAYMENT_PROVIDER_CURRENCIES } from '@core/constants/Currencies';
import { RegularExpressions } from '@core/constants/RegularExpressions';
import { TimezonesDictionary } from '@core/constants/Timezones';
import { AuthService } from '@core/services/auth/auth.service';
import { LoaderService } from '@core/services/loader.service';
import { MerchantAPIService } from '@core/services/merchant-api.service';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { BaseComponent } from '@shared/components/base-component/base-component';
import { PopupService } from '@shared/components/popup/popup.service';
import { combineLatest, Observable } from 'rxjs';
import { map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { isValidURL } from 'src/app/utils/url-utils';
import { Connection } from '../../../products/shop-connection-models';
import { MenuWizardService } from '../menu-wizard/menu-wizard-service/menu-wizard.service';
import { PaymentFormField, PaymentType } from '../payment-settings/payment-connection-models';
import { SelfOnboardingState } from '../store';
import { clearAciOnboardingProgressState } from '../store/actions/aci-onboarding-progress.actions';
import { createAciPaymentProviderDetails } from '../store/actions/aci-payment-provider-details.actions';
import { OnboardingProgressEnum } from '../store/models/aci-onboarding.models';
import { SelectedPaymentProviderState } from '../store/reducers/select-payment-provider.reducer';
import { getAciOnboardingProgress } from '../store/selectors/aci-onboarding-progress.select';
import { getPaymentGatewayDetails } from '../store/selectors/payment-gateway-details.select';
import { getPaymentProvider, getSelectedPaymentProvider } from '../store/selectors/payment-provider-details.select';

interface ContactDetails {
  contactName: string;
  contactNumber: string;
  contactEmail: string;
  contactAddress: string;
}

@Component({
  selector: 'app-other-payment-provider-form',
  templateUrl: './other-payment-provider-form.component.html',
  styleUrls: ['./other-payment-provider-form.component.scss'],
})
export class OtherPaymentProviderFormComponent extends BaseComponent implements OnInit {
  selectedPaymentProvider: Observable<SelectedPaymentProviderState>;
  selectedPaymentPlatform: Observable<any>;
  cardProviders = SETTINGS_CARD_PROVIDERS;
  currencies: string[] = SETTINGS_PAYMENT_PROVIDER_CURRENCIES;
  timezones = TimezonesDictionary;
  form: FormGroup;

  contactDetails: ContactDetails;
  paymentProviderName: string;
  hideKey = {};

  constructor(
    public loaderService: LoaderService,
    private readonly merchantService: MerchantAPIService,
    private readonly authService: AuthService,
    private readonly store: Store<SelfOnboardingState>,
    private readonly menuWizardService: MenuWizardService,
    private readonly translateService: TranslateService,
    private readonly popupService: PopupService,
  ) {
    super();
    this.form = new FormGroup({
      currencyForPayment: new FormControl(null, [Validators.required]),
      timezone: new FormControl('', Validators.required),
      phone: new FormControl('', [Validators.required, Validators.pattern(RegularExpressions.PHONE_NUMBER)]),
      acceptTermsConditions: new FormControl(false, Validators.requiredTrue),
      storeName: new FormControl(
        '',
        [
          Validators.required,
          Validators.minLength(20),
          Validators.maxLength(20),
          Validators.pattern(RegularExpressions.RFC_1738),
        ],
        this.checkExistingStoreNames.bind(this),
      ),
    });

    for (const provider of this.cardProviders) {
      this.form.addControl(provider.key, new FormControl(false));
    }

    this.form.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((_) => {
      this.menuWizardService.canGoToNextStep$.next(this.form.valid);
    });

    this.menuWizardService.nextStepRequest$.pipe(takeUntil(this.destroy$)).subscribe((_) => {
      this.onSubmit().then((r) => r);
    });
  }

  ngOnInit(): void {
    this.loadContactDetails();
    this.loadDataFromStore();

    // TODO: Fetch existing Store Names

    this.store
      .select(getAciOnboardingProgress)
      .pipe(takeUntil(this.destroy$))
      .subscribe(({ merchantAccountStep }) => {
        switch (merchantAccountStep.state) {
          case OnboardingProgressEnum.Success:
            this.loaderService.hide();
            this.menuWizardService.canGoToNextStep$.next(true);
            this.store.dispatch(clearAciOnboardingProgressState());
            if (isValidURL(merchantAccountStep.data)) {
              document.location.assign(merchantAccountStep.data);
            } else {
              this.menuWizardService.goNext();
            }
            break;

          case OnboardingProgressEnum.Failed:
            this.loaderService.hide();
            this.menuWizardService.canGoToNextStep$.next(true);
            this.showMerchantAccountFailed();
            break;

          case OnboardingProgressEnum.Pending:
            this.loaderService.show();
            this.menuWizardService.canGoToNextStep$.next(false);
            break;

          default:
            break;
        }
      });
  }

  async checkExistingStoreNames(control: FormControl): Promise<ValidationErrors | null> {
    const value = control.value;

    try {
      const exists = await this.merchantService.checkRceHostNameAlreadyExist(value).toPromise();

      return exists ? { alreadyExists: true } : null;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  originalOrder(): number {
    return 0;
  }

  updateForm(formFields: PaymentFormField[] | undefined) {
    (formFields || []).forEach((formField) => {
      this.form.addControl(
        formField.formControlName,
        new FormControl('', [...(formField.isRequired ? [Validators.required] : []), ...(formField.validations || [])]),
      );
    });
  }

  async onSubmit() {
    if (this.form.invalid) {
      return;
    }

    const { currencyForPayment, timezone, phone, acceptTermsConditions, ...rest } = this.form.value;

    this.store.dispatch(
      createAciPaymentProviderDetails({
        ...this.form.value,
        paymentProviderDetails: rest,
        paymentProviderName: this.paymentProviderName,
        ...this.contactDetails,
      }),
    );
  }

  private showMerchantAccountFailed(): void {
    this.translateService
      .get('FAILED_TO_INITIALIZE_PROVIDER')
      .pipe(
        switchMap((translated) =>
          this.popupService.successStatusPopup({
            componentConfiguration: { isSuccess: false, message: translated },
            height: 'auto',
            width: 'auto',
            maxWidth: '430px',
            backdropClass: 'blurred-backdrop',
          }),
        ),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  private loadContactDetails(): void {
    this.loaderService.show();
    combineLatest([this.merchantService.getMerchantInfo(), this.authService.userProfile$])
      .pipe(takeUntil(this.destroy$))
      .subscribe(([merchantInfo, profileInfo]) => {
        const customerInfo = merchantInfo?.paymentCustomerInfo;
        const addressData = customerInfo?.paymentCardBillingAddress;
        const fullAddress = addressData
          ? `${addressData.street1}, ${addressData.state} ${addressData.zip}, ${addressData.countryCode}`
          : '';
        this.contactDetails = {
          contactName: customerInfo?.businessName,
          contactNumber: customerInfo?.phone,
          contactAddress: fullAddress,
          contactEmail: profileInfo?.email,
        };
        this.loaderService.hide();
      });
  }

  private loadDataFromStore(): void {
    this.selectedPaymentProvider = this.store.select(getSelectedPaymentProvider);

    this.store
      .select(getPaymentGatewayDetails)
      .pipe(takeUntil(this.destroy$))
      .subscribe((paymentGatewayDetails) => {
        this.form.patchValue({ ...paymentGatewayDetails });
      });

    this.selectedPaymentPlatform = this.store.select(getPaymentProvider).pipe(
      map((paymentPlatform: { [type in PaymentType]: Connection }) => {
        const name = Object.keys(paymentPlatform)[0] as PaymentType;
        return {
          name,
          connection: paymentPlatform[name],
          hasExtraFields: Boolean(paymentPlatform[name].formFields?.length),
        };
      }),
      tap((platform) => {
        this.paymentProviderName = platform.name;
        this.updateForm(platform?.connection?.formFields);
      }),
    );
  }
}
