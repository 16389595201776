export interface UpdateMerchantProductConfigRequest {
  ecommerceConfig?: EcommerceConfig;
  hasSkippedEcommerceConfig: boolean;
}

export const allPossibleShops = [
  '3DCartApi',
  'Amazon',
  'BigcommerceApi',
  'Demandware',
  'EBay',
  'Shopify',
  'Volusion',
  'AceShop',
  'Cscart',
  'CommerceHQ',
  'Cubecart',
  'Gambio',
  'Interspire',
  'JooCart',
  'Loaded Commerce',
  'Magento1212',
  'MijoShop',
  'Opencart14',
  'Oscmax2',
  'Oscommerce22ms2',
  'Oxid',
  'Pinnacle',
  'PrestaShop',
  'Shop-Script Premium',
  'Shopware',
  'AspDotNetStorefront',
  'ECWid',
  'Etsy',
  'Hybris',
  'Lightspeed Ecommerce',
  'Neto',
  'Squarespace',
  'Walmart',
  'Tomatocart',
  'Ubercart',
  'Virtuemart',
  'Woocommerce',
  'WebAsyst',
  'WPecommerce',
  'Xcart',
  'Xtcommerce',
  'XtcommerceVeyton',
  'Zencart137',
] as const;
export type ShopType = typeof allPossibleShops[number];

export interface EcommerceConfig {
  cartId: ShopType;
  shopUrl: string;
  storeId: string;
}
