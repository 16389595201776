import { Injectable } from '@angular/core';
import { MatLegacyDialog, MatLegacyDialogConfig, MatLegacyDialogRef } from '@angular/material/legacy-dialog';
import { RceProduct } from '@core/models/dtos/rce-product';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ProductCreationPopupComponent } from './product-creation-popup.component';

@Injectable({
  providedIn: 'root',
})
export class ProductCreationPopupService {
  constructor(private matDialog: MatLegacyDialog) {}

  open(params: MatLegacyDialogConfig<any>): Observable<RceProduct> {
    let dialogRef: MatLegacyDialogRef<ProductCreationPopupComponent>;

    dialogRef = this.matDialog.open(ProductCreationPopupComponent, params);

    return dialogRef.afterClosed().pipe(map((_) => dialogRef.componentInstance.product));
  }
}
