export enum PageBuildingBlockTypeEnum {
  Header = 0,
  Paragraph = 1,
  Image = 2,
  Video = 3,
  Divider = 4,
  DateField = 5,
  Select = 6,
  TextField = 7,
  Coupon = 8,
  Link = 9,
  MessageEntryField = 10,
}
