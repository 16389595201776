import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import Partners from '@core/constants/Partners';
import Route from '@core/constants/route';
import { MerchantAPIService } from '@core/services/merchant-api.service';
import { environment } from '@env/environment';
import { BaseComponent } from '@shared/components/base-component/base-component';
import { merge, Observable, of } from 'rxjs';
import { filter, map } from 'rxjs/operators';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
})
export class SettingsComponent extends BaseComponent implements OnInit {
  canShowEcommerceSettings$: Observable<boolean>;

  routerLink: string;
  isSellWithRezolveActive$: Observable<boolean>;

  constructor(private readonly router: Router, private merchantService: MerchantAPIService) {
    super();
    this.routerLink = this.isSEOWDisabled() ? Route.ECOMMERCE : Route.SELL_WITH_REZOLVE;
  }

  isSEOWDisabled() {
    return environment['disableSEOW'] !== undefined && environment['disableSEOW'] === true;
  }

  ngOnInit(): void {
    this.canShowEcommerceSettings$ = this.merchantService
      .getMerchantInfo()
      .pipe(map((merchant) => (this.isSEOWDisabled() ? !!merchant?.rceToken : true)));

    this.isSellWithRezolveActive$ = merge(
      of(this.isEcommerceSettingsActive(this.router.url)),
      this.router.events.pipe(
        filter((event): event is NavigationEnd => event instanceof NavigationEnd),
        map((event) => {
          return this.isEcommerceSettingsActive(event.url);
        }),
      ),
    );
  }

  isEcommerceSettingsActive(url: string) {
    return this.routerLink === Route.ECOMMERCE
      ? url.includes(Route.ECOMMERCE)
      : url.includes(Route.SELL_WITH_REZOLVE) || url.includes(Route.SELF_ON_BOARDING);
  }
}
