import { Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

export enum Icons {
  Dashboard = 'dashboard',
  Targets = 'targets',
  Plus = 'plus',
  Settings = 'settings',
  Help = 'help',
  User = 'user',
  Login = 'login',
  Logout = 'logout',
  Rezolve = 'rezolve',
  QR = 'qr',
  Audio = 'audio',
  Beacon = 'beacon',
  Image = 'image',
  Geolocation = 'geolocation',
  SmartCode = 'smartcode',
  Analytics = 'analytics',
  Link = 'link',
  Favorite = 'favorite',
  ThinRightArrow = 'thin-right-arrow',
  ShoppingBag = 'shopping-bag-side',
  ShoppingBagOff = 'shopping-bag-side-off',
  CancelOutlined = 'cancel-outlined',
  PencilWrite = 'pencil-write',
  Bin = 'bin',
  Products = 'products',
  Done = 'done',
  SmartCodeDownloadIcon = 'smartcode-download-icon',
  NavEngagements = 'navigation_engagements',
  NavTarget = 'navigation_targets',
  NavNewEngagement = 'navigation_new_engagement',
  NavHelp = 'navigation_help',
  NavSettings = 'navigation_settings',
  NavLogOut = 'navigation_log_out',
  NavLogIn = 'navigation_log_in',
  UserAvatar = 'user-avatar-filled',
  ChevronDown = 'chevron-down',
  LinkTarget = 'link-target',
  ActTarget = 'act-target',
  InfoTarget = 'info-target',
  ShoppingCartTarget = 'shopping-cart-target',
  InstantWeb = 'instant-web',
  InstantCode = 'instant-code',
  WidthCustom = 'width-custom',
  WidthWide = 'width-wide',
  WidthNarrow = 'width-narrow',
  HeightSmall = 'height-small',
  HeightLarge = 'height-large',
  ColourDark = 'colour-dark',
  ColourLight = 'colour-light',
  CornerRound = 'corner-round',
  CornerRounded = 'corner-rounded',
  CornerSquare = 'corner-square',
  Copy = 'copy',
  SmartCodeOpen = 'smartcode-open-icon',
  Download = 'download',
  EyeOn = 'eye_on',
  TargetBuy = 'target-buy',
  TargetBuyTwoTone = 'target-buy-two-tone',
  TargetAct = 'target-act',
  TargetActTwoTone = 'target-act-two-tone',
  TargetInfo = 'target-info',
  TargetInfoTwoTone = 'target-info-two-tone',
  TargetLink = 'target-link',
  TargetLinkTwoTone = 'target-link-two-tone',
  EngagementLink = 'engagement-link',
  EngagementLinkNoBackground = 'engagement-link-no-background',
  EngagementImage = 'engagement-image',
  EngagementImageNoBackground = 'engagement-image-no-background',
  EngagementAudio = 'engagement-audio',
  EngagementAudioNoBackground = 'engagement-audio-no-background',
  EngagementGeolocation = 'engagement-geolocation',
  EngagementGeolocationNoBackground = 'engagement-geolocation-no-background',
  EngagementSmartCode = 'engagement-smartcode',
  EngagementSmartCodeNoBackground = 'engagement-smartcode-no-background',
  EngagementInstantWeb = 'engagement-instantweb',
  EngagementInstantwebNoBackground = 'engagement-instantweb-no-background',
  NavigationLocation = 'navigation_location',
  FolderArrow = 'folder-arrow',
  Duplicate = 'duplicate',
  Edit = 'edit',
  ECOMMERCE = 'eCommerce',
  OpenInFull = 'open_in_full',
  Fullscreen = 'fullscreen',
}

@Injectable({
  providedIn: 'root',
})
export class IconService {
  constructor(private matIconRegistry: MatIconRegistry, private domSanitizer: DomSanitizer) {}

  registerIcons(): void {
    this.loadIcons(Object.values(Icons), '/assets/images');
  }

  private loadIcons(iconKeys: string[], iconUrl: string): void {
    iconKeys.forEach((key) => {
      this.matIconRegistry.addSvgIcon(key, this.domSanitizer.bypassSecurityTrustResourceUrl(`${iconUrl}/${key}.svg`));
    });
  }
}
