import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { AUTH_SERVICE } from '@core/core.module';
import { AuthServiceInterface } from '@core/services/auth/auth-service-inteface';
import { Observable, of, throwError } from 'rxjs';
import { catchError, mergeMap, switchMap } from 'rxjs/operators';

export class TokenInterceptor implements HttpInterceptor {
  constructor(private authService: AuthServiceInterface) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (!request.url.includes('api')) {
      return next.handle(request);
    }

    return this.authService.isAuthenticated$.pipe(
      switchMap((isAuth) => {
        if (isAuth) {
          return this.authService.getTokenSilently$().pipe(
            mergeMap((token) => {
              const tokenReq = request.clone({
                setHeaders: { Authorization: `Bearer ${token}` },
              });
              return next.handle(tokenReq);
            }),
            catchError((err) => {
              // this.authService.login();
              return throwError(err);
            }),
          );
        }

        return next.handle(request);
      }),
    );
  }
}
