import { Component, OnInit } from '@angular/core';
import { MatLegacyDialogRef } from '@angular/material/legacy-dialog';
import { MatStepper } from '@angular/material/stepper';

@Component({
  selector: 'app-on-boarding-popup',
  templateUrl: './on-boarding-popup.component.html',
  styleUrls: ['./on-boarding-popup.component.scss'],
})
export class OnBoardingPopupComponent implements OnInit {
  isLinear = false;
  constructor(public matDialogRef: MatLegacyDialogRef<OnBoardingPopupComponent>) {}

  ngOnInit(): void {}

  goBack(stepper: MatStepper) {
    stepper.previous();
  }

  goForward(stepper: MatStepper) {
    stepper.next();
  }
}
