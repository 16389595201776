export interface AciOnboardingProgressResponse {
  aciOnboardingProgressStatus: AciOnboardingProgressStatusEnum;
  paymentProviderName?: string;
  aciOnboardingInfo: AciOnboardingInfo;
}

export interface AciOnboardingInfo {
  timezone: string;
  currency: string;
  cardBrands: Array<string>,
  phoneNumber: string;
}

export enum AciOnboardingProgressStatusEnum {
  NotStarted = 0,
  AciStarted = 1,
  AciAccountCreated = 2,
  Payment3dSecureConfirmed = 3,
  EcommerceDetailsConfirmed = 4,
}
