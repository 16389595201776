export interface GetMerchantInfoResponse {
  id: number;
  region: string;
  userId: string;
  name: string;
  rceMerchantId: string;
  rcePartnerId: string;
  rceToken: string;
  productsConfig: ProductsConfig;
  paymentCustomerInfo: any;
  rceHost?: string;
  onboardingProcess: OnboardingProcess;
  paymentProviderInfo: PaymentProviderInfo;
}

export interface PaymentProviderInfo {
  name: string;
}

export interface OnboardingProcess {
  hasSubmitted: boolean;
  boMerchantSetup: OnboardingProcessStep;
  pspSetup: OnboardingProcessStep;
  rceMerchantSetup: OnboardingProcessStep;
  rceEcommerceSetup: OnboardingProcessStep;
}
export interface OnboardingProcessStep {
  status: OnboardingProcessStatusEnum;
}

export enum OnboardingProcessStatusEnum {
  Pending,
  Done,
  Failed,
}

interface ProductsConfig {
  hasSkippedEcommerceConfig: boolean;
  ecommerceConfig: EcommerceConfig;
}

interface EcommerceConfig {
  cartId: string;
  storeId: string;
  shopUrl: string;
}

export interface CheckRceHostNameResponse {
  isRceHostNameExist: boolean;
}

export interface BrandInfo {
  strapline: string;
  logoName: string;
  logoNameUrl: string;
  logoBase64: string;
  heroImageBase64: string;
  heroImageName: string;
  heroImageUrl: string;
  brandColor: string;
  accentColor: string;
}
