import { ActDTO } from '@core/models/dtos/act-dto';
import { createAction, props } from '@ngrx/store';

export const provideTargetIdFromRoute = createAction(
  '[InformationTargetPage] Provide targetId from Route',
  props<{ targetId: string }>(),
);

export const getActResponse = createAction(
  '[InformationTargetPage] Getting Act Response',
  props<{ response: ActDTO }>(),
);

export const saveTarget = createAction(
  '[InformationTargetPage] Saving Information Target Page Data',
  props<{ data: ActDTO }>(),
);

export const savePageBuilderData = createAction(
  '[InformationTargetPage] Saving Page Builder Data',
  props<{ data: string }>(),
);

export const showFields = createAction('[InformationTargetPage] Showing Input Fields');

export const clearTargetState = createAction('[InformationTargetPage] Clearing the Information Target Page State');
