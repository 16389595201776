import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule, FlexModule, GridModule } from '@angular/flex-layout';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyCardModule } from '@angular/material/legacy-card';
import { MatLegacyOptionModule } from '@angular/material/legacy-core';
import { MatLegacyMenuModule } from '@angular/material/legacy-menu';
import { MatLegacyTooltipModule } from '@angular/material/legacy-tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { CustomPipeModule } from '@shared/pipes/custom-pipe.module';
import { TargetPagesCardComponent } from './target-pages-card.component';

@NgModule({
  declarations: [TargetPagesCardComponent],
  imports: [
    MatLegacyMenuModule,
    MatLegacyOptionModule,
    MatLegacyCardModule,
    MatIconModule,
    GridModule,
    CommonModule,
    TranslateModule,
    FlexModule,
    FlexLayoutModule,
    MatLegacyTooltipModule,
    CustomPipeModule,
    MatDividerModule,
  ],
  exports: [TargetPagesCardComponent],
})
export class TargetPagesCardModule {}
