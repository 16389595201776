import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatLegacyDialog, MatLegacyDialogConfig } from '@angular/material/legacy-dialog';
import { FileFormats } from '@core/constants/FileFormats';
import { ImageDimensions } from '@core/models/image-dimensions';
import { ThumbnailImage } from '@core/models/thumbnail-image';
import { LoaderService } from '@core/services/loader.service';
import { TranslateService } from '@ngx-translate/core';
import BaseFileUpload from '@shared/components/file-upload/shared/base-file-upload';
import { ImageCropperModalComponent } from '../image-cropper-modal/image-cropper-modal.component';

const MAX_TITLE_LENGTH = 18;

@Component({
  selector: 'app-thumbnail-upload',
  templateUrl: './thumbnail-upload.component.html',
  styleUrls: ['./thumbnail-upload.component.scss'],
})
export class ThumbnailUploadComponent extends BaseFileUpload implements OnInit {
  @Input() thumbnailImage: string = null;
  @Input() isEditMode = false;
  thumbnailSrc: string = null;
  thumbnailFileName: string = null;
  thumbnailImageTooltip = '';
  dimensions: ImageDimensions = {} as ImageDimensions;
  @ViewChild('thumbnailImageUpload') fileInput: ElementRef<HTMLInputElement>;
  @Output() handleImageChange: EventEmitter<ThumbnailImage> = new EventEmitter();

  constructor(
    public loaderService: LoaderService,
    protected translationService: TranslateService,
    private readonly dialog: MatLegacyDialog,
  ) {
    super(
      {
        acceptedFileFormats: [FileFormats.JPEG, FileFormats.JPG, FileFormats.PNG],
        maxFileSize: 5242880,
        translationSectionName: '',
        minImageDimensions: { height: 1024, width: 1024 },
      },
      translationService,
    );
  }

  ngOnInit(): void {
    const thumbnailOnUpdate = !this.thumbnailImage ? 'assets/images/default-thumbnail.png' : this.thumbnailImage;
    this.thumbnailSrc = this.isEditMode ? thumbnailOnUpdate : null;
  }

  onInitialValidationFailed(): void {}

  onLoadingCompleted(): void {}

  uploadFile(formData: FormData): void {}

  async handleUpload(file: FileList) {
    if (!file) {
      return;
    }
    const Img = new Image();
    Img.src = URL.createObjectURL(file.item(0));
    await Img.decode();
    this.dimensions = { width: Img.width, height: Img.height };

    const error = this.getFileValidationErrorsWithParams(file, this.dimensions);
    this.errors = this.getTranslatedErrorMessagesFromKeysAndParams(error);

    if (!error.length) {
      const uploadedFile = file.item(0);
      this.thumbnailSrc = (await this.toBase64(uploadedFile)) as string;
      this.thumbnailImage = this.thumbnailSrc.split('base64,')[1];
      this.thumbnailFileName = uploadedFile.name;
      this.thumbnailImageTooltip = uploadedFile.name.length > MAX_TITLE_LENGTH ? this.thumbnailFileName : '';
      this.openImageCropperModal();
    } else {
      this.onInitialValidationFailed();
    }
  }

  toBase64 = (uploadedFile: File) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(uploadedFile);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  openImageCropperModal() {
    const dialogConfig = new MatLegacyDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.width = '100%';
    dialogConfig.height = '100%';
    dialogConfig.maxWidth = '100%';
    dialogConfig.panelClass = 'image-cropper';
    dialogConfig.data = {
      thumbnailImage: this.thumbnailImage,
      baseImage: this.thumbnailSrc,
      maintainAspectRatio: true,
      cropperMinHeight: 512,
      cropperMinWidth: 512,
      resizeCroppedImage: 512,
    };

    this.dialog
      .open(ImageCropperModalComponent, dialogConfig)
      .afterClosed()
      .subscribe((image) => {
        if (image) {
          this.thumbnailSrc = image.data.croppedImage;
          this.thumbnailImage = this.thumbnailSrc.split('base64,')[1];
          this.handleImageChange.emit({ base64: this.thumbnailImage, name: this.thumbnailFileName });
          return;
        } else {
          // for removing image from the preview
          this.handleImageChange.emit({ base64: null, name: null });
        }

        this.thumbnailImage = null;
        this.thumbnailFileName = null;
        this.thumbnailSrc = null;
        this.thumbnailImageTooltip = null;
        this.fileInput.nativeElement.value = '';

        // for older safari versions
        if (!/safari/i.test(navigator.userAgent)) {
          this.fileInput.nativeElement.type = '';
          this.fileInput.nativeElement.type = 'file';
        }
      });
  }
}
