import { PageBuildingBlockTypeEnum } from '@core/models/enums/page-building-block-type-enum';
import { Guid } from '@core/utils/Guid';
import { CropperPosition } from 'ngx-image-cropper';
import { AssetDefinition } from './asset-definition';
import { PageBuildingBlockStyle } from './page-building-block-style';
import { SelectionOption } from './selection-option';

export class PageBuildingBlock {
  id: string;
  type: PageBuildingBlockTypeEnum;
  name: string;
  required: boolean;
  style?: PageBuildingBlockStyle;
  selectionOptions?: SelectionOption<number>[];
  assetDefinition?: AssetDefinition;
  text?: string;
  url?: string;
  baseImage?: string;
  cropperPosition?: CropperPosition;
  filePath?: string;
  startDateLimit?: Date;
  endDateLimit?: Date;
  onlyShowDaysInFuture?: boolean;
  content?: string;

  constructor(type: PageBuildingBlockTypeEnum, pageBuildingBlock: PageBuildingBlock = null) {
    this.id = pageBuildingBlock ? pageBuildingBlock.id : Guid.newGuid();
    this.type = type;
    this.name = PageBuildingBlockTypeEnum[type];
    this.required = pageBuildingBlock ? pageBuildingBlock.required : false;
    this.text = pageBuildingBlock?.text;
    this.url = pageBuildingBlock?.url;
    this.baseImage = pageBuildingBlock?.baseImage;
    this.cropperPosition = pageBuildingBlock?.cropperPosition;
    this.startDateLimit = pageBuildingBlock?.startDateLimit;
    this.endDateLimit = pageBuildingBlock?.endDateLimit;
    this.onlyShowDaysInFuture = pageBuildingBlock?.onlyShowDaysInFuture;
    this.assetDefinition = pageBuildingBlock?.assetDefinition;
    this.filePath = pageBuildingBlock?.filePath;
    this.content = pageBuildingBlock?.content;
    switch (type) {
      case PageBuildingBlockTypeEnum.Header:
      case PageBuildingBlockTypeEnum.Paragraph:
        this.style = new PageBuildingBlockStyle(pageBuildingBlock?.style);
        break;
      case PageBuildingBlockTypeEnum.Select:
        this.selectionOptions = pageBuildingBlock?.selectionOptions ? [...pageBuildingBlock.selectionOptions] : [];
      default:
        break;
    }
  }

  static createBusinessNameBuildingBlock(name: string = null): PageBuildingBlock {
    const businessName = new PageBuildingBlock(PageBuildingBlockTypeEnum.Header);
    businessName.text = name ? name : 'NAME_OF_BUSINESS';
    businessName.style.backgroundColor = '#f0f5fb';
    businessName.style.textAlign = 'center';
    return businessName;
  }
}
