import { Component, Input, OnInit } from '@angular/core';
import { MatLegacyDialogRef } from '@angular/material/legacy-dialog';
import { PageBuildingBlockTypeEnum } from '@core/models/enums/page-building-block-type-enum';
import { PageBuildingBlockConfig } from 'src/app/features/target-pages/shared/models/page-building-block-config';
import { PageBuildingBlocksPopupConfiguration } from './page-building-blocks-popup.configuration';

@Component({
  selector: 'app-page-building-blocks-popup',
  templateUrl: './page-building-blocks-popup.component.html',
  styleUrls: ['./page-building-blocks-popup.component.scss'],
})
export class PageBuildingBlocksPopupComponent implements OnInit {
  @Input() configuration: PageBuildingBlocksPopupConfiguration;

  constructor(public matDialogRef: MatLegacyDialogRef<PageBuildingBlocksPopupComponent>) {}

  ngOnInit(): void {}

  onCancel(): void {
    this.matDialogRef.close(false);
  }

  addPageBuildingBlock(type: PageBuildingBlockTypeEnum) {
    this.matDialogRef.close(new PageBuildingBlockConfig(type));
  }
}
