import { GoogleMapGeoFence } from '@core/models/google-map-geo-fence';
import { Action, createReducer, on } from '@ngrx/store';
import * as GeolocationActions from '../actions/geolocation.actions';

export interface GeolocationState {
  geoFences: GoogleMapGeoFence[];
  totalArea: number;
}

export const initialState: GeolocationState = {
  geoFences: [],
  totalArea: 0,
};

const geolocationReducer = createReducer(
  initialState,
  on(GeolocationActions.saveGeoFences, (state, { geoFences, totalArea }) => ({
    ...state,
    geoFences,
    totalArea,
  })),
);

export function reducer(state = initialState, action: Action): GeolocationState {
  return geolocationReducer(state, action);
}
