import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SavedTargetPagesComponent } from './saved-target-pages.component';

const routes: Routes = [{ path: '', component: SavedTargetPagesComponent }];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class SavedTargetPagesRoutingModule {}
