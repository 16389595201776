import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatDividerModule } from '@angular/material/divider';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';
import { MatLegacyFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule } from '@angular/material/legacy-input';
import { MatLegacySelectModule } from '@angular/material/legacy-select';
import { MatToolbarModule } from '@angular/material/toolbar';
import { TranslateModule } from '@ngx-translate/core';
import { CustomPipeModule } from '@shared/pipes/custom-pipe.module';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { PageBuildingBlockPreviewComponent } from './page-building-block-preview.component';

@NgModule({
  declarations: [PageBuildingBlockPreviewComponent],
  imports: [
    CommonModule,
    FlexLayoutModule,
    MatToolbarModule,
    MatLegacyButtonModule,
    MatDividerModule,
    MatLegacyInputModule,
    ProgressSpinnerModule,
    MatLegacyFormFieldModule,
    MatLegacySelectModule,
    TranslateModule.forChild(),
    CustomPipeModule,
  ],
  exports: [PageBuildingBlockPreviewComponent],
})
export class PageBuildingBlockPreviewModule {}
