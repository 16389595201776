import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ExtendedModule, FlexLayoutModule, FlexModule, GridModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GoogleMapsModule } from '@angular/google-maps';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';
import { MatLegacyCardModule } from '@angular/material/legacy-card';
import { MatLegacyCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatLegacyDialogModule } from '@angular/material/legacy-dialog';
import { MatLegacyFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule } from '@angular/material/legacy-input';
import { MatLegacyMenuModule } from '@angular/material/legacy-menu';
import { MatLegacySelectModule } from '@angular/material/legacy-select';
import { MatLegacySlideToggleModule } from '@angular/material/legacy-slide-toggle';
import { MatLegacyTooltipModule } from '@angular/material/legacy-tooltip';
import { MatSortModule } from '@angular/material/sort';
import { CustomDirectivesModule } from '@core/directives/custom-directives.module';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from '@shared/shared.module';
import { EditorModule } from '@tinymce/tinymce-angular';
import { AbmModule } from 'angular-baidu-maps';
import { ButtonPreviewComponent } from '../create-instant-buy-button/button-preview/button-preview.component';
import { CreateInstantBuyButtonModalComponent } from '../create-instant-buy-button/create-instant-buy-button-modal.component';
import { InstantButtonOptionComponent } from '../create-instant-buy-button/instant-button-option/instant-button-option.component';
import { DashboardRoutingModule } from '../dashboard/dashboard-routing.module';
import { ConnectProductsStoreModule } from '../products/connect-products-store/connect-products-store.module';
import { StoreImageUploadModule } from '../store-image-upload/store-image-upload.module';
import { TargetPagesModule } from '../target-pages/target-pages.module';
import { Api2CartSettingsComponent } from './api2-cart-settings/api2-cart-settings.component';
import { LocationFormComponent } from './locations/location-form/location-form.component';
import { LocationTableItemComponent } from './locations/location-table-item/location-table-item.component';
import { LocationsComponent } from './locations/locations.component';
import { MerchantPortalConnectionComponent } from './merchant-portal-connection/merchant-portal-connection.component';
import { SellWithRezolveComponent } from './sell-with-rezolve/sell-with-rezolve.component';
import { SettingsComponent } from './settings.component';
import { YourBrandComponent } from './your-brand/your-brand.component';
import { YourDetailsComponent } from './your-details/your-details.component';

@NgModule({
  declarations: [
    SettingsComponent,
    MerchantPortalConnectionComponent,
    YourDetailsComponent,
    Api2CartSettingsComponent,
    SellWithRezolveComponent,
    CreateInstantBuyButtonModalComponent,
    InstantButtonOptionComponent,
    ButtonPreviewComponent,
    YourBrandComponent,
    LocationsComponent,
    LocationTableItemComponent,
    LocationFormComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    ConnectProductsStoreModule,
    DashboardRoutingModule,
    MatIconModule,
    MatLegacyCheckboxModule,
    MatLegacyMenuModule,
    MatDividerModule,
    FlexModule,
    FlexLayoutModule,
    MatSortModule,
    ExtendedModule,
    GridModule,
    MatLegacyButtonModule,
    MatLegacyCardModule,
    FormsModule,
    MatLegacyFormFieldModule,
    ReactiveFormsModule,
    MatLegacyInputModule,
    TranslateModule,
    MatLegacySelectModule,
    CustomDirectivesModule,
    TargetPagesModule,
    CustomDirectivesModule,
    MatLegacyDialogModule,
    MatLegacyTooltipModule,
    GoogleMapsModule,
    AbmModule,
    MatLegacySlideToggleModule,
    StoreImageUploadModule,
    EditorModule,
  ],
  exports: [],
})
export class SettingsModule {}
