import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { GridModule } from '@angular/flex-layout';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { BaseOverlayCardMessageModule } from '@shared/components/base-overlay-card-message/base-overlay-card-message.module';
import { BaseCardComponent } from './base-card.component';

@NgModule({
  declarations: [BaseCardComponent],
  imports: [CommonModule, BaseOverlayCardMessageModule, MatIconModule, TranslateModule, GridModule, RouterModule],
  exports: [BaseCardComponent],
})
export class BaseCardModule {}
