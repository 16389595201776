import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TargetPageCreationComponent } from './target-page-creation.component';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [TargetPageCreationComponent],
  imports: [CommonModule, RouterModule],
  exports: [TargetPageCreationComponent],
})
export class TargetPageCreationModule {}
