import { HttpClient, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { MatLegacyPaginatorIntl } from '@angular/material/legacy-paginator';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { CoreModule } from '@core/core.module';
import { CustomRouteReuseStrategy } from '@core/utils/custom-route-reuse-strategy';
import { MatPaginatorIntlLoc } from '@core/utils/mat-paginator-intl-loc';
import { environment } from '@env/environment';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { MultiTranslateHttpLoader } from 'ngx-translate-multi-http-loader';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthModule } from './features/auth/auth.module';
import { DashboardModule } from './features/dashboard/dashboard.module';
import { EngagementCreationModule } from './features/engagement-creation/engagement-creation.module';
import { NavbarModule } from './features/navbar/navbar.module';
import { ProductsModule } from './features/products/products.module';
import { SelfOnboardingModule } from './features/settings/self-onboarding/self-onboarding.module';
import { SettingsRoutingModule } from './features/settings/settings-routing.module';
import { SettingsModule } from './features/settings/settings.module';
import { StoreImageUploadModule } from './features/store-image-upload/store-image-upload.module';
import { TargetPageCreationModule } from './features/target-page-creation/target-page-creation.module';
import { TriggersModule } from './features/triggers/triggers.module';
import { WelcomeOnboarderModule } from './features/welcome-onboarder/welcome-onboarder.module';
import { WizardEffects } from './store/effects/wizard.effects';
import { metaReducers, reducers } from './store/store';

export function HttpLoaderFactory(http: HttpClient) {
  const translations = [
    { prefix: './assets/i18n/', suffix: '.json' },
    { prefix: './assets/links/', suffix: '.json' },
  ];

  if (environment['customName']) {
    translations.push({ prefix: `./assets/i18n/${environment['customName']}/`, suffix: '.json' });
  }

  return new MultiTranslateHttpLoader(http, translations);
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    CoreModule,
    HttpClientModule,
    AppRoutingModule,
    SettingsRoutingModule,
    NavbarModule,
    AuthModule,
    DashboardModule,
    SettingsModule,
    EngagementCreationModule,
    TargetPageCreationModule,
    TriggersModule,
    ProductsModule,
    SelfOnboardingModule,
    WelcomeOnboarderModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    StoreModule.forRoot(reducers, { metaReducers: metaReducers(false) }),
    StoreDevtoolsModule.instrument({
      maxAge: 25, // Retains last 25 states
      logOnly: environment.production, // Restrict extension to log-only mode
    }),
    EffectsModule.forRoot([WizardEffects]),
    StoreImageUploadModule,
  ],
  exports: [StoreModule],
  providers: [
    TranslateService,
    { provide: MatLegacyPaginatorIntl, useClass: MatPaginatorIntlLoc },
    { provide: RouteReuseStrategy, useClass: CustomRouteReuseStrategy },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
