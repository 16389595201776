import { Component, Input } from '@angular/core';

export interface LinkData {
  id: string;
  type: 'url' | 'on-click-function';
  name: string;
  value: string | (() => void);
  tenant?: string;
}
@Component({
  selector: 'app-links-container',
  templateUrl: './links-container.component.html',
  styleUrls: ['./links-container.component.scss'],
})
export class LinksContainerComponent {
  @Input() links: LinkData[];

  constructor() {}

  onFunctionLinkClick(link: LinkData) {
    if (typeof link.value == 'function') {
      link.value();
    }
  }

  getLinkValue(link: LinkData): string {
    const result = link.tenant ? `${link.value}_${link.tenant}` : link.value;
    return result.toString();
  }
}
