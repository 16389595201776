import { Component, OnInit } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { SETTINGS_CARD_PROVIDERS } from '@core/constants/CardProviders';
import Route from '@core/constants/route';
import {
  AciOnboardingProgressResponse,
  AciOnboardingProgressStatusEnum,
} from '@core/models/get-aci-onboarding-status-response';
import { GetMerchantInfoResponse } from '@core/models/get-merchant-info-response';
import { AuthService } from '@core/services/auth/auth.service';
import { AutoProvisioningService } from '@core/services/auto-provisioning.service';
import { MerchantAPIService } from '@core/services/merchant-api.service';
import { Store } from '@ngrx/store';
import { BaseComponent } from '@shared/components/base-component/base-component';
import { Observable, of } from 'rxjs';
import { filter, map, shareReplay, switchMap, takeUntil, tap } from 'rxjs/operators';
import { getWizardSteps, MenuWizardService } from './menu-wizard/menu-wizard-service/menu-wizard.service';
import { MenuWizardStep, without3dSecure, wizardSteps } from './menu-wizard/menu-wizard.model';
import {
  GetPaymentConnectionDictionary,
  PaymentType,
  paymentWithout3dSecure,
} from './payment-settings/payment-connection-models';
import { SelfOnboardingState } from './store';
import { updateAciPaymentProviderDetails } from './store/actions/aci-payment-provider-details.actions';
import { selectPaymentProvider } from './store/actions/select-payment-provider.action';

@Component({
  selector: 'app-self-onboarding',
  templateUrl: './self-onboarding.component.html',
})
export class SelfOnboardingComponent extends BaseComponent implements OnInit {
  merchantDetails$: Observable<GetMerchantInfoResponse>;
  paymentProviderDictionary = GetPaymentConnectionDictionary();

  constructor(
    private readonly router: Router,
    private readonly wizardService: MenuWizardService,
    private readonly merchantService: MerchantAPIService,
    private readonly authService: AuthService,
    private readonly store: Store<SelfOnboardingState>,
    private readonly autoProvisioningService: AutoProvisioningService,
  ) {
    super();
  }

  async ngOnInit() {
    const referenceUrl = this.router.url;
    if (referenceUrl.includes('PayPal') && referenceUrl.includes(Route.SELECT_ECOMMERCE_PLATFORM)) {
      await this.autoProvisioningService.updateMerchantStatus(AciOnboardingProgressStatusEnum.AciAccountCreated);
    }
    // avoides user to manipulate the url and break the screen
    this.wizardService
      .getCurrentStepInfo$()
      .pipe(takeUntil(this.destroy$))
      .subscribe((info) => {
        if (!this.router.url.includes(info.url)) {
          this.router.navigate([Route.SETTINGS, Route.SELF_ON_BOARDING, info.url]);
        }
      });

    const merchantDetails$ = this.merchantService.getMerchantInfo().pipe(shareReplay());

    this.merchantDetails$ = merchantDetails$.pipe(
      tap(({ onboardingProcess }): void => {
        if (onboardingProcess && onboardingProcess.hasSubmitted && onboardingProcess.rceEcommerceSetup) {
          this.wizardService.markAsSubmitted();
          return;
        }
      }),
    );

    this.setCompletedStep(merchantDetails$);
  }

  onStepChange(event: MenuWizardStep) {
    if (event && event.url) {
      this.router.navigate([Route.SETTINGS, Route.SELF_ON_BOARDING, event.url]);
    }
  }

  private setCompletedStep(merchantDetails$: Observable<GetMerchantInfoResponse>) {
    merchantDetails$
      .pipe(
        switchMap(({ paymentCustomerInfo }) => {
          if (!this.wizardService.isDirty) {
            return this.merchantService
              .getAciOnBoardingProgressStatus()
              .pipe(
                switchMap((info) =>
                  this.authService.userProfile$.pipe(map((profile) => ({ info, paymentCustomerInfo, profile }))),
                ),
              );
          }
          return of(undefined);
        }),
        filter((res) => !!res),
        takeUntil(this.destroy$),
      )
      .subscribe(({ info, paymentCustomerInfo, profile }) => {
        if (info.paymentProviderName) {
          this.selectPaymentProvider(info);
          this.setAciDetails(info, paymentCustomerInfo, profile);
        }
        let steps = [...wizardSteps];
        // dispatch flow update
        if (paymentWithout3dSecure.includes(info.paymentProviderName)) {
          steps = [...without3dSecure(steps)];
        }

        steps = [...getWizardSteps(steps, info.aciOnboardingProgressStatus)];

        this.wizardService.init(steps);
      });
  }

  private setAciDetails(info: AciOnboardingProgressResponse, paymentCustomerInfo: any, profile: any) {
    const customerInfo = paymentCustomerInfo;
    const addressData = customerInfo?.paymentCardBillingAddress;
    const fullAddress = addressData
      ? `${addressData.street1}, ${addressData.state} ${addressData.zip}, ${addressData.countryCode}`
      : '';
    const brands = this.getSelectedCards(info);
    this.store.dispatch(
      updateAciPaymentProviderDetails({
        timezone: info.aciOnboardingInfo?.timezone,
        phone: info.aciOnboardingInfo?.phoneNumber,
        currencyForPayment: info.aciOnboardingInfo?.currency,
        contactName: customerInfo?.businessName,
        contactNumber: customerInfo?.phone,
        contactAddress: fullAddress,
        contactEmail: profile?.email,
        termsAndConditionsUrl: paymentCustomerInfo?.termsAndConditionsUrl,
        privacyPolicyUrl: paymentCustomerInfo?.privacyPolicyUrl,
        companyInformationUrl: paymentCustomerInfo?.companyInformationUrl,
        paymentProviderDetails: brands,
        paymentProviderName: info.paymentProviderName,
      }),
    );
  }

  private getSelectedCards(info: AciOnboardingProgressResponse): any {
    const brands = {};
    if (!info.aciOnboardingInfo.cardBrands) {
      return brands;
    }
    for (const definedBrand of SETTINGS_CARD_PROVIDERS) {
      let isBrandDefined = false;
      for (const brand in info.aciOnboardingInfo.cardBrands) {
        if (definedBrand.key === brand) {
          brands[brand] = true;
          isBrandDefined = true;
        }
        if (!isBrandDefined) {
          brands[definedBrand.key] = false;
        }
      }
    }
    return brands;
  }

  private selectPaymentProvider(info: AciOnboardingProgressResponse) {
    const connection = this.paymentProviderDictionary[info.paymentProviderName as PaymentType];
    this.store.dispatch(
      selectPaymentProvider({
        paymentDto: {
          providerName: info.paymentProviderName,
          providerLogoUrl: connection?.logo,
          selectedPaymentPlatform: { [info.paymentProviderName]: connection },
        },
      }),
    );
  }
}
