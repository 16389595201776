import { Component, HostListener, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import Route from '@core/constants/route';
import { AppSettingService } from '@core/services/app-setting.service';
import { LoaderService } from '@core/services/loader.service';
import { TranslateService } from '@ngx-translate/core';
import { BaseComponent } from '@shared/components/base-component/base-component';
import { LinkData } from '@shared/components/links-container/links-container.component';
import { PopupService } from '@shared/components/popup/popup.service';
import { Observable } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

const EngagementCreationLinks: LinkData[] = [
  { id: 'terms-and-conditions-link', name: 'TERMS_&_CONDITIONS', value: 'TERMS_&_CONDITIONS_URL', type: 'url' },
  { id: 'privacy-policy-link', name: 'PRIVACY_POLICY', value: 'PRIVACY_POLICY_URL', type: 'url' },
  { id: 'contact-us-link', name: 'CONTACT_US', value: 'CONTACT_US_URL', type: 'url' },
];

@Component({
  selector: 'app-engagement-creation',
  templateUrl: './engagement-creation.component.html',
  styleUrls: ['./engagement-creation.component.scss'],
})
export class EngagementCreationComponent extends BaseComponent implements OnInit {
  loading$: Observable<boolean>;
  showFooter: boolean;
  links: any[] = [];
  selectedLanguage: string;
  selectedEngagement: string;

  constructor(
    private popupService: PopupService,
    public loaderService: LoaderService,
    private readonly router: Router,
    private readonly translateService: TranslateService,
    private readonly appSettingService: AppSettingService,
  ) {
    super();

    this.links = this.addLinkTenantPostfix(EngagementCreationLinks, this.appSettingService.tenant);

    this.router.events
      .pipe(
        filter((e): e is NavigationEnd => e instanceof NavigationEnd),
        takeUntil(this.destroy$),
      )
      .subscribe((route) => {
        this.selectedEngagement = route.urlAfterRedirects;
        this.showFooter = route.urlAfterRedirects === `/${Route.ENGAGEMENT_CREATION}`;
      });

    this.translateService.onLangChange.pipe(takeUntil(this.destroy$)).subscribe((event) => {
      this.links = this.getTranslatedLinks(event.translations);
    });

    this.loading$ = this.loaderService.isLoading('auth');
  }

  @HostListener('window:beforeunload', ['$event'])
  onBrowserRefresh(event: BeforeUnloadEvent) {
    if (!this.showFooter) {
      event.preventDefault();
      event.returnValue = '';
    }
  }

  ngOnInit(): void {
    this.translateService
      .getTranslation(this.translateService.currentLang)
      .pipe(takeUntil(this.destroy$))
      .subscribe((translation) => {
        this.links = this.getTranslatedLinks(translation);
      });
    this.appSettingService.isLoaded$.pipe(takeUntil(this.destroy$)).subscribe((out) => {
      if (out) {
        this.links = this.addLinkTenantPostfix(EngagementCreationLinks, this.appSettingService.tenant);
      }
    });
  }

  openOnBoarding() {
    this.selectedLanguage = this.translateService.currentLang;
    this.popupService.onBoardingPopup(this.selectedLanguage);
  }

  addLinkTenantPostfix(links: LinkData[], tenant: string): LinkData[] {
    return links.map((linkData) => {
      linkData.tenant = tenant.toUpperCase();
      return linkData;
    });
  }

  getTranslatedLinks(translation: any): LinkData[] {
    return EngagementCreationLinks.filter((link) => translation[link.name] != null);
  }
}
