import { ShopType } from '@core/models/update-merchant-product-config-request';
import { Action, createReducer, on } from '@ngrx/store';
import { Connection } from '../../../../products/shop-connection-models';
import * as EcommercePlatformActions from '../actions/ecommerce-platform.actions';

export interface EcommercePlatformState {
  selectedEcommercePlatform: { [type in ShopType]: Connection } | null;
}

export const initialState: EcommercePlatformState = {
  selectedEcommercePlatform: null,
};

const ecommercePlatformReducer = createReducer(
  initialState,
  on(EcommercePlatformActions.createSelectEcommercePlatform, (state, { selectedEcommercePlatform }) => ({
    ...state,
    selectedEcommercePlatform,
  })),
  on(EcommercePlatformActions.resetEcommercePlatformState, () => ({
    ...initialState,
  })),
);

export function reducer(state = initialState, action: Action): EcommercePlatformState {
  return ecommercePlatformReducer(state, action);
}
