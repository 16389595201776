import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Endpoints } from '@core/constants/Endpoints';
import { LocationDto } from '@core/models/dtos/location-dto';
import { LocationTagDto } from '@core/models/dtos/location-tag-dto';
import { Observable } from 'rxjs';
import Route from '@core/constants/route';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class LocationsService {
  constructor(protected http: HttpClient, private router: Router,
  ) {}

  getTags(): Observable<LocationTagDto[]> {
    return this.http.get<LocationTagDto[]>(Endpoints.LOCATION_TAGS);
  }

  getTypes(): Observable<string[]> {
    return this.http.get<string[]>(Endpoints.LOCATIONS_TYPES);
  }

  getLocationByType(locationType: string): Observable<LocationDto[]> {
    return this.http.get<LocationDto[]>(`${Endpoints.LOCATIONS_BY_TYPE}?locationType=${locationType}`);
  }

  deleteTags(tagId: string): Observable<any> {
    return this.http.delete(`${Endpoints.LOCATION_TAGS}?tagId=${tagId}`);
  }
  addTags(request: string[]): Observable<any> {
    return this.http.post<string[]>(Endpoints.LOCATION_TAGS, request);
  }

  getLocation(id: string): Observable<LocationDto> {
    return this.http.get<LocationDto>(`${Endpoints.LOCATION}/${id}`);
  }

  getLocations(name: string, tag: string, active: boolean, locationType?: string): Observable<LocationDto[]> {
    let params = new HttpParams();
    if (name) {
      params = params.set('name', name);
    }

    if (tag) {
      params = params.set('tag', tag);
    }

    if (locationType) {
      params = params.set('locationType', locationType);
    }

    params = params.set('active', active.toString());
    return this.http.get<LocationDto[]>(`${Endpoints.SEARCH_LOCATION}`, { params });
  }
  getLocationsWithinRange(distance: number, lat: number, lng: number): Observable<LocationDto[]> {
    const params = new HttpParams()
      .set('distance', distance.toString())
      .set('lat', lat.toString())
      .set('lng', lng.toString());

    return this.http.get<LocationDto[]>(`${Endpoints.SEARCH_LOCATION}`, { params });
  }
  deleteLocation(locationId: string): Observable<any> {
    return this.http.delete(`${Endpoints.LOCATION}/${locationId}`);
  }
  updateLocation(request: LocationDto, locationId: string): Observable<any> {
    return this.http.put(`${Endpoints.LOCATION}/${locationId}`, request);
  }
  createLocation(request: LocationDto): Observable<any> {
    return this.http.post(`${Endpoints.LOCATION}`, request);
  }

  navigateToEditLocation(id: string): void {
    this.router.navigate([`${Route.LOCATION_CREATION}`], { queryParams: { id } });
  }
}
