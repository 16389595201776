import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule } from '@angular/forms';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';
import { MatLegacyCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatLegacyDialogModule } from '@angular/material/legacy-dialog';
import { TranslateModule } from '@ngx-translate/core';
import { ConfirmButtonModule } from '@shared/components/confirm-button/confirm-button.module';
import { TextFieldCustomisationPopupComponent } from './text-field-customisation-popup.component';

@NgModule({
  declarations: [TextFieldCustomisationPopupComponent],
  imports: [
    CommonModule,
    FlexLayoutModule,
    MatLegacyDialogModule,
    MatLegacyButtonModule,
    FormsModule,
    MatLegacyCheckboxModule,
    TranslateModule.forChild(),
    ConfirmButtonModule,
  ],
  exports: [TextFieldCustomisationPopupComponent],
})
export class TextFieldCustomisationPopupModule {}
