import Route from '@core/constants/route';
import GenericEngagement from '@core/models/engagement/generic-engagement';
import { EngagementTypeEnum } from '@core/models/enums/engagement-type-enum';
import { AppSettingService } from '@core/services/app-setting.service';
import { PaymentApiService } from '@core/services/payment-api.service';
import { of } from 'rxjs';
import { Guid } from '@core/utils/Guid';
import { PricingTargetTypeEnum } from '@core/models/enums/pricing-target-type.enum';

export default class InstantWebEngagement extends GenericEngagement {
  constructor(paymentApiService: PaymentApiService, appSettingService: AppSettingService) {
    super(
      paymentApiService,
      appSettingService,
      EngagementTypeEnum.InstantWeb,
      PricingTargetTypeEnum.InstantWebEngagement,
      [Route.TRIGGERS, Route.INSTANT_WEB_TRIGGER, Route.TARGETS, Route.CHECKOUT, Route.MAKE_PAYMENT],
    );
  }

  getPayload$() {
    return of({
      id: Guid.newGuid(),
      actInformation: this.actInformation,
      activeTrigger: this.activeTrigger,
      deferredPayment: true,
      durationInDays: this.duration$.value.durationInDaysTotal,
    });
  }

  getTrigger() {
    return {
      imageUrl: 'assets/images/confirmation-instant-checkout-icon.jpg',
      name: EngagementTypeEnum[EngagementTypeEnum.InstantWeb].toUpperCase(),
      type: EngagementTypeEnum.InstantWeb,
      svgIcon: 'engagement-instantweb-no-background',
    };
  }
}
