import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ExtendedModule, FlexModule, GridModule } from '@angular/flex-layout';
import { FormsModule } from '@angular/forms';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';
import { MatLegacyCardModule } from '@angular/material/legacy-card';
import { MatLegacyCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatLegacyMenuModule } from '@angular/material/legacy-menu';
import { MatLegacyTooltipModule } from '@angular/material/legacy-tooltip';
import { MatSortModule } from '@angular/material/sort';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from '@shared/shared.module';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { WelcomeOnboarderModule } from '../welcome-onboarder/welcome-onboarder.module';
import { TargetPagesCardModule } from './custom-card/custom-card.module';
import { DashboardRoutingModule } from './dashboard-routing.module';
import { DashboardComponent } from './dashboard.component';

@NgModule({
  declarations: [DashboardComponent],
  imports: [
    CommonModule,
    SharedModule,
    DashboardRoutingModule,
    MatIconModule,
    MatLegacyCheckboxModule,
    MatLegacyMenuModule,
    MatDividerModule,
    FlexModule,
    MatSortModule,
    ExtendedModule,
    GridModule,
    MatLegacyButtonModule,
    MatLegacyCardModule,
    FormsModule,
    ProgressSpinnerModule,
    MatLegacyTooltipModule,
    TranslateModule.forChild(),
    WelcomeOnboarderModule,
    TargetPagesCardModule,
  ],
})
export class DashboardModule {}
