import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { selectSelfOnboardingState, SelfOnboardingState } from '../store';

@Component({
  selector: 'payment-provider-base',
  templateUrl: './payment-provider-base.component.html'
})
export class PaymentProviderBaseComponent {
  isOtherProviderSelected = true;
  constructor(private readonly store: Store<SelfOnboardingState>) {
    this.isOtherProviderSelected = true;
    this.store.select(selectSelfOnboardingState).subscribe((res) => {
      if (res.selectedPaymentProvider.providerName === 'Computop') {
        this.isOtherProviderSelected = false;
      } else {
        this.isOtherProviderSelected = true;
      }
    });
  }
}
