import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { FileFormats } from '@core/constants/FileFormats';
import { FileUploadForWatermarkingResult } from '@core/models/file-upload-for-watermarking-result';
import { EngagementAPIService } from '@core/services/engagement-api.service';
import { LoaderService } from '@core/services/loader.service';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import { take } from 'rxjs/operators';
import { LogUtils } from 'src/app/utils/log-utils';
import BaseFileUpload from '../shared/base-file-upload';
import { FileUploadTypeEnum } from '../shared/file-upload-configuration';

@Component({
  selector: 'app-audio-file-upload',
  templateUrl: './audio-file-upload.component.html',
  styleUrls: ['./audio-file-upload.component.scss'],
})
export class AudioFileUploadComponent extends BaseFileUpload implements OnChanges {
  @Input() savedFile: File = null;
  @Output() uploadResultEvent: EventEmitter<FileUploadForWatermarkingResult> = new EventEmitter();

  uploadType = FileUploadTypeEnum.AUDIO;
  loading$: Observable<boolean>;

  constructor(
    protected translationService: TranslateService,
    protected engagementApiService: EngagementAPIService,
    public loaderService: LoaderService,
  ) {
    super(
      {
        acceptedFileFormats: [FileFormats.MP3, FileFormats.WAV, FileFormats.XWAV],
        maxFileSize: 150000000,
        translationSectionName: 'TEXTDISPLAYFORAUDIOVALIDATION',
      },
      translationService,
    );
    this.loaderService.hide();
    this.loading$ = this.loaderService.isLoading();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.savedFile && this.savedFile) {
      this.errors = of([]);
      this.file = this.savedFile;
      this.previewFile = true;
    }
  }

  uploadFile(formData: FormData): void {
    this.uploadResultEvent.emit(new FileUploadForWatermarkingResult());
    this.loaderService.show();
    this.engagementApiService
      .uploadAudioFileForWatermarking(formData)
      .pipe(take(1))
      .subscribe(
        ({ isSuccess, validationErrors, fileUrl }) => {
          if (!isSuccess) {
            this.showUploadErrors(validationErrors);
          }

          this.uploadResultEvent.emit(new FileUploadForWatermarkingResult(isSuccess, fileUrl, [], this.file));
          this.loaderService.hide();
          this.previewFile = isSuccess;
        },
        (error) => {
          LogUtils.error('Error while uploading Image File For Watermarking:' + error);
          this.loaderService.hide();
        },
      );
  }

  onLoadingCompleted(): void {
    this.loaderService.hide();
  }

  onInitialValidationFailed(): void {
    this.uploadResultEvent.emit(new FileUploadForWatermarkingResult(false, null, []));
  }
}
