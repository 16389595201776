import Partners from '@core/constants/Partners';
import Route from '@core/constants/route';
import { AutoProvisioningService } from '@core/services/auto-provisioning.service';
import { environment } from '@env/environment';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { PopupService } from '@shared/components/popup/popup.service';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { PayPalProviderName } from '../payment-settings/payment-connection-models';
import { getSelectedPaymentProvider } from '../store/selectors/payment-provider-details.select';

interface MenuWizardLink {
  linkUrl?: string;
  linkText: string;
  linkDescription: string;
}
export enum MenuWizardStepTypeEnum {
  Basic,
  Wizard,
}
export interface MenuWizardStepInfo {
  title: string;
  subWizardTitle: string;
  subTitle?: string;
  helpLinkPrimary?: string;
  helpLinkSecondary?: string;
  links?: MenuWizardLink[];
  menuWizardInfoCardText?: string;
  menuWizardActions?: number;
  hideStepTitle?: boolean;
  hideWizardMenu?: boolean;
}
export interface MenuWizardStep {
  state: MenuWizardStateEnum;
  type: MenuWizardStepTypeEnum;
  info?: MenuWizardStepInfo;
  step: number;
  canGoToNextStep: boolean;
  url?: string;
  shouldSkip?: boolean;
  canSkip?: boolean;
  actionOnBack?: (service: AutoProvisioningService) => Observable<any>;
  actionOnNext?: (translateService: TranslateService, popupService: PopupService, store: Store) => Observable<boolean>;
}

export enum MenuWizardActions {
  Cancel = 1 << 0,
  Back = 1 << 1,
  Next = 1 << 2,
  Confirm = 1 << 3,
}
export enum MenuWizardStateEnum {
  Done,
  Active,
  Pending,
}
export enum MenuWizardEventTypeEnum {
  StepChange,
  Cancel,
  OutOfBound,
}
const defaultActions = MenuWizardActions.Back | MenuWizardActions.Cancel | MenuWizardActions.Next;
const ecommercePlatformActions = MenuWizardActions.Back | MenuWizardActions.Cancel;
const confirmPageActions = MenuWizardActions.Back | MenuWizardActions.Cancel | MenuWizardActions.Confirm;
export interface MenuWizardEvent {
  type: MenuWizardEventTypeEnum;
  data?: MenuWizardStep;
}
export const wizardSteps: MenuWizardStep[] = [
  {
    info: {
      title: 'ACCEPT_PAYMENTS_WITH_REZOLVE',
      subWizardTitle: 'SELECT_PAYMENT_PROVIDER',
      subTitle: 'SELECT_YOUR_PAYMENT_PROVIDER',
      helpLinkPrimary: 'PAYMENT_PROVIDER_NOT_LISTED',
      helpLinkSecondary: 'THINKING_OF_SELLING_ONLINE',
      hideWizardMenu: false,
      menuWizardActions: defaultActions,
    },
    state: MenuWizardStateEnum.Active,
    step: 0,
    canGoToNextStep: true,
    url: Route.SELECT_PAYMENT_PROVIDER,
    type: MenuWizardStepTypeEnum.Wizard,
  },
  {
    info: {
      title: 'PAYMENT_DETAILS',
      subWizardTitle: 'PAYMENT_DETAILS',
      menuWizardInfoCardText: 'YOUR_DATA_WILL_BE_ENCRYPTED_AND_STORED_SECURELY',
      menuWizardActions: defaultActions,
    },
    state: MenuWizardStateEnum.Pending,
    step: 1,
    canGoToNextStep: false,
    url: Route.PROVIDER_DETAILS,
    type: MenuWizardStepTypeEnum.Wizard,
    actionOnNext: (translateService: TranslateService, popupService: PopupService, store: Store) =>
      translateService.get('YOU_WILL_NOW_BE_REDIRECTED_TO_CONNECT_YOUR_PAYPAL_ACCOUNT_WITH_REZOLVE').pipe(
        switchMap((translation) =>
          store.select(getSelectedPaymentProvider).pipe(
            switchMap((paymentProvider) => {
              return paymentProvider.providerName === PayPalProviderName
                ? popupService.simpleConfirmationPopup({
                    maxWidth: '320px',
                    width: 'auto',
                    height: 'auto',
                    componentConfiguration: {
                      messageList: [{ message: translation, shoudlWrap: false }],
                      showDeleteIcon: false,
                    },
                  })
                : of(true);
            }),
          ),
        ),
      ),
  },
  {
    info: {
      title: '3D_SECURE.3D_SECURE_SETUP',
      subWizardTitle: '3D_SECURE.3D_SECURE_SETUP',
      menuWizardInfoCardText: 'YOUR_DATA_WILL_BE_ENCRYPTED_AND_STORED_SECURELY',
      menuWizardActions: defaultActions,
    },
    state: MenuWizardStateEnum.Pending,
    step: 2,
    canGoToNextStep: false,
    url: Route.SECURE_SETUP,
    type: MenuWizardStepTypeEnum.Wizard,
    canSkip: environment.partner === Partners.ACI,
    actionOnBack: (service: AutoProvisioningService) => service.resetOnboardingStep(),
  },
  {
    step: 3,
    info: {
      title: 'SELECT_ECOMMERCE_PLATFORM',
      subWizardTitle: 'SELECT_ECOMMERCE_PLATFORM',
      menuWizardActions: defaultActions,
      hideStepTitle: true,
    },
    url: Route.SELECT_ECOMMERCE_PLATFORM,
    type: MenuWizardStepTypeEnum.Wizard,
    canGoToNextStep: false,
    state: MenuWizardStateEnum.Pending,
    actionOnBack: (service: AutoProvisioningService) => service.resetOnboardingStep(),
  },
  {
    info: {
      title: 'ECOMMERCE_CONNECTION',
      subWizardTitle: 'ECOMMERCE_CONNECTION',
      menuWizardActions: defaultActions,
      hideStepTitle: true,
    },
    state: MenuWizardStateEnum.Pending,
    step: 4,
    url: Route.PLATFORM_DETAIL,
    canGoToNextStep: false,
    type: MenuWizardStepTypeEnum.Wizard,
  },
  {
    info: {
      title: 'CONFIRMATION',
      subWizardTitle: 'CONFIRMATION',
      subTitle: 'PLEASE_CONFIRM_YOUR_DETAILS',
      menuWizardActions: confirmPageActions,
    },
    state: MenuWizardStateEnum.Pending,
    step: 5,
    url: Route.CONFIRMATION,
    canGoToNextStep: true,
    type: MenuWizardStepTypeEnum.Wizard,
  },
  {
    type: MenuWizardStepTypeEnum.Basic,
    url: Route.CONFIRMATION_MESSAGE,
    state: MenuWizardStateEnum.Pending,
    step: 6,
    canGoToNextStep: true,
  },
];

export const without3dSecure = (wizardSteps: MenuWizardStep[]) => {
  const steps = [];
  let step = 0;
  for (let index = 0; index < wizardSteps.length; index++) {
    const element = wizardSteps[index];

    if (element.info?.title !== '3D_SECURE.3D_SECURE_SETUP') {
      steps.push({ ...element, step });
      step++;
    }
  }

  return steps;
};
