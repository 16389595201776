// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.hidden {
  display: none;
}

.wizard {
  display: flex;
  margin-bottom: 16px;
}
.wizard .step {
  flex-grow: 1;
}
.wizard .step.active-step {
  flex-grow: 2;
}
.wizard .step:last-child {
  border-right: none;
  flex-grow: 0;
}`, "",{"version":3,"sources":["webpack://./src/app/features/engagement-creation/wizard/wizard.component.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;AADF;;AAIA;EACE,aAAA;EACA,mBAAA;AADF;AAGE;EACE,YAAA;AADJ;AAGI;EACE,YAAA;AADN;AAIE;EACE,kBAAA;EACA,YAAA;AAFJ","sourcesContent":["@import 'variables';\n\n.hidden {\n  display: none;\n}\n\n.wizard {\n  display: flex;\n  margin-bottom: 16px;\n\n  .step {\n    flex-grow: 1;\n\n    &.active-step {\n      flex-grow: 2;\n    }\n  }\n  .step:last-child {\n    border-right: none;\n    flex-grow: 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
