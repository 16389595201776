import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { BaseComponent } from '../base-component/base-component';
import { BottomBar } from './creation-container.settings';
import { GenericCreationContainerService } from './generic-creation-container.service';

@Component({
  selector: 'app-generic-creation-container',
  templateUrl: './generic-creation-container.component.html',
  styleUrls: ['./generic-creation-container.component.scss'],
})
export class GenericCreationContainerComponent extends BaseComponent implements OnInit {
  @Input() header = 'Sample header';
  @Input() nextDisabled: boolean;
  @Input() hideBottomBarDetails: boolean = false;
  @Input() showCloseDialog: boolean = false;
  bottomBar: BottomBar;
  isProcessing$: Observable<boolean>;

  constructor(public genericCreationContainerService: GenericCreationContainerService) {
    super();
    this.isProcessing$ = genericCreationContainerService.isProcessing$.pipe(
      tap((val) => {
        console.log('isProcessing values', val);
      }),
    );
    genericCreationContainerService
      .getCurrentStepInfo()
      .pipe(takeUntil(this.destroy$))
      .subscribe(({ bottomBar }) => {
        this.bottomBar = bottomBar;
      });
  }

  ngOnInit(): void {}

  navigateBack() {
    this.genericCreationContainerService.goToPreviousStep();
  }

  closeDialog() {
    this.genericCreationContainerService.closeDialog();
  }

  navigateNext() {
    this.genericCreationContainerService.nextStepRequest();
  }
}
