import { Component, Input, OnInit } from '@angular/core';
import { MatLegacyDialogRef } from '@angular/material/legacy-dialog';
import { ImagePreviewPopupConfiguration } from './image-preview-popup.configuration';

@Component({
  selector: 'app-image-preview-popup',
  templateUrl: './image-preview-popup.component.html',
  styleUrls: ['./image-preview-popup.component.scss'],
})
export class ImagePreviewPopupComponent implements OnInit {
  @Input() configuration: ImagePreviewPopupConfiguration;

  constructor(public matDialogRef: MatLegacyDialogRef<ImagePreviewPopupComponent>) {}

  ngOnInit(): void {}
}
