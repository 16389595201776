import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyAutocompleteModule } from '@angular/material/legacy-autocomplete';
import { MatLegacyChipsModule } from '@angular/material/legacy-chips';
import { MatLegacyFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule } from '@angular/material/legacy-input';
import { TranslateModule } from '@ngx-translate/core';
import { ChooseCategoryComponent } from '../choose-category/choose-category.component';
import { SearchInputModule } from '../search-input/search-input.module';
import { TreeCheckListModule } from '../tree-checklist/tree-checklist.module';

@NgModule({
  declarations: [ChooseCategoryComponent],
  imports: [
    CommonModule,
    MatLegacyAutocompleteModule,
    FormsModule,
    MatLegacyChipsModule,
    MatIconModule,
    FormsModule,
    ReactiveFormsModule,
    TreeCheckListModule,
    MatLegacyFormFieldModule,
    MatLegacyInputModule,
    SearchInputModule,
    TranslateModule.forChild(),
  ],
  exports: [ChooseCategoryComponent],
})
export class ChooseCategoryModule {}
