import { ValidatorFn, Validators } from '@angular/forms';
import { EnvironmentVariableHelper } from '@core/utils/environment-variable-helper';

export const PayPalProviderName = 'PayPal';
export const allPossiblePayments = [
  'Computop',
  PayPalProviderName,
  'Worldline',
  'BSPayOne',
  'Six',
  'Evo',
  'Elavon',
] as const;
export const paymentWithout3dSecure = ['Computop', PayPalProviderName];
export type PaymentType = typeof allPossiblePayments[number];

export interface PaymentFormField {
  labelName: string;
  formControlName: string;
  type: 'text' | 'password' | 'dropDown' | 'url' | "hidden";
  validations?: ValidatorFn[];
  fieldLength?: number;
  isRequired?: boolean;
}
export interface Connection {
  name?: string;
  formFields?: PaymentFormField[];
  apiUrl: string;
  logo?: string;
}

export const ComputopConnection: PaymentFormField[] = [
  { labelName: 'MERCHANT_ID', formControlName: 'merchantId', type: 'password', isRequired: true },
  { labelName: 'HMAC_KEY', formControlName: 'hmacKey', type: 'password', isRequired: true },
  { labelName: 'BLOWFISH_KEY', formControlName: 'blowfishKey', type: 'password', isRequired: true },
];

export const PayPalConnection: PaymentFormField[] = [];

export const WorldlineConnection: PaymentFormField[] = [
  { labelName: 'MERCHANT_NAME', formControlName: 'merchantName', type: 'text', isRequired: true },
  {
    labelName: 'UID',
    formControlName: 'username',
    type: 'password',
    validations: [Validators.pattern('^[0-9]{8}')],
    fieldLength: 8,
    isRequired: true,
  },
  {
    labelName: 'POS_ID',
    formControlName: 'key',
    type: 'password',
    validations: [Validators.pattern('^[0-9]{4}')],
    fieldLength: 4,
    isRequired: true,
  },
  {
    labelName: 'TERMINAL_ID',
    formControlName: 'terminalId',
    type: 'password',
    validations: [Validators.pattern('^[A-Za-z0-9]{8}')],
    fieldLength: 8,
    isRequired: true,
  },
  {
    labelName: 'MERCHANT_ID',
    formControlName: 'merchantId',
    type: 'password',
    validations: [Validators.pattern('^[0-9]{6}')],
    fieldLength: 10,
    isRequired: false,
  },
];

export const BSPayOneConnection: PaymentFormField[] = [
  {
    labelName: 'MERCHANT_ID',
    formControlName: 'merchantId',
    type: 'password',
    validations: [Validators.pattern('^[0-9]{10}')],
    fieldLength: 10,
    isRequired: true,
  },
  {
    labelName: 'TERMINAL_ID',
    formControlName: 'terminalId',
    type: 'password',
    validations: [Validators.pattern('^[A-Za-z0-9]{8}')],
    fieldLength: 8,
    isRequired: true,
  },
];

export const SixConnection: PaymentFormField[] = [
  {
    labelName: 'TERMINAL_ID',
    formControlName: 'terminalId',
    type: 'password',
    validations: [Validators.pattern('^[A-Za-z0-9]{8}')],
    fieldLength: 8,
    isRequired: true,
  },
  {
    labelName: 'MERCHANT_ID',
    formControlName: 'merchantId',
    type: 'password',
    validations: [Validators.pattern('^[0-9]{9}')],
    fieldLength: 9,
    isRequired: true,
  },
  {
    labelName: 'ACQUIRING_INSTITUTION_ID',
    formControlName: 'username',
    type: 'password',
    validations: [Validators.pattern('^[0-9]{11}')],
    fieldLength: 11,
    isRequired: true,
  },
];

export const EvoConnection: PaymentFormField[] = [
  { labelName: 'MERCHANT_NAME', formControlName: 'merchantId', type: 'password', isRequired: true },
  { labelName: 'SALES_CHANNEL', formControlName: 'key', type: 'password', isRequired: true },
];

export const ElavonConnection: PaymentFormField[] = [
  {
    labelName: 'MERCHANT_NAME',
    formControlName: 'merchantName',
    type: 'text',
    fieldLength: 20,
    isRequired: true,
  },
  {
    labelName: 'MERCHANT_ID',
    formControlName: 'merchantId',
    type: 'password',
    validations: [Validators.pattern('^[0-9]{6,}$')],
    fieldLength: 10,
    isRequired: true,
  },
  {
    labelName: 'TERMINAL_ID',
    formControlName: 'terminalId',
    type: 'password',
    validations: [Validators.pattern('^[A-Za-z0-9]*$')],
    fieldLength: 16,
    isRequired: true,
  },
];

export function GetPaymentConnectionDictionary() {
  return EnvironmentVariableHelper.removeHiddenPaymentProvider(PaymentConnectionDictionary);
}

export const PaymentConnectionDictionary: { [type in PaymentType]: Connection } = {
  [PayPalProviderName]: {
    name: 'PayPal',
    formFields: PayPalConnection,
    apiUrl: 'paypal',
    logo: 'paypal-logo-seow.png',
  },
  Computop: {
    name: 'Computop',
    formFields: ComputopConnection,
    apiUrl: 'computop',
    logo: 'computop.svg',
  },
  BSPayOne: {
    name: 'BS PAYONE',
    formFields: BSPayOneConnection,
    apiUrl: 'bsone',
    logo: 'BS-Payone.png',
  },
  Elavon: {
    name: 'Elavon',
    formFields: ElavonConnection,
    apiUrl: 'elavon',
    logo: 'Elavon_Logo.png',
  },
  Evo: {
    name: 'Evo Payments',
    formFields: EvoConnection,
    apiUrl: 'evo',
    logo: 'EVO_Logo.png',
  },
  Six: {
    name: 'Six (Worldline)',
    formFields: SixConnection,
    apiUrl: 'six',
    logo: 'SIX_Logo.png',
  },
  Worldline: {
    name: 'Worldline',
    formFields: WorldlineConnection,
    apiUrl: 'worldline',
    logo: 'worldline-logo-seow.png',
  },
};
