import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Endpoints } from '@core/constants/Endpoints';
import { AciOnboardingProgressResponse } from '@core/models/get-aci-onboarding-status-response';
import { BrandInfo, CheckRceHostNameResponse, GetMerchantInfoResponse } from '@core/models/get-merchant-info-response';
import { UpdateCustomerPaymentInfoRequest } from '@core/models/update-customer-payment-info-request';
import { UpdateMerchantProductConfigRequest } from '@core/models/update-merchant-product-config-request';
import { BaseAPIServiceService } from '@core/services/http/base-apiservice.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MerchantAPIService extends BaseAPIServiceService {
  constructor(protected http: HttpClient) {
    super(http);
  }

  startAciOnBoarding(): Promise<any> {
    return this.http.post<any>(`${Endpoints.START_ACI_ONBOARDING}`, null).toPromise();
  }

  getAciOnBoardingProgressStatus(): Observable<AciOnboardingProgressResponse> {
    return this.http.get<any>(`${Endpoints.GET_ACI_ONBOARDING_STATUS}`);
  }

  getMerchantInfo(): Observable<GetMerchantInfoResponse> {
    return this.http.get<any>(`${Endpoints.GET_MERCHANT_INFO}`);
  }

  checkRceHostNameAlreadyExist(rceHostName: string): Observable<CheckRceHostNameResponse> {
    return this.http.get<CheckRceHostNameResponse>(
      `${Endpoints.CHECK_RCE_HOST_NAME_ALREADY_EXIST_URL}/?rceHostName=${rceHostName}`,
    );
  }

  updateCustomerPaymentInfo(paymentInfo: UpdateCustomerPaymentInfoRequest): Observable<any> {
    return this.http.put<any>(`${Endpoints.UPDATE_CUSTOMER_PAYMENT_INFO}`, paymentInfo);
  }

  updateMerchantProductConfig(request: UpdateMerchantProductConfigRequest): Observable<any> {
    return this.http.put<any>(`${Endpoints.UPDATE_MERCHANT_API2CART_CONFIG_URL}`, request);
  }

  getBrand(): Observable<BrandInfo> {
    return this.get<BrandInfo>(Endpoints.GET_BRAND_DATA);
  }

  storeBrandInfo(request: BrandInfo): Observable<string> {
    return this.http.put<any>(Endpoints.UPDATE_BRAND_DATA, request);
  }
}
