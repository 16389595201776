import { Component, Input, OnInit } from '@angular/core';
import { MatLegacyDialogRef } from '@angular/material/legacy-dialog';
import { MatStepper } from '@angular/material/stepper';
import { OnBoardingPopupComponent } from '@shared/components/popup/on-boarding-popup/on-boarding-popup.component';

let apiLoaded = false;

@Component({
  selector: 'app-screen-four',
  templateUrl: './screen-four.component.html',
  styleUrls: ['../screens.component.scss', './screen-four.component.scss'],
})
export class ScreenFourComponent implements OnInit {
  @Input() stepper: MatStepper;
  videoId = 'Qcp29YIXxvg';

  constructor(public matDialogRef: MatLegacyDialogRef<OnBoardingPopupComponent>) {}

  ngOnInit(): void {
    if (!apiLoaded) {
      const tag = document.createElement('script');
      tag.src = 'https://www.youtube.com/iframe_api';
      document.body.appendChild(tag);
      apiLoaded = true;
    }
  }

  goBack(stepper: MatStepper) {
    stepper.previous();
  }

  goForward(stepper: MatStepper) {
    stepper.next();
  }
}
