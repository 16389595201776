import { FeatureManagementEnum } from '@core/constants/FeatureManagement';
import Route from '@core/constants/route';
import DurationData from '@core/models/duration-data';
import BaseEngagement from '@core/models/engagement/base-engagement';
import { EngagementTypeEnum } from '@core/models/enums/engagement-type-enum';
import { PricingTargetTypeEnum } from '@core/models/enums/pricing-target-type.enum';
import LocationData from '@core/models/location-data';
import { PriceInfoModel } from '@core/models/price-info.model';
import { AppSettingService } from '@core/services/app-setting.service';
import { PaymentApiService } from '@core/services/payment-api.service';
import { Guid } from '@core/utils/Guid';
import { BehaviorSubject, Observable, of } from 'rxjs';
import {shareReplay, switchMap, takeUntil} from 'rxjs/operators';
import { TriggerNotificationTypeEnum } from '../enums/trigger-notification-type-enum';

export class LocationEngagement extends BaseEngagement {
  location: LocationData;
  triggerNotificationType: TriggerNotificationTypeEnum;
  dwellTime: number;
  targetUserIds: number[];
  isBobEnabled: boolean;

  constructor(private paymentApiService: PaymentApiService, appSettingService: AppSettingService) {
    super(appSettingService, EngagementTypeEnum.Geolocation, PricingTargetTypeEnum.LocationEngagement, [
      Route.TRIGGERS,
      Route.LOCATION,
      Route.DURATION,
      Route.TARGETS,
      Route.CHECKOUT,
      Route.MAKE_PAYMENT,
    ]);
    this.isBobEnabled = appSettingService.isFeatureEnabled(FeatureManagementEnum.Bob);
    this.location = new LocationData();
    this.triggerNotificationType = TriggerNotificationTypeEnum.ON_GEOZONE_ENTER;
    this.dwellTime = null;
    const durationData = new DurationData();
    if (!this.isBobEnabled) {
      durationData.endHour = 1439;
    }

    this.duration$ = new BehaviorSubject(durationData);
    this.targetUserIds = [];
  }

  getDuration$() {
    return this.duration$;
  }

  getTrigger() {
    return {
      type: EngagementTypeEnum.Geolocation,
      svgIcon: 'engagement-geolocation-no-background',
      name: EngagementTypeEnum[this.activeTrigger],
      imageUrl: this.location.locationReference,
      locationAddress: this.location.locationAddress,
    };
  }

  getPrice(): Observable<PriceInfoModel> {
    return this.duration$.pipe(
      shareReplay(1),
      switchMap((duration) => {
        return this.paymentApiService.getLocationPrice(this.activePricingTargetType, duration.durationInDaysTotal);
      }),
    );
  }

  getPayload$() {
    return of({
      engagementId: Guid.newGuid(),
      actInformation: this.actInformation,
      activeTrigger: this.activeTrigger,
      activePricingTargetType: this.activePricingTargetType,
      deferredPayment: false,
      locationTrigger: {
        geoFences: this.getGeoFences(),
        locationReference: this.location.locationReference,
        timescale: this.getTimeScale(),
        targetUserIds: this.targetUserIds,
        triggerNotificationType: this.triggerNotificationType,
        dwellTime: this.dwellTime,
      },
      durationInDays: this.duration$.value.durationInDaysTotal,
    });
  }

  private getGeoFences() {
    return this.location.geoFences.value.map((geoFence) => ({
      center: {
        lat: geoFence.latLng.lat(),
        lng: geoFence.latLng.lng(),
      },
      // tslint:disable-next-line:radix
      radius: parseInt(geoFence.getRadius().toString()),
    }));
  }

  private getTimeScale() {
    return {
      autoRenew: false,
      endDate: this.duration$.value.endDate,
      endTime: this.isBobEnabled
        ? this.duration$.value.endHour + this.duration$.value.endMinute
        : this.duration$.value.endHour,
      repeatType: 30,
      selectedDays: [],
      selectionType: 0,
      standardLength: true,
      startDate: this.duration$.value.startDate,
      startTime: this.isBobEnabled
        ? this.duration$.value.startHour + this.duration$.value.startMinute
        : this.duration$.value.startHour,
      timezone: this.duration$.value.timezone,
      timezoneOffset: this.duration$.value.timezoneOffset,
      activeTimeType: this.duration$.value.activeTime,
    };
  }
}
