import Route from '@core/constants/route';
import { EngagementTypeEnum } from '@core/models/enums/engagement-type-enum';

export interface EngagementCreationWizardStepType {
  engagementType: EngagementTypeEnum[];
  title: string;
  stepNumber: string;
  icon: string;
  allowedRoutes: string[];
  disabled: boolean;
  counts?: number;
  steps?: number;
}

export const EngagementCreationWizardSteps: EngagementCreationWizardStepType[] = [
  {
    engagementType: [EngagementTypeEnum.Geolocation],
    title: 'CHOOSE_A_TRIGGER',
    stepNumber: '1',
    icon: 'flash_on',
    allowedRoutes: [
      Route.TRIGGERS,
      Route.LOCATION,
      Route.IMAGE_UPLOAD,
      Route.AUDIO_UPLOAD,
      Route.SMARTCODE_TRIGGER,
      Route.BEACON,
      Route.LINK,
      Route.INSTANT_WEB_TRIGGER,
    ],
    disabled: false,
  },
  {
    engagementType: [EngagementTypeEnum.Geolocation],
    title: 'CONFIGURE_GEOZONE',
    stepNumber: '2',
    icon: 'watch_later',
    allowedRoutes: [Route.DURATION],
    disabled: true,
  },
  {
    engagementType: [EngagementTypeEnum.Geolocation],
    title: 'CHOOSE_A_TARGET_PAGE',
    stepNumber: '3',
    icon: 'assignment',
    allowedRoutes: [
      Route.TARGETS,
      Route.URL_TARGET_PAGE,
      Route.BUY_TARGET_PAGE,
      Route.INFORMATION_TARGET_PAGE,
      Route.ACT_TARGET_PAGE,
    ],
    disabled: false,
  },
  {
    engagementType: [EngagementTypeEnum.Geolocation],
    title: 'CONFIRM_AND_PUBLISH',
    stepNumber: '4',
    icon: 'check_circle',
    allowedRoutes: [Route.CHECKOUT],
    disabled: false,
  },
  {
    engagementType: [EngagementTypeEnum.Audio, EngagementTypeEnum.Image],
    title: 'UPLOAD_TRIGGER_FILE',
    stepNumber: '1',
    icon: 'flash_on',
    allowedRoutes: [
      Route.TRIGGERS,
      Route.LOCATION,
      Route.IMAGE_UPLOAD,
      Route.AUDIO_UPLOAD,
      Route.SMARTCODE_TRIGGER,
      Route.BEACON,
      Route.LINK,
      Route.INSTANT_WEB_TRIGGER,
    ],
    disabled: false,
  },
  {
    engagementType: [EngagementTypeEnum.Audio, EngagementTypeEnum.Image],
    title: 'SELECT_CONTENT_PAGE',
    stepNumber: '2',
    icon: 'assignment',
    allowedRoutes: [
      Route.TARGETS,
      Route.URL_TARGET_PAGE,
      Route.BUY_TARGET_PAGE,
      Route.INFORMATION_TARGET_PAGE,
      Route.ACT_TARGET_PAGE,
    ],
    disabled: false,
  },
  {
    engagementType: [EngagementTypeEnum.Audio, EngagementTypeEnum.Image],
    title: 'CONFIRM_AND_PUBLISH',
    stepNumber: '3',
    icon: 'check_circle',
    allowedRoutes: [Route.CHECKOUT],
    disabled: false,
  },
  {
    engagementType: [EngagementTypeEnum.Link, EngagementTypeEnum.SmartCode, EngagementTypeEnum.InstantWeb],
    title: 'HOW_IT_WORKS',
    stepNumber: '1',
    icon: 'flash_on',
    allowedRoutes: [
      Route.TRIGGERS,
      Route.LOCATION,
      Route.IMAGE_UPLOAD,
      Route.AUDIO_UPLOAD,
      Route.SMARTCODE_TRIGGER,
      Route.BEACON,
      Route.LINK,
      Route.INSTANT_WEB_TRIGGER,
    ],
    disabled: false,
  },
  {
    engagementType: [EngagementTypeEnum.Link, EngagementTypeEnum.SmartCode, EngagementTypeEnum.InstantWeb],
    title: 'SELECT_CONTENT_PAGE',
    stepNumber: '2',
    icon: 'assignment',
    allowedRoutes: [
      Route.TARGETS,
      Route.URL_TARGET_PAGE,
      Route.BUY_TARGET_PAGE,
      Route.INFORMATION_TARGET_PAGE,
      Route.ACT_TARGET_PAGE,
    ],
    disabled: false,
  },
  {
    engagementType: [EngagementTypeEnum.Link, EngagementTypeEnum.SmartCode, EngagementTypeEnum.InstantWeb],
    title: 'CONFIRM_AND_PUBLISH',
    stepNumber: '3',
    icon: 'check_circle',
    allowedRoutes: [Route.CHECKOUT],
    disabled: false,
  },
];

export function enableAllowedWizardSteps(allowedRoutes: string[]) {
  let count = 0;
  EngagementCreationWizardSteps.forEach((steps) => {
    if (!steps.disabled) {
      steps.counts = 0;
    }
  });

  for (const step of EngagementCreationWizardSteps) {
    const intersection = step.allowedRoutes.filter((route) => allowedRoutes.includes(route));
    if (intersection.length === 0) {
      step.steps = 3;
      step.disabled = true;

      const stepCounter = EngagementCreationWizardSteps.filter((obj) => {
        return obj.disabled === false;
      }).length;

      EngagementCreationWizardSteps.forEach((object) => {
        if (!object.disabled) {
          object.steps = stepCounter;
          object.counts = ++count;
        }
      });
    }
  }
}

export function enableAllWizardSteps() {
  for (let index = 0; index < EngagementCreationWizardSteps.length; index++) {
    EngagementCreationWizardSteps[index].disabled = false;
    EngagementCreationWizardSteps[index].steps = 4;
  }
}

export function filterByEngagementType(type: EngagementTypeEnum): EngagementCreationWizardStepType[] {
  return EngagementCreationWizardSteps.filter((step) => step.engagementType.includes(type));
}
