import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ExtendedModule } from '@angular/flex-layout';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';
import { MatLegacyDialogModule } from '@angular/material/legacy-dialog';
import { MatStepperModule } from '@angular/material/stepper';
import { RouterModule } from '@angular/router';
import { YouTubePlayerModule } from '@angular/youtube-player';
import { TranslateModule } from '@ngx-translate/core';
import { ConfirmButtonModule } from '@shared/components/confirm-button/confirm-button.module';
import { OnBoardingPopupComponent } from '@shared/components/popup/on-boarding-popup/on-boarding-popup.component';
import { ScreenFourComponent } from './on-boarding-screens/screen-four/screen-four.component';
import { ScreenOneComponent } from './on-boarding-screens/screen-one/screen-one.component';
import { ScreenThreeComponent } from './on-boarding-screens/screen-three/screen-three.component';
import { ScreenTwoComponent } from './on-boarding-screens/screen-two/screen-two.component';

@NgModule({
  declarations: [
    OnBoardingPopupComponent,
    ScreenOneComponent,
    ScreenTwoComponent,
    ScreenThreeComponent,
    ScreenFourComponent,
  ],
  imports: [
    CommonModule,
    MatStepperModule,
    MatLegacyButtonModule,
    ConfirmButtonModule,
    TranslateModule,
    YouTubePlayerModule,
    RouterModule,
    ExtendedModule,
    MatIconModule,
    MatLegacyDialogModule,
  ],
  exports: [OnBoardingPopupComponent],
})
export class OnBoardingPopupModule {}
