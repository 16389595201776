// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container {
  padding: 2em 4em;
}

:host ::ng-deep app-information-panel > .container .content {
  width: 100%;
  padding: 3.4em !important;
}
:host ::ng-deep app-information-panel > .container .content .text {
  text-align: center !important;
  max-width: 800px !important;
}

@media screen and (max-width: 940px) {
  .container {
    padding: 1em 2em;
  }
  :host ::ng-deep app-information-panel > .container .content {
    padding: 2.4em !important;
  }
}
@media screen and (max-width: 425px) {
  .container {
    padding: 1em;
  }
  :host ::ng-deep app-information-panel > .container .content {
    padding: 2em 1.4em !important;
  }
}`, "",{"version":3,"sources":["webpack://./src/app/features/products/product-setup-info/product-setup-info.component.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;AACF;;AAGE;EACE,WAAA;EACA,yBAAA;AAAJ;AAEI;EACE,6BAAA;EACA,2BAAA;AAAN;;AAKA;EACE;IACE,gBAAA;EAFF;EAME;IACE,yBAAA;EAJJ;AACF;AAQA;EACE;IACE,YAAA;EANF;EAUE;IACE,6BAAA;EARJ;AACF","sourcesContent":[".container {\n  padding: 2em 4em;\n}\n\n:host ::ng-deep app-information-panel > .container {\n  .content {\n    width: 100%;\n    padding: 3.4em !important;\n\n    .text {\n      text-align: center !important;\n      max-width: 800px !important;\n    }\n  }\n}\n\n@media screen and (max-width: 940px) {\n  .container {\n    padding: 1em 2em;\n  }\n\n  :host ::ng-deep app-information-panel > .container {\n    .content {\n      padding: 2.4em !important;\n    }\n  }\n}\n\n@media screen and (max-width: 425px) {\n  .container {\n    padding: 1em;\n  }\n\n  :host ::ng-deep app-information-panel > .container {\n    .content {\n      padding: 2em 1.4em !important;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
