export const FileFormats = {
  JPEG: 'image/jpeg',
  JPG: 'image/jpg',
  SVG: 'image/svg+xml',
  BMP: 'image/bmp',
  PNG: 'image/png',
  TIFF: 'image/tiff',
  TIF: 'image/tif',
  GIF: 'image/gif',
  MP3: 'audio/mpeg',
  OGG: 'audio/ogg',
  WAV: 'audio/wav',
  XWAV: 'audio/x-wav',
  CERT: 'application/x-x509-ca-cert',
  ANY: '',
};
