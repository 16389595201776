import { ColourDark, ColourList } from '@core/models/enums/colour-enum';
import { CornerList, CornerRound } from '@core/models/enums/corner-enum';
import { HeightLarge, HeightList } from '@core/models/enums/height-enum';
import { WidthList, WidthNarrow } from '@core/models/enums/width-enum';
import { environment } from '@env/environment';

export const InstantButtonOptionType = {
  WIDTH: 'WIDTH',
  HEIGHT: 'HEIGHT',
  CORNERS: 'CORNERS',
  COLOUR: 'COLOUR',
};
export const DefaultButtonProperties = {
  width: WidthNarrow,
  height: HeightLarge,
  corners: CornerRound,
  colour: ColourDark,
};
export const ButtonProperties = [
  {
    type: InstantButtonOptionType.WIDTH,
    propertyOptions: WidthList,
    defaultOption: DefaultButtonProperties.width,
  },
  {
    type: InstantButtonOptionType.CORNERS,
    propertyOptions: CornerList,
    defaultOption: DefaultButtonProperties.corners,
  },
  {
    type: InstantButtonOptionType.COLOUR,
    propertyOptions: ColourList,
    defaultOption: DefaultButtonProperties.colour,
  },
  {
    type: InstantButtonOptionType.HEIGHT,
    propertyOptions: HeightList,
    defaultOption: DefaultButtonProperties.height,
  },
];
export const ButtonScriptAndStyle = `<link href="https://fonts.googleapis.com/css2?family=Noto+Sans:wght@400;600;700&display=swap" rel="stylesheet" />
  <script type="module" src="${environment.instantCheckoutUrl}/icv2-web-components/ic-web-components/ic-web-components.esm.js"></script>`;

export const DefaultBuyButtonValue = 'Instant Buy';
export const DefaultActButtonValue = 'Instant Act';
export const InstantWebIcon = 'instant-web';
export const LearnMoreLink = {
  COPY_BUTTON_CODE: 'https://help.rezolve.com/merchants/ssp/instant-web/implement-button-code/',
  COPY_BUY_BUTTON_CODE: 'https://help.rezolve.com/merchants/ssp/instant-web/implement-button-code/',
};
export const ModalWidth = {
  lg: '831px',
  md: '568px',
  sm: '100%',
};
export const ModalHeight = '736px';
export const MinButtonLength = 50;
export const LetterSizeInPixel = 8;
