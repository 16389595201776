import { Component, Input, OnInit } from '@angular/core';
import { MatLegacyDialogRef } from '@angular/material/legacy-dialog';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { OrderService } from '@core/services/order.service';
import { ServicingService } from '@core/services/servicing.service';
import { BaseComponent } from '@shared/components/base-component/base-component';
import * as moment from 'moment';
import { takeUntil } from 'rxjs/operators';
import { LogUtils } from 'src/app/utils/log-utils';
import { BasePageBuilderConfiguration } from '../base-page-builder-configuration';

@Component({
  selector: 'cupon-customisation-popup',
  templateUrl: './coupon-customisation-popup.component.html',
  styleUrls: ['./coupon-customisation-popup.component.scss'],
})
export class CouponCustomisationPopupComponent extends BaseComponent implements OnInit {
  @Input() configuration: BasePageBuilderConfiguration;
  file: File;
  imageUrl: SafeUrl;
  spinnerLoading: boolean;
  showImage: boolean;
  showImageTypeError: boolean;
  showDateInPastError: boolean;

  constructor(
    public dialogRef: MatLegacyDialogRef<CouponCustomisationPopupComponent>,
    private orderService: OrderService,
    private servicingService: ServicingService,
    private sanitizer: DomSanitizer,
  ) {
    super();
  }

  ngOnInit(): void {
    if (this.configuration.data.url) {
      this.spinnerLoading = true;
      this.loadImageByUrl(this.configuration.data.url);
    } else if (this.configuration.data.filePath) {
      this.spinnerLoading = true;
      this.loadImageByFilePath(this.configuration.data.filePath);
    } else {
      LogUtils.warning('Could not load image because download URL or filePath are not found!');
    }
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  uploadImage(event): void {
    this.showImageTypeError = false;
    const files: FileList = event.target.files as FileList;

    if (files) {
      this.file = files[0];
      const formData: FormData = new FormData();
      if (!this.file?.type || !this.file.type.startsWith('image/')) {
        LogUtils.error('Selected file is not an image!');
        this.showImageTypeError = true;
        return;
      }
      formData.append('file', this.file);
      this.spinnerLoading = true;
      this.orderService
        .uploadFile(formData)
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          (response) => {
            this.configuration.data.filePath = response.filePath;
            this.loadImageByFilePath(response.filePath);
          },
          (error) => {
            LogUtils.error('Error while uploading selected image!');
            LogUtils.error(error);
            this.spinnerLoading = false;
          },
        );
    }
  }

  loadImageByFilePath(filePath: string): void {
    this.servicingService
      .getImage(encodeURIComponent(filePath))
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (image) => {
          const unsafeImageUrl = URL.createObjectURL(image);
          this.imageUrl = this.sanitizer.bypassSecurityTrustUrl(unsafeImageUrl);
          this.spinnerLoading = false;
          this.showImage = true;
        },
        (error) => {
          LogUtils.error('Error while loading image!');
          LogUtils.error(error);
          this.spinnerLoading = false;
        },
      );
  }

  loadImageByUrl(url: string): void {
    this.imageUrl = this.sanitizer.bypassSecurityTrustUrl(url);
    this.spinnerLoading = false;
    this.showImage = true;
  }

  checkIfDateIsInPast(date: Date) {
    const dateTimeDiff = moment(date).diff(new Date(), 'minutes');
    this.showDateInPastError = dateTimeDiff < 0;
    this.configuration.data.endDateLimit = date;
  }
}
