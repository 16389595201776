// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  partner: 'rezolve-shared',
  mapProvider: 'google',
  instantCheckoutUrl: 'https://instantweb-cdn.dev.eu.rezolve.com',
  googleMapsKey: 'AIzaSyBJa8Ioyvh-7WzRvExQMeURF-_8DolCZ7M', // when you add this change in app.components.ts aswell
  baiduMapsKey: '',
  siteLanguageLocale: 'en',
  showLanguageSwitch: true,
  featureManagement: {
    Bob: true,
    IBFW: true,
  },
  auth0: {
    domain: 'rezolve-dev.eu.auth0.com',
    clientId: 'ZZorOX0hV3NqTBhfMxxN1JGCtFU0aV6Q',
    audience: 'https://rezolve.ssp.api',
    redirect_uri: '/callback',
    organization: '',
  },
  minimumFenceRadius: 50,
  defaultFenceRadius: 50,
  mainImageURL: 'https://rezolve-public-images.s3.eu-central-1.amazonaws.com/Rezolve+Logo.png',
  stripeLocale: 'en',
  stripePublicKey:
    'pk_test_51HCP4VIlGzxRNaFP4xqXDrpfiPqIKAlWtHV6XV8YkMXJYwX8cG0WLp4zi0fwp8ELdecHPbXQaMig5BsECDeyzMiO00icQFUS5F', // Specify Stripe Public Key
  paymentCurrency: 'GBP',
  mapImageHeight: '300',
  mapImageWidth: '400',
  tinyApiKey: 'xs0ekepa7wag7zynfngesjzm5hbt8pucwfsdz2xftqckjkf9',
  enableWeChatQRCode: false,
  showTraditionalZh: true,
  multiplicationFactor: 1,
  allowGeozoneResize: true,
  allowMultipleGeozones: true,
  defferedPayment: false,
  hiddenElements: ['beacon-trigger-card'],
  hiddenEngagementActions: [],
  hiddenTargetPageActions: [],
  hiddenPaymentProviders: ['Evo'],
  hiddenRoutes: ['products'],
  supportedEcommercePlatforms: ['Magento1212', 'Demandware', 'Woocommerce'],
  tinyMceSettings: {
    tinyMceFontsToOverride: '',
    tinyMceDefaultFontToOverride: '',
  },
  styleLogo: true,
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
