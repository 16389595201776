export const SETTINGS_PAYMENT_PROVIDER_CURRENCIES = [
  'Euro €',
  'British Pound £',
  'Dollar $',
  'Chinese Yuan ¥',
  'New Taiwan dollar $',
  'South Korean won ₩',
];

export const CurrencyDictionary = {
  'Euro €': 'EUR',
  'British Pound £': 'GBP',
  'Dollar $': 'USD',
  'New Taiwan dollar $': 'TWD',
  'Chinese Yuan ¥': 'CNY',
  'South Korean won ₩': 'KRW',
};

export function isCurrencyNormalized(currency: string) {
  return Object.values(CurrencyDictionary).includes(currency);
}
