import { Action, createReducer, on } from '@ngrx/store';
import * as FilterActions from '../actions/filter.actions';

export interface FilterState {
  filterData: { filterId: string; data: [] }[];
}

export const initialState: FilterState = {
  filterData: [],
};

const filterReducer = createReducer(
  initialState,
  on(FilterActions.updateFilter, (state, { filterId, data }) => {
    const index = state.filterData.findIndex((i) => i.filterId === filterId);
    const newArray = [...state.filterData];
    if (index < 0) {
      newArray.push({ filterId, data });
    } else {
      newArray[index] = { filterId, data };
    }
    return {
      ...state,
      filterData: newArray,
    };
  }),
  on(FilterActions.clearFilter, (state) => ({
    ...state,
    filterData: [],
  })),
);

export function reducer(state = initialState, action: Action): FilterState {
  return filterReducer(state, action);
}
