import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatLegacyDialog } from '@angular/material/legacy-dialog';
import { MatLegacyTableDataSource } from '@angular/material/legacy-table';
import { EngagementAdapter } from '@core/models/adapters/engagement-adapter';
import { EngagementTypeEnum } from '@core/models/enums/engagement-type-enum';
import { EngagementViewModel } from '@core/models/view-models/engagement-view-model';
import { EngagementAPIService } from '@core/services/engagement-api.service';
import { LoaderService } from '@core/services/loader.service';
import { PaginationHelper } from '@core/utils/pagination-helper';
import { TranslateService } from '@ngx-translate/core';
import { BaseComponent } from '@shared/components/base-component/base-component';
import { PaginationComponent } from '@shared/components/pagination/pagination.component';
import { Observable } from 'rxjs';
import { finalize, take, takeUntil } from 'rxjs/operators';
import { SimpleConfirmPopupComponent } from '../simple-confirm-popup/simple-confirm-popup.component';
import { AssociatedEngagementsPopupConfiguration } from './associated-engagements-popup-configuration';

const loadingKey = 'associated-popup';
@Component({
  selector: 'app-associated-engagements-popup',
  templateUrl: './associated-engagements-popup.component.html',
  styleUrls: ['./associated-engagements-popup.component.scss'],
})
export class AssociatedEngagementsPopupComponent extends BaseComponent implements OnInit, AfterViewInit {
  @Input() configuration: AssociatedEngagementsPopupConfiguration;

  @ViewChild(PaginationComponent, { static: true }) paginator: PaginationComponent;

  EngagementTypeEnum = EngagementTypeEnum;
  pageSizeOptions: number[] = [];
  dataSource: MatLegacyTableDataSource<EngagementViewModel> = new MatLegacyTableDataSource([]);

  loading$: Observable<boolean>;

  readonly pageIndex = 0;
  readonly pageSize = 5;

  constructor(
    private readonly engagementService: EngagementAPIService,
    private readonly loaderService: LoaderService,
    private readonly translateService: TranslateService,
    private readonly matDialog: MatLegacyDialog,
    protected adapter: EngagementAdapter,
  ) {
    super();
    this.loading$ = loaderService.isLoading(loadingKey);
  }

  ngAfterViewInit() {
    this.paginator.matPaginator.page.pipe(takeUntil(this.destroy$)).subscribe((page) => {
      this.refresh(page.pageIndex, page.pageSize);
    });
  }

  deleteEngagement() {
    this.refresh(this.paginator.matPaginator.pageIndex, this.paginator.matPaginator.pageSize);
  }

  ngOnInit(): void {
    this.dataSource
      .connect()
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        this.configuration.engagements = data;
      });
    this.refresh(this.pageIndex, this.pageSize);
  }

  refresh(pageIndex: number, pageSize: number, isAfterDelete = false) {
    this.loaderService.show(loadingKey);
    this.engagementService
      .getAssociatedEngagements(pageIndex * pageSize, pageSize, this.configuration.targetId)
      .pipe(
        takeUntil(this.destroy$),
        finalize(() => this.loaderService.hide(loadingKey)),
      )
      .subscribe((response) => {
        this.dataSource.data = response.data;
        this.dataSource.filterPredicate = EngagementViewModel.filterPredicate;
        this.dataSource.sortingDataAccessor = EngagementViewModel.sortingDataAccessor;
        this.pageSizeOptions = PaginationHelper.getPageSizeOptions(response.total);
        this.paginator.length = response.total;
        if (isAfterDelete) {
          this.configuration.afterDeleteCallback();
          this.paginator.matPaginator.firstPage();
        }
      });
  }

  showEngagementDeletePopup(id: string): void {
    const dialogRef = this.matDialog.open(SimpleConfirmPopupComponent, {
      maxWidth: '300px',
      width: 'auto',
      height: 'auto',
    });

    dialogRef.componentInstance.configuration = {
      message: `${this.translateService.instant('ARE_YOU_SURE')}?`,
      showDeleteIcon: true,
    };

    dialogRef.afterClosed().subscribe((ok) => {
      if (!ok) {
        return;
      }
      this.engagementService
        .deleteEngagement(id)
        .pipe(take(1))
        .subscribe(() => {
          this.refresh(this.pageIndex, this.pageSize, true);
        });
    });
  }
}
