import { Clipboard } from '@angular/cdk/clipboard';
import { HttpParams } from '@angular/common/http';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { MatLegacyDialog } from '@angular/material/legacy-dialog';
import {
  DefaultActButtonValue,
  DefaultBuyButtonValue,
  LearnMoreLink,
  ModalHeight,
  ModalWidth,
} from '@core/constants/InstantButton';
import { ActDTO } from '@core/models/dtos/act-dto';
import { ActTypeEnum } from '@core/models/enums/act-type-enum';
import { EngagementStatusEnum } from '@core/models/enums/engagement-status-enum';
import { EngagementTypeEnum } from '@core/models/enums/engagement-type-enum';
import { OverallStatusEnum } from '@core/models/enums/overal-status-enum';
import { RelatedItemTypeEnum } from '@core/models/enums/related-item-type-enum';
import { UserEngagementsActions } from '@core/models/enums/user-engagements-actions';
import { GetMerchantInfoResponse } from '@core/models/get-merchant-info-response';
import { ActViewModel } from '@core/models/view-models/act-view-model';
import { EngagementViewModel } from '@core/models/view-models/engagement-view-model';
import { ProductViewModel } from '@core/models/view-models/product-view-model';
import { AppSettingService } from '@core/services/app-setting.service';
import { EngagementAPIService } from '@core/services/engagement-api.service';
import { ProductService } from '@core/services/product.service';
import { TranslateService } from '@ngx-translate/core';
import { BaseComponent } from '@shared/components/base-component/base-component';
import { PopupService } from '@shared/components/popup/popup.service';
import { saveAs } from 'file-saver';
import * as moment from 'moment';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, delay, finalize, switchMap, takeUntil } from 'rxjs/operators';
import { LogUtils } from 'src/app/utils/log-utils';
import { CreateInstantBuyButtonModalComponent } from '../../create-instant-buy-button/create-instant-buy-button-modal.component';
import { EngagementCreationService } from '../../engagement-creation/engagement-creation.service';

@Component({
  selector: 'app-custom-card',
  templateUrl: './custom-card.component.html',
  styleUrls: ['./custom-card.component.scss'],
})
export class CustomCardComponent extends BaseComponent implements OnInit, OnChanges {
  @Input() engagement: EngagementViewModel;
  @Input() displayNameOnly = false;
  @Input() merchantInfo: GetMerchantInfoResponse;

  @Output() updatedEngagement: EventEmitter<EngagementViewModel> = new EventEmitter();
  @Output() selectEngagement: EventEmitter<EngagementViewModel> = new EventEmitter();
  @Output() deleteEngagement: EventEmitter<EngagementViewModel> = new EventEmitter();

  isLoading$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  hideRefresh: boolean;
  OverallStatusEnum = OverallStatusEnum;
  ActTypeEnum = ActTypeEnum;
  EngagementTypeEnum = EngagementTypeEnum;
  paymentCurrency: string;

  tooltipText: string;
  menuOptions = [];
  actions = [];
  constructor(
    private clipboard: Clipboard,
    private engagementService: EngagementAPIService,
    private popupService: PopupService,
    private engagementCreationService: EngagementCreationService,
    private translateService: TranslateService,
    readonly appSettingService: AppSettingService,
    public dialog: MatLegacyDialog,
    private productService: ProductService,
  ) {
    super();
    this.paymentCurrency = this.appSettingService.paymentCurrency;
  }

  ngOnInit() {
    this.hideRefresh = !this.engagement.availableActions.includes(UserEngagementsActions.Refresh);
    const hideSnippetDownload = this.engagement.act?.actType === ActTypeEnum.Buy && !this.merchantInfo.rceMerchantId;

    this.menuOptions = [
      {
        label: 'DELETE',
        action: () => this.onDelete(),
        show: () => this.engagement.availableActions.includes(0),
      },
      {
        label: 'PAUSE',
        action: () => this.onChangeStatus(1),
        show: () => this.engagement.availableActions.includes(1),
      },
      {
        label: 'RESUME',
        action: () => this.onChangeStatus(0),
        show: () => this.engagement.availableActions.includes(2),
      },
      {
        label: 'DOWNLOAD',
        action: () => this.onDownload(),
        show: () => this.engagement.availableActions.includes(3),
      },
      {
        label: 'COPY_URL',
        action: () => this.onCopyUrl(),
        show: () => this.engagement.availableActions.includes(4),
      },
      {
        label: 'PAY_ENGAGEMENT',
        action: () => this.onPayEngagement(),
        show: () => this.engagement.availableActions.includes(5),
      },
      {
        label: 'REFRESH',
        action: () => this.onRefreshEngagement(),
        show: () => this.engagement.availableActions.includes(6),
      },
      {
        label: 'DOWNLOAD_WECHAT',
        action: () => this.onQrDownload(),
        show: () => this.engagement.availableActions.includes(7),
      },
      {
        label: 'GENERATE_BUTTON_CODE',
        action: () => this.onGenerateCodeSnippet(),
        isLoading: this.isLoading$,
        show: () => this.engagement.availableActions.includes(8) && !hideSnippetDownload,
      },
      {
        label: 'DOWNLOAD_ASSET_PACK',
        action: () => this.onAssetPackDownload(),
        show: () => this.engagement.availableActions.includes(9),
      },
    ];

    this.actions = [
      {
        icon: 'eye_on',
        tooltip: 'PREVIEW',
        action: this.showLocationPopup.bind(this),
        show: () => !!this.engagement.locationReference,
      },
      {
        icon: 'eye_on',
        tooltip: 'PREVIEW',
        action: this.showImagePopup.bind(this),
        show: () =>
          this.engagement.type === EngagementTypeEnum.Image &&
          this.engagement.overallStatus !== OverallStatusEnum.Incomplete &&
          this.engagement.overallStatus !== OverallStatusEnum.Processing &&
          this.engagement.overallStatus !== OverallStatusEnum.PendingPayment,
      },
      {
        icon: 'download',
        tooltip: 'DOWNLOAD',
        action: this.onDownload.bind(this),
        show: () => this.engagement.availableActions.includes(UserEngagementsActions.Download),
      },
      {
        icon: 'folder-arrow',
        tooltip: 'PACK',
        action: this.onAssetPackDownload.bind(this),
        show: () => this.engagement.availableActions.includes(UserEngagementsActions.DownloadAssetPack),
      },
      {
        icon: 'download',
        tooltip: 'DOWNLOAD_WECHAT',
        action: this.onQrDownload.bind(this),
        show: () => this.engagement.availableActions.includes(UserEngagementsActions.WeChatDownload),
      },
      {
        icon: 'download',
        tooltip: 'DOWNLOAD',
        action: () => this.onGenerateCodeSnippet(),
        isLoading: this.isLoading$,
        show: () =>
          this.engagement.availableActions.includes(UserEngagementsActions.GenerateCodeSnippet) && !hideSnippetDownload,
      },
      {
        icon: 'duplicate',
        tooltip: 'COPY_TO_CLIPBOARD',
        action: this.onCopyUrl.bind(this),
        show: () => this.engagement.availableActions.includes(UserEngagementsActions.CopyURL),
      },
    ];
  }

  ngOnChanges() {
    this.updateSwitchOver();
  }

  updateSwitchOver() {
    if (this.engagement) {
      const switchOverTargetInfo = EngagementViewModel.getSwitchOverTargetInfo(
        (this.engagement && this.engagement.relatedItems) || [],
      );
      if (switchOverTargetInfo) {
        this.translateService
          .get('TARGET_PAGE_SWITCHOVER_TOOL_TIP', switchOverTargetInfo)
          .pipe(takeUntil(this.destroy$))
          .subscribe((message) => {
            this.tooltipText = message;
          });
        this.translateService.onLangChange
          .pipe(
            switchMap(() => this.translateService.get('TARGET_PAGE_SWITCHOVER_TOOL_TIP', switchOverTargetInfo)),
            takeUntil(this.destroy$),
          )
          .subscribe((message) => {
            this.tooltipText = message;
          });
      }
    }
  }

  onSelect() {
    this.selectEngagement.emit(this.engagement);
  }

  onChangeStatus(status: EngagementStatusEnum) {
    const params = new HttpParams().set('engagementId', this.engagement.id).set('status', status.toString());
    this.engagementService
      .updateEngagementStatus(params)
      .pipe(takeUntil(this.destroy$))
      .subscribe((engagement) => {
        this.updatedEngagement.emit(engagement);
      });
  }

  onDelete() {
    this.translateService
      .get('ARE_YOU_SURE_THAT_YOU_WANT_TO_DELETE_THIS_ENGAGEMENT')
      .pipe(
        switchMap((translated) =>
          this.popupService.simpleConfirmationPopup({
            maxWidth: '300px',
            width: 'auto',
            height: 'auto',
            componentConfiguration: {
              message: `${translated}?`,
              showDeleteIcon: true,
            },
          }),
        ),
        takeUntil(this.destroy$),
      )
      .subscribe((ok) => {
        if (!ok) {
          return;
        }
        this.engagementService
          .deleteEngagement(this.engagement.id)
          .pipe(takeUntil(this.destroy$))
          .subscribe(() => {
            this.deleteEngagement.emit();
          });
      });
  }

  onDownload() {
    this.engagementService
      .downloadWatermarkedFile(this.engagement.id, this.engagement.type)
      .pipe(takeUntil(this.destroy$))
      .subscribe((url) => {
        window.location.assign(url);
      });
  }

  onQrDownload() {
    this.qrDownload().pipe(takeUntil(this.destroy$)).subscribe();
  }

  onAssetPackDownload() {
    this.assetPackDownload().pipe(takeUntil(this.destroy$)).subscribe();
  }

  qrDownload(): Observable<any> {
    return this.engagementService.getWeChatQrCode(this.engagement.id).pipe(
      switchMap((data) => {
        if (data) {
          this.downloadFile(data, 'image/png', this.getQrCodeFileName());
          return of();
        }
      }),
      catchError((err) => {
        console.log(err);
        return this.showStatusPopup(false, 'FAILED_TO_DOWNLOAD_QR');
      }),
    );
  }

  assetPackDownload(): Observable<any> {
    return this.engagementService.downloadAssetPack(this.engagement.id).pipe(
      switchMap((data) => {
        if (data) {
          this.downloadFile(data, 'application/zip', this.getAssetPackFileName());
          return of();
        }
      }),
      catchError((err) => {
        console.log(err);
        return this.showStatusPopup(false, 'FAILED_TO_DOWNLOAD_ASSETS');
      }),
    );
  }

  downloadFile(data: any, type: string, fileName: string) {
    const blob = new Blob([data], { type });
    saveAs(blob, fileName);
  }

  getQrCodeFileName() {
    const format = '.png';
    return `${this.engagement.name} - wechat${format}`;
  }

  getAssetPackFileName() {
    const format = '.zip';
    return `${this.engagement.id}${format}`;
  }

  onCopyUrl() {
    this.clipboard.copy(this.engagement.shortUrl ?? this.engagement.url);
    alert(this.translateService.instant('LINK_COPIED_CLIPBOARD'));
  }

  onPayEngagement() {
    this.engagementCreationService.navigateToPaymentStep(this.engagement.id, this.engagement.type);
  }

  onRefreshEngagement() {
    this.engagement.overallStatusLabel = 'PROCESSING';
    this.engagement.overallStatus = OverallStatusEnum.Processing;
    this.engagementService
      .getEngagementViewModel(this.engagement.id)
      .pipe(delay(3000), takeUntil(this.destroy$))
      .subscribe((response) => {
        this.engagement = response;
        if (response.overallStatus === OverallStatusEnum.Active) {
          this.ngOnInit();
        }
      });
  }

  onChangeTarget(currentTarget: ActDTO) {
    this.popupService
      .changeTargetPagePopup({
        backdropClass: 'blurred-backdrop',
        width: '90%',
        maxHeight: '100vh',
        maxWidth: '590px',
        componentConfiguration: {
          currentTargetPage: new ActViewModel(currentTarget),
          engagementValidityPeriod: this.engagement.validityPeriods[0],
        },
      })
      .pipe(takeUntil(this.destroy$))
      .subscribe((result) => {
        if (result) {
          if (result.act instanceof ActDTO) {
            this.engagementService
              .updateEngagementTargetPage(this.engagement.id, result.act.id, result.startDate)
              .pipe(takeUntil(this.destroy$))
              .subscribe(
                (response) => {
                  if (response && moment(result.startDate).startOf('day').isSameOrBefore(moment().startOf('day'))) {
                    this.engagement.act = result.act;
                  }
                  this.engagement.relatedItems = [
                    this.engagement.relatedItem,
                    {
                      type: RelatedItemTypeEnum.Act,
                      itemIdInfo: result.act.id,
                      startDate: result.startDate,
                      title: result.act.title,
                    },
                  ];
                  this.updateSwitchOver();
                  console.log('this.engagement', this.engagement);
                },
                (error) => {
                  console.error('Error while Updating Engagement Target: ', error);
                },
              );
          }
        }
      });
  }

  onGenerateCodeSnippet() {
    this.productFetchWrapper((product) =>
      this.dialog.open(CreateInstantBuyButtonModalComponent, {
        data: {
          title: 'GENERATE_BUTTON_CODE',
          learnMoreLink: LearnMoreLink.COPY_BUTTON_CODE,
          engagementId: this.engagement.shortId,
          buttonText: this.engagement.act?.actType === ActTypeEnum.Act ? DefaultActButtonValue : DefaultBuyButtonValue,
          actType: this.engagement.act?.actType,
          merchantId: this.merchantInfo.rceMerchantId,
          externalId: product?.externalId,
          productId: product?.id,
          categoryId: this.engagement?.act?.product?.categoryId,
          isProductExternal: product?.isExternal,
        },
        maxWidth: ModalWidth.lg,
        height: ModalHeight,
        panelClass: 'full-screen-modal',
      }),
    );
  }

  productFetchWrapper(openDialog: (product?: ProductViewModel) => void) {
    const productId = this.engagement?.act?.product?.id;
    if (productId) {
      this.isLoading$.next(true);

      this.productService
        .getProductById(productId)
        .pipe(
          takeUntil(this.destroy$),
          finalize(() => this.isLoading$.next(false)),
        )
        .subscribe((product) => {
          openDialog(product);
        });
    } else {
      openDialog();
    }
  }

  showImagePopup() {
    this.engagementService
      .downloadWatermarkedFile(this.engagement.id, this.engagement.type)
      .pipe(takeUntil(this.destroy$))
      .subscribe((url) => {
        if (url) {
          this.popupService.showImagePreviewPopup({
            backdropClass: 'light-backdrop',
            width: '831px',
            height: 'auto',
            panelClass: 'zero-padding-mat-dialog-container',
            componentConfiguration: { url },
          });
        } else {
          LogUtils.error('Error: Image URL is not found. Previewing watermarked image is not possible!');
        }
      });
  }

  showLocationPopup() {
    this.popupService.showImagePreviewPopup({
      backdropClass: 'light-backdrop',
      maxWidth: '400px',
      height: 'auto',
      panelClass: 'zero-padding-mat-dialog-container',
      componentConfiguration: { url: this.engagement.locationReference },
    });
  }

  showStatusPopup(isSuccess: boolean, message: string): Observable<any> {
    return this.translateService.get(message).pipe(
      switchMap((translated: string) =>
        this.popupService.successStatusPopup({
          componentConfiguration: { isSuccess, message: translated },
          height: 'auto',
          width: 'auto',
          maxWidth: '430px',
          backdropClass: 'blurred-backdrop',
        }),
      ),
    );
  }
}
