export enum CardProviderEnum {
  Visa = 'VISA',
  AmericanExpress = 'AMEX',
  MasterCard = 'MASTER'
}

export const SETTINGS_CARD_PROVIDERS = [
  { key: CardProviderEnum.Visa, value: 'VISA' },
  { key: CardProviderEnum.AmericanExpress, value: 'AMEX' },
  { key: CardProviderEnum.MasterCard, value: 'MasterCard' }
];
