import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule } from '@angular/forms';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';
import { MatLegacyCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatLegacyFormFieldModule } from '@angular/material/legacy-form-field';
import { TranslateModule } from '@ngx-translate/core';
import { ConfirmButtonModule } from '@shared/components/confirm-button/confirm-button.module';
import { CustomPipeModule } from '@shared/pipes/custom-pipe.module';
import { CreditCardViewModule } from '../credit-card-view/credit-card-view.module';
import { StripePaymentComponent } from './stripe-payment.component';

@NgModule({
  declarations: [StripePaymentComponent],
  imports: [
    CommonModule,
    MatLegacyButtonModule,
    FormsModule,
    FlexLayoutModule,
    MatLegacyFormFieldModule,
    MatLegacyCheckboxModule,
    MatIconModule,
    CreditCardViewModule,
    MatExpansionModule,
    TranslateModule.forChild(),
    CustomPipeModule,
    ConfirmButtonModule,
  ],
  exports: [StripePaymentComponent],
})
export class StripePaymentModule {}
