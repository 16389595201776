export enum PricingTargetTypeEnum {
  AudioEngagement,
  ImageEngagement,
  LocationEngagement,
  LinkEngagement,
  SmartCodeEngagement,
  ActAppPage,
  BuyAppPage,
  UrlAppPage,
  InfoAppPage,
  InstantWebEngagement,
}
