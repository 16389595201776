export class FileUploadForWatermarkingResult {
  isSuccess: boolean;
  fileUrl: string;
  validationErrors: string[];
  file?: File;

  constructor(isSuccess: boolean = false, fileUrl: string = null, validationErrors: string[] = [], file: File = null) {
    this.isSuccess = isSuccess;
    this.fileUrl = fileUrl;
    this.validationErrors = validationErrors;
    this.file = file;
  }
}
