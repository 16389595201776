import BaseTargetPage from '@core/models/target-pages/base-target-page';
import { ActTypeEnum } from '../enums/act-type-enum';
import { PageBuildingBlock } from '../page-building-block';

class ActTargetPage extends BaseTargetPage {
  formBuilderData: string;
  pageBuildingBlocks: PageBuildingBlock[];
  constructor() {
    super();
    this.type = ActTypeEnum.Act;
    this.pageBuildingBlocks = [];
  }

  getEditHeader() {
    return 'EDIT_ACT_TARGET';
  }

  getCreateHeader() {
    return 'CREATE_NEW_ACT_TARGET';
  }

  getTargetPageInfo() {
    return {
      name: this.title,
      pageName: this.pageName,
      thumbnailUrl: this.thumbnailUrl,
      svgIcon: 'target-act',
      imageUrl: 'assets/images/act-background.png',
    };
  }
}

export default ActTargetPage;
