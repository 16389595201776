import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule } from '@angular/forms';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';
import { MatLegacyCardModule } from '@angular/material/legacy-card';
import { MatLegacyChipsModule } from '@angular/material/legacy-chips';
import { MatLegacyMenuModule } from '@angular/material/legacy-menu';
import { MatLegacyTooltipModule } from '@angular/material/legacy-tooltip';
import { MatSortModule } from '@angular/material/sort';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from '@shared/shared.module';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { TargetPagesCardModule } from '../../target-pages-dashboard/target-pages-card/target-pages-card.module';
import { TargetPagesDashboardModule } from '../../target-pages-dashboard/target-pages-dashboard.module';
import { SavedTargetPagesRoutingModule } from './saved-target-pages-routing.module';
import { SavedTargetPagesComponent } from './saved-target-pages.component';

@NgModule({
  declarations: [SavedTargetPagesComponent],
  imports: [
    CommonModule,
    SavedTargetPagesRoutingModule,
    MatIconModule,
    MatLegacyMenuModule,
    MatDividerModule,
    MatSortModule,
    FlexLayoutModule,
    MatLegacyButtonModule,
    MatLegacyCardModule,
    FormsModule,
    SharedModule,
    MatSortModule,
    MatButtonToggleModule,
    ProgressSpinnerModule,
    MatLegacyChipsModule,
    MatLegacyTooltipModule,
    TranslateModule.forChild(),
    TargetPagesDashboardModule,
    TargetPagesCardModule,
  ],
  exports: [SavedTargetPagesComponent],
})
export class SavedTargetPagesModule {}
