import { Component, OnInit } from '@angular/core';
import Route from '@core/constants/route';
import { Filters } from '@core/models/filters';
import { ShopType } from '@core/models/update-merchant-product-config-request';
import { Store } from '@ngrx/store';
import { BaseComponent } from '@shared/components/base-component/base-component';
import { takeUntil } from 'rxjs/operators';
import { Connection, ShopConnectionDic } from 'src/app/features/products/shop-connection-models';
import { MenuWizardService } from '../menu-wizard/menu-wizard-service/menu-wizard.service';
import { SelfOnboardingState } from '../store';
import {
  createSelectEcommercePlatform,
  resetEcommercePlatformState,
} from '../store/actions/ecommerce-platform.actions';
import { resetPlatformDetailsState } from '../store/actions/platform-details.action';
import { environment } from '@env/environment';

@Component({
  selector: 'app-select-ecommerce-platform',
  templateUrl: './select-ecommerce-platform.component.html',
  styleUrls: ['./select-ecommerce-platform.component.scss'],
})
export class SelectEcommercePlatformComponent extends BaseComponent implements OnInit {
  filters: Filters = {};
  eCommercePlatforms = ShopConnectionDic;
  dataSource: { [type in ShopType]: Connection } = null;
  selectedShop = null;
  supportedPlatforms = environment.supportedEcommercePlatforms;

  constructor(private readonly store: Store<SelfOnboardingState>, private readonly wizardService: MenuWizardService) {
    super();
  }

  ngOnInit(): void {
    this.dataSource = this.eCommercePlatforms;
    this.wizardService.revertSkippedStep(Route.PLATFORM_DETAIL);
    this.wizardService.canGoToNextStep$.next(false);
    this.wizardService.nextStepRequest$.pipe(takeUntil(this.destroy$)).subscribe((_) => {
      this.wizardService.goNext();
    });
    this.onSuportedeCommercePlatform();
  }

  eCommerceOrder() {
    return 0;
  }

  onSuportedeCommercePlatform() {
    let allPlatforms: { [type in ShopType]: Connection };
    Object.keys(this.eCommercePlatforms).forEach((key) => {
      if (this.supportedPlatforms.includes(key)) {
        allPlatforms = {
          ...allPlatforms,
          ...{
            [key]: this.dataSource[key],
          },
        };
      }
    });
    this.dataSource = allPlatforms;
    return this.dataSource;
  }

  onSearched(value: string) {
    let searchedValues: { [type in ShopType]: Connection } = null;

    if (value) {
      Object.keys(this.onSuportedeCommercePlatform()).forEach((key) => {
        if (key.toLocaleLowerCase().includes(value.toLocaleLowerCase())) {
          searchedValues = {
            ...searchedValues,
            ...{
              [key]: this.dataSource[key],
            },
          };
        }
      });
      this.dataSource = searchedValues;
      return;
    }
    this.dataSource = this.eCommercePlatforms;
    this.onSuportedeCommercePlatform();
  }

  skip() {
    this.store.dispatch(resetEcommercePlatformState());
    this.store.dispatch(resetPlatformDetailsState());
    this.wizardService.skipStep(Route.PLATFORM_DETAIL);
    this.wizardService.goNext();
  }

  onEcommerceSelected(key: any): void {
    this.selectedShop = key;
    const newSelectedEcommercePlatform = { [this.selectedShop]: this.dataSource[this.selectedShop] };
    // @ts-ignore
    this.store.dispatch(createSelectEcommercePlatform({ selectedEcommercePlatform: newSelectedEcommercePlatform }));
    this.wizardService.canGoToNextStep$.next(true);
  }
}
