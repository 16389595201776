import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatLegacyFormFieldModule } from '@angular/material/legacy-form-field';
import { DataViewComponent } from '@shared/components/data-view/data-view.component';

@NgModule({
  declarations: [DataViewComponent],
  imports: [MatLegacyFormFieldModule, CommonModule],
  exports: [DataViewComponent],
})
export class DataViewModule {}
