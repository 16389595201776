export function overrideDefaultFontFamily(editorInit: Record<string, any>, defaultFont: string): Record<string, any> {
  editorInit.setup = (ed) => {
    ed.on('init', function () {
      this.getDoc().body.style.fontFamily = defaultFont;
    });
  };

  return editorInit;
}

export function overrideFontFormats(editorInit: Record<string, any>, fonts: string): Record<string, any> {
  editorInit.font_formats = fonts;

  return editorInit;
}
