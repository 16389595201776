import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AllowOnlyDigitNumbersDirective } from '@core/directives/allow-only-digit-numbers.directive';
import { AutocompleteOffDirective } from '@core/directives/autocomplete-off.directive';
import { DragAndDropDirective } from './drag-and-drop.directive';
import { HideElementDirective } from './hide-element.directive';
import { NgTemplateDataStoreDirective } from './ng-template-data-store.directive';
import { StyleMatPaginatorDirective } from './style-mat-paginator.directive';
import { TwoDigitDecimalNumberDirective } from './two-digit-decimal-number.directive';
import { WhiteSpaceValidatorDirective } from './white-space-validator.directive';

@NgModule({
  declarations: [
    DragAndDropDirective,
    NgTemplateDataStoreDirective,
    StyleMatPaginatorDirective,
    HideElementDirective,
    TwoDigitDecimalNumberDirective,
    WhiteSpaceValidatorDirective,
    AutocompleteOffDirective,
    AllowOnlyDigitNumbersDirective,
  ],
  imports: [CommonModule],
  exports: [
    DragAndDropDirective,
    NgTemplateDataStoreDirective,
    StyleMatPaginatorDirective,
    HideElementDirective,
    TwoDigitDecimalNumberDirective,
    WhiteSpaceValidatorDirective,
    AutocompleteOffDirective,
    AllowOnlyDigitNumbersDirective,
  ],
})
export class CustomDirectivesModule {}
