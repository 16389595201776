import { MatSort } from '@angular/material/sort';
import { ColumnDefinition } from './column-definition';

export class GridOptions {
  rowData: any[] = [];
  columnDefinitions: ColumnDefinition[] = [];
  menuActions: GridAction[] = [];
  buttonActions: GridAction[] = [];
  singleRowSelectionEnabled = false;
  sort: MatSort = new MatSort();
}

export interface GridAction {
  type: GridActionTypeEnum;
  description?: string;
  icon?: string;
  show?: boolean;
}

export enum GridActionTypeEnum {
  Create,
  Read,
  Update,
  Delete,
  Download,
}
