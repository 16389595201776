import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { CardProviderEnum, SETTINGS_CARD_PROVIDERS } from '@core/constants/CardProviders';
import { LoaderService } from '@core/services/loader.service';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { BaseComponent } from '@shared/components/base-component/base-component';
import { PopupService } from '@shared/components/popup/popup.service';
import { switchMap, takeUntil } from 'rxjs/operators';
import {
  aci3DSecureCommonConfiguration,
  Aci3DSecureFormField,
  getBasic3DSecureFormFields,
  getCommon3DSecureFormFields,
} from '../../../products/shop-connection-models';
import { MenuWizardService } from '../menu-wizard/menu-wizard-service/menu-wizard.service';
import { SelfOnboardingState } from '../store';
import { createAci3DSecureDetails, skipAci3DSecureDetails } from '../store/actions/aci-3D-secure-details.actions';
import { clearAciOnboardingProgressState } from '../store/actions/aci-onboarding-progress.actions';
import { OnboardingProgressEnum } from '../store/models/aci-onboarding.models';
import { getAciOnboardingProgress } from '../store/selectors/aci-onboarding-progress.select';
import { getAciPaymentProviderDetails } from '../store/selectors/aci-payment-provider-details.select';

@Component({
  selector: 'app-setting-3DSecure-details',
  templateUrl: './aci-3DSecure-details.component.html',
  styleUrls: ['./aci-3DSecure-details.component.scss'],
})
export class Aci3DSecureDetailsComponent extends BaseComponent implements OnInit {
  form: FormGroup | undefined;
  type: CardProviderEnum;
  commonConfiguration: Aci3DSecureFormField[] = aci3DSecureCommonConfiguration;
  aci3DSecureConfigurationsDict = {};
  @ViewChild('aci3DSecureDetailsForm') ngForm: FormGroupDirective;

  constructor(
    public loaderService: LoaderService,
    private readonly menuWizardService: MenuWizardService,
    private readonly store: Store<SelfOnboardingState>,
    private readonly translateService: TranslateService,
    private readonly popupService: PopupService,
  ) {
    super();
    this.form = new FormGroup({});
  }

  ngOnInit(): void {
    this.menuWizardService.canGoToNextStep$.next(false);
    this.loaderService.show();
    this.updateForm(this.commonConfiguration);
    this.store.select(getAciPaymentProviderDetails).subscribe((res) => {
      // tslint:disable-next-line:forin
      for (const element in res.paymentProviderDetails) {
        for (const brand of SETTINGS_CARD_PROVIDERS) {
          if (element === brand.key && res.paymentProviderDetails[element]) {
            switch (element) {
              case 'VISA':
                this.aci3DSecureConfigurationsDict[element] = getBasic3DSecureFormFields('visa');
                this.updateForm(getBasic3DSecureFormFields('visa'));
                break;
              case 'AMEX':
                this.aci3DSecureConfigurationsDict[element] = getCommon3DSecureFormFields('amex', false);
                this.updateForm(getCommon3DSecureFormFields('amex', false));
                break;
              case 'MASTER':
                this.aci3DSecureConfigurationsDict[element] = getBasic3DSecureFormFields('master');
                this.updateForm(getBasic3DSecureFormFields('master'));
                break;
            }
          }
        }
      }
    });
    this.form.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((_) => {
      this.menuWizardService.canGoToNextStep$.next(this.form.valid);
    });

    this.menuWizardService.nextStepRequest$.pipe(takeUntil(this.destroy$)).subscribe((_) => {
      this.ngForm.ngSubmit.emit();
    });

    this.menuWizardService.skipStepRequest$.pipe(takeUntil(this.destroy$)).subscribe((_) => {
      this.onSkip();
    });
    this.loaderService.hide();

    this.store
      .select(getAciOnboardingProgress)
      .pipe(takeUntil(this.destroy$))
      .subscribe(({ aci3DSecureStep }) => {
        switch (aci3DSecureStep.state) {
          case OnboardingProgressEnum.Success:
            this.loaderService.hide();
            this.menuWizardService.canGoToNextStep$.next(true);
            this.menuWizardService.goNext();
            this.store.dispatch(clearAciOnboardingProgressState());
            break;

          case OnboardingProgressEnum.Failed:
            this.loaderService.hide();
            this.menuWizardService.canGoToNextStep$.next(true);
            this.show3DSecureFailed();
            break;

          case OnboardingProgressEnum.Pending:
            this.loaderService.show();
            this.menuWizardService.canGoToNextStep$.next(false);
            break;

          default:
            break;
        }
      });
  }

  updateForm(formFields: Aci3DSecureFormField[]) {
    (formFields || []).forEach((formField) => {
      this.form.addControl(
        formField.formControlName,
        new FormControl('', [...(formField.isRequired ? [Validators.required] : []), ...(formField.validations || [])]),
      );
    });
  }

  onSubmit() {
    if (this.form.invalid) {
      return;
    }

    this.store.dispatch(
      createAci3DSecureDetails({
        ...this.form.value,
      }),
    );
  }

  onSkip() {
    this.store.dispatch(skipAci3DSecureDetails());
  }

  private show3DSecureFailed(): void {
    this.translateService
      .get('3D_SECURE.FAILED_TO_UPDATE_3D_SECURE')
      .pipe(
        switchMap((translated) =>
          this.popupService.successStatusPopup({
            componentConfiguration: { isSuccess: false, message: translated },
            height: 'auto',
            width: 'auto',
            maxWidth: '430px',
            backdropClass: 'blurred-backdrop',
          }),
        ),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }
}
