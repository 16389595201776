import { SelectItem } from '@core/models/select-item';

export enum ActiveTime {
  ALL_DAY,
  CUSTOM_TIMES,
}

export const ActiveTimes: SelectItem[] = [
  {
    key: `${ActiveTime[ActiveTime.ALL_DAY]}`,
    value: 0,
  },
  {
    key: `${ActiveTime[ActiveTime.CUSTOM_TIMES]}`,
    value: 1,
  },
];
