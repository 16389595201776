import { RedirectLoginResult } from '@auth0/auth0-spa-js';
import { Observable } from 'rxjs';

export const NO_PAY_PAYMENT_FLOW = 'No Pay';

export interface AuthServiceInterface {
  logout(): void;
  login(redirectPath?: string, queryParams?: any): void;
  login$(redirectPath?: string, queryParams?: any): Observable<void>;
  getTokenSilently$(options?): Observable<string>;
  getPaymentFlow$(): Observable<string>;
  isAuthenticated$: Observable<boolean>;
  userProfile$: Observable<any>;
  loggedIn: boolean;
  paymentFlow: string;
  useDigimarc: boolean;
  handleRedirectCallback$: Observable<RedirectLoginResult>;
  isLoggedIn$: Observable<boolean>;
}
