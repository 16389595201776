import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AudioFileUploadModule } from './audio-file-upload/audio-file-upload.module';
import { ImageFileUploadModule } from './image-file-upload/image-file-upload.module';
import { ProductImageFileUploadModule } from './product-image-file-upload/product-image-file-upload.module';

@NgModule({
  declarations: [],
  imports: [CommonModule, AudioFileUploadModule, ImageFileUploadModule, ProductImageFileUploadModule],
  exports: [AudioFileUploadModule, ImageFileUploadModule, ProductImageFileUploadModule],
})
export class FileUploadModule {}
