import { Product } from '@core/models/dtos/product';
import BaseTargetPage from '@core/models/target-pages/base-target-page';
import { ActTypeEnum } from '../enums/act-type-enum';

class BuyTargetPage extends BaseTargetPage {
  customUrl: string;
  isBuy: boolean;
  product: Product;

  constructor() {
    super();
    this.type = ActTypeEnum.Buy;
    this.isBuy = true;
  }

  resetProductAndUrl() {
    this.customUrl = null;
    this.product = null;
  }

  selectProduct(product: Product) {
    this.product = product;
  }

  getEditHeader() {
    return 'EDIT_BUY_TARGET';
  }

  getCreateHeader() {
    return 'CREATE_NEW_BUY_TARGET';
  }

  getTargetPageInfo() {
    return {
      name: this.title,
      pageName: this.pageName,
      thumbnailUrl: this.thumbnailUrl,
      svgIcon: 'target-buy',
      imageUrl: 'assets/images/buy-background.png',
    };
  }
}

export default BuyTargetPage;
