import { Component, Input } from '@angular/core';

@Component({
  selector: 'need-merchant-link',
  templateUrl: './need-merchant-link.component.html',
  styleUrls: ['./need-merchant-link.component.scss'],
})
export class NeedMerchantLinkComponent {
  @Input() needMerchantUrl = '';
}
