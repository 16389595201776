import { Component } from '@angular/core';
import { MatLegacyDialogRef } from '@angular/material/legacy-dialog';

@Component({
  selector: 'go-to-my-details-popup',
  templateUrl: './go-to-my-details-popup.component.html',
  styleUrls: ['./go-to-my-details-popup.component.scss'],
})
export class GoToMyDetailsPopupComponent {
  constructor(public matDialogRef: MatLegacyDialogRef<GoToMyDetailsPopupComponent>) {}
}
