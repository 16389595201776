import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { ConfirmButtonModule } from '@shared/components/confirm-button/confirm-button.module';
import { WelcomeOnboarderComponent } from './welcome-onboarder.component';
import { OnBoardingPopupModule } from '@shared/components/popup/on-boarding-popup/on-boarding-popup.module';
import { MatStepper } from '@angular/material/stepper';
import { CdkStepper } from '@angular/cdk/stepper';

@NgModule({
  exports: [WelcomeOnboarderComponent],
  declarations: [WelcomeOnboarderComponent],
  imports: [CommonModule, ConfirmButtonModule, TranslateModule, RouterModule, OnBoardingPopupModule],
  providers: [MatStepper, CdkStepper],
})
export class WelcomeOnboarderModule {}
