import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';
import { TranslateModule } from '@ngx-translate/core';
import { ConfirmButtonModule } from '@shared/components/confirm-button/confirm-button.module';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { AudioFilePreviewComponent } from './audio-file-preview.component';

@NgModule({
  declarations: [AudioFilePreviewComponent],
  imports: [
    CommonModule,
    FlexLayoutModule,
    ProgressSpinnerModule,
    MatLegacyButtonModule,
    TranslateModule,
    ConfirmButtonModule,
  ],
  exports: [AudioFilePreviewComponent],
})
export class AudioFilePreviewModule {}
