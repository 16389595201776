import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FileFormats } from '@core/constants/FileFormats';
import { FileUploadForWatermarkingResult } from '@core/models/file-upload-for-watermarking-result';
import { MediaType, UploadProductMediaRequest } from '@core/models/product/UploadProductMediaRequest';
import { EngagementAPIService } from '@core/services/engagement-api.service';
import { LoaderService } from '@core/services/loader.service';
import { ProductService } from '@core/services/product.service';
import { Guid } from '@core/utils/Guid';
import { StringFormatHelper } from '@core/utils/string-format-helper';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { LogUtils } from 'src/app/utils/log-utils';
import BaseFileUpload from '../shared/base-file-upload';
import { FileUploadTypeEnum } from '../shared/file-upload-configuration';

export type ProductFileUploadState = 'removed' | 'old' | 'new';
export interface FileInfo {
  file?: File;
  url?: string;
  id: string;
  state: ProductFileUploadState;
  mediaTypes: MediaType[];
}
@Component({
  selector: 'app-product-image-file-upload',
  templateUrl: './product-image-file-upload.component.html',
  styleUrls: ['./product-image-file-upload.component.scss'],
})
export class ProductImageFileUploadComponent extends BaseFileUpload {
  @Input() sku: string;
  @Output() chosenFilesChange: EventEmitter<FileInfo[]> = new EventEmitter();
  @Input() files: FileInfo[] = [];

  uploadType = FileUploadTypeEnum.IMAGE;
  loading$: Observable<boolean>;

  constructor(
    protected translationService: TranslateService,
    protected productApiService: ProductService,
    public loaderService: LoaderService,
  ) {
    super(
      {
        acceptedFileFormats: [FileFormats.JPEG, FileFormats.PNG, FileFormats.TIF, FileFormats.TIFF, FileFormats.JPG],
        maxFileSize: 150000000,
        translationSectionName: 'TEXTDISPLAYFORIMGVALIDATION',
      },
      translationService,
    );
    this.loaderService.hide();
    this.loading$ = this.loaderService.isLoading();
  }

  handleFileUpload(files: FileList) {
    this.file = files.item(0);
    this.files = [...this.files, { file: this.file, id: Guid.newGuid(), state: 'new', mediaTypes: ['image'] }];
    this.chosenFilesChange.next(this.files);
  }

  fileRemoved(id: string) {
    const file = this.files.find((file) => file.id == id);
    if (file.state === 'new') {
      this.files = this.files.filter((x) => x.id != id);
    } else {
      let fileToRemove: FileInfo = { ...file, state: 'removed' };
      this.files = this.files.filter((x) => x.id != id).concat(fileToRemove);
    }
    this.chosenFilesChange.next(this.files);
  }

  fileUpdated(event) {
    let fileToUpdate = this.files.find((file) => file.id == event.tempId);
    let updatedFile: FileInfo = { ...fileToUpdate, mediaTypes: event.mediaTypes };
    let filteredOut = this.files.filter((x) => x.id != event.tempId);
    filteredOut.push(updatedFile);
    this.files = filteredOut;
    this.chosenFilesChange.next(this.files);
  }

  uploadFile(formData: FormData): void {}

  onLoadingCompleted(): void {
    this.loaderService.hide();
  }

  onInitialValidationFailed(): void {}
}
