import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-masked-text',
  templateUrl: './masked-text.component.html',
  styleUrls: ['./masked-text.component.scss'],
})
export class MaskedTextComponent {
  @Input() value!: string | number;
  shouldMaskValue: boolean;

  constructor() {
    this.shouldMaskValue = true;
  }
}
