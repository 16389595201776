import BaseTargetPage from '@core/models/target-pages/base-target-page';
import { ActTypeEnum } from '../enums/act-type-enum';

class UrlTargetPage extends BaseTargetPage {
  customUrl: string;
  isBuy: boolean;

  constructor() {
    super();
    this.type = ActTypeEnum.Url;
    this.isBuy = true;
  }

  getEditHeader() {
    return 'EDIT_URL_TARGET';
  }

  getCreateHeader() {
    return 'CREATE_NEW_URL_TARGET';
  }

  getTargetPageInfo() {
    return {
      name: this.title,
      pageName: this.pageName,
      thumbnailUrl: this.thumbnailUrl,
      svgIcon: 'target-link',
      imageUrl: 'assets/images/link-bg.png',
    };
  }
}

export default UrlTargetPage;
