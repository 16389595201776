import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatLegacyPaginatorModule } from '@angular/material/legacy-paginator';
import { PaginationComponent } from './pagination.component';

@NgModule({
  declarations: [PaginationComponent],
  imports: [CommonModule, MatLegacyPaginatorModule],
  exports: [PaginationComponent],
})
export class PaginationModule {}
