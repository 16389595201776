import { Pipe, PipeTransform } from '@angular/core';
import { EngagementTypeEnum } from '@core/models/enums/engagement-type-enum';

@Pipe({ name: 'displayTriggerType' })
export class DisplayTriggerTypePipe implements PipeTransform {
  transform(triggerType: EngagementTypeEnum): string {
    if (triggerType != null && triggerType != undefined) {
      return EngagementTypeEnum[triggerType].toUpperCase();
    }
  }
}
