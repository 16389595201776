import { Component, Input, OnInit } from '@angular/core';
import { MatLegacyDialogRef } from '@angular/material/legacy-dialog';
import { MatStepper } from '@angular/material/stepper';
import { OnBoardingPopupComponent } from '@shared/components/popup/on-boarding-popup/on-boarding-popup.component';

@Component({
  selector: 'app-screen-three',
  templateUrl: './screen-three.component.html',
  styleUrls: ['../screens.component.scss'],
})
export class ScreenThreeComponent implements OnInit {
  @Input() stepper: MatStepper;

  constructor(public matDialogRef: MatLegacyDialogRef<OnBoardingPopupComponent>) {}

  ngOnInit(): void {}

  goBack(stepper: MatStepper) {
    stepper.previous();
  }

  goForward(stepper: MatStepper) {
    stepper.next();
  }
}
