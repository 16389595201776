import { Injectable } from '@angular/core';
import { ActDTO } from '../dtos/act-dto';
import BaseTargetPage from '../target-pages/base-target-page';
import { Adapter } from './adapter';

@Injectable({
  providedIn: 'root',
})
export class TargetAdapter implements Adapter<BaseTargetPage, ActDTO> {
  fromDTO(dto: ActDTO): BaseTargetPage {
    let target: BaseTargetPage;
    target = (dto as any) as BaseTargetPage;
    target.type = dto.actType;
    return target;
  }

  toDTO(viewModel: BaseTargetPage): ActDTO {
    let dto: ActDTO;
    dto = dto as ActDTO;
    dto.actType = viewModel.type;
    return dto;
  }
}
