import { UserEngagementsActions } from '@core/models/enums/user-engagements-actions';
import { environment } from '@env/environment';
import { GridAction, GridActionTypeEnum } from '@shared/components/dashboard-table/models/grid-options';
import { Connection } from 'src/app/features/products/shop-connection-models';
import { PaymentType } from 'src/app/features/settings/self-onboarding/payment-settings/payment-connection-models';

export class EnvironmentVariableHelper {
  public static removeHiddenEngagementActions(actions: UserEngagementsActions[]): UserEngagementsActions[] {
    if (!environment.hiddenEngagementActions) {
      return actions;
    }

    const hiddenActions: UserEngagementsActions[] = environment.hiddenEngagementActions.map((key: string) => {
      const value: UserEngagementsActions = UserEngagementsActions[key];
      if (value != null) {
        return value;
      }
    });

    return actions.filter((action) => !hiddenActions.includes(action));
  }

  public static removeHiddenTargetPageActions(actions: GridAction[]): GridAction[] {
    if (!environment.hiddenTargetPageActions) {
      return actions;
    }

    const hiddenActions: GridActionTypeEnum[] = environment.hiddenTargetPageActions.map((key: string) => {
      const value: GridActionTypeEnum = GridActionTypeEnum[key];
      if (value != null) {
        return value;
      }
    });

    return actions.filter((action) => !hiddenActions.includes(action.type));
  }

  public static removeHiddenPaymentProvider(dictionary: any): { [type in PaymentType]: Connection } {
    let newDic = {};

    for (const key in dictionary) {
      if (!(environment.hiddenPaymentProviders || []).includes(key)) {
        newDic[key] = dictionary[key];
      }
    }

    return newDic as { [type in PaymentType]: Connection };
  }
}
