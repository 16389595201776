// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container {
  display: flex;
  flex-direction: column;
  flex: 1 1 100%;
  height: 100%;
  align-items: center;
}

.wizard {
  width: 100%;
}

.layout {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1280px;
  padding: 0 56px;
  height: 100%;
}

footer {
  padding: 1em 4em;
  margin-top: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;
}

@media screen and (max-width: 599px) {
  .layout,
  footer {
    padding: 6px 10px;
  }
}
@media screen and (max-width: 455px) {
  footer {
    align-items: center;
  }
}
.new-engagement-btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

@media screen and (min-width: 1025px) {
  .new-engagement-btn {
    padding: 50px;
  }
}
@media screen and (max-width: 1279px) {
  .wizard {
    margin: 0;
    width: 100%;
  }
}
@media screen and (max-width: 679px) {
  .layout {
    width: 100%;
    padding: 0 16px;
    margin: 0;
  }
}
:host ::ng-deep app-google-map,
:host ::ng-deep app-baidu-map {
  height: 100%;
}`, "",{"version":3,"sources":["webpack://./src/app/features/engagement-creation/engagement-creation.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,cAAA;EACA,YAAA;EACA,mBAAA;AACF;;AACA;EACE,WAAA;AAEF;;AACA;EACE,aAAA;EACA,sBAAA;EACA,WAAA;EACA,iBAAA;EACA,eAAA;EACA,YAAA;AAEF;;AACA;EACE,gBAAA;EACA,gBAAA;EACA,aAAA;EACA,sBAAA;EACA,qBAAA;EACA,WAAA;AAEF;;AACA;EACE;;IAEE,iBAAA;EAEF;AACF;AACA;EACE;IACE,mBAAA;EACF;AACF;AAEA;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,mBAAA;AAAF;;AAGA;EACE;IACE,aAAA;EAAF;AACF;AAGA;EACE;IACE,SAAA;IACA,WAAA;EADF;AACF;AAIA;EACE;IACE,WAAA;IACA,eAAA;IACA,SAAA;EAFF;AACF;AAME;;EAEE,YAAA;AAJJ","sourcesContent":[".container {\n  display: flex;\n  flex-direction: column;\n  flex: 1 1 100%;\n  height: 100%;\n  align-items: center;\n}\n.wizard {\n  width: 100%;\n}\n\n.layout {\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n  max-width: 1280px;\n  padding: 0 56px;\n  height: 100%;\n}\n\nfooter {\n  padding: 1em 4em;\n  margin-top: auto;\n  display: flex;\n  flex-direction: column;\n  align-items: flex-end;\n  width: 100%;\n}\n\n@media screen and (max-width: 599px) {\n  .layout,\n  footer {\n    padding: 6px 10px;\n  }\n}\n\n@media screen and (max-width: 455px) {\n  footer {\n    align-items: center;\n  }\n}\n\n.new-engagement-btn {\n  display: flex;\n  flex-direction: row;\n  justify-content: center;\n  align-items: center;\n}\n\n@media screen and (min-width: 1025px) {\n  .new-engagement-btn {\n    padding: 50px;\n  }\n}\n\n@media screen and (max-width: 1279px) {\n  .wizard {\n    margin: 0;\n    width: 100%;\n  }\n}\n\n@media screen and (max-width: 679px) {\n  .layout {\n    width: 100%;\n    padding: 0 16px;\n    margin: 0;\n  }\n}\n\n:host ::ng-deep {\n  app-google-map,\n  app-baidu-map {\n    height: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
