import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatIconModule } from '@angular/material/icon';
import { CustomPipeModule } from '@shared/pipes/custom-pipe.module';
import { MaskedTextComponent } from './masked-text.component';

@NgModule({
  declarations: [MaskedTextComponent],
  imports: [CommonModule, CustomPipeModule, MatIconModule, FlexLayoutModule],
  exports: [MaskedTextComponent],
})
export class MaskedTextModule {}
