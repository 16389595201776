// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container {
  display: flex;
  flex-direction: row;
  flex: 1 1 100%;
  width: 100%;
  height: auto;
  flex-wrap: wrap;
  justify-content: center;
}

.link {
  padding: 1em;
  font-size: 17px;
  font-weight: 500;
  line-height: 1.4;
  color: #adb9ca;
  text-decoration: none;
}

.clickable-link {
  cursor: pointer;
}

@media screen and (max-width: 625px) {
  .link {
    font-size: 15px;
    padding: 10px;
  }
}
@media screen and (max-width: 455px) {
  .container {
    flex-direction: column;
  }
  .link {
    text-align: center;
  }
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/links-container/links-container.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,cAAA;EACA,WAAA;EACA,YAAA;EACA,eAAA;EACA,uBAAA;AACF;;AAEA;EACE,YAAA;EACA,eAAA;EACA,gBAAA;EACA,gBAAA;EACA,cAAA;EACA,qBAAA;AACF;;AAEA;EACE,eAAA;AACF;;AAEA;EACE;IACE,eAAA;IACA,aAAA;EACF;AACF;AAEA;EACE;IACE,sBAAA;EAAF;EAGA;IACE,kBAAA;EADF;AACF","sourcesContent":[".container {\n  display: flex;\n  flex-direction: row;\n  flex: 1 1 100%;\n  width: 100%;\n  height: auto;\n  flex-wrap: wrap;\n  justify-content: center;\n}\n\n.link {\n  padding: 1em;\n  font-size: 17px;\n  font-weight: 500;\n  line-height: 1.4;\n  color: #adb9ca;\n  text-decoration: none;\n}\n\n.clickable-link {\n  cursor: pointer;\n}\n\n@media screen and (max-width: 625px) {\n  .link {\n    font-size: 15px;\n    padding: 10px;\n  }\n}\n\n@media screen and (max-width: 455px) {\n  .container {\n    flex-direction: column;\n  }\n\n  .link {\n    text-align: center;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
