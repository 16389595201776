import { ShopType } from '@core/models/update-merchant-product-config-request';
import { createAction, props } from '@ngrx/store';
import { Connection } from '../../../../products/shop-connection-models';

export const createSelectEcommercePlatform = createAction(
  '[CreateSelectEcommercePlatform] Create Select Ecommerce Platform',
  props<{ selectedEcommercePlatform: { [type in ShopType]: Connection } }>(),
);

export const resetEcommercePlatformState = createAction('[ResetState] Reset eCommerce platform state');
