import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { TargetPageCards } from '@core/constants/TargetPageCards';
import { CardInfo } from '@core/models/card-info';
import { EngagementDTO } from '@core/models/dtos/engagement-dto';
import { ActTypeEnum } from '@core/models/enums/act-type-enum';
import { PageBuildingBlock } from '@core/models/page-building-block';
import BaseTargetPage from '@core/models/target-pages/base-target-page';
import { ActViewModel } from '@core/models/view-models/act-view-model';
import { ActAPIService } from '@core/services/act-api.service';
import { EngagementAPIService } from '@core/services/engagement-api.service';
import { LoaderService } from '@core/services/loader.service';
import { UserTierService } from '@core/services/user-tier.service';
import { TranslateService } from '@ngx-translate/core';
import { BaseComponent } from '@shared/components/base-component/base-component';
import { targetsLoadingKey } from '@shared/components/base-targets-dashboard-component/base-targets-dashboard-component';
import { GridAction, GridActionTypeEnum } from '@shared/components/dashboard-table/models/grid-options';
import { UniqueGridItem } from '@shared/components/dashboard-table/models/unique-grid-item';
import { PopupService } from '@shared/components/popup/popup.service';
import { ConfirmPopupMessage } from '@shared/components/popup/simple-confirm-popup/simple-confirm-popup.configuration';
import { Observable } from 'rxjs';
import { switchMap, take, takeUntil } from 'rxjs/operators';
import { exportActs } from '../act-submission-block/act-submission-block.component';

@Component({
  selector: 'app-target-pages-card',
  templateUrl: './target-pages-card.component.html',
  styleUrls: ['./target-pages-card.component.scss'],
})
export class TargetPagesCardComponent extends BaseComponent implements OnInit {
  @Input() target: ActViewModel;
  @Input() singleRowSelectionEnabled = false;
  @Input() selectedRowIndex?: number = -1;
  @Input() displayNameOnly = false;
  @Output() deleteTarget: EventEmitter<ActViewModel> = new EventEmitter();
  @Output() rowSelectionEvent: EventEmitter<any> = new EventEmitter();

  ActTypeEnum = ActTypeEnum;
  targetActions: GridAction[] = [];
  targetPages: CardInfo[] = TargetPageCards.NEW_TARGET_PAGES;
  hasPassedLimit: boolean;
  selectedRow?: any = null;

  constructor(
    private readonly actService: ActAPIService,
    private readonly loaderService: LoaderService,
    private readonly popupService: PopupService,
    private readonly translateService: TranslateService,
    private readonly engagementService: EngagementAPIService,
    private readonly tierService: UserTierService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.configureActions();

    this.tierService.getActsUsageForUser().subscribe((tierUsage) => {
      this.targetPages.forEach((triggerTypeCard) => {
        const currentTier = tierUsage.find((x) => x.type === triggerTypeCard.type);
        triggerTypeCard.limit = currentTier?.limit;
        triggerTypeCard.hasPassedLimit = currentTier?.hasPassedLimit;
        this.hasPassedLimit = currentTier?.hasPassedLimit;
      });
    });

    if (this.selectedRowIndex > -1) {
      this.selectedRow = this.target[this.selectedRowIndex];
    }
  }

  handleRowSelection(selectedRow: any): void {
    this.selectedRow = this.selectedRow === selectedRow ? null : selectedRow;
    this.rowSelectionEvent.emit(this.selectedRow);
  }

  onEditTarget(gridItem: UniqueGridItem): void {
    const row = gridItem.row as ActViewModel;
    this.actService.navigateToEditPage(row.actType, row.id);
  }

  onPreviewTarget(gridItem: UniqueGridItem): void {
    const row = gridItem.row as ActViewModel;
    this.showTargetPreview(row.actType, row.pageBuildingBlocks);
  }

  getAssociatedEngagements(gridItem: ActViewModel): void {
    const data = gridItem;
    if (data.associatedEngagementsNames.length > 0) {
      this.showAssociatedEngagements(
        this.translateService.instant('ASSOCIATED_ENGAGEMENTS'),
        data.associatedEngagements,
        data.pageName,
        data.icon,
        data.id,
      );
    }
  }

  onDownload(id, title) {
    exportActs(this.translateService, this.actService, this.popupService, id, title)
      .pipe(takeUntil(this.destroy$))
      .subscribe();
  }

  configureActions() {
    this.targetActions = [
      { type: GridActionTypeEnum.Create, description: 'DUPLICATE_AND_EDIT' },
      { type: GridActionTypeEnum.Delete, description: 'DELETE' },
      {
        type: GridActionTypeEnum.Download,
        description: 'DOWNLOAD',
        show: this.target.submissionCount != null,
      },
    ];
  }

  onChosenAction(gridItem: UniqueGridItem): void {
    switch (gridItem.action.type) {
      case GridActionTypeEnum.Create:
        if (this.hasPassedLimit) {
          this.popupService.simpleConfirmationPopup({
            maxWidth: '90%',
            width: '460px',
            height: 'auto',
            componentConfiguration: {
              message: `${this.translateService.instant('QUOTA_ENGAGEMENT_LIMIT_MESSAGE')}.`,
              showDeleteIcon: false,
              disableNoButton: true,
            },
          });
        } else {
          this.cloneTargetPage(gridItem.row as ActViewModel);
        }
        break;
      case GridActionTypeEnum.Read:
        const currentRow = gridItem.row as ActViewModel;
        this.showTargetPreview(currentRow.actType, currentRow.pageBuildingBlocks);
        break;
      case GridActionTypeEnum.Update:
        const row = gridItem.row as ActViewModel;
        this.actService.navigateToEditPage(row.actType, row.id);
        break;
      case GridActionTypeEnum.Delete:
        this.handleTargetDelete(gridItem.row as ActViewModel);
        break;
      case GridActionTypeEnum.Download:
        exportActs(this.translateService, this.actService, this.popupService, gridItem.row.id, gridItem.row.title)
          .pipe(takeUntil(this.destroy$))
          .subscribe();
        break;
      default:
        break;
    }
  }

  onActionSectionClick(event: Event): void {
    event.stopPropagation();
  }

  private handleTargetDelete(item: ActViewModel): void {
    const hasLinks = item.linkedTargets?.length > 0;
    const hasMoreThanOneAssociation = item.associatedEngagements?.length > 1;
    const hasOneAssociation = item.associatedEngagements.length === 1;
    const canBeDeleted = !hasLinks && !hasMoreThanOneAssociation && !hasOneAssociation;

    if (canBeDeleted) {
      this.deleteTargetPopup(item.id);
    } else if (hasOneAssociation && !hasLinks) {
      this.deleteTargetWithEngagementPopup(item.id, item.associatedEngagements[0].id);
    } else if (hasLinks) {
      this.cannotDeleteTargetPopupReasonLink(item.linkedTargets);
    } else if (hasMoreThanOneAssociation) {
      this.cannotDeleteTargetPopup();
    }
  }

  private deleteTargetPopup(targetId: string): void {
    const message: ConfirmPopupMessage[] = [
      { message: `${this.translateService.instant('ARE_YOU_SURE')}?`, shoudlWrap: false },
    ];
    this.showDeletePopup(message).subscribe((ok) => {
      if (!ok) {
        return;
      }
      this.actService
        .deleteAct(targetId)
        .pipe(takeUntil(this.destroy$))
        .subscribe((data) => {
          if (data) {
            this.loaderService.hide(targetsLoadingKey);
            this.deleteTarget.emit(this.target);
            return;
          }
        });
    });
  }

  private deleteTargetWithEngagementPopup(targetId: string, associatedEngagementId: string): void {
    const translatedMessages = this.translateService.instant([
      'DELETING_THIS_TARGET_WILL_ALSO_DELETE_ASSOCIATED_ENGAGEMENTS',
      'ARE_YOU_SURE',
    ]);

    const messageList: ConfirmPopupMessage[] = [
      {
        message: `${translatedMessages['DELETING_THIS_TARGET_WILL_ALSO_DELETE_ASSOCIATED_ENGAGEMENTS']}.`,
        shoudlWrap: false,
      },
      {
        message: `${translatedMessages['ARE_YOU_SURE']}?`,
        shoudlWrap: false,
      },
    ];

    this.showDeletePopup(messageList).subscribe((ok) => {
      if (!ok) {
        return;
      }
      this.deleteEngagement(associatedEngagementId).subscribe(() => {
        this.actService.deleteAct(targetId).subscribe((result) => {
          if (result) {
            this.loaderService.hide(targetsLoadingKey);
            this.deleteTarget.emit(this.target);
            return;
          }
        });
      });
    });
  }

  private cannotDeleteTargetPopupReasonLink(linkedTargets: string[]) {
    this.actService
      .getActByIds(linkedTargets)
      .pipe(
        switchMap((acts) => {
          let messages: ConfirmPopupMessage[] = [
            {
              message: this.translateService.instant('TARGET_CANNOT_BE_DELETED_AS_IT_IS_LINKED_TO'),
              shoudlWrap: false,
            },
          ];

          messages = messages.concat(acts.map((act) => ({ message: act.title, shoudlWrap: false })));

          return this.popupService.simpleConfirmationPopup({
            maxWidth: '90%',
            width: '460px',
            height: 'auto',
            componentConfiguration: {
              messageList: messages,
              showDeleteIcon: true,
              disableNoButton: true,
            },
          });
        }),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  private cannotDeleteTargetPopup(): void {
    this.popupService.simpleConfirmationPopup({
      maxWidth: '90%',
      width: '460px',
      height: 'auto',
      componentConfiguration: {
        message: `${this.translateService.instant('THIS_TARGET_CANNOT_BE_DELETED_ASSOCIATED_ENGAGEMENTS')}.`,
        showDeleteIcon: false,
        disableNoButton: true,
      },
    });
  }

  private showDeletePopup(messageList: ConfirmPopupMessage[]): Observable<boolean> {
    return this.popupService
      .simpleConfirmationPopup({
        maxWidth: '90%',
        width: 'auto',
        height: 'auto',
        componentConfiguration: {
          messageList,
          showDeleteIcon: true,
        },
      })
      .pipe(take(1));
  }

  private cloneTargetPage(act: ActViewModel): void {
    const cloneAct: BaseTargetPage = act.getActInformation();
    cloneAct.generateCloneTitle();
    this.actService
      .createAct(cloneAct)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        this.actService.navigateToEditPage(act.actType, res);
      });
  }

  private showAssociatedEngagements(
    title: string,
    engagements: EngagementDTO[],
    subtitle: string,
    icon: string,
    targetId: string,
  ): void {
    this.popupService
      .showAssociatedEngagementsPopup({
        width: '600px',
        maxHeight: '600px',
        height: 'auto',
        componentConfiguration: {
          title,
          engagements,
          subtitle,
          subtitleIcon: icon,
          targetId,
          showDeleteButton: true,
        },
      })
      .pipe(takeUntil(this.destroy$))
      .subscribe((close) => {
        if (close) {
          return;
        }
      });
  }

  private showTargetPreview(actType: ActTypeEnum, buildingBlocks: PageBuildingBlock[]): void {
    if (buildingBlocks.length === 0) {
      return;
    }
    this.popupService
      .targetPreviewPopup({
        componentConfiguration: { actType, buildingBlocks },
      })
      .pipe(takeUntil(this.destroy$))
      .subscribe((close) => {
        if (close) {
          return;
        }
      });
  }

  private deleteEngagement(id: string): Observable<unknown> {
    return this.engagementService.deleteEngagement(id).pipe(take(1));
  }
}
