import { getCurrencySymbol } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ProductCategory } from '@core/models/dtos/product-category';
import { RceProduct } from '@core/models/dtos/rce-product';
import { CategoryLink } from '@core/models/product/CreateProductRequest';
import { AppSettingService } from '@core/services/app-setting.service';
import { environment } from '@env/environment';
import { BaseComponent } from '@shared/components/base-component/base-component';
import { GenericCreationContainerService } from '@shared/components/generic-creation-container/generic-creation-container.service';
import { TreeFlatNode } from '@shared/components/tree-checklist/tree-checklist.component';
import { of } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-product-creation',
  templateUrl: './product-creation.component.html',
  styleUrls: ['./product-creation.component.scss'],
})
export class ProductCreationComponent extends BaseComponent implements OnInit {
  product: RceProduct;
  tinyApiKey: string;
  @ViewChild('form') productCreationForm: NgForm;
  categories?: TreeFlatNode[] = [];
  currencySymbol: string;

  constructor(
    readonly genericCreationContainerService: GenericCreationContainerService,
    readonly appSettingService: AppSettingService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.currencySymbol = getCurrencySymbol(this.appSettingService.paymentCurrency, 'narrow');
    this.tinyApiKey = environment.tinyApiKey;
    this.product = new RceProduct();
    this.genericCreationContainerService
      .getNextStepRequest((_) => {
        if (this.productCreationForm.valid) {
          return of({
            canGoNext: true,
            data: this.product,
          });
        }
        return of({ canGoNext: false });
      })
      .pipe(takeUntil(this.destroy$))
      .subscribe();
  }

  onCategoriesChange(event: TreeFlatNode[]) {
    this.categories = event;
    this.product.productCategoryLinks = event.map<CategoryLink>((node) => ({
      categoryId: node.id.toString(),
      position: node.level + 1,
    }));
    this.product.productCategories = event.map<ProductCategory>((node) => ({
      id: node.id,
      name: node.item,
    }));
  }

  onSubmit(form: NgForm) {
    return;
  }

  onGetContent(event: any) {
    this.product.description = event?.event?.content;
  }
}
