import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Endpoints } from '@core/constants/Endpoints';
import { RceProductAdapter } from '@core/models/adapters/rce-product-adapter';
import { RceProduct } from '@core/models/dtos/rce-product';
import { RceProductsResponse } from '@core/models/dtos/rce-products-response';
import { RceProductStatusEnum } from '@core/models/enums/rce-product-status-enum';
import { ProductCategoryTreeNode } from '@core/models/product-category-tree-node';
import { CreateProductRequest } from '@core/models/product/CreateProductRequest';
import { UpdateProductMediaRequest } from '@core/models/product/UpdateProductMediaRequest';
import { UpdateProductRequest } from '@core/models/product/UpdateProductRequest';
import { UploadProductMediaRequest } from '@core/models/product/UploadProductMediaRequest';
import { ProductViewModel } from '@core/models/view-models/product-view-model';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { BaseAPIServiceService } from './http/base-apiservice.service';
import { mockDataProductsGenerator } from './mock/products.data.mock';

@Injectable({
  providedIn: 'root',
})
export class ProductService extends BaseAPIServiceService {
  constructor(protected http: HttpClient, private adapter: RceProductAdapter) {
    super(http);
  }

  getAllProductsMock(totalMockedItems: number = 40): Observable<ProductViewModel[]> {
    return of(mockDataProductsGenerator(totalMockedItems));
  }

  deleteProductMock(productId: string): Observable<boolean> {
    return of(true);
  }

  deleteProduct(sku: string): Observable<any> {
    return this.delete(`${Endpoints.DELETE_RCE_PRODUCT}${sku}`);
  }

  getAllRceProducts(): Observable<ProductViewModel[]> {
    return this.get<RceProductsResponse>(Endpoints.GET_RCE_PRODUCTS).pipe(
      map((response) => response.products.map((product) => this.adapter.fromDTO(product))),
    );
  }

  getProductCategories(): Observable<ProductCategoryTreeNode> {
    return this.get<ProductCategoryTreeNode>(Endpoints.GET_RCE_CATEGORIES);
  }

  addProduct(request: CreateProductRequest) {
    return this.http.post(Endpoints.CREATE_PRODUCT, request);
  }

  updateProduct(sku: string, request: UpdateProductRequest) {
    return this.http.put(Endpoints.UPDATE_PRODUCT.replace('{sku}', sku), request);
  }

  uploadMedia(sku: string, request: UploadProductMediaRequest) {
    return this.http.put<any>(Endpoints.UPLOAD_PRODUCT_MEDIA.replace('{sku}', sku), request);
  }

  updateMedia(sku: string, mediaId: string, request: UpdateProductMediaRequest) {
    return this.http.put<any>(
      Endpoints.UPDATE_PRODUCT_MEDIA.replace('{sku}', sku).replace('{mediaId}', mediaId),
      request,
    );
  }

  removeMedia(sku: string, mediaId: string) {
    return this.http.delete<any>(Endpoints.REMOVE_PRODUCT_MEDIA.replace('{sku}', sku).replace('{mediaId}', mediaId));
  }

  updateProductStatus(status: RceProductStatusEnum, sku: string): Observable<any> {
    return this.put(`${Endpoints.UPDATE_PRODUCT_STATUS}`, { status: status, sku: sku });
  }

  getProductById(id: number): Observable<ProductViewModel> {
    return this.get<RceProduct>(`${Endpoints.GET_RCE_PRODUCTS}/${id}`).pipe(
      map((product) => this.adapter.fromDTO(product)),
    );
  }

  connectToApi2Cart(storeRoute: string, request: any) {
    return this.http.post<any>(`${Endpoints.CONNECT_TO_API_2_CART}/${storeRoute}`, request);
  }
}
