export class PaginationHelper {
  static getPageSizeOptions(totalItems: number) {
    if (!totalItems) {
      return [];
    }

    const pageSizeOptions = [5, 10, 50];

    while (pageSizeOptions.length > 0 && pageSizeOptions[pageSizeOptions.length - 1] >= totalItems) {
      pageSizeOptions.pop();
    }

    if (pageSizeOptions.length < 3) {
      pageSizeOptions.push(totalItems);
    }
    
    return pageSizeOptions;
  }
}
