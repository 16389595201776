import { DashboardFilter } from '@core/models/dashboard-filter';
import { ActTypeEnum } from '@core/models/enums/act-type-enum';
import { EngagementTypeEnum } from '@core/models/enums/engagement-type-enum';
import { LocationTypeEnum } from '@core/models/enums/location-type-enum';
import { OverallStatusEnum } from '@core/models/enums/overal-status-enum';
import { UserEngagementsActions } from '@core/models/enums/user-engagements-actions';
import { UserLocationActionsEnum } from '@core/models/enums/user-location-actions-enum';
import * as moment from 'moment';

export const DateRangeFilter = [
  {
    name: 'ALL_TIME',
    value: undefined,
    default: true,
  },
  {
    name: 'THIS_YEAR',
    value: moment().startOf('year').toString(),
  },
  {
    name: 'THIS_MONTH',
    value: moment().startOf('month').toString(),
  },
  {
    name: 'THIS_WEEK',
    value: moment().startOf('week').toString(),
  },
];

export const EngagementsFilters = [
  {
    name: 'TRIGGER',
    elementId: 'trigger-filter',
    subFilters: [
      { value: EngagementTypeEnum[EngagementTypeEnum.Audio], name: 'AUDIO', elementId: 'audio-trigger-filter' },
      { value: EngagementTypeEnum[EngagementTypeEnum.Image], name: 'IMAGE', elementId: 'image-trigger-filter' },
      {
        value: EngagementTypeEnum[EngagementTypeEnum.Geolocation],
        name: 'LOCATION',
        elementId: 'location-trigger-filter',
      },
      {
        value: EngagementTypeEnum[EngagementTypeEnum.SmartCode],
        name: 'SMARTCODE',
        elementId: 'smartcode-trigger-filter',
      },
      { value: EngagementTypeEnum[EngagementTypeEnum.Link], name: 'LINK', elementId: 'link-trigger-filter' },
    ],
  },
  {
    name: 'STATUS',
    elementId: 'status-filter',
    subFilters: [
      { value: OverallStatusEnum[OverallStatusEnum.Paused], name: 'PAUSED', elementId: 'paused-trigger-status-filter' },
      { value: OverallStatusEnum[OverallStatusEnum.Active], name: 'ACTIVE', elementId: 'active-trigger-status-filter' },
      {
        value: OverallStatusEnum[OverallStatusEnum.PendingPayment],
        name: 'PENDINGPAYMENT',
        elementId: 'pending-trigger-status-filter',
      },
      {
        value: OverallStatusEnum[OverallStatusEnum.Incomplete],
        name: 'INCOMPLETE',
        elementId: 'incomplete-trigger-status-filter',
      },
      {
        value: OverallStatusEnum[OverallStatusEnum.Scheduled],
        name: 'SCHEDULED',
        elementId: 'scheduled-trigger-status-filter',
      },
      {
        value: OverallStatusEnum[OverallStatusEnum.Expired],
        name: 'EXPIRED',
        elementId: 'expired-trigger-status-filter',
      },
    ],
  },
];

export const BatchActions = [
  {
    name: 'DELETE',
    value: UserEngagementsActions.Delete,
  },
  {
    name: 'PAUSE',
    value: UserEngagementsActions.Pause,
  },
  {
    name: 'RESUME',
    value: UserEngagementsActions.Resume,
  },
  {
    name: 'DOWNLOAD',
    value: UserEngagementsActions.Download,
  },
];

export const BatchLocationActions = [
  {
    name: 'DELETE',
    value: UserLocationActionsEnum.Delete,
  },
  {
    name: 'ENABLE',
    value: UserLocationActionsEnum.Enable,
  },
  {
    name: 'DISABLE',
    value: UserLocationActionsEnum.Disable,
  },
];

export const TargetPagesFilters = [
  {
    name: 'TARGET_PAGES',
    subFilters: [
      { value: ActTypeEnum.InformationPage, name: 'INFORMATION', elementId: 'information-target-filter' },
      { value: ActTypeEnum.Act, name: 'ACT', elementId: 'act-target-filter' },
      { value: ActTypeEnum.Buy, name: 'BUY', elementId: 'buy-target-filter' },
      { value: ActTypeEnum.Url, name: 'URL', elementId: 'url-target-filter' },
    ],
  },
];

export const ProductStatusFilters: DashboardFilter[] = [
  {
    name: 'STATUS',
    subFilters: [
      { value: true, name: 'ENABLED' },
      { value: false, name: 'DISABLED' },
    ],
  },
];

export const TargetChipFilters = [
  { name: 'ALL', icon: 'assignment', value: ActTypeEnum.All, selected: true, elementId: 'all-target-chip-filter' },
  {
    name: 'INFORMATION',
    icon: 'info',
    value: ActTypeEnum.InformationPage,
    elementId: 'information-target-chip-filter',
  },
  { name: 'ACT', icon: 'assignment_ind', value: ActTypeEnum.Act, elementId: 'act-target-chip-filter' },
  { name: 'BUY', icon: 'shopping_basket', value: ActTypeEnum.Buy, elementId: 'buy-target-chip-filter' },
  { name: 'URL', icon: 'link', value: ActTypeEnum.Url, elementId: 'url-target-chip-filter' },
];

export const LocationTagFilters = [
  {
    name: 'TAG',
    subFilters: [{ value: 'ALL', name: 'ALL', elementId: 'all-tag-filter' }],
  },
];
