import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ActTypeEnum } from '@core/models/enums/act-type-enum';
import { SearchInputComponent } from '@shared/components/search-input/search-input.component';
import { ChipFilterOptions, FilterItem } from './chip-filter-options';

@Component({
  selector: 'app-chip-filters',
  templateUrl: './chip-filters.component.html',
  styleUrls: ['./chip-filters.component.scss'],
})
export class ChipFiltersComponent implements OnInit {
  @Input() chipfilterOptions: ChipFilterOptions;
  @Input() searchEnabled = false;
  @Input() onSearchedValue: boolean;
  @Output() appliedChipFilter: EventEmitter<any> = new EventEmitter();
  @Output() search: EventEmitter<string> = new EventEmitter();
  @ViewChild(SearchInputComponent) searchComponent: SearchInputComponent;

  constructor() {}

  ngOnInit(): void {}

  applyChipFilter(index: number, filter: FilterItem): void {
    if (this.chipfilterOptions.filters[index].selected) {
      return;
    }
    this.chipfilterOptions.filters.forEach((f) => {
      f.selected = false;
    });
    this.chipfilterOptions.filters[index].selected = true;
    this.appliedChipFilter.emit({ filters: this.chipfilterOptions.filters, value: filter.value });
  }

  resetFilters() {
    this.chipfilterOptions.filters.forEach((f) => {
      f.selected = false;
    });
    this.chipfilterOptions.filters[0].selected = true;
    this.appliedChipFilter.emit({ filters: this.chipfilterOptions.filters, value: ActTypeEnum.All });
    this.onSearchedValue = false;
  }

  onSearched(event: string): void {
    if (this.searchEnabled) {
      this.search.emit(event);
      event === '' ? (this.onSearchedValue = false) : (this.onSearchedValue = true);
    }
  }
}
