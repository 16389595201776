import { Injectable } from '@angular/core';
import { AciOnboardingProgressStatusEnum } from '@core/models/get-aci-onboarding-status-response';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  MenuWizardEvent,
  MenuWizardEventTypeEnum,
  MenuWizardStateEnum,
  MenuWizardStep,
  MenuWizardStepTypeEnum,
  wizardSteps,
} from '../menu-wizard.model';

@Injectable({
  providedIn: 'root',
})
export class MenuWizardService {
  isSubmitted = false;
  isDirty = false;
  steps$: BehaviorSubject<MenuWizardStep[]> = new BehaviorSubject<MenuWizardStep[]>([...wizardSteps]);
  wizardMenuSteps$: Observable<MenuWizardStep[]> = this.steps$.pipe(
    map((steps) => steps.filter((curStep) => curStep.type === MenuWizardStepTypeEnum.Wizard)),
  );
  canGoToNextStep$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  nextStepRequest$: Subject<number> = new Subject();
  skipStepRequest$: Subject<number> = new Subject();
  events$: BehaviorSubject<MenuWizardEvent | undefined> = new BehaviorSubject<MenuWizardEvent | undefined>(undefined);

  init(steps: MenuWizardStep[]) {
    this.isDirty = true;
    this.steps$.next(steps);
  }

  reset() {
    this.steps$.next(this.getResetedSteps());
    this.canGoToNextStep$.next(false);
    this.events$.next(undefined);
  }

  getResetedSteps() {
    return wizardSteps.reduce((prev, cur, index) => {
      if (index === 0) {
        prev.push({ ...cur, state: MenuWizardStateEnum.Active });
      } else {
        prev.push({ ...cur, state: MenuWizardStateEnum.Pending });
      }

      return prev;
    }, []);
  }

  getCurrentStep$() {
    return this.steps$.pipe(map((steps) => steps.find((step) => step.state === MenuWizardStateEnum.Active)?.step || 0));
  }

  getCurrentStepInfo$() {
    return this.steps$.pipe(map((steps) => steps.find((step) => step.state === MenuWizardStateEnum.Active)));
  }

  skipStep(stepUrl: string) {
    const steps = this.steps$.value.map((val) => ({ ...val }));
    steps.forEach((step) => {
      step.shouldSkip = step.url === stepUrl;
    });
    this.steps$.next(steps);
  }

  markAsSubmitted() {
    this.isSubmitted = true;
    const steps = this.steps$.value;
    if (steps[steps.length - 1].state === MenuWizardStateEnum.Active) {
      return;
    }

    steps.forEach((val, index) => {
      if (index !== steps.length - 1) {
        val.state = MenuWizardStateEnum.Done;
      } else {
        val.state = MenuWizardStateEnum.Active;
      }
    });

    this.steps$.next(steps);
  }

  revertSkippedStep(stepUrl: string) {
    const steps = this.steps$.value.map((val) => ({ ...val }));
    steps.forEach((step) => {
      if (step.url === stepUrl) {
        step.shouldSkip = false;
      }
    });
    this.steps$.next(steps);
  }

  goBack() {
    if (this.isSubmitted) {
      return;
    }

    const steps = [...this.steps$.value];
    const step = MenuWizardService.getActiveStep(steps);
    if (step) {
      step.state = MenuWizardStateEnum.Pending;
      const prevStep = MenuWizardService.getPreviousStep(steps, step);
      if (prevStep && prevStep.type === MenuWizardStepTypeEnum.Wizard) {
        prevStep.state = MenuWizardStateEnum.Active;
        this.steps$.next(steps);
        this.events$.next({ type: MenuWizardEventTypeEnum.StepChange, data: prevStep });
      } else {
        step.state = MenuWizardStateEnum.Active;
        this.events$.next({ type: MenuWizardEventTypeEnum.OutOfBound });
      }
    }
  }

  switchTo(stepUrl: string) {
    if (this.isSubmitted) {
      return;
    }

    const steps = [...this.steps$.value];

    const activeStep = MenuWizardService.getActiveStep(steps);
    const requestedStep = steps.find((s) => s.url === stepUrl);

    if (!requestedStep || requestedStep.type === MenuWizardStepTypeEnum.Basic) {
      this.events$.next({ type: MenuWizardEventTypeEnum.OutOfBound });
      return;
    }

    if (activeStep.step > requestedStep.step) {
      activeStep.state = MenuWizardStateEnum.Pending;
      requestedStep.state = MenuWizardStateEnum.Active;

      this.events$.next({ type: MenuWizardEventTypeEnum.StepChange, data: requestedStep });
    } else if (this.canGoToNextStep$.value) {
      activeStep.state = MenuWizardStateEnum.Done;
      requestedStep.state = MenuWizardStateEnum.Active;
      this.events$.next({ type: MenuWizardEventTypeEnum.StepChange, data: requestedStep });
    }
  }

  goNext(callback?: () => void) {
    const steps = [...this.steps$.value];
    const activeStep = MenuWizardService.getActiveStep(steps);

    if (activeStep) {
      activeStep.state = MenuWizardStateEnum.Done;
      const nextStep = MenuWizardService.getNextStep(steps, activeStep);
      if (nextStep && nextStep.type === MenuWizardStepTypeEnum.Wizard) {
        nextStep.state = MenuWizardStateEnum.Active;
        this.steps$.next(steps);
        this.events$.next({ type: MenuWizardEventTypeEnum.StepChange, data: nextStep });
        this.canGoToNextStep$.next(nextStep.canGoToNextStep);
      } else {
        nextStep.state = MenuWizardStateEnum.Active;
        this.events$.next({ type: MenuWizardEventTypeEnum.StepChange, data: nextStep });
      }
    }

    if (callback) {
      callback();
    }
  }

  static getActiveStep(steps: MenuWizardStep[]): MenuWizardStep {
    return steps.find((s) => s.state === MenuWizardStateEnum.Active);
  }

  static getNextStep(steps: MenuWizardStep[], currentStep: MenuWizardStep): MenuWizardStep {
    let shiftCount = 1;
    let isLastStep = false;
    const index = steps.indexOf(currentStep);
    while (!isLastStep) {
      isLastStep = steps.length - 1 === index + shiftCount;
      const nextStep = steps[index + shiftCount];
      if (!nextStep.shouldSkip) {
        return nextStep;
      }
      shiftCount++;
    }

    return currentStep;
  }

  static getPreviousStep(steps: MenuWizardStep[], currentStep: MenuWizardStep): MenuWizardStep {
    let shiftCount = 1;
    let isFirstStep = false;
    const index = steps.indexOf(currentStep);
    while (!isFirstStep) {
      isFirstStep = index - shiftCount === 0;
      const previousStep = steps[index - shiftCount];
      if (!previousStep.shouldSkip) {
        return previousStep;
      }
      shiftCount++;
    }

    return currentStep;
  }
}

export function getWizardSteps(steps: MenuWizardStep[], status: AciOnboardingProgressStatusEnum) {
  let completedSteps = -1;
  switch (status) {
    case AciOnboardingProgressStatusEnum.AciAccountCreated:
      completedSteps = 1;
      break;
    case AciOnboardingProgressStatusEnum.Payment3dSecureConfirmed:
      completedSteps = 2;
      break;

    default:
      break;
  }

  return steps.map((step, index) => {
    const isDone = step.step <= completedSteps;
    return index === completedSteps + 1
      ? {
          ...step,
          state: MenuWizardStateEnum.Active,
        }
      : {
          ...step,
          state: isDone ? MenuWizardStateEnum.Done : MenuWizardStateEnum.Pending,
        };
  });
}
