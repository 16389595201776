import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule, FlexModule } from '@angular/flex-layout';
import { CustomDirectivesModule } from '@core/directives/custom-directives.module';
import { BaseCardModule } from '../base-card/base-card.module';
import { BaseCardContainerComponent } from './base-card-container.component';

@NgModule({
  declarations: [BaseCardContainerComponent],
  imports: [CommonModule, BaseCardModule, FlexLayoutModule, FlexModule, CustomDirectivesModule],
  exports: [BaseCardContainerComponent],
})
export class BaseCardContainerModule {}
