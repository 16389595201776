import { Action, ActionReducer, ActionReducerMap, createFeatureSelector, INIT, MetaReducer } from '@ngrx/store';
import { LogUtils } from '../../../../utils/log-utils';
import { clearState as clearStateAction } from './actions/clear-state.actions';
import {
  AciPaymentProviderDetailsState,
  reducer as fromAciPaymentProviderDetailsReducer,
} from './reducers/aci-payment-provider-details.reducer';
import {
  AciOnboardingProgressState,
  reducer as fromAciOnBoardingProgressReducer,
} from './reducers/aci-onboarding-progress.reducer';
import { EcommercePlatformState, reducer as fromEcommercePlatformDetails } from './reducers/ecommerce-platform.reducer';
import {
  PaymentGatewayDetailsState,
  reducer as fromPaymentGatewayDetailsReducer,
} from './reducers/payment-gateway-details.reducer';
import {
  PaymentProviderDetailsState,
  reducer as fromPaymentProviderDetailsReducer,
} from './reducers/payment-provider-details.reducer';
import { PlatformDetailsState, reducer as fromPlatformDetailsReducer } from './reducers/platform-details.reducers';
import {
  reducer as selectPaymentFormReducer,
  SelectedPaymentGatewayState,
} from './reducers/select-payment-form.reducer';
import {
  reducer as selectPaymentReducer,
  SelectedPaymentProviderState,
} from './reducers/select-payment-provider.reducer';
import { storageMetaReducer } from './reducers/storage.metareducer';

export interface SelfOnboardingState {
  aciOnBoardingProgress: AciOnboardingProgressState;
  aciPaymentProviderDetails: AciPaymentProviderDetailsState;
  paymentProviderDetails: PaymentProviderDetailsState;
  paymentGatewayDetails: PaymentGatewayDetailsState;
  ecommercePlatformDetails: EcommercePlatformState;
  selectedPaymentProvider: SelectedPaymentProviderState;
  platformDetails: PlatformDetailsState;
  selectedPaymentGatewayProvider: SelectedPaymentGatewayState;
}

export const selfOnboardingReducers: ActionReducerMap<SelfOnboardingState> = {
  aciOnBoardingProgress: fromAciOnBoardingProgressReducer,
  aciPaymentProviderDetails: fromAciPaymentProviderDetailsReducer,
  paymentProviderDetails: fromPaymentProviderDetailsReducer,
  paymentGatewayDetails: fromPaymentGatewayDetailsReducer,
  ecommercePlatformDetails: fromEcommercePlatformDetails,
  platformDetails: fromPlatformDetailsReducer,
  selectedPaymentProvider: selectPaymentReducer,
  selectedPaymentGatewayProvider: selectPaymentFormReducer,
};

export const selectSelfOnboardingState = createFeatureSelector<SelfOnboardingState>('selfOnboardingState');

export function clearState(reducer: ActionReducer<SelfOnboardingState>): ActionReducer<SelfOnboardingState> {
  return (state: SelfOnboardingState, action: Action): SelfOnboardingState => {
    if (action != null && action.type === clearStateAction.type) {
      return reducer(undefined, { type: INIT });
    }
    return reducer(state, action);
  };
}

/* Meta store */
export function logger(reducer: ActionReducer<SelfOnboardingState>): ActionReducer<SelfOnboardingState> {
  return (state: SelfOnboardingState, action: any): SelfOnboardingState => {
    LogUtils.action(action);
    return reducer(state, action);
  };
}

export function metaReducers(production: boolean): MetaReducer<SelfOnboardingState>[] {
  return !production ? [logger, clearState, storageMetaReducer] : [clearState, storageMetaReducer];
}
