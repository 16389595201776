import { ActDTO } from '@core/models/dtos/act-dto';
import { Action, createReducer, on } from '@ngrx/store';
import * as InformationTargetPageActions from '../actions/information-target-page.actions';

export interface InformationTargetPageState {
  targetId: string;
  isEditMode: boolean;
  showFields: boolean;
  targetData: ActDTO;
  formBuilderData: string;
  savedData: ActDTO;
}

export const initialState: InformationTargetPageState = {
  targetId: null,
  isEditMode: false,
  showFields: false,
  targetData: null,
  formBuilderData: null,
  savedData: null,
};

const informationTargetPageReducer = createReducer(
  initialState,
  on(InformationTargetPageActions.provideTargetIdFromRoute, (state, { targetId }) => ({
    ...state,
    targetId,
    isEditMode: true,
  })),
  on(InformationTargetPageActions.getActResponse, (state, { response }) => ({
    ...state,
    targetData: response,
  })),
  on(InformationTargetPageActions.showFields, (state) => ({
    ...state,
    showFields: true,
  })),
  on(InformationTargetPageActions.saveTarget, (state, { data }) => ({
    ...state,
    savedData: data,
  })),
  on(InformationTargetPageActions.savePageBuilderData, (state, { data }) => ({
    ...state,
    formBuilderData: data,
  })),
  on(InformationTargetPageActions.clearTargetState, (state) => ({
    ...state,
    targetId: null,
    isEditMode: false,
    showFields: false,
    targetData: null,
    formBuilderData: null,
  })),
);

export function reducer(state = initialState, action: Action): InformationTargetPageState {
  return informationTargetPageReducer(state, action);
}
