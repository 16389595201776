import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProductsRoutingModule } from './products-routing.module';
import { ProductsComponent } from './products.component';
import { SharedModule } from '@shared/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { WelcomeProductsPageComponent } from './welcome-products-page/welcome-products-page.component';
import { ProductSetupInfoModule } from './product-setup-info/product-setup-info.module';

@NgModule({
  declarations: [ProductsComponent, WelcomeProductsPageComponent],
  imports: [CommonModule, ProductsRoutingModule, SharedModule, ProductSetupInfoModule, TranslateModule.forChild()],
  exports: [ProductsComponent],
})
export class ProductsModule {}
