import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BrandInfo } from '@core/models/get-merchant-info-response';
import { ThumbnailImage } from '@core/models/thumbnail-image';
import { MerchantAPIService } from '@core/services/merchant-api.service';
import { TranslateService } from '@ngx-translate/core';
import { PopupService } from '@shared/components/popup/popup.service';
import { Subject } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';
import { StoreImageUploadComponent } from '../../store-image-upload/store-image-upload.component';
import {LoaderService} from "@core/services/loader.service";

@Component({
  selector: 'app-your-brand',
  templateUrl: './your-brand.component.html',
  styleUrls: ['./your-brand.component.scss'],
})
export class YourBrandComponent implements OnInit {
  @ViewChild(StoreImageUploadComponent, { static: true }) child: StoreImageUploadComponent;
  destroy$: Subject<boolean> = new Subject<boolean>();

  form: FormGroup;
  isEditMode = true;
  logoImage: ThumbnailImage;
  heroImage: ThumbnailImage;
  isThumbnailUpdated = false;
  storeImage: string;
  storeHeroImage: string;
  loadComp: boolean;

  constructor(
    protected merchantService: MerchantAPIService,
    private popupService: PopupService,
    private translateService: TranslateService,
    public loaderService: LoaderService,
  ) {
    this.loadComp = false;
    this.form = new FormGroup({
      storeStrapline: new FormControl('', [Validators.required, Validators.maxLength(125)]),
      storeLogo: new FormControl('', Validators.required),
      storeHeroImage: new FormControl('', Validators.required),
      storeBrandingColor: new FormControl('#000000'),
      storeAccentColor: new FormControl('#000000'),
    });
  }

  ngOnInit(): void {
    this.merchantService
      .getBrand()
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        this.loadComp = true;

        if (data) {
          this.storeImage = data.logoNameUrl;
          this.storeHeroImage = data.heroImageUrl;

          this.form.get('storeLogo').patchValue(this.storeImage);
          this.form.get('storeHeroImage').patchValue(this.storeHeroImage);
          this.form.setValue({
            storeStrapline: data.strapline,
            storeBrandingColor: data.brandColor,
            storeAccentColor: data.accentColor,
            storeHeroImage: data.heroImageUrl,
            storeLogo: data.logoNameUrl,
          });
        }
      });
  }

  onSubmit() {
    this.loaderService.show();
    if (!this.form.invalid) {
      const brandInfo = {
        logoName: this.logoImage.name,
        logoBase64: this.logoImage.base64,
        heroImageName: this.heroImage.name,
        heroImageBase64: this.heroImage.base64,
        brandColor: this.form.get('storeBrandingColor').value,
        accentColor: this.form.get('storeAccentColor').value,
        strapline: this.form.get('storeStrapline').value,
      } as BrandInfo;

      this.merchantService
        .storeBrandInfo(brandInfo)
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          (_) => {
            this.showStatusPopup(true, 'BRANDING_SUCCESSFULLY_UPDATED');
          },
          (error: HttpErrorResponse) => {
            console.error('Update your brand info error => ', error);
            this.showStatusPopup(false, 'FAILED_TO_UPDATE_BRANDING', error && error.error && error.error.message);
          },
          () => this.loaderService.hide(),
        );
    }
  }
  onThumbnailImageChangeLogo(storeImage: ThumbnailImage) {
    this.isThumbnailUpdated = true;
    this.logoImage = storeImage;
  }
  onThumbnailImageChangeHero(storeImage: ThumbnailImage) {
    this.isThumbnailUpdated = true;
    this.heroImage = storeImage;
  }

  private showStatusPopup(isSuccess: boolean, message: string, failureReason?: string): void {
    this.translateService
      .get(message)
      .pipe(
        switchMap((translated: string) => {
          return this.popupService.successStatusPopup({
            componentConfiguration: { isSuccess, message: translated, failureReason },
            height: 'auto',
            width: 'auto',
            maxWidth: '430px',
            backdropClass: 'blurred-backdrop',
          });
        }),
        takeUntil(this.destroy$),
      )
      .subscribe(() => {});
  }
}
