import { Injectable } from '@angular/core';
import { MatLegacyDialog, MatLegacyDialogRef } from '@angular/material/legacy-dialog';
import { LocationViewModel } from '@core/models/view-models/location-view-model';
import { OnBoardingPopupComponent } from '@shared/components/popup/on-boarding-popup/on-boarding-popup.component';
import { Observable } from 'rxjs';
import { LocationFormComponent } from '../../../features/settings/locations/location-form/location-form.component';
import { AssociatedEngagementsPopupConfiguration } from './associated-engagements-popup/associated-engagements-popup-configuration';
import { AssociatedEngagementsPopupComponent } from './associated-engagements-popup/associated-engagements-popup.component';
import { BasePopupConfiguration } from './base-popup-configuration';
import { ChangeTargetPagePopupComponent } from './change-target-page-popup/change-target-page-popup.component';
import { ChangeTargetPagePopupConfiguration } from './change-target-page-popup/change-target-page-popup.configuration';
import { GoToMyDetailsPopupComponent } from './go-to-my-details-popup/go-to-my-details-popup.component';
import { ImagePreviewPopupComponent } from './image-preview-popup/image-preview-popup.component';
import { ImagePreviewPopupConfiguration } from './image-preview-popup/image-preview-popup.configuration';
import { PageBuildingBlocksPopupComponent } from './page-building-blocks-popup/page-building-blocks-popup.component';
import { PageBuildingBlocksPopupConfiguration } from './page-building-blocks-popup/page-building-blocks-popup.configuration';
import { SimpleConfirmPopupComponent } from './simple-confirm-popup/simple-confirm-popup.component';
import { SimpleConfirmPopupConfiguration } from './simple-confirm-popup/simple-confirm-popup.configuration';
import { SuccessStatusPopupComponent } from './success-status-popup/success-status-popup.component';
import { SuccessStatusPopupConfiguration } from './success-status-popup/success-status-popup.configuration';
import { TargetPreviewPopupComponent } from './target-preview-popup/target-preview-popup.component';
import { TargetPreviewPopupConfiguration } from './target-preview-popup/target-preview-popup.configuration';

@Injectable({
  providedIn: 'root',
})
export class PopupService {
  constructor(private matDialog: MatLegacyDialog) {}

  showAssociatedEngagementsPopup(
    params: BasePopupConfiguration<AssociatedEngagementsPopupConfiguration>,
  ): Observable<any> {
    let dialogRef: MatLegacyDialogRef<AssociatedEngagementsPopupComponent>;

    dialogRef = this.matDialog.open(AssociatedEngagementsPopupComponent, {
      width: params.width,
      height: params.height,
    });

    dialogRef.componentInstance.configuration = params.componentConfiguration;

    return dialogRef.afterClosed();
  }

  successStatusPopup(params: BasePopupConfiguration<SuccessStatusPopupConfiguration>): Observable<boolean> {
    const matDialogConfigParams = Object.assign({}, params);
    delete matDialogConfigParams.componentConfiguration;

    let dialogRef: MatLegacyDialogRef<SuccessStatusPopupComponent>;
    dialogRef = this.matDialog.open(SuccessStatusPopupComponent, matDialogConfigParams);
    dialogRef.componentInstance.configuration = params.componentConfiguration;

    return dialogRef.afterClosed();
  }

  simpleConfirmationPopup(params: BasePopupConfiguration<SimpleConfirmPopupConfiguration>): Observable<boolean> {
    const matDialogConfigParams = Object.assign({}, params);
    delete matDialogConfigParams.componentConfiguration;

    let dialogRef: MatLegacyDialogRef<SimpleConfirmPopupComponent>;
    dialogRef = this.matDialog.open(SimpleConfirmPopupComponent, matDialogConfigParams);
    dialogRef.componentInstance.configuration = params.componentConfiguration;

    return dialogRef.afterClosed();
  }

  changeTargetPagePopup(params: BasePopupConfiguration<ChangeTargetPagePopupConfiguration>) {
    const matDialogConfigParams = Object.assign({}, params);
    delete matDialogConfigParams.componentConfiguration;

    let dialogRef: MatLegacyDialogRef<ChangeTargetPagePopupComponent>;
    dialogRef = this.matDialog.open(ChangeTargetPagePopupComponent, matDialogConfigParams);
    dialogRef.componentInstance.configuration = params.componentConfiguration;

    return dialogRef.afterClosed();
  }

  editLocationPagePopup(params: {
    backdropClass: string;
    maxHeight: string;
    width: string;
    panelClass: 'zero-padding-mat-dialog-container';
    componentConfiguration: { LocationViewModel: LocationViewModel };
    maxWidth: string;
  }) {
    const matDialogConfigParams = Object.assign({}, params);
    delete matDialogConfigParams.componentConfiguration;

    let dialogRef: MatLegacyDialogRef<LocationFormComponent>;
    dialogRef = this.matDialog.open(LocationFormComponent, matDialogConfigParams);
    dialogRef.componentInstance.configuration = params.componentConfiguration;

    return dialogRef.afterClosed();
  }

  showImagePreviewPopup(params: BasePopupConfiguration<ImagePreviewPopupConfiguration>) {
    if (params.componentConfiguration.url.toLowerCase().includes('.tif')) {
      params.componentConfiguration.url = 'assets/images/tiff.png';
    }
    const matDialogConfigParams = Object.assign({}, params);
    delete matDialogConfigParams.componentConfiguration;

    let dialogRef: MatLegacyDialogRef<ImagePreviewPopupComponent>;
    dialogRef = this.matDialog.open(ImagePreviewPopupComponent, matDialogConfigParams);
    dialogRef.componentInstance.configuration = params.componentConfiguration;

    return dialogRef.afterClosed();
  }

  targetPreviewPopup(params: BasePopupConfiguration<TargetPreviewPopupConfiguration>) {
    const matDialogConfigParams = Object.assign({}, params);
    delete matDialogConfigParams.componentConfiguration;
    let dialogRef: MatLegacyDialogRef<TargetPreviewPopupComponent>;
    dialogRef = this.matDialog.open(TargetPreviewPopupComponent, {
      ...matDialogConfigParams,
      panelClass: ['zero-padding-mat-dialog-container', 'phone-border-dialog-container'],
      minWidth: '300px',
      maxWidth: '350px',
      width: '90%',
    });
    dialogRef.componentInstance.configuration = params.componentConfiguration;

    return dialogRef.afterClosed();
  }

  showPageBuildingBlocksPopup(params: BasePopupConfiguration<PageBuildingBlocksPopupConfiguration>) {
    const matDialogConfigParams = Object.assign({}, params);
    delete matDialogConfigParams.componentConfiguration;

    let dialogRef: MatLegacyDialogRef<PageBuildingBlocksPopupComponent>;
    dialogRef = this.matDialog.open(PageBuildingBlocksPopupComponent, {
      ...matDialogConfigParams,
      backdropClass: 'light-backdrop',
      panelClass: 'zero-padding-mat-dialog-container',
      minWidth: '250px',
      maxWidth: '300px',
      width: '100%',
    });
    dialogRef.componentInstance.configuration = params.componentConfiguration;

    return dialogRef.afterClosed();
  }

  goToMyDetailsPopup() {
    return this.matDialog
      .open(GoToMyDetailsPopupComponent, {
        backdropClass: 'dark-backdrop',
        panelClass: 'zero-padding-mat-dialog-container',
        maxWidth: '500px',
        width: '90%',
        disableClose: true,
        autoFocus: false,
      })
      .afterClosed();
  }

  onBoardingPopup(lang: string) {
    switch (lang) {
      case 'en':
        return this.matDialog
          .open(OnBoardingPopupComponent, {
            backdropClass: 'dark-backdrop',
            panelClass: 'zero-padding-mat-dialog-container',
            maxWidth: '500px',
            width: '90%',
            height: '570px',
            disableClose: true,
            autoFocus: false,
          })
          .afterClosed();
      case 'de':
        return this.matDialog
          .open(OnBoardingPopupComponent, {
            backdropClass: 'dark-backdrop',
            panelClass: 'zero-padding-mat-dialog-container',
            maxWidth: '500px',
            width: '90%',
            height: '590px',
            disableClose: true,
            autoFocus: false,
          })
          .afterClosed();
      case 'es':
        return this.matDialog
          .open(OnBoardingPopupComponent, {
            backdropClass: 'dark-backdrop',
            panelClass: 'zero-padding-mat-dialog-container',
            maxWidth: '500px',
            width: '90%',
            height: '590px',
            disableClose: true,
            autoFocus: false,
          })
          .afterClosed();
      case 'zh-CN':
        return this.matDialog
          .open(OnBoardingPopupComponent, {
            backdropClass: 'dark-backdrop',
            panelClass: 'zero-padding-mat-dialog-container',
            maxWidth: '500px',
            width: '90%',
            height: '540px',
            disableClose: true,
            autoFocus: false,
          })
          .afterClosed();
      case 'zh-TW':
        return this.matDialog
          .open(OnBoardingPopupComponent, {
            backdropClass: 'dark-backdrop',
            panelClass: 'zero-padding-mat-dialog-container',
            maxWidth: '500px',
            width: '90%',
            height: '540px',
            disableClose: true,
            autoFocus: false,
          })
          .afterClosed();
      default:
        break;
    }
  }
}
