import { Component, Input, OnInit } from '@angular/core';
import { MatLegacyDialogRef } from '@angular/material/legacy-dialog';
import { TargetChipFilters, TargetPagesFilters } from '@core/constants/Filters';
import { ActTypeEnum } from '@core/models/enums/act-type-enum';
import { ActViewModel } from '@core/models/view-models/act-view-model';
import { ActAPIService } from '@core/services/act-api.service';
import { LoaderService } from '@core/services/loader.service';
import { BaseTargetsDashboardComponent } from '@shared/components/base-targets-dashboard-component/base-targets-dashboard-component';
import { ChipFilterOptions } from '@shared/components/chip-filters/chip-filter-options';
import * as moment from 'moment';
import { ChangeTargetPagePopupConfiguration } from './change-target-page-popup.configuration';

@Component({
  selector: 'app-change-target-page-popup',
  templateUrl: './change-target-page-popup.component.html',
  styleUrls: ['./change-target-page-popup.component.scss'],
})
export class ChangeTargetPagePopupComponent extends BaseTargetsDashboardComponent implements OnInit {
  @Input() configuration: ChangeTargetPagePopupConfiguration;

  chosenTargetPage: ActViewModel;
  ActTypeEnum = ActTypeEnum;
  targetPagesFilters = TargetPagesFilters;
  targetTypeFilter = 'enumTypesSelection';
  filterOptions: ChipFilterOptions = new ChipFilterOptions();
  startDate: string = moment().format('yyyy-MM-DD');
  minDate: string = moment().format('yyyy-MM-DD');
  maxDate: string;
  defaultOptions: [];
  selectedRowIndex = -1;

  constructor(
    public matDialogRef: MatLegacyDialogRef<ChangeTargetPagePopupComponent>,
    readonly actAPIService: ActAPIService,
    readonly loaderService: LoaderService,
  ) {
    super(actAPIService, loaderService);
    this.chosenTargetPage = null;
    this.configureFilterOptions();
  }

  ngOnInit(): void {
    this.maxDate = moment(this.configuration.engagementValidityPeriod?.to).format('yyyy-MM-DD');
    super.ngOnInit();
  }

  onSaveChanges(): void {
    this.matDialogRef.close({ act: this.chosenTargetPage.ActDTO, startDate: this.startDate });
  }

  onCancel(): void {
    this.matDialogRef.close();
  }
  applyFilter(value: string, type: string) {
    this.chosenTargetPage = null;
    this.filters[type] = value;
    this.refreshWithFirstPageNavigation();
  }

  onSelectTargetPage(targetPage: ActViewModel): void {
    if (targetPage.id !== this.configuration.currentTargetPage.id) {
      this.chosenTargetPage = targetPage;
    }
  }

  private configureFilterOptions(): void {
    this.filterOptions.filters = TargetChipFilters;
    const selectedItems = this.filterOptions.filters.filter((item) => item.selected);
    this.filters.enumTypesSelection =
      !selectedItems.length || selectedItems[0].value === ActTypeEnum.All ? [] : [selectedItems[0].value];
  }
}
