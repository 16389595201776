import { createSelector } from '@ngrx/store';
import { EcommercePlatformState } from '../reducers/ecommerce-platform.reducer';
import { selectSelfOnboardingState, SelfOnboardingState } from '../index';

export const getEcommercePlatform = createSelector(
  selectSelfOnboardingState,
  (state: SelfOnboardingState) => state.ecommercePlatformDetails,
);

export const getPlatformDetails = createSelector(
  selectSelfOnboardingState,
  (state: SelfOnboardingState) => state.platformDetails,
);

export const getSelectedEcommercePlatform = createSelector(
  getEcommercePlatform,
  (state: EcommercePlatformState) => state.selectedEcommercePlatform,
);
