import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, FormGroupDirective, ValidationErrors, Validators } from '@angular/forms';
import { SETTINGS_PAYMENT_PROVIDER_CURRENCIES } from '@core/constants/Currencies';
import { RegularExpressions } from '@core/constants/RegularExpressions';
import { TimezonesDictionary } from '@core/constants/Timezones';
import { LoaderService } from '@core/services/loader.service';
import { MerchantAPIService } from '@core/services/merchant-api.service';
import { Store } from '@ngrx/store';
import { BaseComponent } from '@shared/components/base-component/base-component';
import { takeUntil } from 'rxjs/operators';
import { MenuWizardService } from '../menu-wizard/menu-wizard-service/menu-wizard.service';
import { selectSelfOnboardingState, SelfOnboardingState } from '../store';
import { createPaymentProviderDetails } from '../store/actions/payment-provider-details.actions';

@Component({
  selector: 'app-payment-provider-form',
  templateUrl: './payment-provider-form.component.html',
  styleUrls: ['./payment-provider-form.component.scss'],
})
export class PaymentProviderFormComponent extends BaseComponent implements OnInit {
  form: FormGroup;
  currencies: string[] = SETTINGS_PAYMENT_PROVIDER_CURRENCIES;
  timezones = TimezonesDictionary;
  @ViewChild('copmutopForm') ngForm: FormGroupDirective;
  hideHMACKey = true;
  hideBlowfishKey = true;

  constructor(
    public loaderService: LoaderService,
    private readonly menuWizardService: MenuWizardService,
    private readonly store: Store<SelfOnboardingState>,
    private merchantService: MerchantAPIService,
  ) {
    super();
    this.form = new FormGroup({
      computopMerchantId: new FormControl('', [Validators.required, Validators.pattern(/^\S*$/)]),
      computopHmacKey: new FormControl('', [
        Validators.required,
        Validators.minLength(32),
        Validators.maxLength(64),
        Validators.pattern(RegularExpressions.HMAC_BLOWFISH_KEY),
      ]),
      computopBlowfishKey: new FormControl('', [
        Validators.required,
        Validators.minLength(16),
        Validators.maxLength(32),
        Validators.pattern(RegularExpressions.HMAC_BLOWFISH_KEY),
      ]),
      currencyForPayment: new FormControl('', Validators.required),
      timezone: new FormControl('', Validators.required),
      phone: new FormControl('', [Validators.required, Validators.pattern(RegularExpressions.PHONE_NUMBER)]),
      storeName: new FormControl(
        '',
        [
          Validators.required,
          Validators.minLength(20),
          Validators.maxLength(20),
          Validators.pattern(RegularExpressions.RFC_1738),
        ],
        this.checkExistingStoreNames.bind(this),
      ),
    });
  }

  ngOnInit(): void {
    this.form.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((_) => {
      this.menuWizardService.canGoToNextStep$.next(this.form.valid);
    });
    this.store
      .select(selectSelfOnboardingState)
      .pipe(takeUntil(this.destroy$))
      .subscribe((state) => {
        this.form.patchValue(state.paymentProviderDetails);
      });
    this.menuWizardService.nextStepRequest$.pipe(takeUntil(this.destroy$)).subscribe((_) => {
      this.ngForm.ngSubmit.emit();
    });
  }

  async checkExistingStoreNames(control: FormControl): Promise<ValidationErrors | null> {
    const value = control.value;

    try {
      const exists = await this.merchantService.checkRceHostNameAlreadyExist(value).toPromise();

      return exists ? { alreadyExists: true } : null;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  originalOrder(): number {
    return 0;
  }

  get currencyForPayment(): FormControl {
    return this.form.get(`currencyForPayment`).value;
  }

  get timezone(): FormControl {
    return this.form.get(`timezone`).value;
  }

  async onSubmit() {
    if (this.form.invalid) {
      return;
    }

    this.menuWizardService.goNext(() =>
      this.store.dispatch(
        createPaymentProviderDetails({
          paymentProviderDetails: { ...this.form.value },
        }),
      ),
    );
  }
}
