import { FocusMonitor } from '@angular/cdk/a11y';
import {
  Component,
  DoCheck,
  ElementRef,
  forwardRef,
  HostBinding,
  Injector,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, FormControl, NgControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatLegacyAutocomplete, MatLegacyAutocompleteTrigger } from '@angular/material/legacy-autocomplete';
import { MatLegacyFormFieldControl } from '@angular/material/legacy-form-field';
import { ProductCategoryTreeNode } from '@core/models/product-category-tree-node';
import { ProductService } from '@core/services/product.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { BaseComponent } from '../base-component/base-component';
import { ChecklistDatabase, TreeFlatNode } from '../tree-checklist/tree-checklist.component';

@Component({
  selector: 'app-choose-category',
  templateUrl: './choose-category.component.html',
  styleUrls: ['./choose-category.component.scss'],
  providers: [
    ChecklistDatabase,
    { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => ChooseCategoryComponent), multi: true },
    { provide: MatLegacyFormFieldControl, useExisting: ChooseCategoryComponent },
  ],
  host: {
    '[id]': 'id',
    '[attr.aria-describedby]': 'describedBy',
  },
})
export class ChooseCategoryComponent
  extends BaseComponent
  implements OnInit, OnDestroy, DoCheck, ControlValueAccessor, MatLegacyFormFieldControl<any> {
  set value(val: TreeFlatNode[]) {
    // this value is updated by programmatic changes
    if (val !== undefined && this.val !== val) {
      this.val = val;
      this.onChange(val);
    }
  }
  @HostBinding('class.floating')
  get shouldLabelFloat() {
    return this.focused || !this.empty;
  }
  get empty() {
    return this.val.length === 0;
  }
  static nextId = 0;
  //#region control value accessor
  val: TreeFlatNode[] = [];
  //#endregion

  //#region mat form field
  stateChanges: Subject<void> = new Subject();
  @HostBinding() id = `rich-editor-input-${ChooseCategoryComponent.nextId++}`;
  placeholder: string;
  ngControl: NgControl;
  focused: boolean;
  required: boolean;
  disabled: boolean;
  errorState = false;
  controlType = 'choosecategory';
  autofilled?: boolean;
  @HostBinding('attr.aria-describedby') describedBy = '';
  //#endregion

  categoryControl = new FormControl();

  @ViewChild(MatLegacyAutocompleteTrigger) matAutocompleteTrigger: MatLegacyAutocompleteTrigger;
  @ViewChild(MatLegacyAutocomplete) matAutocomplete: MatLegacyAutocomplete;

  constructor(
    public elRef: ElementRef,
    private productService: ProductService,
    private checklistDatabase: ChecklistDatabase,
    public injector: Injector,
    public fm: FocusMonitor,
  ) {
    super();
    fm.monitor(elRef.nativeElement, true).subscribe((origin) => {
      this.focused = !!origin;
      this.stateChanges.next();
    });
    const category$ = this.productService.getProductCategories();
    this.categoryControl.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe((value) => this.checklistDatabase.searchTermChange.next(value));
    category$.pipe(takeUntil(this.destroy$)).subscribe((rce) => {
      this.checklistDatabase.initialize<ProductCategoryTreeNode>(
        [rce],
        (elem: ProductCategoryTreeNode) => ({ item: elem.name, id: elem.id, children: [], filteredChildren: [] }),
        (elem: ProductCategoryTreeNode) => elem.children,
      );
    });
    this.checklistDatabase.selectionChange.pipe(takeUntil(this.destroy$)).subscribe((value) => {
      console.log('checklistDatabase', value);
      this.value = value;
      if (this.matAutocompleteTrigger && this.matAutocomplete) {
        setTimeout(() => {
          this.matAutocompleteTrigger.updatePosition();
        });
      }
    });
  }

  onChange: any = () => {};
  onTouch: any = () => {};

  writeValue(obj: any): void {
    this.value = obj;
  }

  registerOnChange(fn: any): void {
    console.log('registerOnChange', fn);
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  setDisabledState?(isDisabled: boolean): void {}
  setDescribedByIds(ids: string[]) {
    this.describedBy = ids.join(' ');
  }
  onContainerClick(event: MouseEvent): void {}

  ngOnInit(): void {
    this.ngControl = this.injector.get(NgControl);
    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.stateChanges.complete();
    this.fm.stopMonitoring(this.elRef.nativeElement);
  }

  ngDoCheck(): void {
    if (this.ngControl) {
      this.errorState = this.ngControl.invalid && this.ngControl.touched;
      this.stateChanges.next();
    }

    if (this.categoryControl && this.categoryControl.touched) {
      this.onTouch();
    }
  }

  remove(node: TreeFlatNode) {
    this.checklistDatabase.unselect(node);
  }
}
