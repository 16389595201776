export const TINY_MCE_TEXT_BLOCK_INIT: Record<string, any> | undefined = {
  selector: 'textarea',
  min_height: 300,
  width: '100%',
  branding: false,
  menubar: false,
  elementpath: false,
  plugins: 'lists emoticons link autolink',
  emoticons_database: 'emojiimages',
  paste_as_text: true,
  contextmenu: 'bold italic underline forecolor',
  toolbar: [
    'bold italic underline forecolor alignleft aligncenter alignright alignjustify bullist numlist',
    'fontfamily fontsize emoticons link',
  ],
  link_default_target: '_blank',
  mobile: {
    toolbar_mode: 'wrap',
    toolbar1:
      'bold italic underline forecolor alignleft aligncenter alignright alignjustify bullist numlist fontselect fontsizeselect emoticons',
    toolbar2: 'none',
  },
  font_formats:
    'Andale Mono=andale mono,times; Arial=arial,helvetica,sans-serif; Book Antiqua=book antiqua,palatino; Courier New=courier new,courier; Georgia=georgia,palatino; Helvetica=helvetica; Tahoma=tahoma,arial,helvetica,sans-serif; Terminal=terminal,monaco; Times New Roman=times new roman,times; Trebuchet MS=trebuchet ms,geneva; Verdana=verdana,geneva',
};

export const TINY_MCE_OPENING_HOURS_INIT: Record<string, any> | undefined = {
  min_height: 150,
  max_height: 400,
  width: '100%',
  branding: false,
  elementpath: false,
  menubar: false,
  content_style: 'body {font-size: 14px;}',
  plugins: 'table lists emoticons',
  toolbar: ['bold italic underline forecolor alignleft aligncenter alignright alignjustify', 'table tabledelete'],
  toolbar_mode: 'sliding',
  font_formats:
    'Andale Mono=andale mono,times; Arial=arial,helvetica,sans-serif; Book Antiqua=book antiqua,palatino; Courier New=courier new,courier; Georgia=georgia,palatino; Helvetica=helvetica; Tahoma=tahoma,arial,helvetica,sans-serif; Terminal=terminal,monaco; Times New Roman=times new roman,times; Trebuchet MS=trebuchet ms,geneva; Verdana=verdana,geneva',
};

export const TINY_MCE_HEADING_INIT: Record<string, any> | undefined = {
  selector: 'textarea',
  max_height: 200,
  width: '100%',
  branding: false,
  menubar: false,
  elementpath: false,
  mobile: {
    toolbar_mode: 'wrap',
    toolbar1: 'bold italic forecolor fontselect',
    toolbar2: 'alignleft aligncenter alignright',
  },
  paste_as_text: true,
  contextmenu: 'bold italic forecolor',
  toolbar: 'bold italic forecolor | fontfamily | alignleft aligncenter alignright',
  toolbar_mode: 'sliding',
  font_formats:
    'Andale Mono=andale mono,times; Arial=arial,helvetica,sans-serif; Book Antiqua=book antiqua,palatino; Courier New=courier new,courier; Georgia=georgia,palatino; Helvetica=helvetica; Tahoma=tahoma,arial,helvetica,sans-serif; Terminal=terminal,monaco; Times New Roman=times new roman,times; Trebuchet MS=trebuchet ms,geneva; Verdana=verdana,geneva',
  init_instance_callback(editor) {
    editor.on('ExecCommand', (e) => {
      if (e.command === 'mceInsertContent') {
        const innerHtml = e.target.iframeElement.contentDocument.body.innerHTML;
        if (e.target.getContent({ format: 'text' }).trim() === innerHtml) {
          e.target.iframeElement.contentDocument.body.innerHTML = `<div>${innerHtml}</div>`;
        }
      } else if (e.command === 'mceApplyTextcolor' && !e.target.getContent({ format: 'text' }).trim()) {
        const spanEle = e.target.iframeElement.contentDocument.body.querySelector('span');
        if (spanEle) {
          const span = spanEle.innerHTML;
          e.target.iframeElement.contentDocument.body.innerHTML = `<div>${span}</div>`;
        } else {
          console.warn(
            `Fixed bug: https://rezolvetech.atlassian.net/browse/RR-11678. But breaking due to change in tiny editor`,
          );
        }
      }
    });
  },
};
