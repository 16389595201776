import { createAction, props } from '@ngrx/store';

export const selectPaymentProvider = createAction(
  '[Settings/Self-OnBoarding] Payment-Provider-Selected',
  props<{ paymentDto: SelectedPaymentProviderDto }>(),
);

export class SelectedPaymentProviderDto {
  providerName: string;
  providerLogoUrl: string;
  selectedPaymentPlatform: any;
}
