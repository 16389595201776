import { InstantButtonOption } from '@core/models/instant-button-option';

export enum HeightEnum {
  Small = 0,
  Large = 1,
}

export const HeightSmall: InstantButtonOption = {
  name: HeightEnum[HeightEnum.Small],
  value: HeightEnum.Small,
  optionValue: 'height-small',
  svgIcon: 'height-small',
};

export const HeightLarge: InstantButtonOption = {
  name: HeightEnum[HeightEnum.Large],
  value: HeightEnum.Large,
  optionValue: 'height-large',
  svgIcon: 'height-large',
};

export const HeightList: Array<InstantButtonOption> = [HeightSmall, HeightLarge];
