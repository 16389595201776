import { CardInfo } from '@core/models/card-info';
import { environment } from '@env/environment';
import {EngagementTypeEnum} from "@core/models/enums/engagement-type-enum";

const assetPrefix: string = environment['customTriggerImagePrefix'] ? environment['customTriggerImagePrefix'] : '';

export const TriggerTypeCards: CardInfo[] = [
  {
    name: 'GEOLOCATION',
    type: EngagementTypeEnum.Geolocation,
    imageUrl: `assets/images/${assetPrefix}location-bg.jpg`,
    svgIcon: 'location_on',
    elementId: 'geolocation-trigger-card',
    limit: 0,
    hasPassedLimit: false,
  },
  {
    name: 'IMAGE',
    type: EngagementTypeEnum.Image,
    imageUrl: `assets/images/${assetPrefix}image-bg.jpg`,
    svgIcon: 'image',
    elementId: 'image-trigger-card',
    limit: 0,
    hasPassedLimit: false,
  },
  {
    name: 'AUDIO',
    type: EngagementTypeEnum.Audio,
    imageUrl: `assets/images/${assetPrefix}audio-bg.jpg`,
    svgIcon: 'equalizer',
    elementId: 'audio-trigger-card',
    limit: 0,
    hasPassedLimit: false,
  },
  {
    name: 'BEACON',
    type: EngagementTypeEnum.Beacon,
    imageUrl: `assets/images/${assetPrefix}beacon-bg.jpg`,
    svgIcon: 'rss_feed',
    elementId: 'beacon-trigger-card',
    limit: 0,
    hasPassedLimit: false,
  },
  {
    name: 'SMARTCODE',
    type: EngagementTypeEnum.SmartCode,
    imageUrl: `assets/images/${assetPrefix}smartcode-bg-01.svg`,
    svgIcon: 'smartcode',
    elementId: 'smartcode-trigger-card',
    limit: 0,
    hasPassedLimit: false,
  },
  {
    name: 'LINK',
    type: EngagementTypeEnum.Link,
    imageUrl: `assets/images/${assetPrefix}smart-link-trigger.jpg`,
    svgIcon: 'link',
    elementId: 'link-trigger-card',
    limit: 0,
    hasPassedLimit: false,
  },
];

export const InstantWebTriggerTypeCard: CardInfo = {
  name: 'INSTANTWEB',
  type: EngagementTypeEnum.InstantWeb,
  imageUrl: `assets/images/${assetPrefix}instant-web-bg.png`,
  svgIcon: 'instant-web',
  elementId: 'instant-web-trigger-card',
  limit: 0,
  hasPassedLimit: false,
};
