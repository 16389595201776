import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacySliderModule } from '@angular/material/legacy-slider';
import { MatLegacyTooltipModule } from '@angular/material/legacy-tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { SliderPanelComponent } from './slider-panel.component';

@NgModule({
  declarations: [SliderPanelComponent],
  imports: [
    CommonModule,
    FlexLayoutModule,
    MatIconModule,
    MatLegacyTooltipModule,
    MatLegacySliderModule,
    MatExpansionModule,
    TranslateModule,
  ],
  exports: [SliderPanelComponent],
})
export class SliderPanelModule {}
