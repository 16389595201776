import { Injectable } from '@angular/core';
import { CardProviderEnum } from '@core/constants/CardProviders';
import { CurrencyDictionary, isCurrencyNormalized } from '@core/constants/Currencies';
import { ShopType } from '@core/models/update-merchant-product-config-request';
import { AutoProvisioningService } from '@core/services/auto-provisioning.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { SelfOnboardingState } from '..';
import { createAci3DSecureDetails, skipAci3DSecureDetails } from '../actions/aci-3D-secure-details.actions';
import {
  updateAciOnboardingProgress3DSecure,
  updateAciOnboardingProgressMerchantAccount,
  updateAciOnboardingProgressSubmit,
} from '../actions/aci-onboarding-progress.actions';
import { createAciPaymentProviderDetails } from '../actions/aci-payment-provider-details.actions';
import { createEcommercePlatformConnectionRequest } from '../actions/confirmation.actions';
import { OnboardingProgressEnum } from '../models/aci-onboarding.models';
import { getAggregateDetails } from '../selectors/aggregate.select';

@Injectable()
export class SelfOnBoardingEffects {
  cardBrands = CardProviderEnum;

  createACIAccount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createAciPaymentProviderDetails),
      tap(() =>
        this.store.dispatch(
          updateAciOnboardingProgressMerchantAccount({
            state: OnboardingProgressEnum.Pending,
          }),
        ),
      ),
      switchMap((data) => {
        const normalizedCurrency = CurrencyDictionary[data.currencyForPayment];
        const cardProvidersData = this.defineCardProviders(data.paymentProviderDetails);
        return this.autoProvisioningService
          .createAciMerchantAccount({
            paymentProviderDetails: data.paymentProviderDetails,
            currency: normalizedCurrency,
            cardBrands: cardProvidersData,
            paymentProviderName: data.paymentProviderName,
            phoneNumber: data.phone,
            timezone: data.timezone,
          })
          .pipe(
            map((res) =>
              updateAciOnboardingProgressMerchantAccount({
                state: OnboardingProgressEnum.Success,
                data: res.payPalActivationLink,
              }),
            ),
            catchError((error) => {
              return of(
                updateAciOnboardingProgressMerchantAccount({
                  state: OnboardingProgressEnum.Failed,
                }),
              );
            }),
          );
      }),
    ),
  );

  setupAci3DSecure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createAci3DSecureDetails),
      tap(() =>
        this.store.dispatch(
          updateAciOnboardingProgress3DSecure({
            state: OnboardingProgressEnum.Pending,
          }),
        ),
      ),
      switchMap((data) => {
        return this.autoProvisioningService
          .setupAci3DSecure({
            visa3DSecureConfiguration: {
              merchantAcquirerBin_v2: data.visaMerchantAcquirerBin_v2,
              requestorId_v2: data.visaRequestorId_v2,
              requestorName: data.visaRequestorName,
            },
            americanExpress3DSecureConfiguration: {
              merchantAcquirerBin_v2: data.amexMerchantAcquirerBin_v2,
              requestorId_v2: data.amexRequestorId_v2,
              requestorName: data.amexRequestorName,
              merchantId: data.amexMerchantId,
            },
            mastercard3DSecureConfiguration: {
              merchantAcquirerBin_v2: data.masterMerchantAcquirerBin_v2,
              requestorId_v2: data.masterRequestorId_v2,
              requestorName: data.masterRequestorName,
            },
            supports3DSecure: true,
            merchant3DCategory: data.merchant3DCategory,
            merchant3DId: data.merchant3DId,
            merchant3DName: data.merchant3DName,
            merchant3DUrl: data.merchant3DUrl,
            merchantIsoCountryCode: data.merchantIsoCountryCode,
          })
          .pipe(
            map(() =>
              updateAciOnboardingProgress3DSecure({
                state: OnboardingProgressEnum.Success,
              }),
            ),
            catchError((error) =>
              of(
                updateAciOnboardingProgress3DSecure({
                  state: OnboardingProgressEnum.Failed,
                }),
              ),
            ),
          );
      }),
    ),
  );

  skipAci3DSecure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(skipAci3DSecureDetails),
      tap(() =>
        this.store.dispatch(
          updateAciOnboardingProgress3DSecure({
            state: OnboardingProgressEnum.Pending,
          }),
        ),
      ),
      switchMap((_) => {
        return this.autoProvisioningService
          .skipAci3DSecure({
            skip3DSecureSetup: true,
          })
          .pipe(
            map(() =>
              updateAciOnboardingProgress3DSecure({
                state: OnboardingProgressEnum.Success,
              }),
            ),
            catchError((error) =>
              of(
                updateAciOnboardingProgress3DSecure({
                  state: OnboardingProgressEnum.Failed,
                }),
              ),
            ),
          );
      }),
    ),
  );

  setupAutoProvisioning$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createEcommercePlatformConnectionRequest),
      switchMap((_) =>
        this.store.select(getAggregateDetails).pipe(
          switchMap(
            ({
              ecommercePlatformDetails,
              platformDetails,
              paymentGatewayDetails,
              paymentProviderDetails,
              selectedPaymentProvider,
              aciPaymentProviderDetails,
            }) => {
              const ecommercePlatform = ecommercePlatformDetails.selectedEcommercePlatform
                ? {
                    platform: Object.keys(ecommercePlatformDetails.selectedEcommercePlatform)[0] as ShopType,
                    data: {
                      ...platformDetails,
                      cartId: Object.keys(ecommercePlatformDetails.selectedEcommercePlatform)[0] as ShopType,
                    },
                  }
                : null;

              const currency =
                paymentProviderDetails.currencyForPayment ||
                paymentGatewayDetails.currencyForPayment ||
                aciPaymentProviderDetails.currencyForPayment;
              const normalizedCurrency = isCurrencyNormalized(currency) ? currency : CurrencyDictionary[currency];

              const userConfig = {
                defaultLanguage: this.translateService.currentLang,
                currency: normalizedCurrency,
                timezone:
                  paymentGatewayDetails.timezone ||
                  paymentProviderDetails.timezone ||
                  aciPaymentProviderDetails.timezone,
                phoneNumber:
                  paymentGatewayDetails.phone || paymentProviderDetails.phone || aciPaymentProviderDetails.phone,
                storeName: paymentProviderDetails.storeName || aciPaymentProviderDetails.storeName,
              };
              const paymentProviderName = selectedPaymentProvider.providerName;

              if (
                aciPaymentProviderDetails &&
                aciPaymentProviderDetails.paymentProviderName &&
                aciPaymentProviderDetails.paymentProviderDetails
              ) {
                return this.autoProvisioningService
                  .aciSetup({
                    ecommercePlatform,
                    userConfig,
                    paymentProviderName,
                  })
                  .pipe(
                    map(() =>
                      updateAciOnboardingProgressSubmit({
                        state: OnboardingProgressEnum.Success,
                      }),
                    ),
                    catchError((error) =>
                      of(
                        updateAciOnboardingProgressSubmit({
                          state: OnboardingProgressEnum.Failed,
                        }),
                      ),
                    ),
                  );
              }

              const data = {
                paymentProviderDetails: {
                  macKey: paymentProviderDetails.computopHmacKey,
                  encryptionKey: paymentProviderDetails.computopBlowfishKey,
                  merchantId: paymentProviderDetails.computopMerchantId,
                },
                ecommercePlatform,
                paymentGatewayDetails: {
                  currencyForPayment: paymentGatewayDetails.currencyForPayment,
                  merchantCategoryCode: paymentGatewayDetails.merchantCategoryCode,
                  merchantIdVisa: paymentGatewayDetails.merchantIdVisa,
                  merchantIdMasterCard: paymentGatewayDetails.merchantIdMasterCard,
                  acceptAmex: paymentGatewayDetails.acceptAmex,
                  isSameMerchantIdForVisaAndMastercard:
                    paymentGatewayDetails.merchantIdMasterCard === paymentGatewayDetails.merchantIdVisa,
                  businessName: paymentGatewayDetails.contactName,
                  address: paymentGatewayDetails.contactAddress,
                },
                userConfig,
                paymentProviderName: selectedPaymentProvider.providerName,
              };

              return this.autoProvisioningService.setup(data).pipe(
                map(() =>
                  updateAciOnboardingProgressSubmit({
                    state: OnboardingProgressEnum.Success,
                  }),
                ),
                catchError((error) =>
                  of(
                    updateAciOnboardingProgressSubmit({
                      state: OnboardingProgressEnum.Failed,
                    }),
                  ),
                ),
              );
            },
          ),
        ),
      ),
    ),
  );

  constructor(
    private readonly actions$: Actions,
    private readonly store: Store<SelfOnboardingState>,
    private readonly autoProvisioningService: AutoProvisioningService,
    private readonly translateService: TranslateService,
  ) {}

  private defineCardProviders(providerDetails): Array<string> {
    const cardProviders: Array<string> = [];
    for (const en in this.cardBrands) {
      const provider = this.cardBrands[en];
      for (const field in providerDetails) {
        if (provider === field && providerDetails[field]) {
          cardProviders.push(field);
        }
      }
    }
    return cardProviders;
  }
}
