import {
  AfterViewInit,
  Directive,
  ElementRef,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { BaseComponent } from '@shared/components/base-component/base-component';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Directive({
  selector: '[twoDigitDecimalNumber]',
})
export class TwoDigitDecimalNumberDirective implements OnDestroy, AfterViewInit {
  @Input()
  ngModelRef: any;
  protected destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(private el: ElementRef) {}

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  ngAfterViewInit(): void {
    this.ngModelRef.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value) => {
      if (!value) return;
      let result = this.fillWithZeros(value.toString());
      this.ngModelRef.control.setValue(result, { emitEvent: false });
    });
  }

  fillWithZeros(value: string) {
    if (!value) {
      return;
    }

    const formatted = value.replace(/[^\d,.]+/, '');

    var splitted = formatted.split('.');

    if (splitted.length == 1) {
      return `${splitted[0]}.00`;
    }

    if (splitted.length == 2) {
      const firstHalf = splitted[0];
      let secondHalf = splitted[1];

      if (secondHalf.length <= 2) {
        return `${firstHalf}.${secondHalf.length == 2 ? secondHalf : secondHalf.length == 1 ? `${secondHalf}0` : '00'}`;
      }

      const selection = this.el.nativeElement.selectionStart - 1 - firstHalf.length;

      if (selection === 3) {
        secondHalf = secondHalf[0] + secondHalf[2];
      }

      if (selection == 2 || selection == 1) {
        secondHalf = secondHalf[1] + secondHalf[2];
      }

      return `${firstHalf}.${secondHalf}`;
    }
  }
}
