import { Action, createReducer, on} from '@ngrx/store';
import * as PlatformDetailsActions from '../actions/platform-details.action';

export interface PlatformDetailsState {
    storeId: string | null;
    storeURL: string | null;
    [k: string]: any;
}

export const initialState: PlatformDetailsState = {
  storeId: null,
  storeURL: null,
};

const platformDetailsReducer = createReducer(
    initialState,
    on(PlatformDetailsActions.createPlatformDetails, (state, { platformDetails }) => ({
        ...state,
        ...platformDetails,
    })),
    on(PlatformDetailsActions.resetPlatformDetailsState, () => ({
      ...initialState,
    })),
);

export function reducer(state = initialState, action: Action): PlatformDetailsState {
  return platformDetailsReducer(state, action);
}


