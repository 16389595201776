import { ActivatedRouteSnapshot, BaseRouteReuseStrategy } from '@angular/router';

export class CustomRouteReuseStrategy extends BaseRouteReuseStrategy {
  shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
    if (future?.data?.shouldReuseRoute !== undefined) {
      return future?.data?.shouldReuseRoute;
    }

    return future.routeConfig === curr.routeConfig;
  }
}
