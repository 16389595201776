// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container {
  max-width: 100%;
  max-height: 100%;
  padding: 1em;
}

.block-container {
  cursor: pointer;
  display: flex;
  width: 100%;
  min-width: 200px;
  height: auto;
  margin-top: 10px;
}
.block-container .panel {
  width: 100%;
  background: #f0f5fb;
  padding: 5px 10px;
}

.type-icon {
  align-self: center;
  width: 24px;
  font-size: 24px;
}

.name {
  align-self: center;
}

.action-icon {
  display: flex;
  margin-left: auto;
  color: #cbd3dd;
  align-self: center;
  font-size: 22px;
}

.close-button {
  display: flex;
  cursor: pointer;
  color: #adb9ca;
  max-width: 21px;
  margin-left: auto;
  vertical-align: middle;
  align-self: center;
  height: -moz-fit-content;
  height: fit-content;
}

.title {
  display: flex;
  flex: 1 1 100%;
  flex-direction: row;
  margin-bottom: 5px;
  margin-right: 2px;
}
.title .text {
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/popup/page-building-blocks-popup/page-building-blocks-popup.component.scss"],"names":[],"mappings":"AAGA;EACE,eAAA;EACA,gBAAA;EACA,YAAA;AAFF;;AAKA;EACE,eAAA;EACA,aAAA;EACA,WAAA;EACA,gBAAA;EACA,YAAA;EACA,gBAAA;AAFF;AAIE;EACE,WAAA;EACA,mBAAA;EACA,iBAAA;AAFJ;;AAMA;EACE,kBAAA;EACA,WAAA;EACA,eAAA;AAHF;;AAMA;EACE,kBAAA;AAHF;;AAMA;EACE,aAAA;EACA,iBAAA;EACA,cAAA;EACA,kBAAA;EACA,eAAA;AAHF;;AAMA;EACE,aAAA;EACA,eAAA;EACA,cAAA;EACA,eAAA;EACA,iBAAA;EACA,sBAAA;EACA,kBAAA;EACA,wBAAA;EAAA,mBAAA;AAHF;;AAMA;EACE,aAAA;EACA,cAAA;EACA,mBAAA;EACA,kBAAA;EACA,iBAAA;AAHF;AAKE;EACE,eAAA;EACA,gBAAA;EACA,mBAAA;AAHJ","sourcesContent":["@import 'node_modules/@angular/material/theming';\n@import 'variables';\n\n.container {\n  max-width: 100%;\n  max-height: 100%;\n  padding: 1em;\n}\n\n.block-container {\n  cursor: pointer;\n  display: flex;\n  width: 100%;\n  min-width: 200px;\n  height: auto;\n  margin-top: 10px;\n\n  .panel {\n    width: 100%;\n    background: #f0f5fb;\n    padding: 5px 10px;\n  }\n}\n\n.type-icon {\n  align-self: center;\n  width: 24px;\n  font-size: 24px;\n}\n\n.name {\n  align-self: center;\n}\n\n.action-icon {\n  display: flex;\n  margin-left: auto;\n  color: #cbd3dd;\n  align-self: center;\n  font-size: 22px;\n}\n\n.close-button {\n  display: flex;\n  cursor: pointer;\n  color: #adb9ca;\n  max-width: 21px;\n  margin-left: auto;\n  vertical-align: middle;\n  align-self: center;\n  height: fit-content;\n}\n\n.title {\n  display: flex;\n  flex: 1 1 100%;\n  flex-direction: row;\n  margin-bottom: 5px;\n  margin-right: 2px;\n\n  .text {\n    font-size: 16px;\n    font-weight: 400;\n    line-height: normal;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
