import { Directive, ElementRef, Input, OnChanges, SimpleChanges } from '@angular/core';
import { environment } from '@env/environment';
import { LogUtils } from 'src/app/utils/log-utils';

@Directive({
  selector: '[hideElementIfRequired]',
})
export class HideElementDirective implements OnChanges {
  @Input() elementId: string;

  constructor(private el: ElementRef) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.elementId) {
      if (environment.hiddenElements.length > 0) {
        if (environment.hiddenElements.indexOf(this.elementId) > -1) {
          this.el.nativeElement.style.display = 'none';
          this.el.nativeElement.remove();
          LogUtils.info(`Hiding the element with id: ${this.elementId}`);
        }
      }
    }
  }
}
