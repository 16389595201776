import { KeyValue } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import Route from '@core/constants/route';
import { allPossibleShops, EcommerceConfig, ShopType } from '@core/models/update-merchant-product-config-request';
import { ProductService } from '@core/services/product.service';
import { TranslateService } from '@ngx-translate/core';
import { BaseComponent } from '@shared/components/base-component/base-component';
import { GenericCreationContainerService } from '@shared/components/generic-creation-container/generic-creation-container.service';
import { PopupService } from '@shared/components/popup/popup.service';
import { of } from 'rxjs';
import { finalize, switchMap, takeUntil } from 'rxjs/operators';
import { Connection, ShopConnectionDic } from '../shop-connection-models';

@Component({
  selector: 'app-connect-products-store',
  templateUrl: './connect-products-store.component.html',
  styleUrls: ['./connect-products-store.component.scss'],
  providers: [GenericCreationContainerService],
})
export class ConnectProductsStoreComponent extends BaseComponent implements OnInit {
  @Input()
  set ecommerceConfig(value: EcommerceConfig) {
    this._ecommerceConfig = value;
    if (this.ecommerceConfig) {
      this.patchFromConfig(this.ecommerceConfig);
    }
  }
  get ecommerceConfig() {
    return this._ecommerceConfig;
  }

  private _ecommerceConfig: EcommerceConfig;
  shops = allPossibleShops;
  shopsDic = ShopConnectionDic;

  form: FormGroup;
  selectedShop: ShopType;
  selectedShopConnection: Connection;
  selectedForm: FormGroup;
  isResetted = false;

  constructor(
    private genericCreationContainerService: GenericCreationContainerService,
    private productsService: ProductService,
    private translateService: TranslateService,
    private popupService: PopupService,
    private router: Router,
  ) {
    super();
    this.form = new FormGroup({
      cartId: new FormControl('', [Validators.required]),
      storeId: new FormControl('', [Validators.required]),
      storeUrl: new FormControl('', [Validators.required]),
    });
    genericCreationContainerService.initSteps(
      [
        {
          step: 0,
          bottomBar: {
            hideLeftButton: true,
            rightButtonLabel: 'CONNECT_TO_STORE',
          },
        },
      ],
      () => {},
    );
  }

  originalOrder = (a: KeyValue<string, Connection>, b: KeyValue<string, Connection>): number => {
    return 0;
  };

  ngOnInit(): void {
    this.form
      .get('cartId')
      .valueChanges.pipe(takeUntil(this.destroy$))
      .subscribe((val: ShopType) => {
        if (!val) {
          return;
        }

        this.selectShop(val);
        if (val !== (this.ecommerceConfig && this.ecommerceConfig.cartId)) {
          this.isResetted = true;
          this.form.patchValue({ storeId: '', storeUrl: '' });
          this.form.markAsUntouched();
        }
      });

    this.genericCreationContainerService
      .getNextStepRequest((data) => {
        return of({ canGoNext: true });
      })
      .pipe(takeUntil(this.destroy$))
      .subscribe();

    this.genericCreationContainerService
      .getSumUpStep()
      .pipe(takeUntil(this.destroy$))
      .subscribe((_) => {
        this.onButtonClick();
      });
  }

  patchFromConfig(ecommerceConfig: EcommerceConfig) {
    this.form.patchValue({
      cartId: ecommerceConfig.cartId,
      storeId: ecommerceConfig.storeId,
      storeUrl: ecommerceConfig.shopUrl,
    });
    this.selectShop(ecommerceConfig.cartId);
    this.form.markAllAsTouched();
  }

  clear() {
    this.isResetted = true;
    this.form.reset();
    this.selectedForm = null;
  }

  revert() {
    this.isResetted = false;
    this.patchFromConfig(this.ecommerceConfig);
  }

  selectShop(shopType: ShopType) {
    const connection = ShopConnectionDic[shopType];

    this.selectedShop = shopType;
    this.selectedShopConnection = connection;
    this.selectedForm = this.getFormGroup(connection);
  }

  getFormGroup(connection: Connection) {
    const mapped = (connection.formFields || []).reduce(
      (prev, cur) => ({
        ...prev,
        [cur.formControlName]: new FormControl('', [Validators.required, ...(cur.validations || [])]),
      }),
      {},
    );

    return new FormGroup(mapped);
  }

  onButtonClick() {
    const request = { ...this.form.value, ...this.selectedForm.value };
    this.genericCreationContainerService.isProcessing$.next(true);
    this.productsService
      .connectToApi2Cart(this.selectedShopConnection.apiUrl, request)
      .pipe(
        takeUntil(this.destroy$),
        finalize(() => {
          this.genericCreationContainerService.isProcessing$.next(false);
          this.genericCreationContainerService.reset();
        }),
      )
      .subscribe(
        (_) => {
          this.showApi2CartConnectionSuccessPopup();
        },
        (error) => {
          this.showApi2CartConnectionFailurePopup();
        },
      );
  }

  private showApi2CartConnectionSuccessPopup(): void {
    this.translateService
      .get('API2CART_CONNECTION_SUCCEED')
      .pipe(
        switchMap((translated) =>
          this.popupService.successStatusPopup({
            componentConfiguration: { isSuccess: true, message: translated },
            height: 'auto',
            width: 'auto',
            maxWidth: '430px',
            backdropClass: 'blurred-backdrop',
          }),
        ),
        takeUntil(this.destroy$),
      )
      .subscribe((_) => {
        this.router.navigate([Route.PRODUCTS, Route.DASHBOARD]);
      });
  }

  private showApi2CartConnectionFailurePopup(): void {
    this.translateService
      .get('API2CART_CONNECTION_FAILED')
      .pipe(
        switchMap((translated) =>
          this.popupService.successStatusPopup({
            componentConfiguration: { isSuccess: false, message: translated },
            height: 'auto',
            width: 'auto',
            maxWidth: '430px',
            backdropClass: 'blurred-backdrop',
          }),
        ),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }
}
