import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule, GridModule } from '@angular/flex-layout';
import { FormsModule } from '@angular/forms';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';
import { MatLegacyCardModule } from '@angular/material/legacy-card';
import { MatLegacyCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatLegacyOptionModule } from '@angular/material/legacy-core';
import { MatLegacyMenuModule } from '@angular/material/legacy-menu';
import { MatLegacyTooltipModule } from '@angular/material/legacy-tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { ConfirmButtonModule } from '@shared/components/confirm-button/confirm-button.module';
import { CustomPipeModule } from '@shared/pipes/custom-pipe.module';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { CustomCardComponent } from './custom-card.component';

@NgModule({
  declarations: [CustomCardComponent],
  imports: [
    CommonModule,
    MatLegacyMenuModule,
    MatLegacyOptionModule,
    ProgressSpinnerModule,
    TranslateModule,
    MatLegacyCardModule,
    GridModule,
    FlexLayoutModule,
    MatLegacyCheckboxModule,
    FormsModule,
    MatLegacyTooltipModule,
    MatIconModule,
    CustomPipeModule,
    MatDividerModule,
    ConfirmButtonModule,
    MatLegacyButtonModule,
  ],
  exports: [CustomCardComponent],
})
export class TargetPagesCardModule {}
