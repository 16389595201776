import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule } from '@angular/forms';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacySelectModule } from '@angular/material/legacy-select';
import { TranslateModule } from '@ngx-translate/core';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { ProductImagePreviewComponent } from './product-image-preview.component';

@NgModule({
  declarations: [ProductImagePreviewComponent],
  imports: [
    CommonModule,
    FlexLayoutModule,
    ProgressSpinnerModule,
    MatLegacyButtonModule,
    TranslateModule,
    MatIconModule,
    MatLegacySelectModule,
    FormsModule,
  ],
  exports: [ProductImagePreviewComponent],
})
export class ProductImagePreviewModule {}
