import { ProductViewModel } from '@core/models/view-models/product-view-model';
import { Guid } from '@core/utils/Guid';

export function mockDataProductsGenerator(totalItems: number): ProductViewModel[] {
  let result: ProductViewModel[] = [];
  for (let index = 0; index < totalItems; index++) {
    const product = new ProductViewModel();
    product.id = getRandomNumber(totalItems * 17) * (index + 1);
    product.imageThumbUrl = `https://picsum.photos/id/${index}/200`;
    product.merchantId = Guid.newGuid();
    product.name = `Product TEST ${index + 1}`;
    product.partnerId = Guid.newGuid();
    product.price = getRandomNumber(totalItems);
    product.quantity = getRandomNumber(totalItems);
    product.enabled = getRandomNumber(totalItems) % 2 === 0 ? true : false;
    for (let index: number = 0; index < getRandomNumber(5) + 1; index++) {
      product.productCategories.push({
        id: getRandomNumber(product.id),
        name: `Category Test ${getRandomNumber(totalItems)}`,
      });
    }
    product.associatedCategories =
      product.productCategories.length > 0 ? product.productCategories.map((c) => c.name) : [];
    result.push(product);
  }
  return result;
}

function getRandomNumber(max: number) {
  return Math.floor(Math.random() * Math.floor(max));
}
