import { Component, Input, OnInit } from '@angular/core';
import { InformationPanelConfiguration } from './information-panel.configuration';

@Component({
  selector: 'app-information-panel',
  templateUrl: './information-panel.component.html',
  styleUrls: ['./information-panel.component.scss'],
})
export class InformationPanelComponent {
  @Input() configuration: InformationPanelConfiguration;
  constructor() {}
}
