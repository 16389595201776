export enum UserEngagementsActions {
  Delete,
  Pause,
  Resume,
  Download,
  CopyURL,
  Pay,
  Refresh,
  WeChatDownload,
  GenerateCodeSnippet,
  DownloadAssetPack,
}
