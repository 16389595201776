import { Injectable } from '@angular/core';
import { MatLegacyDialog, MatLegacyDialogRef } from '@angular/material/legacy-dialog';
import { RceProduct } from '@core/models/dtos/rce-product';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BasePopupConfiguration } from '../base-popup-configuration';
import { ProductEditPopupComponent } from './product-edit-popup.component';

@Injectable({
  providedIn: 'root',
})
export class ProductEditPopupService {
  constructor(private matDialog: MatLegacyDialog) {}

  open(params: BasePopupConfiguration<RceProduct>): Observable<any> {
    const matDialogConfigParams = Object.assign({}, params);
    delete matDialogConfigParams.componentConfiguration;

    let dialogRef: MatLegacyDialogRef<ProductEditPopupComponent>;
    dialogRef = this.matDialog.open(ProductEditPopupComponent, matDialogConfigParams);
    dialogRef.componentInstance.rceProduct = params.componentConfiguration;

    return dialogRef.afterClosed().pipe(map((_) => dialogRef.componentInstance.updatedProduct));
  }
}
