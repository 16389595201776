import { ImageDimensions } from '@core/models/image-dimensions';
import { StringFormatHelper } from '@core/utils/string-format-helper';
import { TranslateService } from '@ngx-translate/core';
import { BaseComponent } from '@shared/components/base-component/base-component';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { FileUploadConfiguration } from './file-upload-configuration';

abstract class BaseFileUpload extends BaseComponent {
  previewFile: boolean;
  isHovering: boolean;
  file: File;
  errors: Observable<string[]>;
  acceptedFileFormats: string[];
  maxFileSize: number;
  translationSectionName: string;
  minImageDimensions: ImageDimensions;

  constructor(configuration: FileUploadConfiguration, protected translateService: TranslateService) {
    super();
    this.acceptedFileFormats = configuration.acceptedFileFormats;
    this.maxFileSize = configuration.maxFileSize;
    this.translationSectionName = configuration.translationSectionName;
    this.minImageDimensions = configuration.minImageDimensions;
    this.file = null;
    this.errors = of([]);
    this.previewFile = false;
    this.isHovering = false;
  }

  abstract uploadFile(formData: FormData): void;
  abstract onInitialValidationFailed(): void;
  abstract onLoadingCompleted(): void;

  toggleHover(isHovering: boolean) {
    this.isHovering = isHovering;
  }

  handleFileUpload(files: FileList): void {
    this.clearErrors();
    const errors = this.getFileValidationErrorsWithParams(files);
    this.errors = this.getTranslatedErrorMessagesFromKeysAndParams(errors);
    const isValid = errors.length === 0;
    if (isValid) {
      this.file = files.item(0);
      const formData: FormData = new FormData();
      formData.append('file', this.file, StringFormatHelper.getUniqueFileName(this.file.name));
      this.uploadFile(formData);
    } else {
      this.onInitialValidationFailed();
    }
  }

  clearErrors(): void {
    this.errors = of([]);
    this.previewFile = false;
  }

  getTranslatedErrorMessagesFromKeysAndParams(
    errorKeysWithParams: { key: string; params?: object }[],
  ): Observable<string[]> {
    const errorKeys = errorKeysWithParams.map((x) => x.key);
    const errorParams = errorKeysWithParams.reduce((prev, cur) => {
      if (cur.params) {
        return { ...prev, ...cur.params };
      }

      return prev;
    }, {});

    if (errorKeys.length === 0) {
      return of([]);
    }

    return this.translateService.stream(errorKeys, errorParams).pipe(
      map((result: any) => {
        const temp: any = Object.entries(result).map(([_, value]) => value);
        return temp;
      }),
    );
  }

  getTranslatedErrorMessagesFromKeys(errorKeys: string[]): Observable<string[]> {
    return this.translateService.stream(errorKeys).pipe(
      map((result: any) => {
        const temp: any = Object.entries(result).map(([_, value]) => value);
        return temp;
      }),
    );
  }

  getFileValidationErrorsWithParams(files: FileList, dimensions?: ImageDimensions): { key: string; params?: any }[] {
    let errors: { key: string; params?: any }[] = [];
    if (files.length > 1) {
      errors = [{ key: 'PLEASE_UPLOAD_JUST_ONE_FILE' }];
      return errors;
    }

    const selectedFile = files.item(0);
    if (selectedFile.size > this.maxFileSize) {
      errors = [
        { key: `MAXIMUM_UPLOAD_SIZE_EXCEEDED`, params: { uploadSize: (this.maxFileSize / (1024 * 1024)).toFixed(2) } },
        { key: `YOUR_FILE_SIZE_IN_MB`, params: { fileSize: (selectedFile.size / (1024 * 1024)).toFixed(2) } },
      ];
      return errors;
    }

    if (!this.acceptedFileFormats.includes(selectedFile.type)) {
      errors = [
        { key: 'TYPE_IS_NOT_ALLOWED', params: { selectedFile: selectedFile.type } },
        { key: 'ALLOWED_TYPES', params: { allowedTypes: this.acceptedFileFormats.join() } },
      ];
      return errors;
    }

    if (
      dimensions &&
      this.minImageDimensions &&
      (this.minImageDimensions.width > dimensions.width || this.minImageDimensions.height > dimensions.height)
    ) {
      errors = [
        { key: 'DIMENSION_IS_NOT_ALLOWED', params: { filewidth: dimensions.width, fileheight: dimensions.height } },
        {
          key: 'ALLOWED_DIMENSIONS',
          params: { width: this.minImageDimensions.width, height: this.minImageDimensions.height },
        },
      ];
      return errors;
    }

    return errors;
  }

  showUploadErrors(validationKeys: string[]): void {
    this.errors = this.getTranslatedErrorMessagesFromKeys(this.getValidationErrors(validationKeys));
  }

  private getValidationErrors(validationKeys: string[]): string[] {
    const result: string[] = [];
    // tslint:disable-next-line:prefer-for-of
    for (let index = 0; index < validationKeys.length; index++) {
      const validationKey = validationKeys[index];
      const errorMessage: string = validationKey;
      if (errorMessage) {
        result.push(`${this.translationSectionName}.${errorMessage}`);
        continue;
      }
      result.push(`Error message was not found for validation key: ${validationKey}`);
    }
    return result;
  }
}

export default BaseFileUpload;
