import { Clipboard } from '@angular/cdk/clipboard';
import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatLegacyDialogRef, MAT_LEGACY_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar } from '@angular/material/legacy-snack-bar';
import {
  ButtonProperties,
  ButtonScriptAndStyle,
  DefaultButtonProperties,
  InstantButtonOptionType,
  ModalHeight,
  ModalWidth,
} from '@core/constants/InstantButton';
import { ActTypeEnum } from '@core/models/enums/act-type-enum';
import { WidthNarrow, WidthWide } from '@core/models/enums/width-enum';
import { InstantButtonDialogData } from '@core/models/instant-button-dialog-data';
import { InstantButtonOption } from '@core/models/instant-button-option';
import { BaseComponent } from '@shared/components/base-component/base-component';

@Component({
  selector: 'app-create-instant-buy-button-modal',
  templateUrl: './create-instant-buy-button-modal.component.html',
  styleUrls: ['./create-instant-buy-button-modal.component.scss'],
})
export class CreateInstantBuyButtonModalComponent extends BaseComponent implements OnInit {
  form: FormGroup;
  buttonText: string;
  buttonTextLength: number;
  buttonCodeSnippet: string;
  buttonJsAndStyle = ButtonScriptAndStyle;
  buttonProperties = ButtonProperties;
  width = DefaultButtonProperties.width.optionValue;
  height = DefaultButtonProperties.height.optionValue;
  corners = DefaultButtonProperties.corners.optionValue;
  colour = DefaultButtonProperties.colour.optionValue;
  title: string;
  learnMoreLink: string;

  constructor(
    private _snackBar: MatLegacySnackBar,
    public dialogRef: MatLegacyDialogRef<CreateInstantBuyButtonModalComponent>,
    @Inject(MAT_LEGACY_DIALOG_DATA) public data: InstantButtonDialogData,
    private clipboard: Clipboard,
  ) {
    super();
    this.buttonText = this.data.actType === ActTypeEnum.Buy ? 'BUY NOW' : 'ACT NOW';
    this.buttonTextLength = this.buttonText.length;
    this.title = this.data.title;
    this.learnMoreLink = this.data.learnMoreLink;
    this.width = DefaultButtonProperties.width.optionValue;
    this.generateButton();
  }

  get disableClick() {
    return this.data.actType === undefined;
  }

  selectOption(event: { option: InstantButtonOption; type: string }): void {
    switch (event.type) {
      case InstantButtonOptionType.WIDTH:
        this.width = event.option.optionValue;
        break;
      case InstantButtonOptionType.CORNERS:
        this.corners = event.option.optionValue;
        break;
      case InstantButtonOptionType.COLOUR:
        this.colour = event.option.optionValue;
        break;
      case InstantButtonOptionType.HEIGHT:
        this.height = event.option.optionValue;
        break;
    }

    this.generateButton();
  }

  ngOnInit(): void {
    this.generateButton();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    const windowSize = event.target.innerWidth;
    const isLargeScreen = windowSize > 1280;
    const isMediumScreen = windowSize < 1280 && windowSize > 680;
    const newWidth = isLargeScreen ? ModalWidth.lg : isMediumScreen ? ModalWidth.md : ModalWidth.sm;

    this.dialogRef.updateSize(newWidth, ModalHeight);
  }

  generateButton() {
    const buttonWidth = ![WidthWide.optionValue, WidthNarrow.optionValue].includes(this.width)
      ? `custom-width" style="width:${this.width};`
      : this.width;
    const actTypeStr =
      (this.data.actType && ActTypeEnum[this.data.actType].toLowerCase()) ||
      ActTypeEnum[ActTypeEnum.Buy].toLocaleLowerCase();

    let buttonProps = '';

    if (this.data.actType !== undefined) {
      switch (this.data.actType) {
        case ActTypeEnum.Act:
          buttonProps = `engagement_id="${this.data.engagementId}"`;
          break;
        case ActTypeEnum.Buy:
          if (this.data.isProductExternal) {
            buttonProps = `merchant="${this.data.merchantId}" external_id="${this.data.productId}"`;
          } else {
            buttonProps = `merchant="${this.data.merchantId}" product_id="${this.data.productId}" category_id="${this.data.categoryId}"`;
          }
          break;
        default:
          break;
      }
    } else {
      buttonProps = `merchant="${
        this.data.merchantId
      }" external_id="${CreateInstantBuyButtonModalComponent.getPlatformCode(this.data?.platform)}"`;
    }

    buttonProps += ` type="${actTypeStr}"`;

    this.buttonCodeSnippet = this.getButtonCodeSnippet(buttonProps, buttonWidth);
  }

  getButtonCodeSnippet(buttonProps: string, buttonWidth: string) {
    return `
    <rezolve-instant-checkout-button ${buttonProps} class="instant-button ${this.colour} ${this.height} ${this.corners} ${buttonWidth}"></rezolve-instant-checkout-button>
    <rezolve-instant-checkout-modal-view></rezolve-instant-checkout-modal-view>`;
  }

  copyJsCode() {
    this.clipboard.copy(this.buttonJsAndStyle);
    this._snackBar.open('Header level code copied to clipboard', '', { duration: 3000 });
  }

  copyHtmlCode(snippet: string) {
    this.clipboard.copy(snippet);
    this._snackBar.open('Body level code copied to clipboard', '', { duration: 3000 });
  }

  private static getPlatformCode(platform: string) {
    switch (platform) {
      case 'BigcommerceApi':
      case 'Shopify':
        return '{{product.id}}';
      case 'Magento1212':
        return '<?php echo $product->getSku(); ?>';
      case 'Woocommerce':
        return '<?php echo $product->get_sku(); ?>';
    }
  }
}
