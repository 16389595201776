import { Component, Input } from '@angular/core';
import { MenuWizardStep } from '../menu-wizard.model';

@Component({
  selector: 'app-menu-wizard-help-support',
  templateUrl: './menu-wizard-help-support.component.html',
  styleUrls: ['./menu-wizard-help-support.component.scss'],
})
export class MenuWizardHelpSupportComponent {
  @Input()
  currentStep: MenuWizardStep | undefined;
}
