import { Component, Input, OnInit } from '@angular/core';
import { MatLegacyDialogRef } from '@angular/material/legacy-dialog';
import { BasePageBuilderConfiguration } from '../base-page-builder-configuration';

@Component({
  selector: 'app-text-field-customisation-popup',
  templateUrl: './text-field-customisation-popup.component.html',
  styleUrls: ['./text-field-customisation-popup.component.scss'],
})
export class TextFieldCustomisationPopupComponent implements OnInit {
  @Input() configuration: BasePageBuilderConfiguration;

  constructor(public dialogRef: MatLegacyDialogRef<TextFieldCustomisationPopupComponent>) {}

  ngOnInit(): void {}

  onCancel(): void {
    this.dialogRef.close();
  }
}
