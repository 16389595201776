export enum EngagementStatusEnum {
  NA = 0,
  Created = 1,
  AssetReady = 2,
  AssetsReclaimed = 3,
  AssetsFailed = 4,
  AwaitingLicence = 5,
  LicenceReady = 6,
  LicenceFailed = 7,
  Marking = 8,
  MarkingFailed = 9,
  Ready = 10,
}
