import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'maskText',
})
export class MaskTextPipe implements PipeTransform {
  transform(value: any, shouldMask: boolean, maxLength = 20, maskCharacter = '•'): string | number {
    if (shouldMask && value && (typeof value === 'string' || typeof value === 'number')) {
      const numberOfCharacters = `${value}`.length;
      return maskCharacter.repeat(maxLength > numberOfCharacters ? numberOfCharacters : maxLength);
    }

    return value;
  }
}
