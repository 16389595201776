import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { distinctUntilChanged, filter, map, shareReplay, switchMap, tap } from 'rxjs/operators';
import { CreationStep } from './creation-container.settings';

@Injectable({
  providedIn: 'root',
})
export class GenericCreationContainerService {
  private initialSteps: CreationStep[];
  private stepInfo: { [id: number]: CreationStep };
  private currentStep$ = new BehaviorSubject(0);
  private nextStepRequest$ = new Subject();
  public isProcessing$ = new BehaviorSubject(false);

  private outOfBoundPrevStepFunc: () => void;

  constructor() {}

  initSteps(steps: CreationStep[], outOfBoundPrevStepFunc: () => void) {
    this.initialSteps = [...steps, { step: steps.length }];
    this.outOfBoundPrevStepFunc = outOfBoundPrevStepFunc;
    this.stepInfo = this.initialSteps.reduce((prev, cur, currentIndex) => {
      prev[currentIndex] = cur;
      return prev;
    }, {});
  }

  reset() {
    this.currentStep$.next(0);
  }

  getCurrentStep(): Observable<number> {
    return this.currentStep$.pipe(
      filter((currentStep) => currentStep <= this.initialSteps.length - 2),
      shareReplay(1),
      distinctUntilChanged(),
    );
  }

  getCurrentStepInfo(): Observable<CreationStep> {
    return this.getCurrentStep().pipe(map((currentStep) => this.stepInfo[currentStep]));
  }

  getDataPassedFromPrevStep() {
    if (this.currentStep$.value - 1 >= 0) {
      return this.stepInfo[this.currentStep$.value - 1]?.data;
    }
  }

  getSumUpStep(): Observable<CreationStep> {
    return this.currentStep$.pipe(
      filter((currentStep) => currentStep === this.initialSteps.length - 1),
      map((currentStep) => this.stepInfo[currentStep - 1]),
    );
  }

  goToPreviousStep(): void {
    if (this.currentStep$.value > 0) {
      this.currentStep$.next(this.currentStep$.value - 1);
    } else if (this.outOfBoundPrevStepFunc) {
      this.outOfBoundPrevStepFunc();
    }
  }

  getNextStepRequest(
    onNext$: (prevStepInfo: CreationStep) => Observable<{ canGoNext: boolean; data?: any }>,
    step: number = null,
  ): Observable<unknown> {
    return this.nextStepRequest$.pipe(
      filter((_) => (step ? this.currentStep$.value === step : true)),
      switchMap(() => {
        // debugger;
        console.log('getNextStepRequest', this.stepInfo[this.currentStep$.value - 1]);
        return onNext$(this.stepInfo[this.currentStep$.value - 1]).pipe(
          tap(({ canGoNext, data }) => {
            if (canGoNext) {
              this.goToNextStep((nextStep) => {
                this.stepInfo[nextStep].data = data;
                console.log('step info', this.stepInfo, this.initialSteps, nextStep);
              });
            }
          }),
        );
      }),
    );
  }

  nextStepRequest(): void {
    this.nextStepRequest$.next();
  }

  closeDialog(): void {
    this.outOfBoundPrevStepFunc();
  }

  private goToNextStep(sideEffect: (step: number) => void): void {
    const length = Object.keys(this.stepInfo).length;
    if (this.currentStep$.value < length - 1) {
      sideEffect(this.currentStep$.value);
      this.currentStep$.next(this.currentStep$.value + 1);
    }
  }

  resetCurrentStep(): void {
    this.currentStep$.next(0);
  }
}
