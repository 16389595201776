import { ActiveTime } from '@core/models/enums/active-time.enum';
import * as moment from 'moment';
import { tz } from 'moment-timezone';

class DurationData {
  private _startDate: Date;
  private _endDate: Date;
  timezoneOffset: number;
  _timezone: string;
  durationInDaysTotal: number;
  durationInDays: number;
  durationInWeeks: number;
  durationInYears: number;
  startHour: number;
  endHour: number;
  startMinute: number;
  endMinute: number;
  activeTime: ActiveTime;
  targetUsers: number[];
  targetUsersLabels: string[];

  constructor() {
    this._startDate = moment().startOf('day').toDate();
    this._endDate = moment().endOf('day').toDate();

    this.timezone = tz.guess();
    this.startHour = 540;
    this.endHour = 1020;
    this.startMinute = 0;
    this.endMinute = 59;
    this.activeTime = ActiveTime.ALL_DAY;
    this.updateDuration();
    this.targetUsers = [];
    this.targetUsersLabels = [];
  }

  get endDate() {
    return this._endDate;
  }

  set endDate(newDate) {
    this._endDate = moment(newDate).utcOffset(this.timezoneOffset).endOf('day').toDate();
    this.updateDuration();
  }

  get startDate() {
    return this._startDate;
  }

  set startDate(newDate) {
    this._startDate = moment(newDate).utcOffset(this.timezoneOffset).startOf('day').toDate();
    this.updateDuration();
  }

  get timezone() {
    return this._timezone;
  }

  set timezone(timezone: string) {
    this._timezone = timezone;
    this.timezoneOffset = moment.tz(timezone).utcOffset();
  }

  updateDuration() {
    this.durationInDaysTotal = moment(this._endDate).diff(this._startDate, 'days') + 1;
    this.durationInDays = this.durationInDaysTotal % 7;
    this.durationInWeeks = Math.floor(this.durationInDaysTotal / 7);
    this.durationInYears = Math.floor(this.durationInDaysTotal / 365 );
  }
}

export default DurationData;
