import { getCurrencySymbol } from '@angular/common';
import { ViewChild } from '@angular/core';
import { Component, Input, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ProductCategory } from '@core/models/dtos/product-category';
import { RceProduct } from '@core/models/dtos/rce-product';
import { RceProductStatusEnum } from '@core/models/enums/rce-product-status-enum';
import { CategoryLink } from '@core/models/product/CreateProductRequest';
import { AppSettingService } from '@core/services/app-setting.service';
import { environment } from '@env/environment';
import { BaseComponent } from '@shared/components/base-component/base-component';
import { GenericCreationContainerService } from '@shared/components/generic-creation-container/generic-creation-container.service';
import { TreeFlatNode } from '@shared/components/tree-checklist/tree-checklist.component';
import { of } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-product-edit',
  templateUrl: './product-edit.component.html',
  styleUrls: ['./product-edit.component.scss'],
})
export class ProductEditComponent extends BaseComponent implements OnInit {
  tinyApiKey: string;
  categories?: TreeFlatNode[] = [];
  currencySymbol: string;
  private _product: RceProduct;
  @Input()
  set product(val: RceProduct) {
    this._product = val;
  }
  get product() {
    return this._product;
  }

  @ViewChild('form') productEditForm: NgForm;

  constructor(
    readonly genericCreationContainerService: GenericCreationContainerService,
    readonly appSettingService: AppSettingService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.currencySymbol = getCurrencySymbol(this.appSettingService.paymentCurrency, 'narrow');
    this.tinyApiKey = environment.tinyApiKey;
    const combined: (ProductCategory & CategoryLink)[] = this._product.productCategoryLinks.map<
      ProductCategory & CategoryLink
    >((categoryLink) => ({
      categoryId: categoryLink.categoryId,
      position: categoryLink.position,
      name: this._product.productCategories.find((x) => x.id == +categoryLink.categoryId)?.name,
      id: +categoryLink.categoryId,
    }));
    this.categories = this.getParentSelectedNodes(combined);

    this.genericCreationContainerService
      .getNextStepRequest(() => {
        if (this.productEditForm.valid) {
          return of({
            canGoNext: true,
            data: {
              description: this.product.description,
              name: this.product.name,
              price: this.product.price,
              quantity: this.product.quantity,
              shortDescription: this.product.shortDescription,
              sku: this.product.sku,
              weight: this.product.weight,
              categoryLinks: this.product.productCategoryLinks || [],
              status: this.product.enabled ? RceProductStatusEnum.Enabled : RceProductStatusEnum.Disabled,
              mediaEntries: this.product.mediaGalleryEntries,
            },
          });
        }
        return of({ canGoNext: false });
      })
      .pipe(takeUntil(this.destroy$))
      .subscribe();
  }

  getParentSelectedNodes(selectedCategories: (ProductCategory & CategoryLink)[]): TreeFlatNode[] {
    return selectedCategories.map<any>((category) => ({
      id: +category.categoryId,
      item: category.name,
      level: category.position - 1,
    }));
  }

  onCategoriesChange(event: TreeFlatNode[]) {
    this.categories = event;
    this._product.productCategoryLinks =
      event.map<CategoryLink>((node) => ({
        categoryId: node.id.toString(),
        position: node.level + 1,
      })) || [];
    this._product.productCategories =
      event.map<ProductCategory>((node) => ({
        id: node.id,
        name: node.item,
      })) || [];
  }

  onSubmit(event) {}
}
