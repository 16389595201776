import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Endpoints } from '@core/constants/Endpoints';
import { TargetUsage } from '@core/models/user-tier-usage';
import { Observable } from 'rxjs';
import { retry } from 'rxjs/operators';
import { BaseAPIServiceService } from './http/base-apiservice.service';

@Injectable({
  providedIn: 'root',
})
export class UserTierService extends BaseAPIServiceService {
  constructor(protected http: HttpClient) {
    super(http);
  }

  getEngagementsUsageForUser(): Observable<TargetUsage[]> {
    return this.http.get<TargetUsage[]>(Endpoints.GET_ENGAGEMENTS_USAGE).pipe(retry(3));
  }
  getActsUsageForUser(): Observable<TargetUsage[]> {
    return this.http.get<TargetUsage[]>(Endpoints.GET_ACTS_USAGE).pipe(retry(3));
  }
}
