// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  height: auto;
}

.link {
  padding: 0 1em 1em 1em;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-decoration: none;
}
.link span {
  font-size: 17px;
  font-weight: 500;
  line-height: 1.4;
  color: #adb9ca;
  padding-right: 10px;
}

.rezolve-instant-salesware {
  background-image: url("/assets/images/rezolve-instant-salesware.png");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  width: 100px;
  height: 33px;
}

@media screen and (max-width: 625px) {
  .link {
    padding: 10px;
  }
  .link span {
    font-size: 15px;
  }
}
@media screen and (max-width: 455px) {
  .link {
    flex-direction: column;
  }
  .link span {
    padding-right: 0;
    padding-bottom: 10px;
  }
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/powered-by-rezolve/powered-by-rezolve.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,WAAA;EACA,YAAA;AACF;;AAEA;EACE,sBAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,mBAAA;EACA,qBAAA;AACF;AACE;EACE,eAAA;EACA,gBAAA;EACA,gBAAA;EACA,cAAA;EACA,mBAAA;AACJ;;AAGA;EACE,qEAAA;EACA,2BAAA;EACA,wBAAA;EACA,4BAAA;EACA,YAAA;EACA,YAAA;AAAF;;AAGA;EACE;IACE,aAAA;EAAF;EAEE;IACE,eAAA;EAAJ;AACF;AAIA;EACE;IACE,sBAAA;EAFF;EAIE;IACE,gBAAA;IACA,oBAAA;EAFJ;AACF","sourcesContent":[".container {\n  display: flex;\n  flex-direction: row;\n  justify-content: center;\n  width: 100%;\n  height: auto;\n}\n\n.link {\n  padding: 0 1em 1em 1em;\n  display: flex;\n  flex-direction: row;\n  justify-content: center;\n  align-items: center;\n  text-decoration: none;\n\n  span {\n    font-size: 17px;\n    font-weight: 500;\n    line-height: 1.4;\n    color: #adb9ca;\n    padding-right: 10px;\n  }\n}\n\n.rezolve-instant-salesware {\n  background-image: url('/assets/images/rezolve-instant-salesware.png');\n  background-position: center;\n  background-size: contain;\n  background-repeat: no-repeat;\n  width: 100px;\n  height: 33px;\n}\n\n@media screen and (max-width: 625px) {\n  .link {\n    padding: 10px;\n\n    span {\n      font-size: 15px;\n    }\n  }\n}\n\n@media screen and (max-width: 455px) {\n  .link {\n    flex-direction: column;\n\n    span {\n      padding-right: 0;\n      padding-bottom: 10px;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
