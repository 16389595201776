import { Component, OnInit } from '@angular/core';
import {InstantWebTriggerTypeCard, TriggerTypeCards} from '@core/constants/TriggerTypes';
import { CardInfo } from '@core/models/card-info';
import { UserTierService } from '@core/services/user-tier.service';
import { BaseComponent } from '@shared/components/base-component/base-component';
import { EngagementCreationService } from '../engagement-creation/engagement-creation.service';
import {FeatureManagementEnum} from "@core/constants/FeatureManagement";
import {AppSettingService} from "@core/services/app-setting.service";

@Component({
  selector: 'app-triggers',
  templateUrl: './triggers.component.html',
  styleUrls: ['./triggers.component.scss'],
})
export class TriggersComponent extends BaseComponent implements OnInit {
  triggerTypeCards: CardInfo[] = TriggerTypeCards;
  constructor(
    private engagementCreationService: EngagementCreationService,
    private appSettingService: AppSettingService,
    private readonly tierService: UserTierService,
  ) {
    super();
    if (
      appSettingService.isFeatureEnabled(FeatureManagementEnum.IBFW) &&
      !this.triggerTypeCards.includes(InstantWebTriggerTypeCard)
    ) {
      this.triggerTypeCards.push(InstantWebTriggerTypeCard);
    }
  }

  ngOnInit(): void {
    this.engagementCreationService.clearEngagement();
    this.tierService.getEngagementsUsageForUser().subscribe((tierUsage) => {
      this.triggerTypeCards.forEach((triggerTypeCard) => {
        const currentTier = tierUsage.find((x) => x.type === triggerTypeCard.type);
        triggerTypeCard.limit = currentTier?.limit;
        triggerTypeCard.hasPassedLimit = currentTier?.hasPassedLimit;
      });
    });
  }

  async selectTrigger(card: CardInfo) {
    await this.engagementCreationService.setEngagement(card.type);
  }
}
