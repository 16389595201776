import { Component, OnInit } from '@angular/core';
import { InformationPanelConfiguration } from '@shared/components/information-panel/information-panel.configuration';

@Component({
  selector: 'product-setup-info',
  templateUrl: './product-setup-info.component.html',
  styleUrls: ['./product-setup-info.component.scss'],
})
export class ProductSetupInfoComponent implements OnInit {
  infoPanelConfig: InformationPanelConfiguration;
  constructor() {
    this.infoPanelConfig = {
      title: 'PRODUCT_SETUP_INFO_TITLE',
      content: [
        'PRODUCT_SETUP_INFO_CONTENT_LINE_1',
        'PRODUCT_SETUP_INFO_CONTENT_LINE_2',
        '',
        'PRODUCT_SETUP_INFO_CONTENT_LINE_3',
        '',
        '',
      ],
      navigationButton: {
        name: 'PRODUCT_SETUP_INFO_BUTTON_LABEL',
        link: 'PRODUCT_SETUP_INFO_BUTTON_LINK',
      },
    };
  }

  ngOnInit(): void {}
}
