export enum OnboardingProgressEnum {
  Idle,
  Pending,
  Failed,
  Success,
}
export interface OnboardingProgress {
  state: OnboardingProgressEnum;
  errorMessage?: string;
  data?: any;
}
