import { Component, Input, OnChanges, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-button-preview',
  templateUrl: './button-preview.component.html',
  styleUrls: ['./button-preview.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
})
export class ButtonPreviewComponent implements OnChanges {
  @Input() codeSnippet: string;
  @Input() buttonTextLength: number | undefined;
  @Input() disableClick: boolean;

  buttonAlign = 'button-align-center';
  constructor() {}

  ngOnChanges() {
    this.buttonAlign = this.buttonTextLength > 45 ? 'button-align-left' : 'button-align-center';
  }
}
