import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatLegacyFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule } from '@angular/material/legacy-input';
import { CustomDirectivesModule } from '@core/directives/custom-directives.module';
import { TranslateModule } from '@ngx-translate/core';
import { ChooseCategoryModule } from '@shared/components/choose-category/choose-category.module';
import { GenericCreationContainerModule } from '@shared/components/generic-creation-container/generic-creation-container.module';
import { EditorModule } from '@tinymce/tinymce-angular';
import { InputSwitchModule } from 'primeng/inputswitch';
import { ProductCreationComponent } from '../product-creation/product-creation.component';

@NgModule({
  declarations: [ProductCreationComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatLegacyFormFieldModule,
    MatLegacyInputModule,
    EditorModule,
    ChooseCategoryModule,
    GenericCreationContainerModule,
    CustomDirectivesModule,
    InputSwitchModule,
    TranslateModule.forChild(),
  ],
  exports: [ProductCreationComponent],
  providers: [],
})
export class ProductCreationModule {}
