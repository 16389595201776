import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { RegularExpressions } from '@core/constants/RegularExpressions';
import { Country } from '@core/models/country';
import { CountryApiService } from '@core/services/country-api.service';
import { LoaderService } from '@core/services/loader.service';
import { MerchantAPIService } from '@core/services/merchant-api.service';
import { environment } from '@env/environment';
import { TranslateService } from '@ngx-translate/core';
import { BaseComponent } from '@shared/components/base-component/base-component';
import { PopupService } from '@shared/components/popup/popup.service';
import { forkJoin } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-your-details',
  templateUrl: './your-details.component.html',
  styleUrls: ['./your-details.component.scss'],
  host: { class: 'settings-component' },
})
export class YourDetailsComponent extends BaseComponent implements OnInit {
  form: FormGroup;
  countries: Country[] = [];

  cardNameElementId = 'settings-card-name-field';

  constructor(
    public loaderService: LoaderService,
    private merchantService: MerchantAPIService,
    private popupService: PopupService,
    private translateService: TranslateService,
    private countryApiService: CountryApiService,
  ) {
    super();
    this.form = new FormGroup({
      firstName: new FormControl('', [
        Validators.required,
        Validators.pattern(RegularExpressions.NO_EMPTY_SPACE_AT_START),
      ]),
      lastName: new FormControl('', [
        Validators.required,
        Validators.pattern(RegularExpressions.NO_EMPTY_SPACE_AT_START),
      ]),
      businessName: new FormControl('', [
        Validators.required,
        Validators.minLength(0),
        Validators.maxLength(255),
        Validators.pattern(RegularExpressions.NO_EMPTY_SPACE_AT_START),
      ]),
      phone: new FormControl('', [Validators.required, Validators.pattern('^[0-9]*$')]),
      cardName: new FormControl('', [
        Validators.required,
        Validators.pattern(RegularExpressions.NO_EMPTY_SPACE_AT_START),
      ]),
      vatNumber: new FormControl(''),
      termsAndConditionsUrl: new FormControl('', [Validators.required, Validators.pattern(RegularExpressions.URL)]),
      privacyPolicyUrl: new FormControl('', [Validators.required, Validators.pattern(RegularExpressions.URL)]),
      companyInformationUrl: new FormControl('', [Validators.required, Validators.pattern(RegularExpressions.URL)]),
      isTermsAndConditionsAccepted: new FormControl('', [Validators.requiredTrue]),
      isConsentGivenForStoringDetails: new FormControl('', [Validators.requiredTrue]),
      dialCode: new FormControl('', [Validators.required]),
      paymentCardBillingAddress: new FormGroup({
        street1: new FormControl('', [
          Validators.required,
          Validators.pattern(RegularExpressions.NO_EMPTY_SPACE_AT_START),
        ]),
        street2: new FormControl(''),
        city: new FormControl('', [
          Validators.required,
          Validators.pattern(RegularExpressions.NO_EMPTY_SPACE_AT_START),
        ]),
        state: new FormControl('', [
          Validators.required,
          Validators.pattern(RegularExpressions.NO_EMPTY_SPACE_AT_START),
        ]),
        zip: new FormControl('', [Validators.required, Validators.pattern(RegularExpressions.NO_EMPTY_SPACE_AT_START)]),
        countryCode: new FormControl('', [Validators.required, Validators.pattern('^[A-Z]{2}$')]),
      }),
    });

    this.translateService.onLangChange.pipe(takeUntil(this.destroy$)).subscribe((event) => {
      this.countryApiService.getCountries().subscribe((countries) => {
        this.countries = countries;
      });
    });
  }

  get countryCode(): FormControl {
    return this.form.get('paymentCardBillingAddress').get('countryCode') as FormControl;
  }

  get dialCode(): FormControl {
    return this.form.get('dialCode') as FormControl;
  }

  get termsAndConditionsUrl() {
    return this.form.get('termsAndConditionsUrl');
  }

  get privacyPolicyUrl() {
    return this.form.get('privacyPolicyUrl');
  }

  get companyInformationUrl() {
    return this.form.get('companyInformationUrl');
  }

  get firstName() {
    return this.form.get('firstName');
  }

  get lastName() {
    return this.form.get('lastName');
  }

  get cardName() {
    return this.form.get('cardName');
  }

  ngOnInit(): void {
    if (environment.hiddenElements.includes(this.cardNameElementId)) {
      this.firstName.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((firstName) => {
        this.cardName.setValue(`${firstName} ${this.lastName.value}`);
      });
      this.lastName.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((lastName) => {
        this.cardName.setValue(`${this.firstName.value} ${lastName}`);
      });
    }

    forkJoin([this.merchantService.getMerchantInfo(), this.countryApiService.getCountries()])
      .pipe(takeUntil(this.destroy$))
      .subscribe(([merchant, countries]) => {
        if (merchant && merchant && merchant.paymentCustomerInfo) {
          this.form.patchValue(merchant.paymentCustomerInfo);
        }

        this.countries = countries;
      });
  }

  onSubmit() {
    if (this.form.invalid) {
      return;
    }
    this.loaderService.show();
    this.merchantService
      .updateCustomerPaymentInfo(this.form.value)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (_) => {
          this.showStatusPopup(true, 'YOUR_DETAILS_SUCCESSFULLY_UPDATED');
        },
        (error: HttpErrorResponse) => {
          console.error('Update customer payment info error => ', error);
          this.showStatusPopup(false, 'FAILED_TO_UPDATE_YOUR_DETAILS', error && error.error && error.error.message);
        },
        () => this.loaderService.hide(),
      );
  }

  private showStatusPopup(isSuccess: boolean, message: string, failureReason?: string): void {
    this.translateService
      .get(message)
      .pipe(
        switchMap((translated: string) => {
          return this.popupService.successStatusPopup({
            componentConfiguration: { isSuccess, message: translated, failureReason },
            height: 'auto',
            width: 'auto',
            maxWidth: '430px',
            backdropClass: 'blurred-backdrop',
          });
        }),
        takeUntil(this.destroy$),
      )
      .subscribe(() => {});
  }
}
