import { FileFormats } from '@core/constants/FileFormats';
import { StringFormatHelper } from '@core/utils/string-format-helper';

abstract class BaseFilePreview {
  acceptedFileFormats: string;
  name: string;
  type: string;
  fileSizeMB: string;

  constructor(acceptedFileFormats: string[]) {
    this.acceptedFileFormats = acceptedFileFormats.join();
  }

  initialize(file: File) {
    this.type = this.setFileType(file.type);
    this.name = StringFormatHelper.getNameWithoutExtension(file.name);
    this.fileSizeMB = parseFloat(`${file.size / 1000000}`).toFixed(2);
  }

  abstract changeFile(files: FileList): void;
  abstract loadFile(): void;

  setFileType(type: string): string {
    switch (type.toLowerCase()) {
      case FileFormats.JPEG:
        return 'JPEG';
      case FileFormats.JPG:
        return 'JPG';
      case FileFormats.BMP:
        return 'BMP';
      case FileFormats.PNG:
        return 'PNG';
      case FileFormats.TIFF:
      case FileFormats.TIF:
        return 'TIF';
      case FileFormats.MP3:
        return 'MP3';
      case FileFormats.OGG:
        return 'OGG';
      case FileFormats.WAV:
        return 'WAV';
      case FileFormats.XWAV:
        return 'WAV';
      default:
        return 'Unknown type';
    }
  }
}

export default BaseFilePreview;
