import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { DateFormats, getDateFormat } from '@core/constants/DateFormats';
import { TranslateService } from '@ngx-translate/core';
import { Calendar } from 'primeng/calendar';
import { DateTimePickerConfig } from './date-time-picker.configuration';

const DEFAULT_CONFIG: DateTimePickerConfig = {
  showTime: true,
  hourFormat: '24',
  inline: false,
  showButtonBar: true,
  showIcon: true,
  minDate: new Date(),
};

@Component({
  selector: 'date-time-picker',
  templateUrl: './date-time-picker.component.html',
  styleUrls: ['./date-time-picker.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DateTimePickerComponent implements OnChanges {
  @Input() config: DateTimePickerConfig = DEFAULT_CONFIG;
  @Input() value: Date;
  dateFormat = DateFormats.EN_DE_CALENDAR_DATE_FORMAT;

  @Output() changeEvent: EventEmitter<any> = new EventEmitter();

  @ViewChild(Calendar) calendar: Calendar;

  constructor(private translateService: TranslateService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (this.value && typeof this.value === 'string') {
      this.value = new Date(this.value);
    }
    this.dateFormat = getDateFormat(this.translateService);
  }

  onModelChange(event: Date): void {
    this.changeEvent.emit(event);
  }

  clearValue(): void {
    this.value = null;
    this.changeEvent.emit(this.value);
  }

  clickCancel(): void {
    this.clearValue();
    this.calendar.toggle();
  }

  clickToday(): void {
    this.value = new Date();
    this.changeEvent.emit(this.value);
  }

  clickOk(): void {
    this.calendar.toggle();
  }
}
