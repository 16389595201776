import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { FileFormats } from '@core/constants/FileFormats';
import { ImageDimensions } from '@core/models/image-dimensions';
import { LoaderService } from '@core/services/loader.service';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import BaseFilePreview from '../shared/base-file-preview';

const ACCEPTED_FILE_FORMATS: string[] = [
  FileFormats.JPEG,
  FileFormats.TIF,
  FileFormats.TIFF,
  FileFormats.BMP,
  FileFormats.JPG,
  FileFormats.PNG,
];

@Component({
  selector: 'app-image-file-preview',
  templateUrl: './image-file-preview.component.html',
  styleUrls: ['./image-file-preview.component.scss'],
})
export class ImageFilePreviewComponent extends BaseFilePreview implements OnChanges {
  @Input() file: File;
  @Output() loadingCompleted: EventEmitter<boolean> = new EventEmitter();
  @Output() fileChanged: EventEmitter<FileList> = new EventEmitter();

  imageUrl: string;
  dimensions: ImageDimensions;
  loading$: Observable<boolean>;

  constructor(public loaderService: LoaderService, protected translateService: TranslateService) {
    super(ACCEPTED_FILE_FORMATS);
    this.loading$ = this.loaderService.isLoading();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.file && this.file) {
      this.initialize(this.file);
      this.loadFile();
    }
  }

  changeFile(files: FileList) {
    this.fileChanged.emit(files);
  }

  loadFile(): void {
    this.loaderService.show();
    const reader = new FileReader();
    reader.onloadend = () => {
      this.imageUrl = reader.result as string;
      const image = new Image();
      image.src = this.imageUrl;
      if ([FileFormats.TIF, FileFormats.TIFF].includes(this.file.type.toLowerCase())) {
        this.dimensions = { width: 200, height: 200 };
          this.emitLoadingCompleted();
      } else {
        image.onload = () => {
          this.dimensions = { width: image.width, height: image.height };
          this.emitLoadingCompleted();
        };
      }
    };

    reader.readAsDataURL(this.file);
  }

  private emitLoadingCompleted(): void {
    this.loaderService.hide();
    this.loadingCompleted.emit(true);
  }
}
