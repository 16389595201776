import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { FileFormats } from '@core/constants/FileFormats';
import { LoaderService } from '@core/services/loader.service';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import BaseFilePreview from '../shared/base-file-preview';

const ACCEPTED_FILE_FORMATS: string[] = [FileFormats.MP3, FileFormats.WAV, FileFormats.XWAV];

@Component({
  selector: 'app-audio-file-preview',
  templateUrl: './audio-file-preview.component.html',
  styleUrls: ['./audio-file-preview.component.scss'],
})
export class AudioFilePreviewComponent extends BaseFilePreview implements OnChanges {
  @Input() file: File;
  @Output() loadingCompleted: EventEmitter<boolean> = new EventEmitter();
  @Output() fileChanged: EventEmitter<FileList> = new EventEmitter();
  loading$: Observable<boolean>;
  audioSrc: SafeUrl;
  duration: string;

  constructor(
    public loaderService: LoaderService,
    protected translateService: TranslateService,
    private sanitizer: DomSanitizer,
  ) {
    super(ACCEPTED_FILE_FORMATS);
    this.loading$ = this.loaderService.isLoading();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.file && this.file) {
      this.initialize(this.file);
      this.loadFile();
    }
  }

  changeFile(files: FileList) {
    this.fileChanged.emit(files);
  }

  loadFile(): void {
    this.loaderService.show();
    const reader = new FileReader();
    reader.onloadend = () => {
      const audio = new Audio();
      audio.src = reader.result as string;
      audio.addEventListener('loadedmetadata', () => {
        this.audioSrc = this.sanitizer.bypassSecurityTrustUrl(audio.src);
        this.emitLoadingCompleted();
        const duration = audio.duration;
        const minutes = Math.floor(duration / 60);
        const seconds = Math.floor(duration % 60);
        this.duration = `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
        console.log(this.duration);
      });
    };
    reader.readAsDataURL(this.file);
  }

  private emitLoadingCompleted(): void {
    this.loaderService.hide();
    this.loadingCompleted.emit(true);
  }
}
