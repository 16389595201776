import { Component, Input, OnInit } from '@angular/core';
import { MatLegacyDialogRef } from '@angular/material/legacy-dialog';
import { BasePageBuilderConfiguration } from '../base-page-builder-configuration';

@Component({
  selector: 'app-message-entry-field-customisation-popup',
  templateUrl: './message-entry-field-customisation-popup.component.html',
  styleUrls: ['./message-entry-field-customisation-popup.component.scss'],
})
export class MessageEntryFieldCustomisationPopupComponent implements OnInit {
  @Input() configuration: BasePageBuilderConfiguration;

  constructor(public dialogRef: MatLegacyDialogRef<MessageEntryFieldCustomisationPopupComponent>) {}

  ngOnInit(): void {}

  onCancel(): void {
    this.dialogRef.close();
  }
}
