import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { GenericCreationContainerService } from '@shared/components/generic-creation-container/generic-creation-container.service';
import { ProductCreationPopupComponent } from './product-creation-popup.component';
import { ProductCreationPopupService } from './product-creation-popup.service';
import { ProductCreationModule } from './product-creation/product-creation.module';
import { ProductMediaUploadModule } from './product-media-upload/product-media-upload.module';

@NgModule({
  declarations: [
    ProductCreationPopupComponent
  ],
  imports: [
    CommonModule,
    ProductMediaUploadModule,
    ProductCreationModule,
    TranslateModule.forChild()
  ],
  exports: [],
  providers: [ProductCreationPopupService, GenericCreationContainerService]
})
export class ProductCreationPopupModule {}
