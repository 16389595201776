import { Component, Input, OnInit } from '@angular/core';
import { StyleConfig } from '@shared/components/base-overlay-card-message/models/custom-css.model';

@Component({
  selector: 'app-base-overlay-card-message',
  templateUrl: './base-overlay-card-message.component.html',
  styleUrls: ['./base-overlay-card-message.component.scss'],
})
export class BaseOverlayCardMessageComponent implements OnInit {
  @Input() text: string;
  @Input() quota: number;
  @Input() showQuota: boolean;
  @Input() styleConfig: StyleConfig;

  quotaFullMessage: string;

  constructor() {}

  ngOnInit(): void {
    this.quotaFullMessage = 'QUOTA';
  }
}
