import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { BaseComponent } from '@shared/components/base-component/base-component';

@Component({
  selector: 'app-table-dropdown-call-to-action',
  templateUrl: './table-dropdown-call-to-action.component.html',
  styleUrls: ['./table-dropdown-call-to-action.component.scss'],
  // tslint:disable-next-line:use-component-view-encapsulation
  encapsulation: ViewEncapsulation.None,
})
export class TableDropdownCallToActionComponent extends BaseComponent {
  @Input() defaultOptions: [] = [];
  @Input() filterId: string;
  @Input() icon: string;
  @Input() options: any[];
  @Input() placeholder: string;
  @Output() selected: EventEmitter<any> = new EventEmitter();

  constructor() {
    super();
  }

  search(option) {
    this.selected.emit(option.value);
  }
}
