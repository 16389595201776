import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'DateFormatPipe',
  pure: false,
})
export class DateFormatPipe implements PipeTransform {
  constructor(private translateService: TranslateService) {}

  transform(value: any, format?: string): any {
    const language = this.translateService.currentLang;
    const dateFormat = this.getDateFormat(language);
    const datePipe = new DatePipe('en-US');
    return datePipe.transform(value, dateFormat);
  }

  getDateFormat(language: string): string {
    switch (language) {
      case 'en':
        return 'dd.MM.yyyy';
      case 'de':
        return 'dd.MM.yyyy';
      case 'es':
        return 'dd/MM/yyyy';
      case 'zh-CN':
        return 'yyyy-MM-dd';
      case 'zh-TW':
        return 'yyyy-MM-dd';
      default:
        return 'dd.MM.yyyy';
    }
  }
}
