const Route = {
  CALLBACK: 'callback',
  LOGIN: 'login',
  DASHBOARD: 'dashboard',
  CONNECT_TO_STORE: 'connect-to-store',
  SETTINGS: 'settings',
  YOUR_DETAILS_SETTINGS: 'your-details',
  ENGAGEMENT_CREATION: 'engagement-creation',
  TRIGGERS: '',
  TARGETS: 'targets',
  DURATION: 'duration',
  CHECKOUT: 'checkout',
  LOCATION: 'location',
  IMAGE_UPLOAD: 'image-upload',
  AUDIO_UPLOAD: 'audio-upload',
  PRODUCTS: 'products',
  URL_TARGET_PAGE: 'url-target-page',
  BUY_TARGET_PAGE: 'buy-target-page',
  INFORMATION_TARGET_PAGE: 'information-target-page',
  SAVED_TARGETS_PAGES: 'saved-target-pages',
  ANALYTICS: 'analytics',
  TARGET_PAGES: 'target-pages',
  ACT_TARGET_PAGE: 'act-target-page',
  SMARTCODE_TRIGGER: 'smartcode-trigger',
  LINK: 'link-trigger',
  INSTANT_WEB_TRIGGER: 'instant-web-trigger',
  MAKE_PAYMENT: 'make-payment',
  TARGET_PAGE_CREATION: 'target-page-creation',
  CREATE_PRODUCT: 'create-product',
  BEACON: 'beacon',
  ECOMMERCE: 'ecommerce',
  SELF_ON_BOARDING: 'self-on-boarding',
  PROVIDER_DETAILS: 'payment-provider-details',
  SECURE_SETUP: '3d-secure-setup',
  PROVIDER_OTHER_DETAILS: 'payment-gateway-details',
  CONFIRMATION_MESSAGE: 'confirmation-message',
  SELECT_ECOMMERCE_PLATFORM: 'select-ecommerce-platform',
  SELECT_PAYMENT_PROVIDER: 'select-payment-provider',
  CONFIRMATION: 'confirmation',
  PLATFORM_DETAIL: 'platform-detail',
  SELL_WITH_REZOLVE: 'sell-with-rezolve',
  YOUR_BRAND: 'your-brand',
  LOCATIONS: 'locations',
  LOCATION_CREATION: 'location-creation',
  E_COMMERCE: 'e-commerce',
  METRICS: 'metrics',
};

export default Route;
