import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import Route from '@core/constants/route';
import { LoaderService } from '@core/services/loader.service';
import { MerchantAPIService } from '@core/services/merchant-api.service';
import { BaseComponent } from '@shared/components/base-component/base-component';
import { Observable } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss'],
})
export class ProductsComponent extends BaseComponent implements OnInit {
  shouldLoad: boolean = false;
  hasMerchantId: boolean;
  loading$: Observable<boolean>;

  constructor(
    private merchantService: MerchantAPIService,
    private loaderService: LoaderService,
    private router: Router,
  ) {
    super();
    this.loading$ = this.loaderService.isLoading();
  }

  ngOnInit(): void {
    this.loaderService.show();
    this.shouldLoad = history.state.shouldLoad;
    this.merchantService
      .getMerchantInfo()
      .pipe(
        takeUntil(this.destroy$),
        finalize(() => this.loaderService.hide()),
      )
      .subscribe((merchant) => {
        if (merchant) {
          this.hasMerchantId = merchant.rceMerchantId ? true : false;
          this.shouldLoad =
            this.shouldLoad ||
            !!(
              merchant?.productsConfig?.ecommerceConfig &&
              merchant?.productsConfig?.ecommerceConfig.cartId &&
              merchant?.productsConfig?.ecommerceConfig.storeId
            ) ||
            merchant?.productsConfig?.hasSkippedEcommerceConfig;
          if (this.router.url.substr(1) === Route.PRODUCTS && merchant?.productsConfig?.hasSkippedEcommerceConfig) {
            this.router.navigate([Route.PRODUCTS, Route.DASHBOARD]);
          }
        }
      });
  }

  onConnect() {
    this.shouldLoad = true;
  }
}
