import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import Route from '@core/constants/route';
import { AuthGuard } from '@core/guards/auth.guard';
import { ConfirmEngagementGuard } from '@core/guards/confirm-engagement.guard';
import { EcommerceGuard } from '@core/guards/ecommerce.guard';
import { HideGuard } from '@core/guards/hide.guard';
import { PaymentGuard } from '@core/guards/payment.guard';
import { ProductWelcomeGuard } from '@core/guards/product-welcome.guard';
import { WizardGuard } from '@core/guards/wizard.guard';
import { CallbackComponent } from './features/auth/callback/callback.component';
import { LoginComponent } from './features/auth/login/login.component';
import { DashboardComponent } from './features/dashboard/dashboard.component';
import { EngagementCreationComponent } from './features/engagement-creation/engagement-creation.component';
import { ProductsComponent } from './features/products/products.component';
import { LocationFormComponent } from './features/settings/locations/location-form/location-form.component';
import { LocationsComponent } from './features/settings/locations/locations.component';
import { TargetPageCreationComponent } from './features/target-page-creation/target-page-creation.component';
import { TriggersComponent } from './features/triggers/triggers.component';
import { MetricsComponent } from './features/metrics/metrics.component';

const routes: Routes = [
  {
    path: Route.CALLBACK,
    component: CallbackComponent,
  },
  {
    path: Route.LOCATIONS,
    canActivate: [AuthGuard],
    component: LocationsComponent,
  },
  {
    path: Route.LOCATION_CREATION,
    component: LocationFormComponent,
  },
  {
    path: Route.LOGIN,
    component: LoginComponent,
  },
  {
    path: Route.DASHBOARD,
    canActivate: [AuthGuard],
    component: DashboardComponent,
  },
  {
    path: Route.TARGET_PAGES,
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./features/target-pages-dashboard/target-pages-dashboard.module').then(
        (m) => m.TargetPagesDashboardModule,
      ),
  },
  {
    path: Route.METRICS,
    canActivate: [AuthGuard, HideGuard],
    component: MetricsComponent,
    loadChildren: () => import('./features/metrics/metrics.module').then((m) => m.MetricsModule),
  },
  {
    path: Route.ANALYTICS,
    canActivate: [AuthGuard, HideGuard],
    loadChildren: () =>
      import('./features/analytics-dashboard/analytics-dashboard.module').then((m) => m.AnalyticsDashboardModule),
  },
  {
    path: Route.TARGET_PAGE_CREATION,
    component: TargetPageCreationComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: Route.URL_TARGET_PAGE,
        loadChildren: () =>
          import('./features/target-pages/url-target-page/url-target-page.module').then((m) => m.UrlTargetPageModule),
      },
      {
        path: Route.BUY_TARGET_PAGE,
        canActivate: [HideGuard],
        loadChildren: () =>
          import('./features/target-pages/buy-target-page/buy-target-page.module').then((m) => m.BuyTargetPageModule),
      },
      {
        path: Route.INFORMATION_TARGET_PAGE,
        loadChildren: () =>
          import('./features/target-pages/information-taget-page/information-target-page.module').then(
            (m) => m.InformationTargetPageModule,
          ),
      },
      {
        path: Route.ACT_TARGET_PAGE,
        loadChildren: () =>
          import('./features/target-pages/act-target-page/act-target-page.module').then((m) => m.ActTargetPageModule),
      },
    ],
  },
  {
    path: Route.PRODUCTS,
    data: { shouldReuseRoute: false },
    component: ProductsComponent,
    canActivate: [AuthGuard, HideGuard],
    children: [
      {
        path: Route.CONNECT_TO_STORE,
        canActivate: [EcommerceGuard],
        loadChildren: () =>
          import('./features/products/connect-products-store/connect-products-store.module').then(
            (m) => m.ConnectProductsStoreModule,
          ),
      },
      {
        path: Route.DASHBOARD,
        canActivate: [ProductWelcomeGuard],
        loadChildren: () =>
          import('./features/products/product-dashboard/product-dashboard.module').then(
            (m) => m.ProductDashboardModule,
          ),
      },
    ],
  },
  {
    path: Route.ENGAGEMENT_CREATION,
    component: EngagementCreationComponent,
    children: [
      {
        path: Route.TRIGGERS,
        component: TriggersComponent,
      },
      {
        path: Route.DURATION,
        canActivate: [WizardGuard],
        loadChildren: () => import('./features/time-scale/time-scale.module').then((m) => m.TimeScaleModule),
      },
      {
        path: Route.TARGETS,
        canActivate: [WizardGuard],
        loadChildren: () => import('./features/target-pages/target-pages.module').then((m) => m.TargetPagesModule),
      },
      {
        path: Route.CHECKOUT,
        canActivate: [ConfirmEngagementGuard],
        loadChildren: () =>
          import('./features/confirm-engagement/confirm-engagement.module').then((m) => m.ConfirmEngagementModule),
      },
      {
        path: Route.LOCATION,
        canActivate: [WizardGuard],
        loadChildren: () => import('./features/map/map.module').then((m) => m.MapModule),
      },
      {
        path: Route.IMAGE_UPLOAD,
        canActivate: [WizardGuard],
        loadChildren: () => import('./features/image-trigger/image-trigger.module').then((m) => m.ImageTriggerModule),
      },
      {
        path: Route.AUDIO_UPLOAD,
        canActivate: [WizardGuard],
        loadChildren: () => import('./features/audio-trigger/audio-trigger.module').then((m) => m.AudioTriggerModule),
      },
      {
        path: Route.URL_TARGET_PAGE,
        canActivate: [WizardGuard],
        loadChildren: () =>
          import('./features/target-pages/url-target-page/url-target-page.module').then((m) => m.UrlTargetPageModule),
      },
      {
        path: Route.BUY_TARGET_PAGE,
        canActivate: [WizardGuard],
        loadChildren: () =>
          import('./features/target-pages/buy-target-page/buy-target-page.module').then((m) => m.BuyTargetPageModule),
      },
      {
        path: Route.INFORMATION_TARGET_PAGE,
        canActivate: [WizardGuard],
        loadChildren: () =>
          import('./features/target-pages/information-taget-page/information-target-page.module').then(
            (m) => m.InformationTargetPageModule,
          ),
      },
      {
        path: Route.ACT_TARGET_PAGE,
        canActivate: [WizardGuard],
        loadChildren: () =>
          import('./features/target-pages/act-target-page/act-target-page.module').then((m) => m.ActTargetPageModule),
      },
      {
        path: Route.SMARTCODE_TRIGGER,
        canActivate: [WizardGuard],
        loadChildren: () =>
          import('./features/smartcode-trigger/smartcode-trigger.module').then((m) => m.SmartcodeTriggerModule),
      },
      {
        path: Route.LINK,
        canActivate: [WizardGuard],
        loadChildren: () => import('./features/link-trigger/link-trigger.module').then((m) => m.LinkTriggerModule),
      },
      {
        path: Route.MAKE_PAYMENT,
        canActivate: [PaymentGuard],
        loadChildren: () => import('./features/payment/payment.module').then((m) => m.PaymentModule),
      },
      {
        path: Route.BEACON,
        canActivate: [WizardGuard],
        loadChildren: () =>
          import('./features/beacon-trigger/beacon-trigger.module').then((m) => m.BeaconTriggerModule),
      },
      {
        path: Route.INSTANT_WEB_TRIGGER,
        canActivate: [WizardGuard],
        loadChildren: () =>
          import('./features/instant-web-trigger/instant-web-trigger.module').then((m) => m.InstantWebTriggerModule),
      },
    ],
  },
  {
    path: Route.E_COMMERCE,
    canActivate: [AuthGuard],
    loadChildren: () =>
      import(/* webpackChunkName: 'e-commerce' */'./features/e-commerce/e-commerce.module').then(
        (m) => m.ECommerceModule,
      ),
  },
  { path: '', redirectTo: Route.ENGAGEMENT_CREATION, pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule],
})
export class AppRoutingModule {}
