import { Component, Input, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import {
  enableAllWizardSteps,
  EngagementCreationWizardSteps,
  filterByEngagementType,
} from '@core/constants/EngagementCreationWizardSteps';
import Route from '@core/constants/route';
import { EngagementTypeEnum } from '@core/models/enums/engagement-type-enum';
import { BaseComponent } from '@shared/components/base-component/base-component';
import { filter, takeUntil } from 'rxjs/operators';
import { EngagementCreationService } from '../engagement-creation.service';

@Component({
  selector: 'app-wizard',
  templateUrl: './wizard.component.html',
  styleUrls: ['./wizard.component.scss'],
})
export class WizardComponent extends BaseComponent implements OnInit {
  engagementCreationWizardSteps = EngagementCreationWizardSteps;
  activeStepNumber: string = EngagementCreationWizardSteps[0].stepNumber;
  _selectedEngagement: string;
  isHomePage:boolean=false;
  @Input() get selectedEngagement() {
    return this._selectedEngagement;
  }
  set selectedEngagement(value: string) {
    this._selectedEngagement = value;
    this.isHomePage=false;
    switch (this._selectedEngagement) {
      case '/engagement-creation/image-upload':
        this.engagementCreationWizardSteps = filterByEngagementType(EngagementTypeEnum.Image);
        break;
      case '/engagement-creation/audio-upload':
        this.engagementCreationWizardSteps = filterByEngagementType(EngagementTypeEnum.Audio);
        break;
      case '/engagement-creation/smartcode-trigger':
        this.engagementCreationWizardSteps = filterByEngagementType(EngagementTypeEnum.SmartCode);
        break;
      case '/engagement-creation/link-trigger':
        this.engagementCreationWizardSteps = filterByEngagementType(EngagementTypeEnum.Link);
        break;
      case '/engagement-creation/instant-web-trigger':
        this.engagementCreationWizardSteps = filterByEngagementType(EngagementTypeEnum.InstantWeb);
        break;
      case '/engagement-creation':
        this.engagementCreationWizardSteps = filterByEngagementType(EngagementTypeEnum.Geolocation);
        this.isHomePage=true;
        break;
    }
  }

  constructor(public engagementCreationService: EngagementCreationService, private router: Router) {
    super();
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        takeUntil(this.destroy$),
      )
      .subscribe((event: NavigationEnd) => {
        if (event.urlAfterRedirects === `/${Route.ENGAGEMENT_CREATION}`) {
          enableAllWizardSteps();
        }
      });
  }

  ngOnInit(): void {
    enableAllWizardSteps();
    this.engagementCreationWizardSteps = filterByEngagementType(EngagementTypeEnum.Geolocation);
  }

  setActiveStepNumber(activeStepNumber: string) {
    this.activeStepNumber = activeStepNumber;
  }
}
