import { Directive, EventEmitter, HostListener, Input, Output } from '@angular/core';

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[dragAndDrop]',
})
export class DragAndDropDirective {
  @Output() dropped: EventEmitter<FileList> = new EventEmitter();
  @Output() hovered: EventEmitter<boolean> = new EventEmitter();
  @Input() isImageDropped = true;

  @HostListener('drop', ['$event'])
  onDrop($event) {
    $event.preventDefault();
    $event.stopPropagation();
    this.dropped.emit($event.dataTransfer.files);
    this.hovered.emit(false);
  }

  @HostListener('dragover', ['$event'])
  onDragOver($event) {
    if (this.isImageDropped) {
      $event.preventDefault();
      $event.stopPropagation();
      this.hovered.emit(true);
    }
  }

  @HostListener('dragleave', ['$event'])
  onDragLeave($event) {
    $event.preventDefault();
    $event.stopPropagation();
    this.hovered.emit(false);
  }
}
