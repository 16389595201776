import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule, GridModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyAutocompleteModule } from '@angular/material/legacy-autocomplete';
import { MatLegacyChipsModule } from '@angular/material/legacy-chips';
import { MatLegacyFormFieldModule, MAT_LEGACY_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/legacy-form-field';
import { MatLegacyMenuModule } from '@angular/material/legacy-menu';
import { MatLegacyProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { MatLegacySelectModule } from '@angular/material/legacy-select';
import { MatTreeModule } from '@angular/material/tree';
import { RouterModule } from '@angular/router';
import { CustomDirectivesModule } from '@core/directives/custom-directives.module';
import { TranslateModule } from '@ngx-translate/core';
import { BaseOverlayCardMessageModule } from '@shared/components/base-overlay-card-message/base-overlay-card-message.module';
import { ConfirmButtonModule } from '@shared/components/confirm-button/confirm-button.module';
import { CreationContainerComponent } from '@shared/components/creation-container/creation-container.component';
import { SnackBarModule } from '@shared/components/snack-bar/snack-bar.module';
import { CalendarModule } from 'primeng/calendar';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { BaseCardContainerModule } from './components/base-card-container/base-card-container.module';
import { BaseCardModule } from './components/base-card/base-card.module';
import { BaseTriggerContainerComponent } from './components/base-trigger-container/base-trigger-container.component';
import { ChipFiltersModule } from './components/chip-filters/chip-filters.module';
import { CreationContainerBarItemComponent } from './components/creation-container-bar-item/creation-container-bar-item.component';
import { DashboardTableModule } from './components/dashboard-table/dashboard-table.module';
import { DividerComponent } from './components/divider/divider.component';
import { FilePreviewModule } from './components/file-preview/file-preview.module';
import { FileUploadModule } from './components/file-upload/file-upload.module';
import { HeaderComponent } from './components/header/header.component';
import { NavigationCardsModule } from './components/navigation-cards/navigation-cards.module';
import { PaginationModule } from './components/pagination/pagination.module';
import { PopupModule } from './components/popup/popup.module';
import { SearchInputModule } from './components/search-input/search-input.module';
import { SliderPanelModule } from './components/slider-panel/slider-panel.module';
import { StripePaymentModule } from './components/stripe-payment/stripe-payment.module';
import { TableDropdownCallToActionComponent } from './components/table-dropdown-call-to-action/table-dropdown-call-to-action.component';
import { TableDropdownGroupsModule } from './components/table-dropdown-groups/table-dropdown-groups.module';
import { TableDropdownComponent } from './components/table-dropdown/table-dropdown.component';
import { TabComponent } from './components/tabs/tab/tab.component';
import { TabsComponent } from './components/tabs/tabs.component';
import { CustomPipeModule } from './pipes/custom-pipe.module';

@NgModule({
  declarations: [
    TableDropdownComponent,
    TableDropdownCallToActionComponent,
    CreationContainerComponent,
    CreationContainerBarItemComponent,
    HeaderComponent,
    BaseTriggerContainerComponent,
    TabsComponent,
    TabComponent,
    DividerComponent,
  ],
  exports: [
    PaginationModule,
    TableDropdownComponent,
    CreationContainerComponent,
    HeaderComponent,
    BaseCardModule,
    BaseCardContainerModule,
    BaseOverlayCardMessageModule,
    FilePreviewModule,
    FileUploadModule,
    DashboardTableModule,
    PopupModule,
    ChipFiltersModule,
    StripePaymentModule,
    SliderPanelModule,
    SearchInputModule,
    NavigationCardsModule,
    CustomPipeModule,
    CustomDirectivesModule,
    MatLegacyMenuModule,
    TableDropdownGroupsModule,
    ReactiveFormsModule,
    MatTreeModule,
    MatLegacyChipsModule,
    MatLegacyAutocompleteModule,
    MatLegacyFormFieldModule,
    FormsModule,
    FlexLayoutModule,
    ProgressSpinnerModule,
    MatIconModule,
    CalendarModule,
    BaseOverlayCardMessageModule,
    BaseTriggerContainerComponent,
    TabsComponent,
    TabComponent,
    ConfirmButtonModule,
    SnackBarModule,
    TableDropdownCallToActionComponent,
    DividerComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    FlexLayoutModule,
    MatLegacyFormFieldModule,
    MatIconModule,
    RouterModule,
    BaseCardModule,
    BaseCardContainerModule,
    GridModule,
    FilePreviewModule,
    FileUploadModule,
    DashboardTableModule,
    PopupModule,
    StripePaymentModule,
    MatDividerModule,
    TranslateModule,
    SliderPanelModule,
    SearchInputModule,
    NavigationCardsModule,
    TranslateModule.forChild(),
    CustomPipeModule,
    CustomDirectivesModule,
    ProgressSpinnerModule,
    MatTreeModule,
    MatLegacyMenuModule,
    MatLegacyChipsModule,
    MatLegacyAutocompleteModule,
    ReactiveFormsModule,
    PaginationModule,
    TableDropdownGroupsModule,
    ConfirmButtonModule,
    BaseOverlayCardMessageModule,
    MatLegacySelectModule,
    MatLegacyProgressSpinnerModule,
  ],
  providers: [{ provide: MAT_LEGACY_FORM_FIELD_DEFAULT_OPTIONS, useValue: { floatLabel: 'never' } }],
})
export class SharedModule {}
