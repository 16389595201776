import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatIconModule } from '@angular/material/icon';
import { CustomDirectivesModule } from '@core/directives/custom-directives.module';
import { TranslateModule } from '@ngx-translate/core';
import { BaseOverlayCardMessageModule } from '@shared/components/base-overlay-card-message/base-overlay-card-message.module';
import { CustomPipeModule } from '@shared/pipes/custom-pipe.module';
import { NavigationCardsComponent } from './navigation-cards.component';

@NgModule({
  declarations: [NavigationCardsComponent],
  imports: [
    CommonModule,
    FlexLayoutModule,
    MatIconModule,
    CustomDirectivesModule,
    TranslateModule.forChild(),
    BaseOverlayCardMessageModule,
    CustomPipeModule,
  ],
  exports: [NavigationCardsComponent],
})
export class NavigationCardsModule {}
