import { Type } from '@angular/core';

export class ColumnDefinition {
  field: string;
  description: string;
  events: ColumnDefinitionEventsEnum[];
  isActive: boolean;
  type: ColumnDefinitionTypesEnum;
  sortable: boolean;
  component: Type<any>;

  constructor(
    field: string,
    isActive: boolean = false,
    description: string = '',
    type: ColumnDefinitionTypesEnum = ColumnDefinitionTypesEnum.DEFAULT,
    sortable: boolean = false,
    events: ColumnDefinitionEventsEnum[] = [],
    component: Type<any> = null,
  ) {
    this.field = field;
    this.description = isActive ? description : field;
    this.type = type;
    this.isActive = isActive;
    this.sortable = sortable;
    this.events = events;
    this.component = component;
  }
}

export enum ColumnDefinitionTypesEnum {
  DEFAULT,
  TITLE_TEXT,
  LIST,
  DATE,
  FAVORITE_SELECTION,
  ELEMENT,
  // DATE_TIME,
  // ACTIONS,
  // NUMERIC,
  // CHECKBOX,
}

export enum ColumnDefinitionEventsEnum {
  CELL_CLICKED,
  // ROW_CLICKED,
  // CHECKBOX_SELECTION,
}
