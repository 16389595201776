import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule } from '@angular/forms';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';
import { MatLegacyDialogModule } from '@angular/material/legacy-dialog';
import { TranslateModule } from '@ngx-translate/core';
import { ConfirmButtonModule } from '@shared/components/confirm-button/confirm-button.module';
import { FileUploadModule } from 'primeng/fileupload';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { ImageCustomisationPopupComponent } from './image-customisation-popup.component';

@NgModule({
  declarations: [ImageCustomisationPopupComponent],
  imports: [
    CommonModule,
    MatLegacyDialogModule,
    MatLegacyButtonModule,
    FormsModule,
    MatDividerModule,
    FlexLayoutModule,
    FileUploadModule,
    ProgressSpinnerModule,
    TranslateModule.forChild(),
    ConfirmButtonModule,
    MatIconModule,
  ],
  exports: [ImageCustomisationPopupComponent],
})
export class ImageCustomisationPopupModule {}
