import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule, FlexModule } from '@angular/flex-layout';
import { FormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';
import { MatLegacyCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatLegacyFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule } from '@angular/material/legacy-input';
import { MatLegacyMenuModule } from '@angular/material/legacy-menu';
import { MatLegacySelectModule } from '@angular/material/legacy-select';
import { MatSortModule } from '@angular/material/sort';
import { MatTreeModule } from '@angular/material/tree';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { EditorModule } from '@tinymce/tinymce-angular';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { SearchInputModule } from '../search-input/search-input.module';
import { TreeChecklistComponent } from './tree-checklist.component';

@NgModule({
  declarations: [TreeChecklistComponent],
  imports: [
    CommonModule,
    SearchInputModule,
    MatLegacyCheckboxModule,
    MatLegacyMenuModule,
    RouterModule,
    FlexModule,
    FlexLayoutModule,
    MatSortModule,
    MatLegacyButtonModule,
    EditorModule,
    ProgressSpinnerModule,
    MatLegacyInputModule,
    FormsModule,
    MatIconModule,
    FlexLayoutModule,
    MatLegacyFormFieldModule,
    MatLegacySelectModule,
    MatTreeModule,
    TranslateModule.forChild(),
  ],
  exports: [TreeChecklistComponent],
})
export class TreeCheckListModule {}
