import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule } from '@angular/forms';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';
import { MatLegacyCardModule } from '@angular/material/legacy-card';
import { MatLegacyCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatLegacyMenuModule } from '@angular/material/legacy-menu';
import { MatLegacyTooltipModule } from '@angular/material/legacy-tooltip';
import { MatSortModule } from '@angular/material/sort';
import { TranslateModule } from '@ngx-translate/core';
import { NavigationCardsModule } from '@shared/components/navigation-cards/navigation-cards.module';
import { SharedModule } from '@shared/shared.module';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { ActSubmissionBlockComponent } from './act-submission-block/act-submission-block.component';
import { TargetPagesCardModule } from './target-pages-card/target-pages-card.module';
import { TargetPagesDashboardRoutingModule } from './target-pages-dashboard-routing.module';
import { TargetPagesDashboardComponent } from './target-pages-dashboard.component';

@NgModule({
  declarations: [TargetPagesDashboardComponent, ActSubmissionBlockComponent],
  imports: [
    CommonModule,
    TargetPagesDashboardRoutingModule,
    MatIconModule,
    MatLegacyMenuModule,
    MatDividerModule,
    MatSortModule,
    FlexLayoutModule,
    MatLegacyButtonModule,
    MatLegacyCardModule,
    FormsModule,
    SharedModule,
    MatSortModule,
    MatButtonToggleModule,
    ProgressSpinnerModule,
    TranslateModule.forChild(),
    MatLegacyTooltipModule,
    NavigationCardsModule,
    MatLegacyCheckboxModule,
    TargetPagesCardModule,
  ],
  exports: [TargetPagesDashboardComponent],
})
export class TargetPagesDashboardModule {}
