import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CreditCardViewModel } from '@core/models/view-models/credit-card-view-model';

@Component({
  selector: 'app-credit-card-view',
  templateUrl: './credit-card-view.component.html',
  styleUrls: ['./credit-card-view.component.scss'],
})
export class CreditCardViewComponent implements OnInit {
  @Input() creditCard: CreditCardViewModel = null;
  @Input() index: number;
  @Output() removeCardEvent: EventEmitter<number> = new EventEmitter();
  @Output() selectCardEvent: EventEmitter<number> = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}

  selectCard(): void {
    this.selectCardEvent.emit(this.index);
  }

  removeCreditCard(): void {
    this.removeCardEvent.emit(this.index);
  }
}
