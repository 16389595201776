import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { BaseComponent } from '@shared/components/base-component/base-component';
import { SearchInputComponent } from '@shared/components/search-input/search-input.component';
import { takeUntil } from 'rxjs/operators';
import { MenuWizardService } from '../../menu-wizard/menu-wizard-service/menu-wizard.service';
import { without3dSecure, wizardSteps } from '../../menu-wizard/menu-wizard.model';
import { selectSelfOnboardingState, SelfOnboardingState } from '../../store';
import { resetSelectPaymentGatewayDetails } from '../../store/actions/payment-gateway-details.actions';
import { resetPaymentProviderDetails } from '../../store/actions/payment-provider-details.actions';
import { resetSelectPaymentForm } from '../../store/actions/select-payment-form.action';
import { selectPaymentProvider } from '../../store/actions/select-payment-provider.action';
import {
  Connection,
  PaymentType,
  paymentWithout3dSecure,
  GetPaymentConnectionDictionary,
  PayPalProviderName,
} from '../payment-connection-models';
import { PaymentProvider } from '../PaymentProvider';

@Component({
  selector: 'app-payment-selector',
  templateUrl: './payment-selector.component.html',
  styleUrls: ['./payment-selector.component.scss'],
})
export class PaymentSelectorComponent extends BaseComponent implements OnInit {
  paymentPlatforms = GetPaymentConnectionDictionary();

  dataSource: { [type in PaymentType]: Connection } = null;
  selectedPayment = null;

  @Input() selectPaymentSubtitle = '';
  @Input() paymentProviderLogoAlt = 'Payment provider logo';
  @ViewChild(SearchInputComponent) searchComponent: SearchInputComponent;

  @Output() paymentSelectionChanged: EventEmitter<PaymentProvider> = new EventEmitter<PaymentProvider>();

  constructor(
    private readonly store: Store<SelfOnboardingState>,
    private readonly menuWizardService: MenuWizardService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.dataSource = this.orderPaymentMethods(this.paymentPlatforms);
    this.menuWizardService.nextStepRequest$.pipe(takeUntil(this.destroy$)).subscribe((_) => {
      this.menuWizardService.goNext();
    });
    this.menuWizardService.canGoToNextStep$.next(false);
    this.store
      .select(selectSelfOnboardingState)
      .pipe(takeUntil(this.destroy$))
      .subscribe((state) => {
        if (state.selectedPaymentProvider && state.selectedPaymentProvider.providerName) {
          this.selectedPayment = Object.keys(this.dataSource).find(
            (provider) => provider === state.selectedPaymentProvider.providerName,
          );
          const steps = wizardSteps.map((step) => ({ ...step }));
          // dispatch flow update
          if (paymentWithout3dSecure.includes(this.selectedPayment)) {
            this.menuWizardService.steps$.next(without3dSecure(steps));
          } else {
            this.menuWizardService.steps$.next(steps);
          }
          this.menuWizardService.canGoToNextStep$.next(true);
        }
      });
  }

  onSearched(value: string) {
    let searchedValues: { [type in PaymentType]: Connection } = null;

    if (value) {
      Object.keys(this.paymentPlatforms).forEach((key) => {
        if (key.toLocaleLowerCase().includes(value.toLocaleLowerCase()) && this.dataSource) {
          searchedValues = {
            ...searchedValues,
            ...{
              [key]: this.dataSource[key],
            },
          };
        }
      });

      this.dataSource = searchedValues;
      return;
    }

    this.dataSource = this.paymentPlatforms;
  }

  paymentSelected(paymentDetails) {
    if (paymentDetails !== this.dataSource) {
      this.store.dispatch(resetSelectPaymentForm());
      this.store.dispatch(resetSelectPaymentGatewayDetails());
      this.store.dispatch(resetPaymentProviderDetails());
    }
    this.selectedPayment = paymentDetails.key;
    this.paymentSelectionChanged.emit(paymentDetails);
    const newSelectedPaymentPlatform = { [this.selectedPayment]: this.dataSource[this.selectedPayment] };

    this.store.dispatch(
      selectPaymentProvider({
        paymentDto: {
          providerName: paymentDetails.key,
          providerLogoUrl: paymentDetails.value.logo,
          selectedPaymentPlatform: newSelectedPaymentPlatform,
        },
      }),
    );
    this.menuWizardService.canGoToNextStep$.next(true);
  }

  resetSearch() {
    this.searchComponent.searchElement.nativeElement.value = null;
    this.onSearched(this.searchComponent.searchElement.nativeElement.value);
  }

  paymentOrder() {
    return 0;
  }

  private orderPaymentMethods(object: { [type in PaymentType]: Connection }): { [type in PaymentType]: Connection } {
    const sorted = this.putFieldAtTop(object, PayPalProviderName).reduce((accumulator, key) => {
      accumulator[key] = object[key];

      return accumulator;
    }, {});

    return sorted as { [type in PaymentType]: Connection };
  }

  private putFieldAtTop(object: { [type in PaymentType]: Connection }, element: string): string[] {
    let properties = Object.keys(object).sort();
    var index = properties.indexOf(element);
    if (index !== -1) {
      properties.splice(index, 1);
      properties.unshift(element);
    }
    return properties;
  }
}
