import { CreditCardDTO } from '../dtos/credit-card-dto';

export class CreditCardViewModel {
  paymentMethodId: string;
  isSelected: boolean;
  lastFourDigits: number;
  expiryYear: number;
  expiryMonth: number;

  constructor(creditCard: CreditCardDTO, isSelected: boolean = false) {
    this.lastFourDigits = creditCard.accountNumberLastFourDigit;
    this.expiryMonth = creditCard.expMonth;
    this.expiryYear = creditCard.expYear;
    this.paymentMethodId = creditCard.paymentMethodId;
    this.isSelected = isSelected;
  }
}
