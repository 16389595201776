import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { DateRangeFilter, TargetChipFilters } from '@core/constants/Filters';
import { ActDTO } from '@core/models/dtos/act-dto';
import { ActTypeEnum } from '@core/models/enums/act-type-enum';
import { ActViewModel } from '@core/models/view-models/act-view-model';
import { ActAPIService } from '@core/services/act-api.service';
import { LoaderService } from '@core/services/loader.service';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { BaseTargetsDashboardComponent } from '@shared/components/base-targets-dashboard-component/base-targets-dashboard-component';
import { ChipFilterOptions } from '@shared/components/chip-filters/chip-filter-options';
import { GridOptions } from '@shared/components/dashboard-table/models/grid-options';
import { PopupService } from '@shared/components/popup/popup.service';
import { SearchInputComponent } from '@shared/components/search-input/search-input.component';
import { BehaviorSubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { clearFilter, updateFilter } from '../../../store/actions/filter.actions';
import { getFilterState, State } from '../../../store/store';
import { EngagementCreationService } from '../../engagement-creation/engagement-creation.service';

@Component({
  selector: 'app-saved-target-pages',
  templateUrl: './saved-target-pages.component.html',
  styleUrls: ['./saved-target-pages.component.scss'],
})
export class SavedTargetPagesComponent extends BaseTargetsDashboardComponent implements OnInit, AfterViewInit {
  @Input() pageSize = 5;
  @Input() targetPagesFilters;
  @Input() isInstantWeb;
  dateRangeFilter = DateRangeFilter;
  targetTypeFilter = 'enumTypesSelection';
  filterOptions: ChipFilterOptions = new ChipFilterOptions();
  gridOptions: GridOptions = new GridOptions();
  selectedTargetPage: ActDTO;
  selectedRowIndex = -1;
  defaultOptions: [];
  searchedValues = null;
  targets: BehaviorSubject<ActViewModel[]>;
  selectedRow?: any = null;

  @ViewChild(SearchInputComponent) searchComponent: SearchInputComponent;

  constructor(
    actService: ActAPIService,
    loaderService: LoaderService,
    private popupService: PopupService,
    private engagementCreationService: EngagementCreationService,
    private translationService: TranslateService,
    private readonly store: Store<State>,
  ) {
    super(actService, loaderService);
  }

  ngOnInit(): void {
    this.store
      .select(getFilterState)
      .pipe(takeUntil(this.destroy$))
      .subscribe((filterState) => {
        filterState.filterData.forEach((filter) => {
          this.filters[filter.filterId] = filter.data;
          if (filter.filterId === this.targetTypeFilter) {
            this.searchedValues = filter.data;
          }
        });
      });
    this.targets = this.dataSource.connect();
    this.dataSource
      .connect()
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        this.gridOptions.rowData = data;
      });
    this.configureFilterOptions();
    this.refresh(this.pageIndex, this.pageSize);
  }

  ngAfterViewInit(): void {
    this.paginator.matPaginator.page.pipe(takeUntil(this.destroy$)).subscribe((page) => {
      this.refresh(page.pageIndex, page.pageSize);
    });
    this.sort.sortChange.pipe(takeUntil(this.destroy$)).subscribe((sort) => {
      this.refresh(this.paginator.matPaginator.pageIndex, this.paginator.matPaginator.pageSize);
    });
  }

  applyFilter(value: string, type: string) {
    this.filters[type] = value;
    this.store.dispatch(updateFilter({ filterId: this.targetTypeFilter, data: value }));
    this.refreshWithFirstPageNavigation();
  }

  resetFilters() {
    this.searchComponent.searchElement.nativeElement.value = null;
    this.defaultOptions = [];
    this.filters = {};
    this.searchedValues = null;
    this.store.dispatch(clearFilter());
    this.applyFilter(undefined, 'dateRange');
  }

  onSelectedRow(target: ActViewModel, index: number): void {
    this.selectedRowIndex = this.selectedRowIndex === index ? -1 : index;
    this.selectedRow = this.selectedRow === target ? null : target;
    this.selectedTargetPage = this.selectedRowIndex > -1 ? target.ActDTO : null;
  }

  proceedToNextStep(): void {
    if (this.selectedTargetPage != null) {
      if (this.engagementCreationService.engagement) {
        this.engagementCreationService.engagement.actInformation = this.selectedTargetPage.getActInformation();
        this.engagementCreationService.setNextStep();
      }
    }
  }

  deleteTarget() {
    this.refresh(this.paginator.matPaginator.pageIndex, this.paginator.matPaginator.pageSize);
  }

  private configureFilterOptions(): void {
    this.filterOptions.filters = TargetChipFilters;
    const selectedItems = this.filterOptions.filters.filter((item) => item.selected);
    if (this.isInstantWeb) {
      this.filters.enumTypesSelection = [ActTypeEnum.Buy, ActTypeEnum.Act];
    } else {
      this.filters.enumTypesSelection =
        !selectedItems.length || selectedItems[0].value === ActTypeEnum.All ? [] : [selectedItems[0].value];
    }
  }
}
