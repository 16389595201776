import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule } from '@angular/forms';
import { MatDividerModule } from '@angular/material/divider';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';
import { MatLegacyCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatLegacyDialogModule } from '@angular/material/legacy-dialog';
import { MatLegacyInputModule } from '@angular/material/legacy-input';
import { YouTubePlayerModule } from '@angular/youtube-player';
import { TranslateModule } from '@ngx-translate/core';
import { ConfirmButtonModule } from '@shared/components/confirm-button/confirm-button.module';
import { VideoCustomisationPopupComponent } from './video-customisation-popup.component';

@NgModule({
  declarations: [VideoCustomisationPopupComponent],
  imports: [
    CommonModule,
    MatLegacyDialogModule,
    MatLegacyButtonModule,
    MatDividerModule,
    MatLegacyInputModule,
    FormsModule,
    FlexLayoutModule,
    TranslateModule.forChild(),
    YouTubePlayerModule,
    MatLegacyCheckboxModule,
    ConfirmButtonModule,
  ],
  exports: [VideoCustomisationPopupComponent],
})
export class VideoCustomisationPopupModule {}
