import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ExtendedModule, FlexModule } from '@angular/flex-layout';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { BaseCardContainerModule } from '@shared/components/base-card-container/base-card-container.module';
import { TriggersRoutingModule } from './triggers-routing.module';
import { TriggersComponent } from './triggers.component';

@NgModule({
  declarations: [TriggersComponent],
  imports: [
    CommonModule,
    MatIconModule,
    TranslateModule,
    FlexModule,
    ExtendedModule,
    TriggersRoutingModule,
    BaseCardContainerModule,
  ],
  exports: [TriggersComponent],
})
export class TriggersModule {}
