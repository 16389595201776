import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';
import { MatLegacyDialogModule } from '@angular/material/legacy-dialog';
import { TranslateModule } from '@ngx-translate/core';
import { ConfirmButtonModule } from '@shared/components/confirm-button/confirm-button.module';
import { SuccessStatusPopupComponent } from './success-status-popup.component';

@NgModule({
  declarations: [SuccessStatusPopupComponent],
  imports: [
    CommonModule,
    FlexLayoutModule,
    MatLegacyDialogModule,
    MatLegacyButtonModule,
    MatIconModule,
    TranslateModule.forChild(),
    ConfirmButtonModule,
  ],
  exports: [SuccessStatusPopupComponent],
})
export class SuccessStatusPopupModule {}
