import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule } from '@angular/material/legacy-input';
import { TranslateModule } from '@ngx-translate/core';
import { SearchInputComponent } from './search-input.component';

@NgModule({
  declarations: [SearchInputComponent],
  imports: [
    CommonModule,
    MatLegacyFormFieldModule,
    FlexLayoutModule,
    MatIconModule,
    MatLegacyInputModule,
    TranslateModule.forChild(),
  ],
  exports: [SearchInputComponent],
})
export class SearchInputModule {}
