import { Component, OnInit, Renderer2 } from '@angular/core';
import { AppSettingService } from '@core/services/app-setting.service';
import { IconService } from '@core/services/icon/icon.service';
import { EngagementCreationService } from './features/engagement-creation/engagement-creation.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit {
  title = 'app';
  constructor(
    private iconService: IconService,
    private engagementCreationService: EngagementCreationService,
    private readonly appSettingService: AppSettingService,
    private readonly renderer: Renderer2,
  ) {
    this.iconService.registerIcons();
  }

  ngOnInit() {
    this.appSettingService.load();
    this.appSettingService.applyTheme$.subscribe((themeChange) => {
      this.removeTheme(themeChange.previousThemeClass);
      this.applyTheme(themeChange.newThemeClass);
    });

    if (this.appSettingService.hasTenantChanged) {
      this.applyTheme(this.appSettingService.themeClass);
    }
  }

  private applyTheme(themeClass: string) {
    this.renderer.addClass(document.body, themeClass);
  }

  private removeTheme(themeClass: string) {
    this.renderer.removeClass(document.body, themeClass);
  }
}
