import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Endpoints } from '@core/constants/Endpoints';
import Route from '@core/constants/route';
import { ActAdapter } from '@core/models/adapters/act-adapter';
import { TargetAdapter } from '@core/models/adapters/target-adapter';
import { ActDTO } from '@core/models/dtos/act-dto';
import { Product } from '@core/models/dtos/product';
import { ActTypeEnum } from '@core/models/enums/act-type-enum';
import BaseTargetPage from '@core/models/target-pages/base-target-page';
import { ActViewModel } from '@core/models/view-models/act-view-model';
import { PaginatedViewModel } from '@core/models/view-models/paginated-view-model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { LogUtils } from 'src/app/utils/log-utils';
import { BaseAPIServiceService } from './http/base-apiservice.service';

@Injectable({
  providedIn: 'root',
})
export class ActAPIService extends BaseAPIServiceService {
  constructor(
    protected http: HttpClient,
    private adapter: ActAdapter,
    private targetAdapter: TargetAdapter,
    private router: Router,
  ) {
    super(http);
  }

  getTargetPages(
    start?: number,
    pageSize?: number,
    actTypeEnums?: ActTypeEnum[],
    fromDate?: string,
    searchTerm?: string,
    sort: { fieldName: string; ascending: boolean } = null,
  ): Observable<{ data: ActViewModel[]; total: number }> {
    let paramObjects: any = {};
    if ((start !== null || start !== undefined) && (pageSize !== null || pageSize !== undefined)) {
      paramObjects = {
        start,
        take: pageSize,
      };
    }

    if (sort) {
      paramObjects = { ...paramObjects, sortField: sort.fieldName, ascending: sort.ascending };
    }

    if (actTypeEnums && actTypeEnums.length > 0) {
      paramObjects = { ...paramObjects, actTypeEnums };
    }

    if (fromDate) {
      paramObjects = { ...paramObjects, fromDate: new Date(fromDate).toISOString() };
    }

    if (searchTerm) {
      paramObjects = { ...paramObjects, searchTerm };
    }

    const params = new HttpParams({
      fromObject: paramObjects,
    });

    return this.http
      .get<any>(Endpoints.GET_TARGET_PAGES, { params })
      .pipe(
        map((response) => ({ data: response.data.map((act) => this.adapter.fromDTO(act)), total: response.total })),
      );
  }

  createAct(request: BaseTargetPage): Observable<string> {
    return this.http.post<string>(Endpoints.CREATE_ACT, request);
  }

  exportAct(id: string): Observable<any> {
    const options: {
      headers?: HttpHeaders;
      observe?: 'body';
      params?: HttpParams;
      reportProgress?: boolean;
      responseType: 'arraybuffer';
      withCredentials?: boolean;
    } = {
      responseType: 'arraybuffer',
    };
    const timeZoneOffset = new Date().getTimezoneOffset();
    return this.http.get(`${Endpoints.EXPORT_ACTS_URL}${id}/${timeZoneOffset}`, options).pipe(
      map((file: ArrayBuffer) => {
        return file;
      }),
    );
  }

  getAct(id: string): Observable<BaseTargetPage> {
    return this.get<ActDTO>(Endpoints.GET_ACT + id).pipe(map((response) => this.targetAdapter.fromDTO(response)));
  }

  getActByIds(actIds: string[]): Observable<ActViewModel[]> {
    const paramObjects = { actIds };

    const params = new HttpParams({
      fromObject: paramObjects,
    });

    return this.http
      .get<ActDTO[]>(Endpoints.GET_ACTS_BY_IDS, { params })
      .pipe(map((response) => response.map((act) => this.adapter.fromDTO(act))));
  }

  editAct(request: BaseTargetPage): Observable<boolean> {
    return this.http.put<any>(Endpoints.EDIT_ACT, request);
  }

  deleteAct(actid: string): Observable<boolean> {
    return this.http.delete<any>(Endpoints.DELETE_ACT + actid);
  }

  getProductsFromSearch(productName: string): Observable<Product[]> {
    return this.http
      .post<PaginatedViewModel<Product>>(Endpoints.SEARCH_PRODUCTS, { Query: productName })
      .pipe(map((response) => response.data));
  }

  navigateToEditPage(type: ActTypeEnum, id: string): void {
    let targetRoute: string = null;
    switch (type) {
      case ActTypeEnum.Buy:
        targetRoute = Route.BUY_TARGET_PAGE;
        break;
      case ActTypeEnum.InformationPage:
        targetRoute = Route.INFORMATION_TARGET_PAGE;
        break;
      case ActTypeEnum.Act:
        targetRoute = Route.ACT_TARGET_PAGE;
        break;
      case ActTypeEnum.Url:
        targetRoute = Route.URL_TARGET_PAGE;
        break;
      default:
        LogUtils.error(`Edit page for Target Page with type '${ActTypeEnum[type]}' is not implemented!`);
        return;
    }

    if (targetRoute != null) {
      this.router.navigate([`/${Route.TARGET_PAGE_CREATION}/${targetRoute}`], { queryParams: { id } });
    }
  }
}
