import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import Route from '@core/constants/route';
import { MerchantAPIService } from '@core/services/merchant-api.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ProductWelcomeGuard  {
  constructor(private merchantService: MerchantAPIService, private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.merchantService.getMerchantInfo().pipe(
      map((merchant) => {
        const canActivate = merchant?.productsConfig?.hasSkippedEcommerceConfig;

        if (!canActivate) {
          this.router.navigate([Route.PRODUCTS]);
          return false;
        }

        return canActivate;
      }),
    );
  }
}
