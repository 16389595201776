import { ActDTO } from '@core/models/dtos/act-dto';
import { Action, createReducer, on } from '@ngrx/store';
import * as SavedTargetPagesActions from '../actions/saved-target-page.actions';

export interface SavedTargetPagesState {
  selectedTarget: ActDTO;
}

export const initialState: SavedTargetPagesState = {
  selectedTarget: null,
};

const savedTargetPagesReducer = createReducer(
  initialState,
  on(SavedTargetPagesActions.selectSavedTarget, (state, { selectedTarget }) => ({
    ...state,
    selectedTarget,
  })),
);

export function reducer(state = initialState, action: Action): SavedTargetPagesState {
  return savedTargetPagesReducer(state, action);
}
