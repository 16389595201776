import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatDividerModule } from '@angular/material/divider';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';
import { MatLegacyCardModule } from '@angular/material/legacy-card';
import { MatLegacyDialogModule } from '@angular/material/legacy-dialog';
import { TranslateModule } from '@ngx-translate/core';
import { PageBuildingBlockPreviewModule } from '../../../../features/target-pages/shared/page-building-block-preview/page-building-block-preview.module';
import { TargetPreviewPopupComponent } from './target-preview-popup.component';

@NgModule({
  declarations: [TargetPreviewPopupComponent],
  imports: [
    CommonModule,
    MatLegacyDialogModule,
    MatLegacyButtonModule,
    FlexLayoutModule,
    MatDividerModule,
    MatLegacyCardModule,
    PageBuildingBlockPreviewModule,
    TranslateModule.forChild(),
  ],
  exports: [TargetPreviewPopupComponent],
})
export class TargetPreviewPopupModule {}
