import { InstantButtonOption } from '@core/models/instant-button-option';

export enum CornerEnum {
  Round = 0,
  Rounded = 1,
  Square = 2,
}

export const CornerRound: InstantButtonOption = {
  name: CornerEnum[CornerEnum.Round],
  value: CornerEnum.Round,
  optionValue: 'corner-round',
  svgIcon: 'corner-round',
};

export const CornerRounded: InstantButtonOption = {
  name: CornerEnum[CornerEnum.Rounded],
  value: CornerEnum.Rounded,
  optionValue: 'corner-rounded',
  svgIcon: 'corner-rounded',
};

export const CornerSquare: InstantButtonOption = {
  name: CornerEnum[CornerEnum.Square],
  value: CornerEnum.Square,
  optionValue: 'corner-square',
  svgIcon: 'corner-square',
};

export const CornerList: Array<InstantButtonOption> = [CornerRound, CornerRounded, CornerSquare];
