import { createSelector } from '@ngrx/store';
import { getAciPaymentProviderDetails } from './aci-payment-provider-details.select';
import { getEcommercePlatform, getPlatformDetails } from './ecommerce-platform.select';
import { getPaymentGatewayDetails } from './payment-gateway-details.select';
import { getPaymentProviderDetails, getSelectedPaymentProvider } from './payment-provider-details.select';

export const getAggregateDetails = createSelector(
  getEcommercePlatform,
  getPlatformDetails,
  getPaymentGatewayDetails,
  getPaymentProviderDetails,
  getSelectedPaymentProvider,
  getAciPaymentProviderDetails,
  (
    ecommercePlatformDetails,
    platformDetails,
    paymentGatewayDetails,
    paymentProviderDetails,
    selectedPaymentProvider,
    aciPaymentProviderDetails,
  ) => ({
    ecommercePlatformDetails,
    platformDetails,
    paymentGatewayDetails,
    paymentProviderDetails,
    selectedPaymentProvider,
    aciPaymentProviderDetails,
  }),
);
