import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import Route from '@core/constants/route';
import { AUTH_SERVICE } from '@core/core.module';
import AudioEngagement from '@core/models/engagement/audio-engagement';
import BaseEngagement from '@core/models/engagement/base-engagement';
import { BeaconEngagement } from '@core/models/engagement/beacon-engagement';
import ImageEngagement from '@core/models/engagement/image-engagement';
import LinkEngagement from '@core/models/engagement/link-engagement';
import { LocationEngagement } from '@core/models/engagement/location-engagement';
import SmartCodeEngagement from '@core/models/engagement/smartcode-engagement';
import { EngagementTypeEnum } from '@core/models/enums/engagement-type-enum';
import { AppSettingService } from '@core/services/app-setting.service';
import { AuthServiceInterface, NO_PAY_PAYMENT_FLOW } from '@core/services/auth/auth-service-inteface';
import { EngagementAPIService } from '@core/services/engagement-api.service';
import { PaymentApiService } from '@core/services/payment-api.service';
import { environment } from '@env/environment';
import { TranslateService } from '@ngx-translate/core';
import { PopupService } from '@shared/components/popup/popup.service';
import { BehaviorSubject } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { LogUtils } from 'src/app/utils/log-utils';
import InstantWebEngagement from '@core/models/engagement/instant-web-engagement';
import Partners from "@core/constants/Partners";

@Injectable()
export class EngagementCreationService {
  engagement: BaseEngagement;
  engagementDetailsShown = new BehaviorSubject<boolean>(true);
  showCreationWizard = new BehaviorSubject<boolean>(true);
  processingEngagement = new BehaviorSubject<boolean>(false);

  constructor(
    private router: Router,
    @Inject(AUTH_SERVICE) private authService: AuthServiceInterface,
    private engagementAPIService: EngagementAPIService,
    private paymentAPIService: PaymentApiService,
    private popupService: PopupService,
    private translateService: TranslateService,
    private appSettingService: AppSettingService,
  ) {}

  async setEngagement(type: EngagementTypeEnum) {
    if (!this.authService.loggedIn) {
      this.authService.login('/', { partner: environment.partner });
      return;
    }
    switch (type) {
      case EngagementTypeEnum.Image:
        this.engagement = new ImageEngagement(this.paymentAPIService, this.appSettingService, this.authService);
        break;
      case EngagementTypeEnum.Audio:
        this.engagement = new AudioEngagement(this.paymentAPIService, this.appSettingService);
        break;
      case EngagementTypeEnum.Beacon:
        this.engagement = new BeaconEngagement(this.paymentAPIService, this.appSettingService);
        break;
      case EngagementTypeEnum.Geolocation:
        this.engagement = new LocationEngagement(this.paymentAPIService, this.appSettingService);
        break;
      case EngagementTypeEnum.SmartCode:
        this.engagement = new SmartCodeEngagement(this.paymentAPIService, this.appSettingService);
        break;
      case EngagementTypeEnum.Link:
        this.engagement = new LinkEngagement(this.paymentAPIService, this.appSettingService);
        break;
      case EngagementTypeEnum.InstantWeb:
        this.engagement = new InstantWebEngagement(this.paymentAPIService, this.appSettingService);
        break;
      default:
        console.log('Unsupported type');
    }
    await this.navigateToStep();
  }

  showEngagementDetails() {
    this.engagementDetailsShown.next(true);
  }

  hideEngagementDetails() {
    this.engagementDetailsShown.next(false);
  }

  showEngagementCreationWizard() {
    this.showCreationWizard.next(true);
  }

  hideEngagementCreationWizard() {
    this.showCreationWizard.next(false);
  }

  showProcessingButton() {
    this.processingEngagement.next(true);
  }

  hideProcessingButton() {
    this.processingEngagement.next(false);
  }

  setPreviousStep() {
    if (this.router.url.substring(1) === this.engagement.getPathForStep()[0]) {
      this.engagement.currentStep -= 1;
    }

    this.navigateToStep();
  }

  setNextStep() {
    this.engagement.currentStep += 1;
    this.navigateToStep();
  }

  async navigateToStep() {
    await this.router.navigate(this.engagement.getPathForStep());
  }

  async navigateToFirstStep() {
    await this.router.navigate([Route.ENGAGEMENT_CREATION]);
  }

  createEngagement(reference: string, redirectMethod: string) {
    this.showProcessingButton();
    this.engagement
      .getPayload$()
      .pipe(
        switchMap((payload) => {
          payload.engagementReference = reference;
          payload.redirectMethod = redirectMethod;
          payload.deferredPayment = this.getDefferedPayment();
          return this.engagementAPIService.createEngagement(payload);
        }),
      )
      .subscribe(
        (response) => {
          if (!response) {
            this.showEngagementFailurePopup();
          }
          this.engagement.engagementId = response.id;
          this.setNextStep();
          this.hideProcessingButton();
        },
        (error) => {
          console.error(error);
          this.showEngagementFailurePopup();
          this.hideProcessingButton();
        },
      );
  }

  clearEngagement(): void {
    this.engagement = null;
  }

  async navigateToPaymentStep(engagementId: string, type: EngagementTypeEnum) {
    switch (type) {
      case EngagementTypeEnum.Image:
        this.engagement = new ImageEngagement(this.paymentAPIService, this.appSettingService, this.authService);
        break;
      case EngagementTypeEnum.Audio:
        this.engagement = new AudioEngagement(this.paymentAPIService, this.appSettingService);
        break;
      case EngagementTypeEnum.Beacon:
        this.engagement = new BeaconEngagement(this.paymentAPIService, this.appSettingService);
        break;
      case EngagementTypeEnum.Geolocation:
        this.engagement = new LocationEngagement(this.paymentAPIService, this.appSettingService);
        break;
      case EngagementTypeEnum.SmartCode:
        this.engagement = new SmartCodeEngagement(this.paymentAPIService, this.appSettingService);
        break;
      case EngagementTypeEnum.Link:
        this.engagement = new LinkEngagement(this.paymentAPIService, this.appSettingService);
        break;
      case EngagementTypeEnum.InstantWeb:
        this.engagement = new InstantWebEngagement(this.paymentAPIService, this.appSettingService);
        break;
      default:
        LogUtils.error(
          `Unsupported Engagement Type '${EngagementTypeEnum[type]}' with Id: ${engagementId} could not be paid`,
        );
        return;
    }
    this.engagement.engagementId = engagementId;
    this.engagement.currentStep = this.engagement.creationSteps.lastIndexOf(Route.MAKE_PAYMENT);
    await this.navigateToStep();
  }

  private getDefferedPayment() {
    if (environment['partner'] !== Partners.ACI) {
      return environment.defferedPayment || this.authService.paymentFlow === NO_PAY_PAYMENT_FLOW;
    } else {
      return true;
    }

  }
  private showEngagementFailurePopup(): void {
    this.translateService
      .get('FAILED_TO_CREATE_ENGAGEMENT')
      .pipe(
        switchMap((translated) =>
          this.popupService.successStatusPopup({
            componentConfiguration: { isSuccess: false, message: translated },
            height: 'auto',
            width: 'auto',
            maxWidth: '430px',
            backdropClass: 'blurred-backdrop',
          }),
        ),
      )
      .subscribe(() => {
        this.clearEngagement();
        this.router.navigateByUrl(Route.DASHBOARD);
      });
  }
}
