import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';
import { TranslateModule } from '@ngx-translate/core';
import { InformationPanelComponent } from './information-panel.component';

@NgModule({
  declarations: [InformationPanelComponent],
  imports: [CommonModule, FlexLayoutModule, TranslateModule, MatLegacyButtonModule],
  exports: [InformationPanelComponent],
})
export class InformationPanelModule {}
