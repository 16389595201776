import { Action, createReducer, on } from '@ngrx/store';
import * as PaymentProviderDetailsActions from '../actions/payment-provider-details.actions';

export interface PaymentProviderDetailsState {
  computopMerchantId: string | null;
  computopHmacKey: string | null;
  computopBlowfishKey: string | null;
  currencyForPayment: string | null;
  timezone: string | null;
  phone: string | null;
  termsAndConditionsUrl: string | null;
  privacyPolicyUrl: string | null;
  companyInformationUrl: string | null;
  storeName: string | null;
}

export const initialState: PaymentProviderDetailsState = {
  computopMerchantId: null,
  computopHmacKey: null,
  computopBlowfishKey: null,
  currencyForPayment: null,
  timezone: null,
  phone: null,
  termsAndConditionsUrl: null,
  privacyPolicyUrl: null,
  companyInformationUrl: null,
  storeName: null,
};

const paymentProviderDetailsReducer = createReducer(
  initialState,
  on(PaymentProviderDetailsActions.createPaymentProviderDetails, (state, { paymentProviderDetails }) => ({
    ...state,
    ...paymentProviderDetails,
  })),
  on(PaymentProviderDetailsActions.resetPaymentProviderDetails, () => ({
    ...initialState,
  })),
);

export function reducer(state = initialState, action: Action): PaymentProviderDetailsState {
  return paymentProviderDetailsReducer(state, action);
}
