import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Endpoints } from '@core/constants/Endpoints';
import { EngagementAdapter } from '@core/models/adapters/engagement-adapter';
import { EngagementDTO } from '@core/models/dtos/engagement-dto';
import { Merchant } from '@core/models/dtos/merchant';
import { MethodResponseDTO } from '@core/models/dtos/method-response-dto';
import { UserEngagementDTO, UserEngagementsDTO } from '@core/models/dtos/user-engagements-dto';
import { WatermarkedFileDownloadDTO } from '@core/models/dtos/watermarked-file-download';
import { EngagementTypeEnum } from '@core/models/enums/engagement-type-enum';
import { FileUploadForWatermarkingResult } from '@core/models/file-upload-for-watermarking-result';
import { EngagementViewModel } from '@core/models/view-models/engagement-view-model';
import { BaseAPIServiceService } from '@core/services/http/base-apiservice.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class EngagementAPIService extends BaseAPIServiceService {
  constructor(protected http: HttpClient, private adapter: EngagementAdapter) {
    super(http);
  }

  getAssociatedEngagements(page: number, pageSize: number, targetPageId: string) {
    const paramObjects: any = {
      start: page,
      take: pageSize,
      targetPageId,
    };

    const params = new HttpParams({
      fromObject: paramObjects,
    });

    return this.http
      .get<{ data: EngagementViewModel[]; total: number }>(Endpoints.GET_ASSOCIATED_ENGAGEMENTS, {
        params: params,
      })
      .pipe(
        map((response) => ({
          data: response.data.map((engagement) => this.adapter.fromDTO(engagement)),
          total: response.total,
        })),
      );
  }

  getUserEngagements(
    page: number,
    pageSize: number,
    engagementTypeEnums: string[] = [],
    engagementOverallStatusEnums: string[] = [],
    fromDate?: string,
    searchTerm?: string,
    sort: { fieldName: string; ascending: boolean } = null,
  ): Observable<{ data: EngagementViewModel[]; total: number }> {
    let paramObjects: any = {
      start: page.toString(),
      take: pageSize.toString(),
    };

    if (sort) {
      paramObjects = { ...paramObjects, sortField: sort.fieldName, ascending: sort.ascending };
    }

    if (engagementTypeEnums && engagementTypeEnums.length > 0) {
      paramObjects = { ...paramObjects, engagementTypeEnums: engagementTypeEnums };
    }

    if (engagementOverallStatusEnums && engagementOverallStatusEnums.length > 0) {
      paramObjects = { ...paramObjects, engagementStatusEnums: engagementOverallStatusEnums };
    }

    if (fromDate) {
      paramObjects = { ...paramObjects, fromDate: new Date(fromDate).toISOString() };
    }

    if (searchTerm) {
      paramObjects = { ...paramObjects, searchTerm };
    }

    const params = new HttpParams({
      fromObject: paramObjects,
    });

    return this.http
      .get<{ data: EngagementViewModel[]; total: number }>(Endpoints.GET_ENGAGEMENTS, {
        params: params,
      })
      .pipe(
        map((response) => ({
          data: response.data.map((engagement) => this.adapter.fromDTO(engagement)),
          total: response.total,
        })),
      );
  }

  deleteEngagement(id: string): Observable<unknown> {
    return this.http.delete(Endpoints.DELETE_ENGAGEMENT + id);
  }

  batchDeleteEngagement(engagements: string[]): Observable<unknown> {
    return this.http.post(Endpoints.BATCH_DELETE_ENGAGEMENT, engagements);
  }

  updateEngagementStatus(payload: any): Observable<EngagementViewModel> {
    return this.http
      .post<UserEngagementDTO>(Endpoints.SET_ENGAGEMENT_STATUS, payload)
      .pipe(map((response) => this.adapter.fromDTO(response.data)));
  }

  batchUpdateEngagement(engagements: string[], status: string): Observable<unknown> {
    return this.http.post(`${Endpoints.BATCH_UPDATE_ENGAGEMENT}/${status}`, engagements);
  }

  createEngagement(payload: any): Observable<EngagementViewModel> {
    console.log('Creating engagement: ', { endpoint: Endpoints.CREATE_ENGAGEMENT, payload });
    return this.http.post<any>(Endpoints.CREATE_ENGAGEMENT, payload);
  }

  downloadWatermarkedFile(id: string, type: EngagementTypeEnum): Observable<string> {
    return this.http
      .get<WatermarkedFileDownloadDTO>(
        `${Endpoints.GET_WATERMARKED_FILE}?engagementId=${id}&preview=false&type=${type}&watermarked=true`,
      )
      .pipe(map((response) => response.data.url));
  }

  downloadAssetPack(id: string): Observable<ArrayBuffer> {
    const options: {
      headers?: HttpHeaders;
      observe?: 'body';
      params?: HttpParams;
      reportProgress?: boolean;
      responseType: 'arraybuffer';
      withCredentials?: boolean;
    } = {
      responseType: 'arraybuffer',
    };
    return this.http
      .get(`${Endpoints.GET_ASSETS_PACK}?engagementId=${id}`, options)
      .pipe(map((file: ArrayBuffer) => {
        return file;
      }));
  }

  downloadWatermarkedFileWithSize(id: string, type: EngagementTypeEnum, width: Number): Observable<string> {
    return this.http
      .get<WatermarkedFileDownloadDTO>(
        `${Endpoints.GET_WATERMARKED_FILE}?engagementId=${id}&preview=false&type=${type}&watermarked=true&width=${width}`,
      )
      .pipe(map((response) => response.data.url));
  }

  getWeChatQrCode(id: string) {
    const options: {
      headers?: HttpHeaders;
      observe?: 'body';
      params?: HttpParams;
      reportProgress?: boolean;
      responseType: 'arraybuffer';
      withCredentials?: boolean;
    } = {
      responseType: 'arraybuffer',
    };
    return this.http.get(`${Endpoints.GET_WE_CHAT_QR_CODE}${id}`, options).pipe(
      map((file: ArrayBuffer) => {
        return file;
      }),
    );
  }

  uploadImageFileForWatermarking(file: FormData): Observable<FileUploadForWatermarkingResult> {
    return this.http.post<FileUploadForWatermarkingResult>(Endpoints.UPLOAD_WATERMARK_IMAGES_URL, file);
  }

  uploadAudioFileForWatermarking(file: FormData): Observable<FileUploadForWatermarkingResult> {
    return this.http.post<FileUploadForWatermarkingResult>(Endpoints.UPLOAD_WATERMARK_AUDIO_URL, file);
  }

  getMerchantInfo(): Observable<Merchant> {
    return this.http
      .get<MethodResponseDTO<Merchant>>(Endpoints.GET_MERCHANT_NAME)
      .pipe(map((response) => new Merchant(response.data)));
  }

  getEngagement(engagementId: string): Observable<EngagementDTO> {
    return this.http.get<EngagementDTO>(`${Endpoints.GET_ENGAGEMENT}?engagementId=${engagementId}`);
  }

  getEngagementViewModel(engagementId: string): Observable<EngagementViewModel> {
    return this.http
      .get<EngagementDTO>(`${Endpoints.GET_ENGAGEMENT}?engagementId=${engagementId}`)
      .pipe(map((response) => this.adapter.fromDTO(response)));
  }

  updateEngagementTargetPage(engagementId: string, actId: string, startDate: Date): Observable<boolean> {
    return this.http.put<boolean>(`${Endpoints.UPDATE_ENGAGEMENT_ACT}${engagementId}/${actId}/${startDate}`, null);
  }
}
