import { ImageDimensions } from '@core/models/image-dimensions';

export enum FileUploadTypeEnum {
  IMAGE,
  AUDIO,
}

export interface FileUploadConfiguration {
  acceptedFileFormats: string[];
  maxFileSize: number;
  translationSectionName: string;
  minImageDimensions?: ImageDimensions;
}
