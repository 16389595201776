import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule, Optional, SkipSelf, InjectionToken, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { TokenInterceptor } from '@core/interceptors/token.interceptor';
import { AuthService } from '@core/services/auth/auth.service';
import { EngagementAPIService } from '@core/services/engagement-api.service';
import { TranslateService } from '@ngx-translate/core';
import { AuthServiceInterface } from './services/auth/auth-service-inteface';
import { AuthTestService } from './services/auth/auth-test.service';
import { MerchantAPIService } from './services/merchant-api.service';

export let AUTH_SERVICE = new InjectionToken<AuthServiceInterface>('AuthServiceInterface');

@NgModule({
  imports: [CommonModule],
  declarations: [],
  providers: [
    {
      provide: AUTH_SERVICE,
      useFactory: (routerRouter: Router, translateService: TranslateService) => {
        if (localStorage.getItem('auth0Cypress')) {
          return new AuthTestService();
        }

        return new AuthService(routerRouter, translateService);
      },
      deps: [Router, TranslateService],
    },
    MerchantAPIService,
    EngagementAPIService,
    {
      provide: HTTP_INTERCEPTORS,
      useFactory: (injector: Injector) => new TokenInterceptor(injector.get(AUTH_SERVICE)),
      deps: [Injector],
      multi: true,
    },
  ],
  exports: [],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule?: CoreModule) {
    if (parentModule) {
      throw new Error('CoreModule is already loaded. Import it in the AppModule only');
    }
  }
}
