// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  width: 100%;
}

.container {
  display: flex;
  width: 100%;
  height: auto;
  align-items: center;
}
.container .file-preview-container {
  flex-direction: row;
  display: flex;
  overflow: auto;
  height: 150px;
  margin-bottom: 20px;
}
.container .file-preview {
  width: 100%;
  display: flex;
  height: auto;
  background-color: white;
  box-shadow: 0 0 12px 2px rgba(205, 208, 212, 0.5607843137);
  flex-direction: column;
  padding: 1em;
}
.container .file-preview .image-container {
  max-height: auto;
  max-width: 1000px;
}
.container .file-preview .img {
  max-width: 100%;
  max-height: 500px;
  object-fit: contain;
}
.container .file-preview .button-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.container .file-preview .button-container .change-btn {
  width: 200px;
  height: 50px;
  line-height: 1.4;
  color: rgb(16, 39, 118);
  font-size: 16px;
  background-color: #f0f5fb;
  font-weight: 600;
  margin: auto;
}

.margin-left-15 {
  margin-left: 15px;
}

.media-type-select {
  font-weight: 800;
  font-size: 11px;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/file-preview/product-image-preview/product-image-preview.component.scss"],"names":[],"mappings":"AAGA;EACE,WAAA;AAFF;;AAKA;EACE,aAAA;EACA,WAAA;EACA,YAAA;EACA,mBAAA;AAFF;AAIE;EACE,mBAAA;EACA,aAAA;EACA,cAAA;EACA,aAAA;EACA,mBAAA;AAFJ;AAKE;EACE,WAAA;EACA,aAAA;EACA,YAAA;EAEA,uBAAA;EACA,0DAAA;EACA,sBAAA;EACA,YAAA;AAJJ;AAMI;EACE,gBAAA;EACA,iBAAA;AAJN;AAOI;EACE,eAAA;EACA,iBAAA;EACA,mBAAA;AALN;AAQI;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,WAAA;AANN;AAQM;EACE,YAAA;EACA,YAAA;EACA,gBAAA;EACA,uBAAA;EACA,eAAA;EACA,yBAAA;EACA,gBAAA;EACA,YAAA;AANR;;AAYA;EACE,iBAAA;AATF;;AAYA;EACE,gBAAA;EACA,eAAA;AATF","sourcesContent":["@import 'node_modules/@angular/material/theming';\n@import 'variables';\n\n:host {\n  width: 100%;\n}\n\n.container {\n  display: flex;\n  width: 100%;\n  height: auto;\n  align-items: center;\n\n  .file-preview-container {\n    flex-direction: row;\n    display: flex;\n    overflow: auto;\n    height: 150px;\n    margin-bottom: 20px;\n  }\n\n  .file-preview {\n    width: 100%;\n    display: flex;\n    height: auto;\n    // margin-right: 5px;\n    background-color: white;\n    box-shadow: 0 0 12px 2px #cdd0d48f;\n    flex-direction: column;\n    padding: 1em;\n\n    .image-container {\n      max-height: auto;\n      max-width: 1000px;\n    }\n\n    .img {\n      max-width: 100%;\n      max-height: 500px;\n      object-fit: contain;\n    }\n\n    .button-container {\n      display: flex;\n      align-items: center;\n      justify-content: center;\n      width: 100%;\n\n      .change-btn {\n        width: 200px;\n        height: 50px;\n        line-height: 1.4;\n        color: rgb(16 39 118);\n        font-size: 16px;\n        background-color: #f0f5fb;\n        font-weight: 600;\n        margin: auto;\n      }\n    }\n  }\n}\n\n.margin-left-15 {\n  margin-left: 15px;\n}\n\n.media-type-select {\n  font-weight: 800;\n  font-size: 11px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
