import Route from '@core/constants/route';
import { CardInfo } from '@core/models/card-info';
import { TargetPagesEnum } from '@core/models/enums/target-page-enum';

const NewTargetPagesCards: CardInfo[] = [
  {
    name: 'INFORMATION',
    type: TargetPagesEnum.InformationPage,
    imageUrl: 'assets/images/information-background.png',
    svgIcon: 'info-target',
    url: Route.INFORMATION_TARGET_PAGE,
    elementId: 'information-target-page-card',
    limit: 0,
    hasPassedLimit: false,
    isDisabled: false,
  },
  {
    name: 'ACT',
    type: TargetPagesEnum.Act,
    imageUrl: 'assets/images/act-background.png',
    svgIcon: 'act-target',
    url: Route.ACT_TARGET_PAGE,
    elementId: 'act-target-page-card',
    limit: 0,
    hasPassedLimit: false,
    isDisabled: false,
  },
  {
    name: 'BUY',
    type: TargetPagesEnum.Buy,
    imageUrl: 'assets/images/buy-background.png',
    svgIcon: 'shopping-cart-target',
    url: Route.BUY_TARGET_PAGE,
    elementId: 'buy-target-page-card',
    limit: 0,
    hasPassedLimit: false,
    isDisabled: false,
  },
  {
    name: 'URL',
    type: TargetPagesEnum.Url,
    imageUrl: 'assets/images/link-bg.png', // TODO: provide desired image location
    svgIcon: 'link-target',
    url: Route.URL_TARGET_PAGE,
    elementId: 'url-target-page-card',
    limit: 0,
    hasPassedLimit: false,
    isDisabled: false,
  },
];

const SavedTargetPagesCards: CardInfo[] = [
  {
    name: 'SAVED_TARGET_PAGES',
    type: TargetPagesEnum.SavedTargetPages,
    imageUrl: 'assets/images/saved-target-pages-background.png',
    svgIcon: 'folder',
    url: Route.SAVED_TARGETS_PAGES,
    elementId: 'saved-target-page-card',
    limit: 0,
    hasPassedLimit: false,
    isDisabled: false,
  },
];

const InstantWebEngagementTargetPageCards: CardInfo[] = [
  {
    name: 'INFORMATION',
    type: TargetPagesEnum.InformationPage,
    imageUrl: 'assets/images/information-background.png',
    svgIcon: 'info-target',
    url: Route.INFORMATION_TARGET_PAGE,
    elementId: 'information-target-page-card',
    limit: 0,
    hasPassedLimit: false,
    isDisabled: true,
  },
  {
    name: 'ACT',
    type: TargetPagesEnum.Act,
    imageUrl: 'assets/images/act-background.png',
    svgIcon: 'act-target',
    url: Route.ACT_TARGET_PAGE,
    elementId: 'act-target-page-card',
    limit: 0,
    hasPassedLimit: false,
    isDisabled: false,
  },
  {
    name: 'BUY',
    type: TargetPagesEnum.Buy,
    imageUrl: 'assets/images/buy-background.png',
    svgIcon: 'shopping-cart-target',
    url: Route.BUY_TARGET_PAGE,
    elementId: 'buy-target-page-card',
    limit: 0,
    hasPassedLimit: false,
    isDisabled: false,
  },
  {
    name: 'URL',
    type: TargetPagesEnum.Url,
    imageUrl: 'assets/images/link-bg.png', // TODO: provide desired image location
    svgIcon: 'link-target',
    url: Route.URL_TARGET_PAGE,
    elementId: 'url-target-page-card',
    limit: 0,
    hasPassedLimit: false,
    isDisabled: true,
  },
];

export const TargetPageCards = {
  NEW_TARGET_PAGES: NewTargetPagesCards,
  EXISTING_TARGET_PAGES: SavedTargetPagesCards,
  INSTANT_WEB_ENGAGEMENT_TARGET_PAGES: InstantWebEngagementTargetPageCards,
};
