import { SelectionModel } from '@angular/cdk/collections';
import { FlatTreeControl } from '@angular/cdk/tree';
import {
  ChangeDetectorRef,
  Component,
  Injectable,
  Input,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import {
  MatTree,
  MatTreeFlatDataSource,
  MatTreeFlattener,
  MatTreeNode,
  MatTreeNodePadding,
} from '@angular/material/tree';
import { BehaviorSubject, combineLatest, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, takeUntil } from 'rxjs/operators';
import { BaseComponent } from '../base-component/base-component';

/**
 * Node for to-do item
 */
export class TreeNode {
  children: TreeNode[];
  filteredChildren: TreeNode[];
  item: string;
  id: number;
}

/** Flat to-do item node with expandable and level information */
export class TreeFlatNode {
  id: number;
  item: string;
  level: number;
  expandable: boolean;
  visible: boolean;
}

/**
 * Checklist database, it can build a tree structured Json object.
 * Each node in Json object represents a to-do item or a category.
 * If a node is a category, it has children items and new items can be added under the category.
 */
// @Injectable()
export class ChecklistDatabase {
  searchTermChange = new BehaviorSubject('');
  dataChange = new BehaviorSubject<TreeNode[]>([]);
  selectionChange = new BehaviorSubject<TreeFlatNode[]>([]);
  unselectedNodeChange = new Subject<TreeFlatNode>();

  get data(): TreeNode[] {
    return this.dataChange.value;
  }

  constructor() {}

  getSearchTerm$() {
    return this.searchTermChange.pipe(debounceTime(500), distinctUntilChanged());
  }

  unselect(node: TreeFlatNode) {
    this.unselectedNodeChange.next(node);
  }

  getInitialData$() {
    return this.dataChange;
  }

  getData$() {
    return combineLatest([this.searchTermChange.pipe(debounceTime(300), distinctUntilChanged()), this.dataChange]).pipe(
      map(([searchTerm, data]) => {
        const temp = this.filterTreeAndRetainStructure(searchTerm, data);
        return temp;
      }),
    );
  }

  filterTreeAndRetainStructure(searchTerm: string, data: TreeNode[]): TreeNode[] {
    let result = [];
    for (let elem of data) {
      if (elem.item.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase())) {
        result.push(elem);
        elem.filteredChildren = this.filterTreeAndRetainStructure(searchTerm, elem.children);
      } else {
        result = result.concat(this.filterTreeAndRetainStructure(searchTerm, elem.children));
      }
    }

    return result;
  }

  initialize<T>(elems: T[], projElem: (elem: T) => TreeNode, projchildren: (elem: T) => T[]) {
    const data = this.buildFileTree(elems, projElem, projchildren);

    // Notify the change.
    this.dataChange.next(data);
  }

  /**
   * Build the file structure tree. The `value` is the Json object, or a sub-tree of a Json object.
   * The return value is the list of `TodoItemNode`.
   */
  private buildFileTree<T>(elems: T[], projElem: (elem: T) => TreeNode, projchildren: (elem: T) => T[]): TreeNode[] {
    if (elems == null || elems.length == 0) {
      return [];
    }

    const result: TreeNode[] = [];
    for (let elem of elems) {
      const todoItem = projElem(elem);
      todoItem.children = this.buildFileTree(projchildren(elem), projElem, projchildren);
      todoItem.filteredChildren = todoItem.children;
      result.push(todoItem);
    }

    return result;
  }

  updateItem(node: TreeNode, name: string) {
    node.item = name;
    this.dataChange.next(this.data);
  }
}

@Component({
  selector: 'app-tree-checklist',
  templateUrl: './tree-checklist.component.html',
  styleUrls: ['./tree-checklist.component.scss'],
})
export class TreeChecklistComponent extends BaseComponent implements OnInit {
  @Input()
  selectedNodes: TreeFlatNode[] = [];
  flatNodeMap = new Map<TreeFlatNode, TreeNode>();

  /** Map from nested node to flattened node. This helps us to keep the same object for selection */
  nestedNodeMap = new Map<TreeNode, TreeFlatNode>();

  /** A selected parent node to be inserted */
  selectedParent: TreeFlatNode | null = null;

  treeControl: FlatTreeControl<TreeFlatNode>;

  treeFlattener: MatTreeFlattener<TreeNode, TreeFlatNode>;

  dataSource: MatTreeFlatDataSource<TreeNode, TreeFlatNode>;

  /** The selection for checklist */
  checklistSelection = new SelectionModel<TreeFlatNode>(true /* multiple */, [], true);

  @ViewChildren(MatTreeNodePadding) paddings: QueryList<MatTreeNodePadding<TreeFlatNode>>;

  constructor(private _database: ChecklistDatabase, private changeDetectorRefs: ChangeDetectorRef) {
    super();
    this.treeFlattener = new MatTreeFlattener(this.transformer, this.getLevel, this.isExpandable, this.getChildren);
    this.treeControl = new FlatTreeControl<TreeFlatNode>(this.getLevel, this.isExpandable);
    this.dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);
    this.checklistSelection.changed.pipe(takeUntil(this.destroy$)).subscribe((_) => {
      this._database.selectionChange.next(this.checklistSelection.selected);
    });
    this._database.unselectedNodeChange.pipe(takeUntil(this.destroy$)).subscribe((flatNode) => {
      const nodeToUnselect = (this.treeControl.dataNodes || []).find((node) => node.id === flatNode.id);
      if (nodeToUnselect) {
        this.todoItemSelectionToggle(nodeToUnselect);
      }
    });
  }

  ngOnInit(): void {
    this._database
      .getInitialData$()
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        this.dataSource.data = data;
        this.updateSelection();
      });
    this._database
      .getData$()
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        this.dataSource.data = data;
        this.paddings.forEach((n) => {
          n._setPadding(true);
        });
        this.paddings.notifyOnChanges();
        this.treeControl.expandAll();
      });
  }

  ngAfterViewInit() {}

  getSelectedNodes() {
    const nodes = this.treeControl.dataNodes || [];

    return nodes.filter((node) => !!this.selectedNodes.find((x) => x.id == node.id));
  }

  private updateSelection() {
    const nodes = this.treeControl.dataNodes || [];
    for (let i = 0; i < nodes.length; i++) {
      if (!!this.selectedNodes.find((x) => x.id == nodes[i].id)) {
        this.todoItemSelectionToggle(nodes[i]);
      }
    }
  }

  getLevel = (node: TreeFlatNode) => node.level;

  isExpandable = (node: TreeFlatNode) => node.expandable;

  getChildren = (node: TreeNode): TreeNode[] => node.filteredChildren;

  hasChild = (_: number, _nodeData: TreeFlatNode) => _nodeData.expandable;

  isVisible = (_: number, _nodeData: TreeFlatNode) => _nodeData.visible;

  hasNoContent = (_: number, _nodeData: TreeFlatNode) => _nodeData.item === '';

  /**
   * Transformer to convert nested node to flat node. Record the nodes in maps for later use.
   */
  transformer = (node: TreeNode, level: number) => {
    const existingNode = this.nestedNodeMap.get(node);
    const flatNode = existingNode && existingNode.item === node.item ? existingNode : new TreeFlatNode();
    flatNode.item = node.item;
    flatNode.level = level;
    flatNode.id = node.id;
    flatNode.visible = true;
    flatNode.expandable = !!node.filteredChildren?.length;
    this.flatNodeMap.set(flatNode, node);
    this.nestedNodeMap.set(node, flatNode);
    return flatNode;
  };

  /** Whether all the descendants of the node are selected. */
  descendantsAllSelected(node: TreeFlatNode): boolean {
    const descendants = this.treeControl.getDescendants(node);
    const descAllSelected =
      descendants.length > 0 &&
      descendants.every((child) => {
        return this.checklistSelection.isSelected(child);
      });
    return descAllSelected;
  }

  /** Whether part of the descendants are selected */
  descendantsPartiallySelected(node: TreeFlatNode): boolean {
    const descendants = this.treeControl.getDescendants(node);
    const result = descendants.some((child) => this.checklistSelection.isSelected(child));
    return result && !this.descendantsAllSelected(node);
  }

  /** Toggle the to-do item selection. Select/deselect all the descendants node */
  todoItemSelectionToggle(node: TreeFlatNode): void {
    this.checklistSelection.toggle(node);
  }

  /** Toggle a leaf to-do item selection. Check all the parents to see if they changed */
  todoLeafItemSelectionToggle(node: TreeFlatNode): void {
    this.checklistSelection.toggle(node);
  }

  /* Checks all the parents when a leaf node is selected/unselected */
  checkAllParentsSelection(node: TreeFlatNode): void {
    let parent: TreeFlatNode | null = this.getParentNode(node);
    while (parent) {
      this.checkRootNodeSelection(parent);
      parent = this.getParentNode(parent);
    }
  }

  /** Check root node checked state and change it accordingly */
  checkRootNodeSelection(node: TreeFlatNode): void {
    const nodeSelected = this.checklistSelection.isSelected(node);
    const descendants = this.treeControl.getDescendants(node);
    const descAllSelected =
      descendants.length > 0 &&
      descendants.every((child) => {
        return this.checklistSelection.isSelected(child);
      });
    if (nodeSelected && !descAllSelected) {
      this.checklistSelection.deselect(node);
    } else if (!nodeSelected && descAllSelected) {
      this.checklistSelection.select(node);
    }
  }

  /* Get the parent node of a node */
  getParentNode(node: TreeFlatNode): TreeFlatNode | null {
    const currentLevel = this.getLevel(node);

    if (currentLevel < 1) {
      return null;
    }

    const startIndex = this.treeControl.dataNodes.indexOf(node) - 1;

    for (let i = startIndex; i >= 0; i--) {
      const currentNode = this.treeControl.dataNodes[i];

      if (this.getLevel(currentNode) < currentLevel) {
        return currentNode;
      }
    }
    return null;
  }
}
