import {
  ChangeDetectorRef,
  Component,
  ComponentFactoryResolver,
  EventEmitter,
  Input,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { MatLegacyTable } from '@angular/material/legacy-table';
import { MatSort } from '@angular/material/sort';
import { MAX_TITLE_LENGTH } from '@core/constants/Limits';
import { NgTemplateDataStoreDirective } from '@core/directives/ng-template-data-store.directive';
import { ActViewModel } from '@core/models/view-models/act-view-model';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { LogUtils } from 'src/app/utils/log-utils';
import { BaseComponent } from '../base-component/base-component';
import { ColumnDefinition, ColumnDefinitionEventsEnum, ColumnDefinitionTypesEnum } from './models/column-definition';
import { DashboardBlockComponent } from './models/dashboard-block-component';
import { GridOptions } from './models/grid-options';
import { UniqueGridItem } from './models/unique-grid-item';

const SpecialGridColumnNames = {
  MENU_ACTIONS: 'menuActions',
  BUTTON_ACTIONS: 'buttonActions',
};

@Component({
  selector: 'app-dashboard-table',
  templateUrl: './dashboard-table.component.html',
  styleUrls: ['./dashboard-table.component.scss'],
})
export class DashboardTableComponent extends BaseComponent implements OnInit {
  @ViewChildren(NgTemplateDataStoreDirective)
  set ngTemplateDirectives(val: QueryList<NgTemplateDataStoreDirective>) {
    this._ngTemplateDirectives$.next(val);
  }

  private _ngTemplateDirectives$: Subject<QueryList<NgTemplateDataStoreDirective>> = new Subject();
  SpecialGridColumnNames = SpecialGridColumnNames;
  ColumnDefinitionTypesEnum = ColumnDefinitionTypesEnum;
  maxTitleLength = MAX_TITLE_LENGTH;

  target: ActViewModel[];
  targetThumbnailImage: string[] = [];

  @Input() gridOptions: GridOptions;
  @Input() selectedRowIndex = -1;
  @Output() cellClickEvent: EventEmitter<UniqueGridItem> = new EventEmitter();
  @Output() favoriteSelectionEvent: EventEmitter<UniqueGridItem> = new EventEmitter();
  @Output() settingsPopupEvent: EventEmitter<GridOptions> = new EventEmitter();
  @Output() columnSortEvent: EventEmitter<MatSort> = new EventEmitter();
  @Output() chosenActionEvent: EventEmitter<UniqueGridItem> = new EventEmitter();
  @Output() rowSelectionEvent: EventEmitter<any> = new EventEmitter();

  @ViewChild(MatLegacyTable)
  table: MatLegacyTable<any>;

  @ViewChild(MatSort) sort: MatSort;
  columnsToShow: string[] = [];
  selectedRow?: any = null;

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private changeDetectorRef: ChangeDetectorRef,
  ) {
    super();
  }

  ngOnInit(): void {
    this.columnsToShow = this.getColumnsToShow();
    if (this.selectedRowIndex > -1) {
      this.selectedRow = this.gridOptions.rowData[this.selectedRowIndex];
    }
    this._ngTemplateDirectives$
      .pipe(takeUntil(this.destroy$))
      .subscribe((directives) => this.handleViewChange(directives));
    this.target = this.gridOptions.rowData;
    this.target.forEach((target) => this.targetThumbnailImage.push(target.thumbnailUrl));
  }

  handleViewChange(directives: QueryList<NgTemplateDataStoreDirective>) {
    if (!directives) {
      return;
    }
    for (const ngTemplateDirective of directives) {
      const viewContainerRef = ngTemplateDirective.vcRef;
      const internalData: { row: any; column: ColumnDefinition } = ngTemplateDirective.data;
      viewContainerRef.clear();

      if (internalData.column.component == null) {
        LogUtils.warning('Please provider a component for the custom ColumnDefinition');
        return;
      }

      const componentFactory = this.componentFactoryResolver.resolveComponentFactory(internalData.column.component);
      const componentRef = viewContainerRef.createComponent<DashboardBlockComponent>(componentFactory);
      componentRef.instance.rowData = internalData.row;
      this.changeDetectorRef.detectChanges();
    }
  }

  handleRowSelection(selectedRow: any): void {
    this.selectedRow = this.selectedRow === selectedRow ? null : selectedRow;
    this.rowSelectionEvent.emit(this.selectedRow);
  }

  onCellClick(index: number, row: any, column: ColumnDefinition): any {
    if (column.events.includes(ColumnDefinitionEventsEnum.CELL_CLICKED)) {
      this.cellClickEvent.emit({ row, column, index });
      return;
    }
    LogUtils.warning(this.getGridEmitWarnMessage('cellClickEvent', ColumnDefinitionEventsEnum.CELL_CLICKED));
  }

  handleFavoriteSelection(index: number, row: any, column: ColumnDefinition): void {
    if (column.events.includes(ColumnDefinitionEventsEnum.CELL_CLICKED)) {
      this.favoriteSelectionEvent.emit({ index, row, column });
      return;
    }

    LogUtils.warning(this.getGridEmitWarnMessage('favoriteSelectionEvent', ColumnDefinitionEventsEnum.CELL_CLICKED));
  }

  openSettingsPopup(): void {
    this.settingsPopupEvent.emit(this.gridOptions);
  }

  onSortChange(): void {
    this.columnSortEvent.emit(this.sort);
  }

  onActionClick(gridItem: UniqueGridItem): void {
    this.chosenActionEvent.emit(gridItem);
  }

  getGridEmitWarnMessage(targetEventName: string, missingColumnDefinitionEvent: ColumnDefinitionEventsEnum): string {
    return `In order to emit the ${targetEventName} it is required to specify the ${ColumnDefinitionEventsEnum[missingColumnDefinitionEvent]} event in ColumnDefinition.events`;
  }

  private getColumnsToShow(): string[] {
    const result: string[] = [];

    this.gridOptions.columnDefinitions
      .filter((column) => column.isActive)
      .forEach((column) => result.push(column.field));

    if (this.gridOptions.buttonActions.length > 0) {
      result.push(SpecialGridColumnNames.BUTTON_ACTIONS);
    }

    if (this.gridOptions.menuActions.length > 0) {
      result.push(SpecialGridColumnNames.MENU_ACTIONS);
    }

    return result;
  }
}
