import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';

class Observer {
  complete: () => any;
  error: (error?: any) => any;
  next: (success: any) => any;
}

export class SingleResponse {
  details: string;
  IsSuccess: boolean;
  status: number;
}

export class Responses {
  errors: SingleResponse[] = [];
  success: SingleResponse[] = [];
}

@Injectable({
  providedIn: 'root',
})
export class BaseAPIServiceService {
  genericError = '';
  constructor(protected http: HttpClient) {}

  createObservable(response) {
    return new Observable((observer: Observer) => {
      response.subscribe(
        (res) => {
          if (res?.errors && res.errors.length > 0) {
            observer.error(res.errors);
          } else {
            observer.next(res);
          }
          observer.complete();
        },
        (error) => {
          observer.error([{ title: error.name, detail: this.genericError, error }]);
        },
      );
    });
  }

  delete(url: string): Observable<Responses[]> {
    const response = this.http.delete<Responses>(url);

    return this.createObservable(response);
  }

  get<T>(url: string): Observable<T> {
    const response = this.http.get<Responses & T>(url);

    return this.createObservable(response);
  }

  post(url: string, body: object): Observable<Responses[]> {
    const response = this.http.post<Responses>(url, body);

    return this.createObservable(response);
  }

  put(url: string, body: object): Observable<Responses[]> {
    const response = this.http.put<Responses>(url, body);

    return this.createObservable(response);
  }
}
