import { Component, Input, OnInit } from '@angular/core';
import { EngagementTypeEnum } from '@core/models/enums/engagement-type-enum';

@Component({
  selector: 'app-creation-container-bar-item',
  templateUrl: './creation-container-bar-item.component.html',
  styleUrls: ['./creation-container-bar-item.component.scss'],
})
export class CreationContainerBarItemComponent implements OnInit {
  @Input() header: string;
  @Input() value: string;
  @Input() icon: string;
  @Input() bold: boolean;
  engagementType = EngagementTypeEnum;
  isIconAsAttribute: boolean;
  constructor() {}

  ngOnInit(): void {
    this.isIconAsAttribute =
      this.value === EngagementTypeEnum[EngagementTypeEnum.SmartCode].toUpperCase() ||
      this.value === EngagementTypeEnum[EngagementTypeEnum.InstantWeb].toUpperCase();
  }
}
