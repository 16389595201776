import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule, FlexModule } from '@angular/flex-layout';
import { MatDividerModule } from '@angular/material/divider';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';
import { MatLegacyCardModule } from '@angular/material/legacy-card';
import { MatLegacyCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatLegacyFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule } from '@angular/material/legacy-input';
import { MatLegacyProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { MatLegacyRadioModule } from '@angular/material/legacy-radio';
import { MatLegacySelectModule } from '@angular/material/legacy-select';
import { MatLegacyTabsModule } from '@angular/material/legacy-tabs';
import { MatLegacyTooltipModule } from '@angular/material/legacy-tooltip';
import { RouterModule } from '@angular/router';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { DataViewModule } from '@shared/components/data-view/data-view.module';
import { MaskedTextModule } from '@shared/components/masked-text/masked-text.module';
import { CustomPipeModule } from '@shared/pipes/custom-pipe.module';
import { SharedModule } from '@shared/shared.module';
import { ProgressSpinnerModule } from "primeng/progressspinner";
import { Aci3DSecureDetailsComponent } from './aci-3DSecure-details/aci-3DSecure-details.component';
import { ConfirmationMessageComponent } from './confirmation-message/confirmation-message.component';
import { ConfirmationComponent } from './confirmation/confirmation.component';
import { MenuWizardHelpSupportComponent } from './menu-wizard/menu-wizard-help-support/menu-wizard-help-support.component';
import { MenuWizardService } from './menu-wizard/menu-wizard-service/menu-wizard.service';
import { MenuWizardComponent } from './menu-wizard/menu-wizard.component';
import { OtherPaymentProviderFormComponent } from './other-payment-provider-form/other-payment-provider-form.component';
import { PaymentProviderBaseComponent } from './payment-provider-base/payment-provider-base.component';
import { PaymentProviderDetailsComponent } from './payment-provider-details/payment-provider-details.component';
import { PaymentProviderFormComponent } from './payment-provider-form/payment-provider-form.component';
import { PaymentProviderOtherDetailsComponent } from './payment-provider-other-details/payment-provider-other-details.component';
import { NeedMerchantLinkComponent } from './payment-settings/need-merchant-link/need-merchant-link.component';
import { PaymentSelectorComponent } from './payment-settings/payment-selector/payment-selector.component';
import { ProviderNotListedComponent } from './payment-settings/provider-not-listed/provider-not-listed.component';
import { PlatformDetailComponent } from './platform-detail/platform-detail.component';
import { SelectEcommercePlatformComponent } from './select-ecommerce-platform/select-ecommerce-platform.component';
import { SelfOnboardingComponent } from './self-onboarding.component';
import { metaReducers, selfOnboardingReducers } from './store';
import { SelfOnBoardingEffects } from './store/effects/self-on-boarding.effect';

@NgModule({
  declarations: [
    SelfOnboardingComponent,
    MenuWizardComponent,
    PaymentProviderDetailsComponent,
    ConfirmationMessageComponent,
    SelectEcommercePlatformComponent,
    PaymentSelectorComponent,
    NeedMerchantLinkComponent,
    PlatformDetailComponent,
    ProviderNotListedComponent,
    ConfirmationComponent,
    MenuWizardHelpSupportComponent,
    PaymentProviderFormComponent,
    PaymentProviderOtherDetailsComponent,
    OtherPaymentProviderFormComponent,
    PaymentProviderBaseComponent,
    Aci3DSecureDetailsComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    MatIconModule,
    MatDividerModule,
    FlexModule,
    RouterModule,
    TranslateModule,
    StoreModule.forFeature(`selfOnboardingState`, selfOnboardingReducers, { metaReducers: metaReducers(false) }),
    EffectsModule.forFeature([SelfOnBoardingEffects]),
    MatIconModule,
    CustomPipeModule,
    MatGridListModule,
    RouterModule,
    FlexLayoutModule,
    MaskedTextModule,
    MatLegacyCheckboxModule,
    MatLegacyTabsModule,
    MatLegacyRadioModule,
    MatLegacyProgressSpinnerModule,
    MatLegacySelectModule,
    MatLegacyButtonModule,
    MatLegacyCardModule,
    MatLegacyFormFieldModule,
    MatLegacyInputModule,
    MatLegacyTooltipModule,
    MatLegacySelectModule,
    DataViewModule,
    ProgressSpinnerModule
  ],
  providers: [MenuWizardService],
})
export class SelfOnboardingModule {}
