import Route from '@core/constants/route';
import DurationData from '@core/models/duration-data';
import GenericEngagement from '@core/models/engagement/generic-engagement';
import { EngagementTypeEnum } from '@core/models/enums/engagement-type-enum';
import { PricingTargetTypeEnum } from '@core/models/enums/pricing-target-type.enum';
import { ImageFile } from '@core/models/image-file';
import { PriceInfoModel } from '@core/models/price-info.model';
import { AppSettingService } from '@core/services/app-setting.service';
import { PaymentApiService } from '@core/services/payment-api.service';
import { Guid } from '@core/utils/Guid';
import * as moment from 'moment';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { ImageEngagementData } from '../image-engagement-data';
import { AuthServiceInterface } from '@core/services/auth/auth-service-inteface';

export default class ImageEngagement extends GenericEngagement {
  images: ImageFile[];
  file: File;
  useDigimarc: boolean;

  constructor(paymentApiService: PaymentApiService, appSettingService: AppSettingService, 
    private authService: AuthServiceInterface) {
    super(paymentApiService, appSettingService, EngagementTypeEnum.Image, PricingTargetTypeEnum.ImageEngagement, [
      Route.TRIGGERS,
      Route.IMAGE_UPLOAD,
      Route.TARGETS,
      Route.CHECKOUT,
      Route.MAKE_PAYMENT,
    ]);
    this.images = [];
    const duration = new DurationData();
    duration.endDate = moment().add(1, 'year').toDate();
    this.duration$ = new BehaviorSubject(duration);
    this.useDigimarc = false;
  }

  getTrigger() {
    return {
      svgIcon: 'engagement-image-no-background',
      name: this.file?.name,
      imageUrl: `/api/servicing/GetImage?path=${this.images[0]?.tempFileId}`,
      type: EngagementTypeEnum.Image,
    };
  }

  getPayload$() {
    return of({
      id: Guid.newGuid(),
      actInformation: this.actInformation,
      activeTrigger: this.activeTrigger,
      activePricingTargetType: this.activePricingTargetType,
      deferredPayment: false,
      watermarkedImageTrigger: {
        images: this.images,
        useDigimarc: this.authService.useDigimarc
      },
    });
  }

  getDuration$() {
    return this.duration$;
  }

  getPrice(): Observable<PriceInfoModel> {
    return super.getPrice();
  }

  updateImageEngagement(data: ImageEngagementData): void {
    this.images = data.images;
    this.file = data.file;
  }
}
