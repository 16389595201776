import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatLegacyDialogRef } from '@angular/material/legacy-dialog';
import { TINY_MCE_TEXT_BLOCK_INIT } from '@core/constants/TinyMceEditorInits';
import { environment } from '@env/environment';
import { EditorComponent } from '@tinymce/tinymce-angular';
import { overrideDefaultFontFamily, overrideFontFormats } from '../../../../../utils/tiny-mce-utils';
import { BasePageBuilderConfiguration } from '../base-page-builder-configuration';
import { ColorPickingBlockAbstractComponent } from '../color-picking-block-abstract-component';

@Component({
  selector: 'app-paragraph-customisation-popup',
  templateUrl: './paragraph-customisation-popup.component.html',
  styleUrls: ['./paragraph-customisation-popup.component.scss'],
})
export class ParagraphCustomisationPopupComponent
  extends ColorPickingBlockAbstractComponent<ParagraphCustomisationPopupComponent>
  implements OnInit, AfterViewInit {
  tinyApiKey: string;
  form: FormGroup;
  editorInit = TINY_MCE_TEXT_BLOCK_INIT;
  @ViewChild('textTinyMce') tinymce: EditorComponent;
  @Input() configuration: BasePageBuilderConfiguration;
  textContent: string;

  constructor(public dialogRef: MatLegacyDialogRef<ParagraphCustomisationPopupComponent>) {
    super(dialogRef);

    if (environment.tinyMceSettings.tinyMceFontsToOverride) {
      this.editorInit = overrideFontFormats(this.editorInit, environment.tinyMceSettings.tinyMceFontsToOverride);
    }
    if (environment.tinyMceSettings.tinyMceDefaultFontToOverride) {
      this.editorInit = overrideDefaultFontFamily(
        this.editorInit,
        environment.tinyMceSettings.tinyMceDefaultFontToOverride,
      );
    }
    this.tinyApiKey = environment.tinyApiKey;
    this.form = new FormGroup({
      content: new FormControl(null, [Validators.required, Validators.pattern(/^\S*/)]),
      backgroundColor: new FormControl(),
    });
  }

  ngOnInit(): void {
    this.form.patchValue({
      content: this.configuration.data.content,
      backgroundColor: this.configuration.data.style.backgroundColor,
    });
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  ngAfterViewInit(): void {
    this.form.get('content').valueChanges.subscribe((htmlContent) => {
      this.textContent = this.getTextContent();

      if (htmlContent === '<p>&nbsp;</p>\n<p>&nbsp;</p>') {
        this.form.patchValue({ content: '' });
      }
    });
  }

  onSubmit() {
    this.textContent = this.getTextContent();
    const { content, backgroundColor } = this.form.value;
    this.configuration.data.content = content;
    this.configuration.data.style.backgroundColor = backgroundColor;
    this.configuration.data.text = this.textContent;
    this.dialogRef.close(this.configuration.data);
  }

  private getTextContent() {
    return this.tinymce.editor?.getContent({ format: 'text' }) ?? '';
  }
}
