import Route from '@core/constants/route';

export const EngagementCreationFlows = {
  Location: [Route.TRIGGERS, Route.LOCATION, Route.TARGETS, Route.DURATION],
  Image: [Route.TRIGGERS, Route.IMAGE_UPLOAD, Route.TARGETS, Route.DURATION],
  Audio: [Route.TRIGGERS, Route.AUDIO_UPLOAD, Route.TARGETS, Route.DURATION],
  Beacon: [],
  SmartCode: [Route.TRIGGERS, Route.SMARTCODE_TRIGGER, Route.TARGETS, Route.DURATION],
  Link: [Route.TRIGGERS, Route.LINK, Route.TARGETS, Route.DURATION],
  InstantWeb: [Route.TRIGGERS, Route.INSTANT_WEB_TRIGGER, Route.TARGETS, Route.ACT_TARGET_PAGE, Route.BUY_TARGET_PAGE],
  InformationPage: [Route.TARGETS, Route.INFORMATION_TARGET_PAGE, Route.DURATION],
  Act: [Route.TARGETS, Route.ACT_TARGET_PAGE, Route.DURATION],
  Buy: [Route.TARGETS, Route.BUY_TARGET_PAGE, Route.DURATION],
  Url: [Route.TARGETS, Route.URL_TARGET_PAGE, Route.DURATION],
  SavedTargetPages: [Route.TARGETS, Route.SAVED_TARGETS_PAGES, Route.DURATION],
};
