import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { AuthServiceInterface } from './auth-service-inteface';
import jwt_decode from 'jwt-decode';
import { RedirectLoginResult } from '@auth0/auth0-spa-js';

@Injectable({
  providedIn: 'root',
})
export class AuthTestService implements AuthServiceInterface {
  isAuthenticated$: Observable<boolean> = new BehaviorSubject<boolean>(false);
  userProfile$: Observable<any> = new BehaviorSubject<any>({});
  handleRedirectCallback$: Observable<RedirectLoginResult> = new BehaviorSubject<any>({});
  loggedIn: boolean;
  isLoggedIn$ = new BehaviorSubject<boolean>(false);

  private accessToken$: BehaviorSubject<string> = new BehaviorSubject<string>('');

  constructor() {}

  login$(redirectPath?: string, queryParams?: any): Observable<void> {
    const loginResult = JSON.parse(localStorage.getItem('auth0Cypress'));
    if (loginResult) {
      this.accessToken$.next(loginResult.access_token);
      (this.isAuthenticated$ as BehaviorSubject<boolean>).next(true);
      this.loggedIn = true;
      (this.userProfile$ as BehaviorSubject<any>).next(jwt_decode(loginResult.id_token));
    }
    return of();
  }
  paymentFlow: string = 'Pay';
  useDigimarc: boolean = false;

  getPaymentFlow$(): Observable<string> {
    return of('Pay');
  }

  getTokenSilently$(options?: any): Observable<string> {
    return this.accessToken$;
  }

  logout(): void {
    throw new Error('Method not implemented.');
  }

  login(redirectPath: string): void {
    const loginResult = JSON.parse(localStorage.getItem('auth0Cypress'));
    if (loginResult) {
      this.accessToken$.next(loginResult.access_token);
      (this.isAuthenticated$ as BehaviorSubject<boolean>).next(true);
      this.loggedIn = true;
      (this.userProfile$ as BehaviorSubject<any>).next(jwt_decode(loginResult.id_token));
    }
  }
}
