import { AfterViewInit, Directive, OnDestroy, Renderer2, ViewContainerRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Directive({
  selector: '[styleMatPaginator]',
})
export class StyleMatPaginatorDirective implements OnDestroy, AfterViewInit {
  protected destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(private translateService: TranslateService, private vr: ViewContainerRef, private renderer: Renderer2) {
    this.translateService.onLangChange.pipe(takeUntil(this.destroy$)).subscribe((_) => {
      this.initNextPrevButtonTexts();
    });
  }

  ngAfterViewInit(): void {
    this.initNextPrevButtonTexts();
  }

  initNextPrevButtonTexts() {
    const nextButton = this.vr.element.nativeElement.querySelector('.mat-paginator-navigation-next');
    const prevButton = this.vr.element.nativeElement.querySelector('.mat-paginator-navigation-previous');

    let nextButtonDiv = nextButton.querySelector('.next-button-text');
    let prevButtonDiv = prevButton.querySelector('.prev-button-text');

    // display text for next button
    if (nextButtonDiv) {
      this.renderer.removeChild(nextButton, nextButtonDiv);
    }
    const nextButtonText = this.renderer.createText(this.translateService.instant('NEXT_PAGE'));
    nextButtonDiv = this.renderer.createElement('div');
    this.renderer.setAttribute(nextButtonDiv, 'class', 'next-button-text');
    this.renderer.appendChild(nextButtonDiv, nextButtonText);

    this.renderer.appendChild(nextButton, nextButtonDiv);

    // display text for prev button
    if (prevButtonDiv) {
      this.renderer.removeChild(prevButton, prevButtonDiv);
    }
    const prevButtonText = this.renderer.createText(this.translateService.instant('PREVIOUS_PAGE'));
    prevButtonDiv = this.renderer.createElement('div');
    this.renderer.setAttribute(prevButtonDiv, 'class', 'prev-button-text');
    this.renderer.appendChild(prevButtonDiv, prevButtonText);

    this.renderer.appendChild(prevButton, prevButtonDiv);
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
