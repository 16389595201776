import { Component, ElementRef, Input, NgZone, OnInit, Optional, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatLegacyDialogRef } from '@angular/material/legacy-dialog';
import { ActivatedRoute, Router } from '@angular/router';
import Partners from '@core/constants/Partners';
import Route from '@core/constants/route';
import { TINY_MCE_OPENING_HOURS_INIT } from '@core/constants/TinyMceEditorInits';
import { Country } from '@core/models/country';
import { LocationDto } from '@core/models/dtos/location-dto';
import { LocationModel } from '@core/models/location.model';
import { ThumbnailImage } from '@core/models/thumbnail-image';
import { LocationViewModel } from '@core/models/view-models/location-view-model';
import { CountryApiService } from '@core/services/country-api.service';
import { LocationsService } from '@core/services/locations.service';
import { environment } from '@env/environment';
import { TranslateService } from '@ngx-translate/core';
import { BaseComponent } from '@shared/components/base-component/base-component';
import { SnackBarService } from '@shared/components/snack-bar/snack-bar.service';
import { EditorComponent } from '@tinymce/tinymce-angular';
import { AbmComponent } from 'angular-baidu-maps';
import MarkerOptions = google.maps.MarkerOptions;
import { Observable } from 'rxjs';
import { map, startWith, takeUntil } from 'rxjs/operators';

interface ModeConfig {
  isEditMode: boolean;
  title: string;
  submitButtonTitle: string;
}

const GOOGLE_MAP_OPTIONS = {
  clickableIcons: false,
  fullscreenControl: false,
  mapTypeControl: false,
  streetViewControl: false,
  zoom: 15,
  draggable: true,
};

const modeConfig = {
  Create: {
    isEditMode: false,
    title: 'NEW_LOCATION',
    submitButtonTitle: 'CREATE',
  },
  Edit: {
    isEditMode: true,
    title: 'UPDATE_LOCATION',
    submitButtonTitle: 'SUBMIT',
  },
};

@Component({
  selector: 'app-location-form',
  templateUrl: './location-form.component.html',
  styleUrls: ['./location-form.component.scss'],
})
export class LocationFormComponent extends BaseComponent implements OnInit {
  @ViewChild('search') set search(elementRef: ElementRef) {
    this.initializeSearch(elementRef);
  }

  get dialCode(): FormControl {
    return this.form.get('dialCode') as FormControl;
  }
  get locationTag(): FormControl {
    return this.form.get('locationTag') as FormControl;
  }

  form: FormGroup;
  countries: Country[] = [];
  locationTags: string[] = [];
  message = 'ENABLED';
  isChecked = true;
  modeConfig: ModeConfig = modeConfig.Create;

  options: google.maps.MapOptions = GOOGLE_MAP_OPTIONS;
  mapCenter: google.maps.LatLng = new google.maps.LatLng(51.5, -0.12);
  place: LocationModel;
  locationMarker: google.maps.LatLng;
  markerOptions: MarkerOptions;
  lat: number;
  lng: number;
  storeImage: '';
  image: ThumbnailImage;
  isThumbnailUpdated = false;
  tinyApiKey: string;
  editorInit = TINY_MCE_OPENING_HOURS_INIT;
  locationId: string;
  isEditForm: boolean;
  location: LocationDto;
  locationTypes$: Observable<string[]>;
  allLocationTypes: string[];

  @ViewChild('map') mapComp: AbmComponent;
  @ViewChild('mapInfoWindow') infoWindowElement: ElementRef;
  @ViewChild('textTinyMce') tinymce: EditorComponent;
  @Input() configuration: { LocationViewModel: LocationViewModel };

  constructor(
    private translateService: TranslateService,
    private countryApiService: CountryApiService,
    private ngZone: NgZone,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private locationsService: LocationsService,
    private snackbarService: SnackBarService,
    @Optional() public matDialogRef: MatLegacyDialogRef<LocationFormComponent>,
  ) {
    super();
    this.activatedRoute.queryParams.pipe(takeUntil(this.destroy$)).subscribe(this.getTargetPage);

    this.markerOptions = {
      draggable: true,
      icon: {
        url:
          environment.partner === Partners.ACI
            ? '/assets/images/rezolve-aci-pin.svg'
            : '/assets/images/rezolve-pin.svg',
        scaledSize: new google.maps.Size(100, 100),
        anchor: new google.maps.Point(51, 65), // anchor point coordinates
      },
    };
    this.form = new FormGroup({
      status: new FormControl(true),
      name: new FormControl('', Validators.required),
      description: new FormControl(''),
      locationType: new FormControl(''),
      locationTag: new FormControl(''),
      latitude: new FormControl(),
      longitude: new FormControl(),
      displayAddress: new FormControl('', Validators.required),
      dialCode: new FormControl(''),
      contactPhone: new FormControl('', Validators.minLength(6)),
      openingHours: new FormControl(''),
      additionalInfo: new FormControl(''),
      image: new FormControl(''),
    });

    this.translateService.onLangChange.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.countryApiService.getCountries().subscribe((countries) => {
        this.countries = countries;
      });
    });
    this.tinyApiKey = environment.tinyApiKey;
  }

  getTargetPage = (id) => {
    if (Object.keys(id).length > 0) {
      this.isEditForm = true;
      this.locationId = Object.values(id)[0].toString();
    } else {
      this.isEditForm = false;
      this.locationId = null;
    }
  };

  ngOnInit(): void {
    this.locationsService
      .getTags()
      .pipe(takeUntil(this.destroy$))
      .subscribe((locationTags) => (this.locationTags = locationTags.map((x) => x.name)));

    this.countryApiService
      .getCountries()
      .pipe(takeUntil(this.destroy$))
      .subscribe((countries) => (this.countries = countries));

    if (this.isEditForm) {
      this.modeConfig = modeConfig.Edit;
      this.locationsService
        .getLocation(this.locationId)
        .pipe(takeUntil(this.destroy$))
        .subscribe((location) => {
          this.form.patchValue({
            status: location.status,
            name: location.name,
            description: location.description,
            locationType: location.locationType ?? '',
            locationTag: location.tags[0],
            latitude: location.latitude,
            longitude: location.longitude,
            displayAddress: location.addressLineOne,
            dialCode: location.phone?.split(' ')[0],
            contactPhone: location.phone?.split(' ')[1],
            openingHours: location.openingHours,
            additionalInfo: location.additionalInfo,
            image: location.image,
          });

          this.place = {
            city: location.city,
            country: location.country,
            postCode: location.postCode,
            state: location.state,
            latitude: location.latitude,
            longitude: location.longitude,
            addressLineOne: location.addressLineOne,
          } as LocationModel;

          if (location.latitude && location.longitude) {
            this.locationMarker = new google.maps.LatLng(location.latitude, location.longitude);
          }
          this.mapCenter = new google.maps.LatLng(location.latitude, location.longitude);
        });
    }

    this.locationsService.getTypes().subscribe((locationTypes) => {
      this.allLocationTypes = locationTypes.map((types) => types);
      this.initializeLocationTypes();
    });
  }

  initializeLocationTypes() {
    this.locationTypes$ = this.form.get('locationType').valueChanges.pipe(
      startWith(''),
      map((value) => this._filter(value || '')),
    );
  }

  onSubmit() {
    if (this.form.invalid) {
      return;
    }
    if (this.modeConfig.isEditMode) {
      this.locationsService
        .updateLocation(this.getPayload(), this.locationId)
        .pipe(takeUntil(this.destroy$))
        .subscribe(() => {
          this.snackbarService.showSuccessMessage('UPDATE_LOCATION_SUCCESS');
          this.router.navigate([Route.LOCATIONS]);
        });
    } else {
      this.locationsService
        .createLocation(this.getPayload())
        .pipe(takeUntil(this.destroy$))
        .subscribe(() => {
          this.snackbarService.showSuccessMessage('CREATE_LOCATION_SUCCESS');
          this.router.navigate([Route.LOCATIONS]);
        });
    }
  }

  getPayload() {
    const formValue = this.form.value;
    return {
      ...this.place,
      status: formValue.status,
      name: formValue.name,
      description: formValue.description,
      locationType: formValue.locationType,
      tags: [this.locationTag.value],
      openingHours: formValue.openingHours,
      additionalInfo: formValue.additionalInfo,
      image: formValue.image,
      email: formValue.email,
      phone: this.form.value.dialCode + ' ' + this.form.value.contactPhone,
    } as LocationDto;
  }

  getLocationDetailsFromPlace(place: google.maps.places.PlaceResult) {
    return !!place
      ? ({
          city: place.address_components.find((x) => x.types.includes('locality'))?.long_name,
          country: place.address_components.find((x) => x.types.includes('country'))?.short_name,
          postCode: place.address_components.find((x) => x.types.includes('postal_code'))?.short_name,
          state: place.address_components.find((x) => x.types.includes('administrative_area_level_1'))?.short_name,
          latitude: place.geometry.location.lat(),
          longitude: place.geometry.location.lng(),
          addressLineOne: place.formatted_address,
        } as LocationModel)
      : ({
          addressLineOne: this.form.value.displayAddress,
        } as LocationModel);
  }

  displayMessage(e) {
    if (e.checked) {
      this.message = 'ENABLED';
    } else {
      this.message = 'DISABLED';
    }
  }

  onThumbnailImageChange(storeImage: ThumbnailImage) {
    this.isThumbnailUpdated = true;
    this.image = storeImage;
  }

  addLocation({ latLng }) {
    const geocoder = new google.maps.Geocoder();
    geocoder.geocode({ location: latLng }, (resp, status) => {
      if (status === 'OK') {
        this.place = {
          city: resp[0].address_components.find((x) => x.types.includes('locality') || x.types.includes('postal_town'))
            ?.long_name,
          streetNumber: resp[0].address_components.find((x) => x.types.includes('street_number'))?.long_name,
          country: resp[0].address_components.find((x) => x.types.includes('country'))?.short_name,
          postCode: resp[0].address_components.find((x) => x.types.includes('postal_code'))?.short_name,
          state: resp[0].address_components.find((x) => x.types.includes('administrative_area_level_1'))?.short_name,
          addressLineOne: resp[0].formatted_address,
          latitude: resp[0].geometry.location.lat(),
          longitude: resp[0].geometry.location.lng(),
        } as LocationModel;

        this.form.patchValue({
          latitude: resp[0].geometry.location.lat(),
          longitude: resp[0].geometry.location.lng(),
          displayAddress: resp[0].formatted_address,
        });
        this.form.updateValueAndValidity();

        if (resp[0].geometry.location.lat() && resp[0].geometry.location.lng()) {
          this.locationMarker = new google.maps.LatLng(
            resp[0].geometry.location.lat(),
            resp[0].geometry.location.lng(),
          );
        }
      }
    });
  }

  private _filter(value: string): string[] {
    if (this.allLocationTypes) {
      const filterValue = value.toLowerCase();
      return this.allLocationTypes.filter((option) => option.toLowerCase().includes(filterValue));
    }
  }

  private initializeSearch(element: ElementRef) {
    if (element) {
      const searchBox = new google.maps.places.SearchBox(element.nativeElement);

      google.maps.event.addListener(searchBox, 'places_changed', () => {
        this.ngZone.run(() => {
          const places = searchBox.getPlaces();
          if (places && places.length > 0) {
            const firstItem = places[0];
            this.place = this.getLocationDetailsFromPlace(firstItem);
            this.lat = firstItem.geometry.location.lat();
            this.lng = firstItem.geometry.location.lng();
            if (firstItem && firstItem.geometry && firstItem.geometry.location) {
              this.mapCenter = new google.maps.LatLng(
                firstItem.geometry.location.lat(),
                firstItem.geometry.location.lng(),
              );
              this.locationMarker = new google.maps.LatLng(
                firstItem.geometry.location.lat(),
                firstItem.geometry.location.lng(),
              );
            }
          }
        });
      });
    }
  }
}
