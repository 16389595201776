import { PaymentGatewayEnum } from '@core/models/enums/payment-gateway-enum';
import { Action, createReducer, on } from '@ngrx/store';
import { selectPaymentForm, resetSelectPaymentForm } from '../actions/select-payment-form.action';

export interface SelectedPaymentGatewayState {
  paymentGateway: PaymentGatewayEnum;
}

export const initialState: SelectedPaymentGatewayState = {
  paymentGateway: PaymentGatewayEnum.SELECTED_PROVIDER,
};

const selectPaymentFormReducer = createReducer(
  initialState,
  on(selectPaymentForm, (state, { paymentProviderForm }) => ({
    ...state,
    paymentGateway: paymentProviderForm,
  })),
  on(resetSelectPaymentForm, () => ({
    ...initialState,
  })),
);

export function reducer(state = initialState, action: Action): SelectedPaymentGatewayState {
  return selectPaymentFormReducer(state, action);
}
