// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.image-container {
  padding: 10px;
  max-height: auto;
  display: flex;
  align-items: center;
}

.img {
  width: 100%;
  height: 100%;
  max-width: 800px;
  max-height: 800px;
  object-fit: contain;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/popup/image-preview-popup/image-preview-popup.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,gBAAA;EACA,aAAA;EACA,mBAAA;AACF;;AAEA;EACE,WAAA;EACA,YAAA;EACA,gBAAA;EACA,iBAAA;EACA,mBAAA;AACF","sourcesContent":[".image-container {\n  padding: 10px;\n  max-height: auto;\n  display: flex;\n  align-items: center;\n}\n\n.img {\n  width: 100%;\n  height: 100%;\n  max-width: 800px;\n  max-height: 800px;\n  object-fit: contain;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
