// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tabs-container {
  margin: 2px;
}

.tabs-container .tab {
  padding: 10px 10px;
  text-align: center;
  cursor: pointer;
  border: solid 1px rgba(33, 33, 33, 0.16);
}

.nav-tabs .border-radius-left {
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}
.nav-tabs .border-radius-right {
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}
.nav-tabs .no-border-left {
  border-left: none;
}

.tabs-container .tab:hover {
  border-radius: 2px;
  opacity: 90%;
}

.tabs-container .tab.active {
  background-color: rgba(145, 208, 80, 0.24);
}

ul {
  list-style-type: none !important;
  display: flex;
}

ul > li {
  display: inline-block;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/tabs/tabs.component.scss"],"names":[],"mappings":"AAEA;EACE,WAAA;AADF;;AAME;EACE,kBAAA;EACA,kBAAA;EACA,eAAA;EACA,wCAAA;AAHJ;;AAQE;EACE,2BAAA;EACA,8BAAA;AALJ;AAQE;EACE,4BAAA;EACA,+BAAA;AANJ;AASE;EACE,iBAAA;AAPJ;;AAWA;EACE,kBAAA;EACA,YAAA;AARF;;AAWA;EACE,0CAAA;AARF;;AAWA;EACE,gCAAA;EACA,aAAA;AARF;;AAWA;EACE,qBAAA;AARF","sourcesContent":["@import 'variables';\n\n.tabs-container {\n  margin: 2px;\n}\n\n.tabs-container \n{\n  .tab {\n    padding: 10px 10px;\n    text-align: center;\n    cursor: pointer;\n    border: solid 1px $selected-item-border-light\n  }\n}\n\n.nav-tabs{\n  .border-radius-left{\n    border-top-left-radius: 6px;\n    border-bottom-left-radius: 6px;\n  }\n\n  .border-radius-right{\n    border-top-right-radius: 6px;\n    border-bottom-right-radius: 6px;\n  }\n\n  .no-border-left{\n    border-left: none;\n  }\n}\n\n.tabs-container .tab:hover {\n  border-radius: 2px;\n  opacity: 90%;\n}\n\n.tabs-container .tab.active {\n  background-color: rgba($selected-item-border, .24);\n}\n\nul{\n  list-style-type: none !important;\n  display:flex;\n}\n\nul > li {\n  display: inline-block;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
