import { Inject, Injectable } from '@angular/core';
import { AUTH_SERVICE } from '@core/core.module';
import { Observable, Subject } from 'rxjs';
import { LogUtils } from 'src/app/utils/log-utils';
import { AuthServiceInterface } from './auth/auth-service-inteface';
import AppSettings from '@core/constants/AppSettings';
import Tenants from '@core/constants/Tenants';
import { ThemeClassChange } from '@core/models/theme-class-change';
import { FeatureManagementEnum } from '@core/constants/FeatureManagement';

@Injectable({
  providedIn: 'root',
})
export class AppSettingService {
  private readonly _tenantMetadataKey: string;
  private readonly _loaded: Subject<boolean>;
  private readonly _applyTheme: Subject<ThemeClassChange>;
  private _tenant: string;
  private _appSettings: any;
  isLoaded$: Observable<boolean>;
  applyTheme$: Observable<ThemeClassChange>;

  constructor(@Inject(AUTH_SERVICE) public authService: AuthServiceInterface) {
    this._tenantMetadataKey = 'https://rezolve.com/tenant';
    this._tenant = Tenants.DEFAULT;
    this._appSettings = AppSettings.DEFAULT;
    this._loaded = new Subject<boolean>();
    this._applyTheme = new Subject<ThemeClassChange>();
    this.isLoaded$ = this._loaded.asObservable();
    this.applyTheme$ = this._applyTheme.asObservable();
  }

  load() {
    return this.authService.userProfile$.subscribe((user) => {
      if (user) {
        this.setAppSettingsByTenant(user[this._tenantMetadataKey] ?? Tenants.DEFAULT);
        this._loaded.next(true);
        LogUtils.info(`Loaded app settings for '${this._tenant}' tenant`);
      }
    });
  }

  styleLogo(): boolean {
    return this._appSettings?.styleLogo;
  }

  isFeatureEnabled(feature: FeatureManagementEnum): boolean {
    const featureManagement = this._appSettings.featureManagement;
    return featureManagement[feature];
  }

  get tenant(): string {
    return localStorage.getItem('tenant') ?? this._tenant;
  }

  get themeClass(): string {
    return `${this.tenant}-theme`;
  }

  get language(): string {
    return (
      (this.isDefaultTenant
        ? localStorage.getItem('default-tenant-language')
        : localStorage.getItem('tenant-language')) || this._appSettings.siteLanguageLocale
    );
  }

  set language(language: string) {
    this.isDefaultTenant
      ? localStorage.setItem('default-tenant-language', language)
      : localStorage.setItem('tenant-language', language);
  }

  get paymentCurrency(): string {
    return this._appSettings.paymentCurrency;
  }

  get hasTenantChanged(): boolean {
    return this.tenant !== this._tenant;
  }

  get isTenantSetToLocalStorage() {
    return localStorage.getItem('tenant') !== null;
  }

  private get isDefaultTenant(): boolean {
    return this._tenant === Tenants.DEFAULT;
  }

  private setAppSettingsByTenant(tenant: string) {
    if (!tenant) {
      LogUtils.warning('Tenant data was not provided. Default app settings is loaded.');
      return;
    }

    this._tenant = Tenants.DEFAULT;
    this._appSettings = AppSettings.DEFAULT;

    if (this.hasTenantChanged || !this.isTenantSetToLocalStorage) {
      const previousThemeClass = this.themeClass;
      localStorage.setItem('tenant', this._tenant);
      this._applyTheme.next({ previousThemeClass, newThemeClass: this.themeClass });
      LogUtils.info(`Applying theme for '${this._tenant}' tenant`);
    }
  }
}
