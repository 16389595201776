import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { InstantButtonOptionType, LetterSizeInPixel, MinButtonLength } from '@core/constants/InstantButton';
import { WidthEnum } from '@core/models/enums/width-enum';
import { InstantButtonOption } from '@core/models/instant-button-option';
import { BaseComponent } from '@shared/components/base-component/base-component';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-instant-button-option',
  templateUrl: './instant-button-option.component.html',
  styleUrls: ['./instant-button-option.component.scss'],
  // tslint:disable-next-line:use-component-view-encapsulation
  encapsulation: ViewEncapsulation.None,
})
export class InstantButtonOptionComponent extends BaseComponent implements OnInit {
  @Input() options: Array<InstantButtonOption>;
  @Input() defaultOption: InstantButtonOption;
  @Input() type: string;
  @Input() buttonTextLength: number;
  @Output() selectOption: EventEmitter<any> = new EventEmitter<any>();
  @Output() buttonCharacterCount: EventEmitter<number> = new EventEmitter<number>();
  selectedOption: InstantButtonOption;
  optionForm: FormGroup;
  isCustomWidthSelected = false;
  optionType = InstantButtonOptionType;

  constructor() {
    super();
    this.optionForm = new FormGroup({
      widthCustom: new FormControl(''),
    });
  }

  ngOnInit(): void {
    this.selectedOption = this.defaultOption;
    this.optionForm.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(() => {
      if (this.isCustomWidthSelected) {
        this.setCustomWidth();
        this.selectOption.emit({ option: this.selectedOption, type: InstantButtonOptionType.WIDTH });
      }
    });

    const minWidth = this.getMinWidth();
    this.optionForm.patchValue({
      widthCustom: `${minWidth}`,
    });
  }

  get widthCustom() {
    return this.optionForm.get('widthCustom').value;
  }

  setSelectedOption(option: InstantButtonOption, type: string) {
    this.selectedOption = option;
    this.setIsCustomWidthSelected();

    if (this.isCustomWidthSelected) {
      this.setCustomWidth();
    }

    this.selectOption.emit({ option, type });
  }

  setIsCustomWidthSelected() {
    this.isCustomWidthSelected = this.selectedOption.name === WidthEnum[WidthEnum.Custom];
  }

  setCustomWidth() {
    const minWidth = this.getMinWidth();
    const newWidth = Number(this.widthCustom) < minWidth ? minWidth.toString() : this.widthCustom;
    this.selectedOption.optionValue = `${newWidth}px`;
  }

  getMinWidth(): number {
    return MinButtonLength + this.buttonTextLength * LetterSizeInPixel;
  }
}
