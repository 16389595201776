export const RegularExpressions = {
  URL: /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z0-9\u00a1-\uffff][a-z0-9\u00a1-\uffff_-]{0,62})?[a-z0-9\u00a1-\uffff]\.)+(?:[a-z\u00a1-\uffff]{2,}\.?))(?::\d{2,5})?(?:[/?#]\S*)?$/,
  LEADING_WHITESPACE: /\S/,
  HMAC_BLOWFISH_KEY: /^[!‘()*\-0123456789:;=ABCDEFGHIJKLMNOPQRSTUVWXYZ\[\]_`abcdefghijklmnopqrstuvwxyz{|}~]*$/,
  NEW_LINES: /\n/g,
  NEW_LINES_AT_END: /\n+$/,
  HTML_SINGLE_LINE_BREAKS_AT_END: /(<br \/>)+$/,
  PHONE_NUMBER: /^\+?[0-9]{4,14}$/,
  PHONE_NUMBER_WITH_DELIMITER: /^\+?\d{1,4}?[-.\s]?\(?\d{1,3}?\)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9}$/,
  NO_EMPTY_SPACE_AT_START: /^(?!\s)[\S]+/,
  RFC_1738: /^[a-zA-Z0-9\-._~:/?#[\]@!$&'()*+,;=]*$/,
  RFC_1738_WITH_WHITESPACE: /^[a-zA-Z0-9\s\-._~:/?#[\]@!$&'()*+,;=]*$/,
};
