import { Action, createReducer, on } from '@ngrx/store';
import * as PaymentGatewayDetailsActions from '../actions/payment-gateway-details.actions';

export interface PaymentGatewayDetailsState {
  merchantIdVisa: string | null;
  merchantIdMasterCard: string | null;
  merchantIdAmex: string | null;
  merchantCategoryCode: string | null;
  acceptAmex: boolean | null;
  isSameMerchantIdForVisaAndMastercard: boolean | null;
  currencyForPayment: string | null;
  timezone: string | null;
  phone: string | null;
  termsAndConditionsUrl: string | null;
  privacyPolicyUrl: string | null;
  companyInformationUrl: string | null;
  contactName: string | null;
  contactNumber: string | null;
  contactEmail: string | null;
  contactAddress: string | null;
}

export const initialState: PaymentGatewayDetailsState = {
  merchantIdVisa: null,
  merchantIdMasterCard: null,
  merchantIdAmex: null,
  merchantCategoryCode: null,
  acceptAmex: false,
  isSameMerchantIdForVisaAndMastercard: true,
  currencyForPayment: null,
  timezone: null,
  phone: null,
  termsAndConditionsUrl: null,
  privacyPolicyUrl: null,
  companyInformationUrl: null,
  contactName: null,
  contactNumber: null,
  contactEmail: null,
  contactAddress: null,
};

const paymentGatewayDetailsReducer = createReducer(
  initialState,
  on(PaymentGatewayDetailsActions.createPaymentGatewayDetails, (state, { paymentGatewayDetails }) => ({
    ...state,
    ...paymentGatewayDetails,
  })),
  on(PaymentGatewayDetailsActions.resetSelectPaymentGatewayDetails, () => ({
    ...initialState,
  })),
);

export function reducer(state = initialState, action: Action): PaymentGatewayDetailsState {
  return paymentGatewayDetailsReducer(state, action);
}
