export class PageBuildingBlockStyle {
  textAlign: string = TextAlignOptions.LEFT;
  fontWeight: string = EmphasisOptions.NORMAL;
  fontStyle: string = EmphasisOptions.NORMAL;
  color = '#333333'; // black
  backgroundColor = '#ffffff'; // white

  constructor(style: PageBuildingBlockStyle = null) {
    if (style != null) {
      this.textAlign = style.textAlign;
      this.fontWeight = style.fontWeight;
      this.color = style.color;
      this.backgroundColor = style.backgroundColor;
      this.fontStyle = style.fontStyle;
    }
  }

  selectEmphasis(value: string) {
    switch (value) {
      case EmphasisOptions.BOLD:
        this.fontWeight = this.fontWeight !== value ? value : EmphasisOptions.NORMAL;
        break;
      case EmphasisOptions.ITALIC:
        this.fontStyle = this.fontStyle !== value ? value : EmphasisOptions.NORMAL;
        break;
      default:
        break;
    }
  }
}

export const EmphasisOptions = {
  NORMAL: 'normal',
  BOLD: 'bold',
  ITALIC: 'italic',
};

export const TextAlignOptions = {
  LEFT: 'left',
  CENTER: 'center',
  RIGHT: 'right',
};
