import { LocationDto } from '@core/models/dtos/location-dto';
import { LocationTypeEnum } from '@core/models/enums/location-type-enum';
import { UserLocationActionsEnum } from '@core/models/enums/user-location-actions-enum';

export class LocationViewModel {
  id: string;
  name: string;
  latitude: number;
  longitude: number;
  locationType: string;
  tags: string[];
  status: boolean;
  openingHours: string;
  image: string;
  description: string;
  additionalInfo: string;
  email: string;
  phone: string;
  country: string;
  state: string;
  city: string;
  addressLineOne: string;
  addressLineTwo: string;
  postCode: string;
  availableActions: UserLocationActionsEnum[];
  selected: boolean;
  locationReference: string;
  typeLabel: string;
  overallStatusLabel: string;
  overallStatusColor: string;
  dialCode: string;
  contactPhone: string;
  additionalInformation: string;

  locationDto: LocationDto;

  constructor(dto: LocationDto) {
    this.locationDto = dto;
    this.id = dto.id;
    this.name = dto.name;
    this.latitude = dto.latitude;
    this.longitude = dto.longitude;
    this.locationType = dto.locationType;
    this.tags = dto.tags;
    this.status = dto.status;
    this.openingHours = dto.openingHours;
    this.image = dto.image;
    this.description = dto.description;
    this.additionalInfo = dto.additionalInfo;
    this.email = dto.email;
    this.phone = dto.phone;
    this.country = dto.country;
    this.state = dto.state;
    this.city = dto.city;
    this.addressLineOne = dto.addressLineOne;
    this.addressLineTwo = dto.addressLineTwo;
    this.postCode = dto.postCode;
    this.image = dto.image;

    this.availableActions = [UserLocationActionsEnum.Delete];
    this.selected = false;

    this.overallStatusLabel = this.status ? 'enabled'.toUpperCase() : 'disabled'.toUpperCase();

    if (this.status === true) {
      this.overallStatusColor = 'green';
      this.availableActions.push(UserLocationActionsEnum.Disable);
    } else {
      this.overallStatusColor = 'gray';
      this.availableActions.push(UserLocationActionsEnum.Enable);
    }
  }
}
