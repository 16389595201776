import { Injectable, OnDestroy } from '@angular/core';
import { MatLegacyPaginatorIntl } from '@angular/material/legacy-paginator';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Injectable()
export class MatPaginatorIntlLoc extends MatLegacyPaginatorIntl implements OnDestroy {
  protected destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(private readonly translateService: TranslateService) {
    super();

    translateService.onLangChange.pipe(takeUntil(this.destroy$)).subscribe((_: LangChangeEvent) => {
      this.nextPageLabel = this.translateService.instant('NEXT_PAGE');
      this.previousPageLabel = this.translateService.instant('PREVIOUS_PAGE');
      this.changes.next();
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  getRangeLabel = (page: number, pageSize: number, length: number) => {
    if (length === 0 || pageSize === 0) {
      return this.translateService.instant('RANGE_PAGE_LABEL_1', { length });
    }
    length = Math.max(length, 0);
    const startIndex = page * pageSize;
    // If the start index exceeds the list length, do not try and fix the end index to the end.
    const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;
    return this.translateService.instant('RANGE_PAGE_LABEL_2', { startIndex: startIndex + 1, endIndex, length });
  };
}
