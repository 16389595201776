import { InstantButtonOption } from '@core/models/instant-button-option';

export enum ColourEnum {
  Dark = 0,
  Light = 1,
}

export const ColourDark: InstantButtonOption = {
  name: ColourEnum[ColourEnum.Dark],
  value: ColourEnum.Dark,
  optionValue: `dark`,
  svgIcon: 'colour-dark',
};

export const ColourLight: InstantButtonOption = {
  name: ColourEnum[ColourEnum.Light],
  value: ColourEnum.Light,
  optionValue: `light`,
  svgIcon: 'colour-light',
};

export const ColourList: Array<InstantButtonOption> = [ColourDark, ColourLight];
