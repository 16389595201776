import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';
import { MatLegacyDialogModule } from '@angular/material/legacy-dialog';
import { TranslateModule } from '@ngx-translate/core';
import { ConfirmButtonModule } from '@shared/components/confirm-button/confirm-button.module';
import { GoToMyDetailsPopupComponent } from './go-to-my-details-popup.component';

@NgModule({
  declarations: [GoToMyDetailsPopupComponent],
  imports: [CommonModule, MatLegacyDialogModule, TranslateModule, MatLegacyButtonModule, ConfirmButtonModule],
})
export class GoToMyDetailsPopupModule {}
