import { createAction, props } from '@ngrx/store';
import { OnboardingProgressEnum } from '../models/aci-onboarding.models';

interface UpdateAciOnboardingDto {
  state: OnboardingProgressEnum;
  errorMessage?: string;
  data?: any;
}

export const updateAciOnboardingProgressMerchantAccount = createAction(
  '[UpdateAciOnboardingProgressMerchantAccount] Update ACI onboarding progress merchant account',
  props<UpdateAciOnboardingDto>(),
);

export const updateAciOnboardingProgressSubmit = createAction(
  '[UpdateAciOnboardingProgressSubmit] Update ACI onboarding progress submit',
  props<UpdateAciOnboardingDto>(),
);

export const updateAciOnboardingProgress3DSecure = createAction(
  '[UpdateAciOnboardingProgress3DSecure] Update ACI onboarding progress 3D secure',
  props<UpdateAciOnboardingDto>(),
);

export const clearAciOnboardingProgressState = createAction(
  '[ClearAciOnboardingProgressState] Clear ACI onboarding progress state',
);
