// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container {
  border-radius: 2px;
  border: 1px solid #d6dce5;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-content: center;
}
.container .content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
}
.container .content .image-upload-container {
  align-items: center;
  padding: 2em;
  display: flex;
  flex-direction: column;
  flex: 1 1 100%;
  background-color: rgba(240, 245, 251, 0.4196078431);
  box-shadow: inset 0 10px 12px -6px rgba(205, 208, 212, 0.5607843137), inset 10px 0 12px -6px rgba(205, 208, 212, 0.5607843137);
}
.container .content .image-upload-container .drop-zone-container {
  display: flex;
  width: 100%;
  height: 100%;
}

@media screen and (max-width: 1080px) {
  .container > .content {
    flex-direction: column;
  }
  .container > .content .image-upload-container {
    padding: 1em;
    max-width: 100%;
    box-shadow: inset 0 10px 12px -6px rgba(205, 208, 212, 0.5607843137);
  }
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/popup/product-creation-popup/product-media-upload/product-media-upload.component.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,yBAAA;EACA,WAAA;EACA,YAAA;EACA,aAAA;EACA,sBAAA;EACA,qBAAA;AACF;AACE;EACE,WAAA;EACA,YAAA;EACA,aAAA;EACA,mBAAA;AACJ;AACI;EACE,mBAAA;EACA,YAAA;EACA,aAAA;EACA,sBAAA;EACA,cAAA;EACA,mDAAA;EACA,8HAAA;AACN;AACM;EACE,aAAA;EACA,WAAA;EACA,YAAA;AACR;;AAKA;EACE;IACE,sBAAA;EAFF;EAIE;IACE,YAAA;IACA,eAAA;IACA,oEAAA;EAFJ;AACF","sourcesContent":[".container {\n  border-radius: 2px;\n  border: 1px solid #d6dce5;\n  width: 100%;\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n  align-content: center;\n\n  .content {\n    width: 100%;\n    height: 100%;\n    display: flex;\n    flex-direction: row;\n\n    .image-upload-container {\n      align-items: center;\n      padding: 2em;\n      display: flex;\n      flex-direction: column;\n      flex: 1 1 100%;\n      background-color: #f0f5fb6b;\n      box-shadow: inset 0 10px 12px -6px #cdd0d48f, inset 10px 0 12px -6px #cdd0d48f;\n\n      .drop-zone-container {\n        display: flex;\n        width: 100%;\n        height: 100%;\n      }\n    }\n  }\n}\n\n@media screen and (max-width: 1080px) {\n  .container > .content {\n    flex-direction: column;\n\n    .image-upload-container {\n      padding: 1em;\n      max-width: 100%;\n      box-shadow: inset 0 10px 12px -6px #cdd0d48f;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
