import * as moment from 'moment';

export const LogLevel = {
  DEBUG: 1,
  INFO: 2,
  WARNING: 3,
  ERROR: 4,
  DISABLED: 5,
};

export class LogUtils {
  static getCurrentTime(): string {
    return moment(new Date(), 'h:mm:ss').toString();
  }

  static debug(text: any): void {
    console.log(`%c[${LogUtils.getCurrentTime()}][DEBUG]`, 'color:#2ecc71', text);
  }

  static info(text: any): void {
    console.log(`%c[${LogUtils.getCurrentTime()}][INFO]`, 'color:#00bcd4', text);
  }

  static warning(text: any): void {
    console.log(`%c[${LogUtils.getCurrentTime()}][WARNING]`, 'color:#f39c12', text);
  }

  static error(text: any): void {
    console.log(`%c[${LogUtils.getCurrentTime()}][ERROR]`, 'color:#e74c3c', text);
  }

  static action(object: any): void {
    console.log(`%c[${LogUtils.getCurrentTime()}][ACTION]`, 'color:#3f51b5', object.hideLog ? object.type : object);
  }
}
