import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatLegacyDialogModule } from '@angular/material/legacy-dialog';
import { TranslateModule } from '@ngx-translate/core';
import { MessageEntryFieldCustomisationPopupModule } from '@shared/components/popup/page-builder-customisation/text-area-customisation-popup/message-entry-field-customisation-popup.module';
import { SimpleConfirmPopupModule } from '../simple-confirm-popup/simple-confirm-popup.module';
import { CouponCustomisationPopupModule } from './coupon-customisation-popup/coupon-customisation-popup.module';
import { DateFieldCustomisationPopupModule } from './date-field-customisation-popup/date-field-customisation-popup.module';
import { HeadingCustomisationPopupModule } from './heading-customisation-popup/heading-customisation-popup.module';
import { ImageCustomisationPopupModule } from './image-customisation-popup/image-customisation-popup.module';
import { LinkToPageCustomisationPopupModule } from './link-to-page-customisation-popup/link-to-page-customisation-popup.module';
import { PageBuilderCustomisationService } from './page-builder-customisation.service';
import { ParagraphCustomisationPopupModule } from './paragraph-customisation-popup/paragraph-customisation-popup.module';
import { SelectionCustomisationPopupModule } from './selection-customisation-popup/selection-customisation-popup.module';
import { TextFieldCustomisationPopupModule } from './text-field-customisation-popup/text-field-customisation-popup.module';
import { VideoCustomisationPopupModule } from './video-customisation-popup/video-customisation-popup.module';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    MatLegacyDialogModule,
    DateFieldCustomisationPopupModule,
    HeadingCustomisationPopupModule,
    ImageCustomisationPopupModule,
    ParagraphCustomisationPopupModule,
    SelectionCustomisationPopupModule,
    TextFieldCustomisationPopupModule,
    VideoCustomisationPopupModule,
    SimpleConfirmPopupModule,
    CouponCustomisationPopupModule,
    LinkToPageCustomisationPopupModule,
    MessageEntryFieldCustomisationPopupModule,
    TranslateModule.forChild(),
  ],
  providers: [PageBuilderCustomisationService],
  exports: [
    DateFieldCustomisationPopupModule,
    HeadingCustomisationPopupModule,
    ImageCustomisationPopupModule,
    ParagraphCustomisationPopupModule,
    SelectionCustomisationPopupModule,
    TextFieldCustomisationPopupModule,
    VideoCustomisationPopupModule,
    SimpleConfirmPopupModule,
    CouponCustomisationPopupModule,
    LinkToPageCustomisationPopupModule,
    MessageEntryFieldCustomisationPopupModule,
  ],
})
export class PageBuilderCustomisationModule {}
