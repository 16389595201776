import { Component, OnInit } from '@angular/core';
import { TargetPagesFilters } from '@core/constants/Filters';
import Route from '@core/constants/route';
import { TargetPageCards } from '@core/constants/TargetPageCards';
import { CardInfo } from '@core/models/card-info';
import { EngagementTypeEnum } from '@core/models/enums/engagement-type-enum';
import { EngagementCreationService } from '../engagement-creation/engagement-creation.service';
import { ActTypeEnum } from '@core/models/enums/act-type-enum';
import { Filters } from '@core/models/filters';

@Component({
  selector: 'app-target-page',
  templateUrl: './target-pages.component.html',
  styleUrls: ['./target-pages.component.scss'],
})
export class TargetPagesComponent implements OnInit {
  targetPagesFilters=TargetPagesFilters;
  isInstantWeb: boolean =false;
  targetPages: CardInfo[] = TargetPageCards.NEW_TARGET_PAGES;
  header: string = 'CREATE_A_NEW_TARGET_PAGE';
  navigationRoute = `${Route.ENGAGEMENT_CREATION}`;
  constructor(private engagementCreationService: EngagementCreationService) {
    const trigger = engagementCreationService.engagement.getTrigger();
    const targetPagesFiltersCopy =JSON.parse(JSON.stringify(this.targetPagesFilters))
    if (trigger?.name === EngagementTypeEnum[EngagementTypeEnum.InstantWeb]) {
      this.targetPages = TargetPageCards.INSTANT_WEB_ENGAGEMENT_TARGET_PAGES;
      targetPagesFiltersCopy[0].subFilters = targetPagesFiltersCopy[0].subFilters.filter(ActType => ActType.value ==  ActTypeEnum.Buy || ActType.value == ActTypeEnum.Act)
        this.targetPagesFilters=targetPagesFiltersCopy
        this.isInstantWeb=true;
    }
    else
    {
      this.targetPagesFilters=TargetPagesFilters
    }
  }

  ngOnInit(): void {}
}
