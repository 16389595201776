import { Injectable } from '@angular/core';
import { CreditCardDTO } from '../dtos/credit-card-dto';
import { CreditCardViewModel } from '../view-models/credit-card-view-model';
import { Adapter } from './adapter';

@Injectable({
  providedIn: 'root',
})
export class CreditCardAdapter implements Adapter<CreditCardViewModel, CreditCardDTO> {
  fromDTO(dto: CreditCardDTO): CreditCardViewModel {
    return new CreditCardViewModel(dto);
  }

  toDTO(viewModel: CreditCardViewModel): CreditCardDTO {
    const creditCard = new CreditCardDTO();
    creditCard.paymentMethodId = viewModel.paymentMethodId;
    creditCard.accountNumberLastFourDigit = viewModel.lastFourDigits;
    creditCard.expMonth = viewModel.expiryMonth;
    creditCard.expYear = viewModel.expiryYear;
    return creditCard;
  }
}
