import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import Route from '@core/constants/route';
import { AUTH_SERVICE } from '@core/core.module';
import { AuthServiceInterface, NO_PAY_PAYMENT_FLOW } from '@core/services/auth/auth-service-inteface';
import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { EngagementCreationService } from 'src/app/features/engagement-creation/engagement-creation.service';

@Injectable({
  providedIn: 'root',
})
export class PaymentGuard  {
  constructor(
    @Inject(AUTH_SERVICE) private authService: AuthServiceInterface,
    private engagementCreation: EngagementCreationService,
    private router: Router,
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.authService.isAuthenticated$.pipe(
      switchMap((res) => {
        if (!res) return of(false);

        return this.authService.getPaymentFlow$().pipe(
          map((paymentFlow) => {
            if (paymentFlow == NO_PAY_PAYMENT_FLOW) {
              this.engagementCreation.clearEngagement();
              this.router.navigate([Route.DASHBOARD]);
              return false;
            }

            return true;
          }),
        );
      }),
    );
  }
}
