// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card-title {
  color: #241160;
  font-size: 22px;
  font-weight: bold;
  margin-bottom: 15px;
}

.input-label {
  margin-bottom: 5px;
  color: #241160;
  font-size: 15px;
}

mat-card {
  background-color: #e9eef4;
  height: 600px;
  flex: unset;
  max-height: 600px;
  overflow-y: auto;
}

.not-supported-text {
  word-break: break-word;
  font-size: 17px;
  font-weight: 500;
  max-width: 700px;
  overflow-wrap: break-word;
  text-align: center;
  line-height: 1.4;
  color: #878fc8;
}

.input-field {
  padding-bottom: 0;
  display: block;
}

.help-link-container {
  background-color: #241160;
  justify-content: center;
  align-items: center;
  display: flex;
  padding: 3px 5px 3px 5px;
  margin-bottom: 15px;
  font-size: 12px;
}

.help-link {
  color: white;
}

.error-msg {
  font-size: 13px;
}`, "",{"version":3,"sources":["webpack://./src/app/features/products/shop-settings-form/shop-settings-form.component.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;EACA,eAAA;EACA,iBAAA;EACA,mBAAA;AACF;;AAEA;EACE,kBAAA;EACA,cAAA;EACA,eAAA;AACF;;AAEA;EACE,yBAAA;EACA,aAAA;EACA,WAAA;EACA,iBAAA;EACA,gBAAA;AACF;;AAEA;EACE,sBAAA;EACA,eAAA;EACA,gBAAA;EACA,gBAAA;EACA,yBAAA;EACA,kBAAA;EACA,gBAAA;EACA,cAAA;AACF;;AAEA;EACE,iBAAA;EACA,cAAA;AACF;;AAEA;EACE,yBAAA;EACA,uBAAA;EACA,mBAAA;EACA,aAAA;EACA,wBAAA;EACA,mBAAA;EACA,eAAA;AACF;;AAEA;EACE,YAAA;AACF;;AAEA;EACE,eAAA;AACF","sourcesContent":[".card-title {\n  color: #241160;\n  font-size: 22px;\n  font-weight: bold;\n  margin-bottom: 15px;\n}\n\n.input-label {\n  margin-bottom: 5px;\n  color: #241160;\n  font-size: 15px;\n}\n\nmat-card {\n  background-color: #e9eef4;\n  height: 600px;\n  flex: unset;\n  max-height: 600px;\n  overflow-y: auto;\n}\n\n.not-supported-text {\n  word-break: break-word;\n  font-size: 17px;\n  font-weight: 500;\n  max-width: 700px;\n  overflow-wrap: break-word;\n  text-align: center;\n  line-height: 1.4;\n  color: #878fc8;\n}\n\n.input-field {\n  padding-bottom: 0;\n  display: block;\n}\n\n.help-link-container {\n  background-color: #241160;\n  justify-content: center;\n  align-items: center;\n  display: flex;\n  padding: 3px 5px 3px 5px;\n  margin-bottom: 15px;\n  font-size: 12px;\n}\n\n.help-link {\n  color: white;\n}\n\n.error-msg {\n  font-size: 13px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
