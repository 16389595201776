import { Component, ContentChild, EventEmitter, HostListener, OnInit, Output, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import Route from '@core/constants/route';
import { AutoProvisioningService } from '@core/services/auto-provisioning.service';
import { LoaderService } from '@core/services/loader.service';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { BaseComponent } from '@shared/components/base-component/base-component';
import { PopupService } from '@shared/components/popup/popup.service';
import { Observable } from 'rxjs';
import { filter, finalize, switchMap, takeUntil, tap } from 'rxjs/operators';
import { clearState } from '../store/actions/clear-state.actions';
import { MenuWizardService } from './menu-wizard-service/menu-wizard.service';
import {
  MenuWizardActions,
  MenuWizardEventTypeEnum,
  MenuWizardStateEnum,
  MenuWizardStep,
  MenuWizardStepTypeEnum,
} from './menu-wizard.model';

@Component({
  selector: 'app-menu-wizard',
  templateUrl: './menu-wizard.component.html',
  styleUrls: ['./menu-wizard.component.scss'],
})
export class MenuWizardComponent extends BaseComponent implements OnInit {
  wizardMenuSteps$: Observable<MenuWizardStep[]>;
  currentStep$: Observable<MenuWizardStep>;
  canGoToNextStep$: Observable<boolean>;
  MenuWizardActions = MenuWizardActions;
  MenuWizardState = MenuWizardStateEnum;
  MenuWizardStepType = MenuWizardStepTypeEnum;

  @Output() stepChange = new EventEmitter<MenuWizardStep>();

  @ContentChild(TemplateRef) wizardContent: TemplateRef<any>;

  constructor(
    private readonly menuWizardService: MenuWizardService,
    private readonly router: Router,
    private readonly store: Store,
    private readonly autoProvisioningService: AutoProvisioningService,
    private readonly popupService: PopupService,
    private readonly translateService: TranslateService,
    private readonly loaderService: LoaderService,
  ) {
    super();
    this.wizardMenuSteps$ = menuWizardService.wizardMenuSteps$;
    this.currentStep$ = menuWizardService.getCurrentStepInfo$();
    this.canGoToNextStep$ = menuWizardService.canGoToNextStep$;
  }

  @HostListener('window:beforeunload', ['$event'])
  onBrowserRefresh(event: BeforeUnloadEvent) {
    event.preventDefault();
    event.returnValue = false;
    this.menuWizardService.getCurrentStep$();
  }

  @HostListener('window:popstate', ['$event'])
  onPopState(event) {
    const splitted = location.href.split('/');
    this.menuWizardService.switchTo(splitted[splitted.length - 1]);
  }

  canShowAction(action: MenuWizardActions, step: MenuWizardStep) {
    return (step.info?.menuWizardActions & action) === action;
  }

  ngOnInit(): void {
    this.menuWizardService.events$
      .pipe(
        filter((event) => !!event),
        takeUntil(this.destroy$),
      )
      .subscribe((event) => {
        switch (event.type) {
          case MenuWizardEventTypeEnum.Cancel:
            this.cancel();
            break;
          case MenuWizardEventTypeEnum.OutOfBound:
            this.cancel();
            break;
          case MenuWizardEventTypeEnum.StepChange:
            this.stepChange.emit(event.data);
            break;

          default:
            break;
        }
      });
  }

  goNextStep(currentStep: MenuWizardStep) {
    if (currentStep.actionOnNext) {
      currentStep
        .actionOnNext(this.translateService, this.popupService, this.store)
        .pipe(
          filter((res) => res),
          takeUntil(this.destroy$),
        )
        .subscribe(() => {
          this.menuWizardService.nextStepRequest$.next(1);
        });
    } else {
      this.menuWizardService.nextStepRequest$.next(1);
    }
  }

  skipToNextStep(currentStep: MenuWizardStep) {
    if (currentStep.actionOnNext) {
      currentStep
        .actionOnNext(this.translateService, this.popupService, this.store)
        .pipe(
          filter((res) => res),
          takeUntil(this.destroy$),
        )
        .subscribe(() => {
          this.menuWizardService.skipStepRequest$.next(1);
        });
    } else {
      this.menuWizardService.skipStepRequest$.next(1);
    }
  }

  goBack(currentStep: MenuWizardStep) {
    if (currentStep.actionOnBack) {
      this.loaderService.show();
      currentStep
        .actionOnBack(this.autoProvisioningService)
        .pipe(
          finalize(() => this.loaderService.hide()),
          takeUntil(this.destroy$),
        )
        .subscribe(() => {
          this.menuWizardService.goBack();
        });
    } else {
      this.menuWizardService.goBack();
    }
  }

  isStepActive(step: MenuWizardStep) {
    return step.state === MenuWizardStateEnum.Active;
  }

  isStepWizard(step: MenuWizardStep) {
    return step.type === MenuWizardStepTypeEnum.Wizard;
  }

  showStepTitle(step: MenuWizardStep): boolean {
    return this.isStepWizard(step) && !step.info?.hideStepTitle;
  }

  showWizardMenu(step: MenuWizardStep): boolean {
    return this.isStepWizard(step) && !step.info?.hideWizardMenu;
  }

  cancel() {
    this.translateService
      .get('ARE_YOU_SURE_YOU_WANT_TO_RESET_WIZARD')
      .pipe(
        switchMap((translation) =>
          this.popupService
            .simpleConfirmationPopup({
              maxWidth: '320px',
              width: 'auto',
              height: 'auto',
              componentConfiguration: {
                messageList: [{ message: translation, shoudlWrap: false }],
                showDeleteIcon: true,
              },
            })
            .pipe(
              filter((ok) => ok),
              switchMap(() => this.autoProvisioningService.resetOnboarding()),
              tap(() => {
                this.store.dispatch(clearState());
                this.menuWizardService.reset();
              }),
              takeUntil(this.destroy$),
            ),
        ),
      )
      .subscribe((x) => {
        this.router.navigate([Route.SETTINGS, Route.SELL_WITH_REZOLVE]);
      });
  }
}
