import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';
import { MatLegacyListModule } from '@angular/material/legacy-list';
import { MatLegacyTooltipModule } from '@angular/material/legacy-tooltip';
import { CustomDirectivesModule } from '@core/directives/custom-directives.module';
import { TranslateModule } from '@ngx-translate/core';
import { ConfirmButtonModule } from '@shared/components/confirm-button/confirm-button.module';
import { FilePreviewModule } from '@shared/components/file-preview/file-preview.module';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { ImageFileUploadComponent } from './image-file-upload.component';

@NgModule({
  declarations: [ImageFileUploadComponent],
  imports: [
    CommonModule,
    FilePreviewModule,
    FlexLayoutModule,
    MatIconModule,
    MatLegacyListModule,
    MatLegacyTooltipModule,
    ProgressSpinnerModule,
    MatLegacyButtonModule,
    CustomDirectivesModule,
    TranslateModule.forChild(),
    ConfirmButtonModule,
  ],
  exports: [ImageFileUploadComponent],
})
export class ImageFileUploadModule {}
