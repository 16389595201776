import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatLegacyTableDataSource } from '@angular/material/legacy-table';
import { MatSort } from '@angular/material/sort';
import { Filters } from '@core/models/filters';
import { ActViewModel } from '@core/models/view-models/act-view-model';
import { ActAPIService } from '@core/services/act-api.service';
import { LoaderService } from '@core/services/loader.service';
import { PaginationHelper } from '@core/utils/pagination-helper';
import { Observable } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { BaseComponent } from '../base-component/base-component';
import { PaginationComponent } from '../pagination/pagination.component';

export const targetsLoadingKey = 'targets-dashboard';
@Component({
  selector: 'app-base-targets',
  template: '',
})
export abstract class BaseTargetsDashboardComponent extends BaseComponent implements AfterViewInit, OnInit {
  sort: MatSort;
  @ViewChild(MatSort) set matSort(matSort: MatSort) {
    this.sort = matSort;
  }
  @ViewChild(PaginationComponent, { static: true }) paginator: PaginationComponent;

  loading$: Observable<boolean>;
  dataSource: MatLegacyTableDataSource<ActViewModel> = new MatLegacyTableDataSource([]);
  filters: Filters = {};
  pageSizeOptions: number[] = [];
  data: ActViewModel[] = [];
  selectedRowIndex = -1;

  readonly pageIndex: number = 0;
  readonly pageSize: number = 5;

  constructor(readonly actAPIService: ActAPIService, readonly loaderService: LoaderService) {
    super();
    this.loading$ = this.loaderService.isLoading(targetsLoadingKey);
  }

  ngOnInit(): void {
    this.dataSource
      .connect()
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        this.data = data;
      });

    this.refresh(this.pageIndex, this.pageSize);
  }

  ngAfterViewInit(): void {
    this.paginator.matPaginator.page.pipe(takeUntil(this.destroy$)).subscribe((_) => {
      this.refresh(this.paginator.matPaginator.pageIndex, this.paginator.matPaginator.pageSize);
    });
  }

  refreshWithFirstPageNavigation = () => this.refresh(this.pageIndex, this.pageSize, true);

  refresh(pageIndex: number, pageSize: number, shouldNavigateToFirstPage = false) {
    this.loaderService.show(targetsLoadingKey);
    this.actAPIService
      .getTargetPages(
        pageIndex * pageSize,
        pageSize,
        this.filters.enumTypesSelection,
        this.filters.dateRange,
        this.filters.searchTerm,
        this.sort && this.sort.active && this.sort.start
          ? {
              fieldName: this.sort.active[0].toUpperCase() + this.sort.active.slice(1),
              ascending: this.sort.direction === 'asc',
            }
          : null,
      )
      .pipe(
        finalize(() => this.loaderService.hide(targetsLoadingKey)),
        takeUntil(this.destroy$),
      )
      .subscribe(({ data, total }) => {
        this.dataSource.data = data;
        this.paginator.length = total;
        this.dataSource.filterPredicate = ActViewModel.filterPredicate;
        this.dataSource.sortingDataAccessor = ActViewModel.sortingDataAccessor;
        this.pageSizeOptions = PaginationHelper.getPageSizeOptions(total);
        if (shouldNavigateToFirstPage) {
          this.paginator.matPaginator.firstPage();
        }
      });
  }
}
