import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-slider-panel',
  templateUrl: './slider-panel.component.html',
  styleUrls: ['./slider-panel.component.scss'],
})
export class SliderPanelComponent implements OnChanges {
  @Input() min: number;
  @Input() max: number;
  @Input() sliderValue: number;
  @Input() sliderDisabled: boolean;
  @Output() sliderValueEvent: EventEmitter<number> = new EventEmitter();

  translation: any;
  isCollapsed: boolean;

  constructor() {
    this.isCollapsed = true;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.sliderDisabled) {
      this.sliderDisabled = changes.sliderDisabled.currentValue;
    }
    console.log('SliderPanelComponent -> ngOnChanges is called!', changes);
  }

  expandPanel(): void {
    this.isCollapsed = !this.isCollapsed;
  }

  onSliderValueChange(value: number): void {
    this.sliderValueEvent.emit(value);
  }
}
