import { BaseMapGeoFence } from '@core/models/base-map-geo-fence';
import { BehaviorSubject } from 'rxjs';
import { environment } from '@env/environment';

class LocationData {
  geoFences: BehaviorSubject<BaseMapGeoFence[]>;
  locationReference: string;
  locationAddress: BehaviorSubject<string[]>;

  constructor() {
    this.geoFences = new BehaviorSubject([]);
    this.locationAddress = new BehaviorSubject([]);
  }

  getTotalArea() {
    return this.geoFences.value.reduce((acc, current) => {
      acc += current.getArea();
      return acc;
    }, 0);
  }

  addAddress(address) {
    this.locationAddress.next([...this.locationAddress.value, address]);
  }

  addGeoFence(geoFence, selectedType?) {
    if (environment.allowMultipleGeozones) {
      this.geoFences.next([...this.geoFences.value, geoFence]);
      if (selectedType) {
        geoFence.selectedType = selectedType;
      }
    } else {
      this.geoFences.next([geoFence]);
    }
  }

  updateGeoFences() {
    this.geoFences.next(this.geoFences.value);
  }

  removeGeoFencesByType(selectedType: string) {
    this.geoFences.next(this.geoFences.value.filter((geoFence) => geoFence.selectedType !== selectedType));
  }

  removeGeoFence(index) {
    this.geoFences.value.splice(index, 1);
    this.geoFences.next([...this.geoFences.value]);
    this.locationAddress.value.splice(index, 1);
  }

  removeAllGeoFences() {
    this.geoFences.next([]);
    this.locationAddress.next([]);
  }

  setLocationReference(url: string) {
    this.locationReference = url;
  }
}

export default LocationData;
