import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';
import { MatLegacyCardModule } from '@angular/material/legacy-card';
import { MatLegacyChipsModule } from '@angular/material/legacy-chips';
import { RouterModule } from '@angular/router';
import { CustomDirectivesModule } from '@core/directives/custom-directives.module';
import { TranslateModule } from '@ngx-translate/core';
import { SearchInputModule } from '../search-input/search-input.module';
import { ChipFiltersComponent } from './chip-filters.component';

@NgModule({
  declarations: [ChipFiltersComponent],
  imports: [
    CommonModule,
    FlexLayoutModule,
    MatLegacyButtonModule,
    MatLegacyCardModule,
    MatIconModule,
    RouterModule,
    MatLegacyChipsModule,
    TranslateModule,
    SearchInputModule,
    CustomDirectivesModule,
  ],
  exports: [ChipFiltersComponent],
})
export class ChipFiltersModule {}
