// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.download-button {
  width: 24px;
  height: 24px;
  border-radius: 0;
  line-height: unset;
}
.download-button mat-icon {
  font-size: 20px;
  line-height: unset;
}

.container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
}`, "",{"version":3,"sources":["webpack://./src/app/features/target-pages-dashboard/act-submission-block/act-submission-block.component.scss"],"names":[],"mappings":"AAEA;EACE,WAAA;EACA,YAAA;EACA,gBAAA;EACA,kBAAA;AADF;AAEE;EACE,eAAA;EACA,kBAAA;AAAJ;;AAIA;EACE,aAAA;EACA,2BAAA;EACA,mBAAA;EACA,SAAA;AADF","sourcesContent":["@import 'variables';\n\n.download-button {\n  width: 24px;\n  height: 24px;\n  border-radius: 0;\n  line-height: unset;\n  mat-icon {\n    font-size: 20px;\n    line-height: unset;\n  }\n}\n\n.container {\n  display: flex;\n  justify-content: flex-start;\n  align-items: center;\n  gap: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
