import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ShopType } from '@core/models/update-merchant-product-config-request';
import { BehaviorSubject } from 'rxjs';
import { Connection } from '../shop-connection-models';

@Component({
  selector: 'app-shop-settings-form',
  templateUrl: './shop-settings-form.component.html',
  styleUrls: ['./shop-settings-form.component.scss'],
})
export class ShopSettingsFormComponent implements OnInit, OnChanges {
  supported$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  @Input()
  shop: ShopType;

  @Input()
  formGroup: FormGroup;

  @Input()
  connectionSettings: Connection;

  constructor() {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (this.connectionSettings) {
      this.supported$.next(!!this.connectionSettings.formFields);
    }
  }
}
