import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatLegacyTableDataSource } from '@angular/material/legacy-table';
import { MatSort } from '@angular/material/sort';
import { BatchLocationActions, LocationTagFilters } from '@core/constants/Filters';
import Route from '@core/constants/route';
import { LocationAdapter } from '@core/models/adapters/location-adapter';
import { UserLocationActionsEnum } from '@core/models/enums/user-location-actions-enum';
import { LocationViewModel } from '@core/models/view-models/location-view-model';
import { LoaderService } from '@core/services/loader.service';
import { LocationsService } from '@core/services/locations.service';
import { BaseComponent } from '@shared/components/base-component/base-component';
import { PaginationComponent } from '@shared/components/pagination/pagination.component';
import { SearchInputComponent } from '@shared/components/search-input/search-input.component';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { debounceTime, finalize, switchMap, takeUntil } from 'rxjs/operators';

interface LocationFilters {
  tag?: string;
  searchTerm?: string;
}
@Component({
  selector: 'app-locations',
  templateUrl: './locations.component.html',
  styleUrls: ['./locations.component.scss'],
})
export class LocationsComponent extends BaseComponent implements OnInit, AfterViewInit {
  @ViewChild(MatSort) set matSort(matSort: MatSort) {
    this.sort = matSort;
  }
  private readonly filterSubject = new BehaviorSubject<LocationFilters>({});
  Route = Route;
  locationFilter = 'locationFilters';
  locationTagFilters = LocationTagFilters;
  searchedValues = null;
  availableAction = BatchLocationActions;
  pageSizeOptions: number[] = [];
  locations: BehaviorSubject<LocationViewModel[]>;
  loading$: Observable<boolean>;
  selectedTags: string[] = [];
  dataSource = new MatLegacyTableDataSource<LocationViewModel>([]);

  @ViewChild(PaginationComponent, { static: true }) paginator: PaginationComponent;
  sort: MatSort;
  @ViewChild(SearchInputComponent) searchComponent: SearchInputComponent;

  constructor(
    private locationsService: LocationsService,
    private loaderService: LoaderService,
    private locationAdapter: LocationAdapter,
  ) {
    super();
    this.loading$ = this.loaderService.isLoading();
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator.matPaginator;
    this.dataSource.sort = this.sort;
  }

  ngOnInit(): void {
    this.locations = this.dataSource.connect();

    this.filterSubject
      .pipe(
        debounceTime(300),
        switchMap((filters) => {
          this.loaderService.show();
          return this.locationsService.getLocations(filters.searchTerm, (filters && filters.tag) || '', true).pipe(
            takeUntil(this.destroy$),
            finalize(() => this.loaderService.hide()),
          );
        }),
      )
      .subscribe((res) => {
        this.dataSource.data = res.map((dto) => new LocationViewModel(dto));
      });

    this.fetchCategories();
  }

  fetchCategories() {
    this.locationsService
      .getTags()
      .pipe(takeUntil(this.destroy$))
      .subscribe((tags) => {
        const tagFilter = this.locationTagFilters.find((x) => x.name === 'TAG');
        if (tagFilter) {
          tagFilter.subFilters = tags.map(({ name }) => ({ value: name, name, elementId: name }));
        }
      });
  }

  refresh() {
    this.filterSubject.next({ ...this.filterSubject.value });
  }

  applyFilter(value: string, type: string) {
    switch (type) {
      case (type = 'locationFilters'):
        this.filterSubject.next({
          ...this.filterSubject.value,
          tag: value,
        });
        break;
      case (type = 'searchTerm'):
        this.filterSubject.next({
          ...this.filterSubject.value,
          searchTerm: value,
        });
        break;
    }
  }

  selectAll(value: boolean) {
    // this.locations = Array.from(this.locations).map((e) => ({ ...e, selected: value }));
  }

  batchAction(action: UserLocationActionsEnum) {
    switch (action) {
      case UserLocationActionsEnum.Delete:
        const selectedLocations = this.dataSource.data
          .filter((x) => x.selected)
          .map((location) => this.locationsService.deleteLocation(location.id));
        combineLatest(selectedLocations)
          .pipe(takeUntil(this.destroy$))
          .subscribe(() => {
            this.refresh();
          });
        break;
      case UserLocationActionsEnum.Enable:
        combineLatest(
          this.dataSource.data
            .filter((x) => x.selected)
            .map((location) =>
              this.locationsService.updateLocation(
                {
                  ...this.locationAdapter.toDTO(location),
                  status: true,
                },
                location.id,
              ),
            ),
        )
          .pipe(takeUntil(this.destroy$))
          .subscribe(() => {
            this.refresh();
          });
        break;
      case UserLocationActionsEnum.Disable:
        combineLatest(
          this.dataSource.data
            .filter((x) => x.selected)
            .map((location) =>
              this.locationsService.updateLocation(
                {
                  ...this.locationAdapter.toDTO(location),
                  status: false,
                },
                location.id,
              ),
            ),
        )
          .pipe(takeUntil(this.destroy$))
          .subscribe(() => {
            this.refresh();
          });
        break;
    }
  }

  updateLocation() {
    this.refresh();
  }

  changeStatus(location: LocationViewModel, status: boolean) {
    this.loaderService.show();
    this.locationsService
      .updateLocation(
        {
          ...this.locationAdapter.toDTO(location),
          status,
        },
        location.id,
      )
      .pipe(
        takeUntil(this.destroy$),
        finalize(() => this.loaderService.hide()),
      )
      .subscribe((_) => {
        this.refresh();
      });
  }
  deleteLocation(location: LocationViewModel) {
    this.loaderService.show();
    this.locationsService
      .deleteLocation(location.id)
      .pipe(
        takeUntil(this.destroy$),
        finalize(() => this.loaderService.hide()),
      )
      .subscribe((_) => {
        this.refresh();
      });
  }
}
