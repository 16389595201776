import { EngagementDTO } from '@core/models/dtos/engagement-dto';

export class AssociatedEngagementsPopupConfiguration {
  title: string;
  subtitle: string;
  subtitleIcon: string;
  engagements: EngagementDTO[];
  targetId: string;
  afterDeleteCallback?: () => void;
  showDeleteButton?: boolean;
}
