import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Endpoints } from '@core/constants/Endpoints';
import { FileUploadResult } from '@core/models/file-upload-result';
import { Observable } from 'rxjs';
import { BaseAPIServiceService } from './http/base-apiservice.service';

@Injectable({
  providedIn: 'root',
})
export class OrderService extends BaseAPIServiceService {
  constructor(protected http: HttpClient) {
    super(http);
  }

  uploadFile(file: FormData): Observable<FileUploadResult> {
    return this.http.post<FileUploadResult>(Endpoints.UPLOAD_FILE, file);
  }
}
