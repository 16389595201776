import { Component, OnInit } from '@angular/core';
import { PopupService } from '@shared/components/popup/popup.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-welcome-onboarder',
  templateUrl: './welcome-onboarder.component.html',
  styleUrls: ['./welcome-onboarder.component.scss'],
})
export class WelcomeOnboarderComponent implements OnInit {
  selectedLanguage: string;

  constructor(private popupService: PopupService, private translateService: TranslateService) {}

  ngOnInit(): void {}

  openOnBoarding() {
    this.selectedLanguage = this.translateService.currentLang;
    this.popupService.onBoardingPopup(this.selectedLanguage);
  }
}
