import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { FilePreviewModule } from '@shared/components/file-preview/file-preview.module';
import { FileUploadModule } from '@shared/components/file-upload/file-upload.module';
import { GenericCreationContainerModule } from '@shared/components/generic-creation-container/generic-creation-container.module';
import { ProductMediaUploadComponent } from './product-media-upload.component';

@NgModule({
  declarations: [ProductMediaUploadComponent],
  imports: [
    CommonModule,
    FileUploadModule,
    FilePreviewModule,
    GenericCreationContainerModule,
    TranslateModule.forChild(),
  ],
  exports: [ProductMediaUploadComponent],
  providers: [],
})
export class ProductMediaUploadModule {}
