import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import Route from '@core/constants/route';
import { MerchantAPIService } from '@core/services/merchant-api.service';
import { BaseComponent } from '@shared/components/base-component/base-component';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-welcome-products-page',
  templateUrl: './welcome-products-page.component.html',
  styleUrls: ['./welcome-products-page.component.scss'],
})
export class WelcomeProductsPageComponent extends BaseComponent implements OnInit {
  @Output()
  onStoreConnectChange = new EventEmitter();

  constructor(private router: Router, private merchantService: MerchantAPIService) {
    super();
  }

  ngOnInit(): void {}

  onGetStartedClick() {
    this.router.navigate([Route.PRODUCTS, Route.CONNECT_TO_STORE], { state: { shouldLoad: true } });
    this.onStoreConnectChange.emit(true);
  }

  onDismissClick() {
    this.merchantService
      .updateMerchantProductConfig({ hasSkippedEcommerceConfig: true })
      .pipe(takeUntil(this.destroy$))
      .subscribe((_) => {
        this.router.navigate([Route.PRODUCTS, Route.DASHBOARD]);
        this.onStoreConnectChange.emit(true);
      });
  }
}
