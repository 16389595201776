import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatLegacyDialog, MatLegacyDialogConfig, MatLegacyDialogRef } from '@angular/material/legacy-dialog';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { ThumbnailImage } from '@core/models/thumbnail-image';
import { OrderService } from '@core/services/order.service';
import { ServicingService } from '@core/services/servicing.service';
import { BaseComponent } from '@shared/components/base-component/base-component';
import { CropperPosition } from 'ngx-image-cropper';
import { takeUntil } from 'rxjs/operators';
import { LogUtils } from 'src/app/utils/log-utils';
import { ImageCropperModalComponent } from '../../../../../features/target-pages/shared/image-cropper-modal/image-cropper-modal.component';
import { BasePageBuilderConfiguration } from '../base-page-builder-configuration';

@Component({
  selector: 'app-image-customisation-popup',
  templateUrl: './image-customisation-popup.component.html',
  styleUrls: ['./image-customisation-popup.component.scss'],
})
export class ImageCustomisationPopupComponent extends BaseComponent implements OnInit {
  @Input() configuration: BasePageBuilderConfiguration;
  file: File;
  files = null;
  imageUrl: SafeUrl;
  spinnerLoading: boolean;
  showImage = false;
  showImageTypeError = false;
  croppedImage: string;
  imageSrc: string = null;
  thumbnailFileName: string = null;
  baseImage: string | SafeUrl;
  cropperPosition: CropperPosition;
  isImageEdited: boolean;
  imageType: string;
  imageName: string;

  @Output() handleImageChange: EventEmitter<ThumbnailImage> = new EventEmitter();

  constructor(
    public matDialogRef: MatLegacyDialogRef<ImageCustomisationPopupComponent>,
    private orderService: OrderService,
    private servicingService: ServicingService,
    private sanitizer: DomSanitizer,
    private http: HttpClient,
    private readonly dialog: MatLegacyDialog,
  ) {
    super();
  }

  ngOnInit(): void {
    if (this.configuration.data.filePath) {
      this.spinnerLoading = true;
      this.loadImageByFilePath(this.configuration.data.filePath);
    } else if (this.configuration.data.url) {
      this.spinnerLoading = true;
      this.loadImageByUrl(this.configuration.data.url, this.configuration.data.baseImage);
    } else {
      LogUtils.error('Could not load image because download URL or filePath are not found!');
    }
  }

  onCancel(): void {
    this.matDialogRef.close();
  }

  toBase64 = (uploadedFile: File) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(uploadedFile);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  async uploadImage(event) {
    if (!this.isImageEdited) {
      this.configuration.data.cropperPosition = undefined;
      this.showImageTypeError = false;
      this.isImageEdited = false;
      this.files = event.target.files as FileList;
      this.file = this.files[0];
      this.imageName = this.files[0].name;
      const uploadedFile = this.files.item(0);
      await this.uploadImageToAWS(uploadedFile);
    } else if (this.isImageEdited) {
      this.files = event[0] as FileList;
      this.file = this.files;
      const uploadedFile = this.files;
      this.imageName = event[0].name;
      await this.uploadImageToAWS(uploadedFile);
    }
  }

  async uploadImageToAWS(uploadedFile) {
    const formData: FormData = new FormData();
    this.imageSrc = (await this.toBase64(uploadedFile)) as string;
    this.croppedImage = this.imageSrc.split('base64,')[1];
    this.thumbnailFileName = uploadedFile.name;
    this.imageType = uploadedFile.type;
    if (!this.file?.type || !this.file.type.startsWith('image/')) {
      LogUtils.error('Selected file is not an image!');
      this.showImageTypeError = true;
      return;
    }
    formData.append('file', this.file);
    this.spinnerLoading = true;
    this.orderService
      .uploadFile(formData)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (response) => {
          if (response) {
            this.configuration.data.filePath = response.filePath;
            this.configuration.data.baseImage = this.imageSrc;
            this.loadImageByFilePath(response.filePath);
          } else {
            this.configuration.data.url = this.imageSrc;
            this.configuration.data.baseImage = this.imageSrc;
            this.loadImageByUrl(this.imageSrc, this.imageSrc);
          }
        },
        (error) => {
          LogUtils.error('Error while uploading selected image!');
          LogUtils.error(error);
          this.spinnerLoading = false;
        },
      );
  }

  openImageCropperModal() {
    const dialogConfig = new MatLegacyDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.width = '100%';
    dialogConfig.height = '100%';
    dialogConfig.maxWidth = '100%';
    dialogConfig.panelClass = 'image-cropper';
    dialogConfig.data = {
      croppedImage: this.imageSrc,
      baseImage: this.baseImage,
      maintainAspectRatio: false,
      cropperPosition: this.configuration.data.cropperPosition,
      formData: this.file,
      imageName: this.imageName,
      imageType: this.imageType,
      imageId: this.configuration.data.id,
    };
    this.dialog
      .open(ImageCropperModalComponent, dialogConfig)
      .afterClosed()
      .subscribe((image) => {
        if (image.data.isImageEdited) {
          this.imageSrc = image.data.croppedImage;
          this.baseImage = image.data.baseImage;
          this.imageName = image.data.imageName;
          this.imageUrl = this.imageSrc;
          this.croppedImage = this.imageSrc.split('base64,')[1];
          this.cropperPosition = image.data.cropperPosition;
          this.imageType = image.data.imageType;
          this.configuration.data.baseImage = this.baseImage.toString();
          this.configuration.data.cropperPosition = image.data.cropperPosition;
          this.handleImageChange.emit({ base64: this.croppedImage, name: this.thumbnailFileName });
          this.isImageEdited = true;
          this.uploadImage(image.data.imageFile).then((uploadedImage) => (image = uploadedImage));
          return;
        } else {
          this.handleImageChange.emit({ base64: this.croppedImage, name: this.thumbnailFileName });
          this.baseImage = image.data.baseImage;
          this.isImageEdited = false;
        }
      });
  }

  loadImageByFilePath(filePath: string): void {
    this.servicingService
      .getImage(encodeURIComponent(filePath))
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (image) => {
          this.spinnerLoading = false;
          this.showImage = true;
          const reader = new FileReader();
          reader.readAsDataURL(image);
          reader.onloadend = () => {
            const formatUrl =
              this.imageType === undefined
                ? `data:image/jpg;${reader.result.toString().split(';')[1]}`
                : `data:${this.imageType};${reader.result.toString().split(';')[1]}`;
            this.imageUrl = this.sanitizer.bypassSecurityTrustUrl(formatUrl);
            this.baseImage = formatUrl;
          };
        },
        (error) => {
          LogUtils.error('Error while loading image!');
          LogUtils.error(error);
          this.spinnerLoading = false;
        },
      );
  }

  loadImageByUrl(url: string, baseImage: string): void {
    this.imageUrl = this.sanitizer.bypassSecurityTrustUrl(url);
    this.imageSrc = url;
    this.spinnerLoading = false;
    this.showImage = true;
    if (baseImage) {
      this.baseImage = baseImage;
    } else {
      this.baseImage = url;
    }
  }
}
