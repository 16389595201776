import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import Route from '@core/constants/route';
import { EngagementTypeEnum } from '@core/models/enums/engagement-type-enum';
import { TargetPagesEnum } from '@core/models/enums/target-page-enum';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { map, tap, withLatestFrom } from 'rxjs/operators';
import { EngagementCreationFlows } from '../../core/constants/EngagementCreationFlows';
import { navigateBack, selectTargetPage, selectTrigger } from '../actions/wizard.actions';
import { getWizardState, State } from '../store';

@Injectable()
export class WizardEffects {
  triggerSelected$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(selectTrigger),
        tap(({ trigger }) => {
          this.navigateToTriggerEngagementCreationStep(trigger, 1);
        }),
      ),
    { dispatch: false },
  );

  targetSelected$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(selectTargetPage),
        tap(({ target }) => {
          this.navigateToTargetEngagementCreationStep(target, 1);
        }),
      ),
    { dispatch: false },
  );

  navigateBack$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(navigateBack),
        withLatestFrom(this.store.select(getWizardState)),
        map(([, store]) => store),
        tap(({ trigger, target, step }) => {
          if (trigger !== null) {
            this.navigateToTriggerEngagementCreationStep(trigger, step);
          } else {
            this.navigateToTargetEngagementCreationStep(target, step);
          }
        }),
      ),
    { dispatch: false },
  );

  constructor(private actions$: Actions, private router: Router, private store: Store<State>) {}

  navigateToTriggerEngagementCreationStep(trigger: EngagementTypeEnum, step) {
    if (!trigger) {
      this.router.navigate([`/${Route.ENGAGEMENT_CREATION}/${Route.TRIGGERS}`]);
    } else {
      this.router.navigate([
        `/${Route.ENGAGEMENT_CREATION}/${EngagementCreationFlows[EngagementTypeEnum[trigger]][step]}`,
      ]);
    }
  }

  navigateToTargetEngagementCreationStep(target: TargetPagesEnum, step) {
    if (!target) {
      this.router.navigate([`/${Route.ENGAGEMENT_CREATION}/${Route.TARGETS}`]);
    } else {
      this.router.navigate([`/${Route.ENGAGEMENT_CREATION}/${EngagementCreationFlows[TargetPagesEnum[target]][step]}`]);
    }
  }
}
