import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatLegacyDialogModule } from '@angular/material/legacy-dialog';
import { AssociatedEngagementsPopupModule } from './associated-engagements-popup/associated-engagements-popup.module';
import { ChangeTargetPagePopupModule } from './change-target-page-popup/change-target-page-popup.module';
import { GoToMyDetailsPopupModule } from './go-to-my-details-popup/go-to-my-details-popup.module';
import { ImagePreviewPopupModule } from './image-preview-popup/image-preview-popup.module';
import { OnBoardingPopupModule } from './on-boarding-popup/on-boarding-popup.module';
import { PageBuilderCustomisationModule } from './page-builder-customisation/page-builder-customisation.module';
import { PageBuildingBlocksPopupModule } from './page-building-blocks-popup/page-building-blocks-popup.module';
import { PopupService } from './popup.service';
import { ProductCreationPopupModule } from './product-creation-popup/product-creation-popup.module';
import { ProductEditPopupModule } from './product-edit-popup/product-edit-popup.module';
import { SimpleConfirmPopupModule } from './simple-confirm-popup/simple-confirm-popup.module';
import { SuccessStatusPopupModule } from './success-status-popup/success-status-popup.module';
import { TargetPreviewPopupModule } from './target-preview-popup/target-preview-popup.module';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    AssociatedEngagementsPopupModule,
    PageBuilderCustomisationModule,
    MatLegacyDialogModule,
    SuccessStatusPopupModule,
    SimpleConfirmPopupModule,
    ChangeTargetPagePopupModule,
    TargetPreviewPopupModule,
    ImagePreviewPopupModule,
    PageBuildingBlocksPopupModule,
    ProductCreationPopupModule,
    ProductEditPopupModule,
    GoToMyDetailsPopupModule,
    OnBoardingPopupModule,
  ],
  providers: [PopupService],
})
export class PopupModule {}
