import {PageBuildingBlockTypeEnum} from '@core/models/enums/page-building-block-type-enum';
import {PageBuildingBlock} from '@core/models/page-building-block';
import {environment} from '@env/environment';

export class PageBuildingBlockConfig {
  type: PageBuildingBlockTypeEnum;
  name: string;
  icon?: string;
  details: PageBuildingBlock;
  isEditable: boolean;
  isApplied: boolean;
  elementId: string;

  constructor(type: PageBuildingBlockTypeEnum, details: PageBuildingBlock = null, isApplied: boolean = false) {
    this.type = type;
    this.setConfigByType();
    this.isApplied = isApplied;
    this.details = new PageBuildingBlock(type, details);
  }

  private setConfigByType(): void {
    switch (this.type) {
      case PageBuildingBlockTypeEnum.Header:
        this.name = 'HEADER';
        this.icon = 'title';
        this.isEditable = true;
        this.elementId = 'page-building-block-header';
        break;
      case PageBuildingBlockTypeEnum.Paragraph:
        this.name = 'TEXT_BLOCK';
        this.icon = 'format_align_left';
        this.elementId = 'page-building-block-paragraph';
        this.isEditable = true;
        break;
      case PageBuildingBlockTypeEnum.Image:
        this.name = 'IMAGE';
        this.icon = 'image';
        this.elementId = 'page-building-block-image';
        this.isEditable = true;
        break;
      case PageBuildingBlockTypeEnum.Video:
        this.name = 'VIDEO';
        this.icon = 'theaters';
        this.elementId = 'page-building-block-video';
        this.isEditable = true;
        break;
      case PageBuildingBlockTypeEnum.Divider:
        this.name = 'DIVIDER';
        this.icon = 'more_horiz';
        this.elementId = 'page-building-block-divider';
        this.isEditable = false;
        break;
      case PageBuildingBlockTypeEnum.DateField:
        this.name = 'DATE_PICKER';
        this.icon = 'date_range';
        this.elementId = 'page-building-block-date-field';
        this.isEditable = true;
        break;
      case PageBuildingBlockTypeEnum.Select:
        this.name = 'SELECT_DROP_DOWN_LIST';
        this.icon = 'unfold_more';
        this.elementId = 'page-building-block-select';
        this.isEditable = true;
        break;
      case PageBuildingBlockTypeEnum.TextField:
        this.name = 'TEXT_ENTRY_FIELD';
        this.icon = 'text_fields';
        this.elementId = 'page-building-block-text-field';
        this.isEditable = true;
        break;
      case PageBuildingBlockTypeEnum.Coupon:
        this.name = 'COUPON';
        this.icon = 'card_giftcard';
        this.elementId = 'page-building-block-coupon';
        this.isEditable = true;
        break;
      case PageBuildingBlockTypeEnum.Link:
        this.name = 'LINK_TO_PAGE';
        this.icon = 'link_to_page';
        this.elementId = 'page-building-block-link-to-page';
        this.isEditable = true;
        break;
      case PageBuildingBlockTypeEnum.MessageEntryField:
        this.name = 'MESSAGE_ENTRY_FIELD';
        this.icon = 'subject';
        this.elementId = 'page-building-block-message-entry-field';
        this.isEditable = true;
        break;
      default:
        this.name = '';
        this.icon = 'report_problem';
        this.isEditable = false;
        break;
    }
  }

  static getPageBuildingBlocksWithoutInputFields(): PageBuildingBlockConfig[] {
    const buildingBlocks = [
      new PageBuildingBlockConfig(PageBuildingBlockTypeEnum.Header),
      new PageBuildingBlockConfig(PageBuildingBlockTypeEnum.Paragraph),
      new PageBuildingBlockConfig(PageBuildingBlockTypeEnum.Image),
      new PageBuildingBlockConfig(PageBuildingBlockTypeEnum.Video),
      new PageBuildingBlockConfig(PageBuildingBlockTypeEnum.Divider),
      new PageBuildingBlockConfig(PageBuildingBlockTypeEnum.Link),
    ];

    return buildingBlocks.filter((b) => !environment.hiddenElements.includes(b.elementId));
  }

  static getAllPageBuildingBlocks(): PageBuildingBlockConfig[] {
    const buildingBlocks = [
      new PageBuildingBlockConfig(PageBuildingBlockTypeEnum.Header),
      new PageBuildingBlockConfig(PageBuildingBlockTypeEnum.Paragraph),
      new PageBuildingBlockConfig(PageBuildingBlockTypeEnum.Image),
      new PageBuildingBlockConfig(PageBuildingBlockTypeEnum.Video),
      new PageBuildingBlockConfig(PageBuildingBlockTypeEnum.Coupon),
      new PageBuildingBlockConfig(PageBuildingBlockTypeEnum.Divider),
      new PageBuildingBlockConfig(PageBuildingBlockTypeEnum.DateField),
      new PageBuildingBlockConfig(PageBuildingBlockTypeEnum.Select),
      new PageBuildingBlockConfig(PageBuildingBlockTypeEnum.TextField),
      new PageBuildingBlockConfig(PageBuildingBlockTypeEnum.Link),
    ];

    return buildingBlocks.filter((b) => !environment.hiddenElements.includes(b.elementId));
  }

  static getAllPageBuildingBlocksForDemoUser(): PageBuildingBlockConfig[] {
    const buildingBlocks = [
      new PageBuildingBlockConfig(PageBuildingBlockTypeEnum.Header),
      new PageBuildingBlockConfig(PageBuildingBlockTypeEnum.Paragraph),
      new PageBuildingBlockConfig(PageBuildingBlockTypeEnum.Image),
      new PageBuildingBlockConfig(PageBuildingBlockTypeEnum.Video),
      new PageBuildingBlockConfig(PageBuildingBlockTypeEnum.Coupon),
      new PageBuildingBlockConfig(PageBuildingBlockTypeEnum.Divider),
      new PageBuildingBlockConfig(PageBuildingBlockTypeEnum.DateField),
      new PageBuildingBlockConfig(PageBuildingBlockTypeEnum.Select),
      new PageBuildingBlockConfig(PageBuildingBlockTypeEnum.TextField),
      new PageBuildingBlockConfig(PageBuildingBlockTypeEnum.MessageEntryField),
      new PageBuildingBlockConfig(PageBuildingBlockTypeEnum.Link),
    ];

    return buildingBlocks.filter((b) => !environment.hiddenElements.includes(b.elementId));
  }
}
