import { Injectable } from '@angular/core';
import { MatLegacyDialog, MatLegacyDialogRef } from '@angular/material/legacy-dialog';
import { PageBuildingBlockTypeEnum } from '@core/models/enums/page-building-block-type-enum';
import { PageBuildingBlock } from '@core/models/page-building-block';
import { LinkToPageCustomisationPopupComponent } from '@shared/components/popup/page-builder-customisation/link-to-page-customisation-popup/link-to-page-customisation-popup.component';
import { Observable, of } from 'rxjs';
import { delay } from 'rxjs/operators';
import { LogUtils } from 'src/app/utils/log-utils';
import { BasePopupConfiguration } from '../base-popup-configuration';
import { SimpleConfirmPopupComponent } from '../simple-confirm-popup/simple-confirm-popup.component';
import { SimpleConfirmPopupConfiguration } from '../simple-confirm-popup/simple-confirm-popup.configuration';
import { BasePageBuilderConfiguration } from './base-page-builder-configuration';
import { CouponCustomisationPopupComponent } from './coupon-customisation-popup/coupon-customisation-popup.component';
import { DateFieldCustomisationPopupComponent } from './date-field-customisation-popup/date-field-customisation-popup.component';
import { HeadingCustomisationPopupComponent } from './heading-customisation-popup/heading-customisation-popup.component';
import { ImageCustomisationPopupComponent } from './image-customisation-popup/image-customisation-popup.component';
import { ParagraphCustomisationPopupComponent } from './paragraph-customisation-popup/paragraph-customisation-popup.component';
import { SelectionCustomisationPopupComponent } from './selection-customisation-popup/selection-customisation-popup.component';
import { MessageEntryFieldCustomisationPopupComponent } from './text-area-customisation-popup/message-entry-field-customisation-popup.component';
import { TextFieldCustomisationPopupComponent } from './text-field-customisation-popup/text-field-customisation-popup.component';
import { VideoCustomisationPopupComponent } from './video-customisation-popup/video-customisation-popup.component';

@Injectable({
  providedIn: 'root',
})
export class PageBuilderCustomisationService {
  constructor(private matDialog: MatLegacyDialog) {}

  customizePageBuildingBlocksByType(
    type: PageBuildingBlockTypeEnum,
    configuration: BasePopupConfiguration<BasePageBuilderConfiguration>,
  ): Observable<PageBuildingBlock> {
    switch (type) {
      case PageBuildingBlockTypeEnum.Header:
        return this.customizeHeading(configuration);
      case PageBuildingBlockTypeEnum.Paragraph:
        return this.customizeParagraph(configuration);
      case PageBuildingBlockTypeEnum.Image:
        return this.customizeImage(configuration);
      case PageBuildingBlockTypeEnum.Video:
        return this.customizeVideo(configuration);
      case PageBuildingBlockTypeEnum.DateField:
        return this.customizeDateField(configuration);
      case PageBuildingBlockTypeEnum.Select:
        return this.customizeSelection(configuration);
      case PageBuildingBlockTypeEnum.TextField:
        return this.customizeTextField(configuration);
      case PageBuildingBlockTypeEnum.Coupon:
        return this.customizeCoupon(configuration);
      case PageBuildingBlockTypeEnum.Link:
        return this.customizeLinkToPage(configuration);
      case PageBuildingBlockTypeEnum.MessageEntryField:
        return this.customizeMessageEntryField(configuration);
      default:
        LogUtils.warning(
          `No customisation popup component is implemented for page building block with type: ${PageBuildingBlockTypeEnum[type]}!`,
        );
        return of(configuration.componentConfiguration.data).pipe(delay(200));
    }
  }

  customizeDateField(params: BasePopupConfiguration<BasePageBuilderConfiguration>): Observable<PageBuildingBlock> {
    let dialogRef: MatLegacyDialogRef<DateFieldCustomisationPopupComponent>;
    dialogRef = this.matDialog.open(DateFieldCustomisationPopupComponent, this.getMatDialogConfig(params, '320px'));
    dialogRef.componentInstance.configuration = params.componentConfiguration;

    return dialogRef.afterClosed();
  }

  customizeHeading(params: BasePopupConfiguration<BasePageBuilderConfiguration>): Observable<PageBuildingBlock> {
    let dialogRef: MatLegacyDialogRef<HeadingCustomisationPopupComponent>;
    dialogRef = this.matDialog.open(HeadingCustomisationPopupComponent, this.getMatDialogConfig(params, '450px'));
    dialogRef.componentInstance.configuration = params.componentConfiguration;

    return dialogRef.afterClosed();
  }

  customizeImage(params: BasePopupConfiguration<BasePageBuilderConfiguration>): Observable<PageBuildingBlock> {
    let dialogRef: MatLegacyDialogRef<ImageCustomisationPopupComponent>;
    dialogRef = this.matDialog.open(ImageCustomisationPopupComponent, this.getMatDialogConfig(params));
    dialogRef.componentInstance.configuration = params.componentConfiguration;

    return dialogRef.afterClosed();
  }

  customizeParagraph(params: BasePopupConfiguration<BasePageBuilderConfiguration>): Observable<PageBuildingBlock> {
    let dialogRef: MatLegacyDialogRef<ParagraphCustomisationPopupComponent>;
    dialogRef = this.matDialog.open(ParagraphCustomisationPopupComponent, this.getMatDialogConfig(params, '450px'));
    dialogRef.componentInstance.configuration = params.componentConfiguration;

    return dialogRef.afterClosed();
  }

  customizeVideo(params: BasePopupConfiguration<BasePageBuilderConfiguration>): Observable<PageBuildingBlock> {
    let dialogRef: MatLegacyDialogRef<VideoCustomisationPopupComponent>;
    dialogRef = this.matDialog.open(VideoCustomisationPopupComponent, this.getMatDialogConfig(params));
    dialogRef.componentInstance.configuration = params.componentConfiguration;

    return dialogRef.afterClosed();
  }

  customizeSelection(params: BasePopupConfiguration<BasePageBuilderConfiguration>): Observable<PageBuildingBlock> {
    let dialogRef: MatLegacyDialogRef<SelectionCustomisationPopupComponent>;
    dialogRef = this.matDialog.open(SelectionCustomisationPopupComponent, this.getMatDialogConfig(params));
    dialogRef.componentInstance.configuration = params.componentConfiguration;

    return dialogRef.afterClosed();
  }

  customizeTextField(params: BasePopupConfiguration<BasePageBuilderConfiguration>): Observable<PageBuildingBlock> {
    let dialogRef: MatLegacyDialogRef<TextFieldCustomisationPopupComponent>;
    dialogRef = this.matDialog.open(TextFieldCustomisationPopupComponent, this.getMatDialogConfig(params));
    dialogRef.componentInstance.configuration = params.componentConfiguration;

    return dialogRef.afterClosed();
  }

  customizeMessageEntryField(
    params: BasePopupConfiguration<BasePageBuilderConfiguration>,
  ): Observable<PageBuildingBlock> {
    let dialogRef: MatLegacyDialogRef<MessageEntryFieldCustomisationPopupComponent>;
    dialogRef = this.matDialog.open(MessageEntryFieldCustomisationPopupComponent, this.getMatDialogConfig(params));
    dialogRef.componentInstance.configuration = params.componentConfiguration;
    return dialogRef.afterClosed();
  }

  customizeCoupon(params: BasePopupConfiguration<BasePageBuilderConfiguration>): Observable<PageBuildingBlock> {
    let dialogRef: MatLegacyDialogRef<CouponCustomisationPopupComponent>;
    dialogRef = this.matDialog.open(CouponCustomisationPopupComponent, this.getMatDialogConfig(params));
    dialogRef.componentInstance.configuration = params.componentConfiguration;

    return dialogRef.afterClosed();
  }

  customizeLinkToPage(params: BasePopupConfiguration<BasePageBuilderConfiguration>): Observable<PageBuildingBlock> {
    let dialogRef: MatLegacyDialogRef<LinkToPageCustomisationPopupComponent>;
    dialogRef = this.matDialog.open(LinkToPageCustomisationPopupComponent, this.getMatDialogConfigLinkToPage(params));
    dialogRef.componentInstance.configuration = params.componentConfiguration;

    return dialogRef.afterClosed();
  }

  simpleConfirmationPopup(params: BasePopupConfiguration<SimpleConfirmPopupConfiguration>): Observable<boolean> {
    const matDialogConfigParams = Object.assign({}, params);
    delete matDialogConfigParams.componentConfiguration;

    let dialogRef: MatLegacyDialogRef<SimpleConfirmPopupComponent>;
    dialogRef = this.matDialog.open(SimpleConfirmPopupComponent, matDialogConfigParams);
    dialogRef.componentInstance.configuration = params.componentConfiguration;

    return dialogRef.afterClosed();
  }

  getMatDialogConfig(params: BasePopupConfiguration<BasePageBuilderConfiguration>, maxWidth: string = '420px') {
    const matDialogConfigParams = Object.assign({}, { ...params, minWidth: '295px', width: '90%', maxWidth });
    delete matDialogConfigParams.componentConfiguration;
    return matDialogConfigParams;
  }

  getMatDialogConfigLinkToPage(params: BasePopupConfiguration<BasePageBuilderConfiguration>, maxWidth = '570px') {
    const matDialogConfigParams = Object.assign({}, { ...params, minWidth: 'auto', width: '90%', maxWidth });
    delete matDialogConfigParams.componentConfiguration;
    return matDialogConfigParams;
  }
}
