// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mat-option {
  height: 0;
}

input {
  height: 50px;
}

mat-label {
  font-size: 15px;
  font-weight: 400;
}

.category-label {
  margin-bottom: 5px;
}

.search-input {
  height: 20px;
}

mat-form-field {
  width: 100%;
}

:host {
  display: flex;
  flex-direction: column;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/choose-category/choose-category.component.scss"],"names":[],"mappings":"AAAA;EACE,SAAA;AACF;;AACA;EACE,YAAA;AAEF;;AAAA;EACE,eAAA;EACA,gBAAA;AAGF;;AADA;EACE,kBAAA;AAIF;;AADA;EACE,YAAA;AAIF;;AADA;EACE,WAAA;AAIF;;AADA;EACE,aAAA;EACA,sBAAA;AAIF","sourcesContent":[".mat-option {\n  height: 0;\n}\ninput {\n  height: 50px;\n}\nmat-label {\n  font-size: 15px;\n  font-weight: 400;\n}\n.category-label {\n  margin-bottom: 5px;\n}\n\n.search-input {\n  height: 20px;\n}\n\nmat-form-field {\n  width: 100%;\n}\n\n:host {\n  display: flex;\n  flex-direction: column;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
