import { createAction, props } from '@ngrx/store';

interface AciPaymentProviderDetailsDto {
  paymentProviderName: string | null;
  paymentProviderDetails: any;
  currencyForPayment: string | null;
  timezone: string | null;
  phone: string | null;
  termsAndConditionsUrl: string | null;
  privacyPolicyUrl: string | null;
  companyInformationUrl: string | null;
  contactName: string | null;
  contactNumber: string | null;
  contactEmail: string | null;
  contactAddress: string | null;
  storeName?: string | null;
}

export const createAciPaymentProviderDetails = createAction(
  '[CreateAciPaymentProviderDetails] Create ACI Payment Provider Details',
  props<AciPaymentProviderDetailsDto>(),
);

export const updateAciPaymentProviderDetails = createAction(
  '[UpdateAciPaymentProviderDetails] Update ACI Payment Provider Details',
  props<AciPaymentProviderDetailsDto>(),
);
