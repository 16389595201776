import { Action, createReducer, on } from '@ngrx/store';
import * as AciPaymentProviderDetailsActions from '../actions/aci-payment-provider-details.actions';

export interface AciPaymentProviderDetailsState {
  currencyForPayment: string | null;
  timezone: string | null;
  phone: string | null;
  termsAndConditionsUrl: string | null;
  privacyPolicyUrl: string | null;
  companyInformationUrl: string | null;
  contactName: string | null;
  contactNumber: string | null;
  contactEmail: string | null;
  contactAddress: string | null;
  paymentProviderName: string | null;
  paymentProviderDetails: any | null;
  storeName: string | null;
}

export const initialState: AciPaymentProviderDetailsState = {
  currencyForPayment: null,
  timezone: null,
  phone: null,
  termsAndConditionsUrl: null,
  privacyPolicyUrl: null,
  companyInformationUrl: null,
  contactName: null,
  contactNumber: null,
  contactEmail: null,
  contactAddress: null,
  paymentProviderName: null,
  paymentProviderDetails: null,
  storeName: null,
};

const aciPaymentProviderDetailsReducer = createReducer(
  initialState,
  on(AciPaymentProviderDetailsActions.createAciPaymentProviderDetails, (state, payload) => ({
    ...state,
    ...payload,
  })),
  on(AciPaymentProviderDetailsActions.updateAciPaymentProviderDetails, (state, payload) => ({
    ...state,
    ...payload,
  })),
);

export function reducer(state = initialState, action: Action): AciPaymentProviderDetailsState {
  return aciPaymentProviderDetailsReducer(state, action);
}
