import { Guid } from './Guid';

export class StringFormatHelper {
  static getNameWithoutExtension(name: string, separator: string = '.') {
    return name.split(separator).length < 2 ? name : name.split(separator).slice(0, -1).join(separator);
  }

  static getUniqueFileName(oldFileName: string): string {
    const id = Guid.newGuid().toUpperCase();
    const extension = oldFileName.substring(oldFileName.lastIndexOf('.') + 1, oldFileName.length) || oldFileName;
    return `${id}_${new Date().getTime()}.${extension}`;
  }
}
