import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CardInfo } from '@core/models/card-info';

@Component({
  selector: 'app-base-card-container',
  templateUrl: './base-card-container.component.html',
  styleUrls: ['./base-card-container.component.scss'],
})
export class BaseCardContainerComponent implements OnInit {
  @Input() baseCards: CardInfo[];
  @Input() headerName?: string;
  @Input() headerBackgroundColor?: string;
  @Input() type?: string;
  @Output() selectCard: EventEmitter<any> = new EventEmitter();

  ngOnInit(): void {}

  select(card: CardInfo) {
    if (card.hasPassedLimit) {
      return;
    }

    this.selectCard.emit(card);
  }
}
