import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';
import { MatLegacyDialogModule } from '@angular/material/legacy-dialog';
import { TranslateModule } from '@ngx-translate/core';
import { ConfirmButtonModule } from '@shared/components/confirm-button/confirm-button.module';
import { EditorModule } from '@tinymce/tinymce-angular';
import { HeadingCustomisationPopupComponent } from './heading-customisation-popup.component';

@NgModule({
  declarations: [HeadingCustomisationPopupComponent],
  imports: [
    CommonModule,
    FlexLayoutModule,
    MatLegacyDialogModule,
    MatLegacyButtonModule,
    FormsModule,
    ReactiveFormsModule,
    EditorModule,
    TranslateModule.forChild(),
    ConfirmButtonModule,
  ],
  exports: [HeadingCustomisationPopupComponent],
})
export class HeadingCustomisationPopupModule {}
