import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Endpoints } from '@core/constants/Endpoints';
import { CreditCardAdapter } from '@core/models/adapters/credit-card-adapter';
import { CreditCardDTO } from '@core/models/dtos/credit-card-dto';
import { MethodResponseDTO } from '@core/models/dtos/method-response-dto';
import { PricingTargetTypeEnum } from '@core/models/enums/pricing-target-type.enum';
import { MakePaymentRequest } from '@core/models/make-payment-request';
import { MakePaymentResponse } from '@core/models/make-payment-response';
import { PriceInfoModel } from '@core/models/price-info.model';
import { CreditCardViewModel } from '@core/models/view-models/credit-card-view-model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BaseAPIServiceService } from './http/base-apiservice.service';

@Injectable({
  providedIn: 'root',
})
export class PaymentApiService extends BaseAPIServiceService {
  constructor(protected http: HttpClient, private adapter: CreditCardAdapter) {
    super(http);
  }

  makePayment(request: MakePaymentRequest): Observable<MethodResponseDTO<MakePaymentResponse>> {
    return this.http.post<MethodResponseDTO<MakePaymentResponse>>(Endpoints.MAKE_PAYMENT, request);
  }

  getSavedCreditCards(): Observable<CreditCardViewModel[]> {
    return this.get<CreditCardDTO[]>(Endpoints.GET_PAYMENT_METHODS).pipe(
      map((response) => response.map((creditCard) => this.adapter.fromDTO(creditCard))),
    );
  }

  detachPaymentMethod(paymentMethodId: string): Observable<unknown> {
    return this.http.delete(`${Endpoints.DETACH_PAYMENT_METHOD}?paymentMethodId=${paymentMethodId}`);
  }

  getLocationPrice(pricingTargetType: PricingTargetTypeEnum, durationInDays: number): Observable<PriceInfoModel> {
    const params = new HttpParams()
      .set('pricingTargetType', pricingTargetType.toString())
      .set('durationInDays', durationInDays.toString());
    return this.http.get<PriceInfoModel>(Endpoints.GET_ENGAGEMENT_PRICE, { params });
  }

  getPrice(pricingTargetType: PricingTargetTypeEnum): Observable<PriceInfoModel> {
    const params = new HttpParams().set('pricingTargetType', pricingTargetType.toString());
    return this.http.get<PriceInfoModel>(Endpoints.GET_ENGAGEMENT_PRICE, { params });
  }
}
