// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.input-field {
  width: 100%;
  height: auto;
  padding-top: 7px;
  padding-bottom: 12px;
}

input[type=text] {
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  outline: none;
  padding: 10px 10px;
  font-size: 15px;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  width: 100%;
}

input[type=text]:focus {
  box-shadow: 0 0 5px #02c6ff;
  border: 1px solid #02c6ff;
  padding: 10px 10px;
  font-size: 15px;
  border-radius: 5px;
  width: 100%;
}

.title {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 10px;
}

.label {
  font-size: 14px;
  font-weight: 500;
}

.btn-actions {
  display: flex;
  justify-content: space-between;
  padding: 10px 0 24px 0;
}

@media screen and (max-width: 425px) {
  .btn-actions {
    flex-direction: column;
    gap: 10px;
  }
  app-confirm-button {
    width: 100%;
  }
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/popup/page-builder-customisation/text-area-customisation-popup/message-entry-field-customisation-popup.component.scss"],"names":[],"mappings":"AAGA;EACE,WAAA;EACA,YAAA;EACA,gBAAA;EACA,oBAAA;AAFF;;AAKA;EACE,wCAAA;EACA,qCAAA;EACA,oCAAA;EACA,mCAAA;EACA,aAAA;EACA,kBAAA;EACA,eAAA;EACA,yBAAA;EACA,kBAAA;EACA,WAAA;AAFF;;AAKA;EACE,2BAAA;EACA,yBAAA;EACA,kBAAA;EACA,eAAA;EACA,kBAAA;EACA,WAAA;AAFF;;AAKA;EACE,eAAA;EACA,gBAAA;EACA,mBAAA;AAFF;;AAKA;EACE,eAAA;EACA,gBAAA;AAFF;;AAKA;EACE,aAAA;EACA,8BAAA;EACA,sBAAA;AAFF;;AAKA;EACE;IACE,sBAAA;IACA,SAAA;EAFF;EAIA;IACE,WAAA;EAFF;AACF","sourcesContent":["@import '@angular/material/theming';\n@import 'variables';\n\n.input-field {\n  width: 100%;\n  height: auto;\n  padding-top: 7px;\n  padding-bottom: 12px;\n}\n\ninput[type='text'] {\n  -webkit-transition: all 0.3s ease-in-out;\n  -moz-transition: all 0.3s ease-in-out;\n  -ms-transition: all 0.3s ease-in-out;\n  -o-transition: all 0.3s ease-in-out;\n  outline: none;\n  padding: 10px 10px;\n  font-size: 15px;\n  border: 1px solid #e0e0e0;\n  border-radius: 5px;\n  width: 100%;\n}\n\ninput[type='text']:focus {\n  box-shadow: 0 0 5px rgba($primary, 1);\n  border: 1px solid rgba($primary, 1);\n  padding: 10px 10px;\n  font-size: 15px;\n  border-radius: 5px;\n  width: 100%;\n}\n\n.title {\n  font-size: 18px;\n  font-weight: 600;\n  margin-bottom: 10px;\n}\n\n.label {\n  font-size: 14px;\n  font-weight: 500;\n}\n\n.btn-actions {\n  display: flex;\n  justify-content: space-between;\n  padding: 10px 0 24px 0;\n}\n\n@media screen and (max-width: 425px) {\n  .btn-actions {\n    flex-direction: column;\n    gap: 10px;\n  }\n  app-confirm-button {\n    width: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
