import { Component, OnDestroy, OnInit } from '@angular/core';
import { EngagementCreationService } from '../engagement-creation/engagement-creation.service';

@Component({
  selector: 'app-target-page-creation',
  templateUrl: './target-page-creation.component.html',
  styleUrls: ['./target-page-creation.component.scss'],
})
export class TargetPageCreationComponent implements OnInit, OnDestroy {
  constructor(private engagementCreationService: EngagementCreationService) {}

  ngOnDestroy(): void {
    this.engagementCreationService.showEngagementDetails();
  }

  ngOnInit(): void {
    this.engagementCreationService.hideEngagementDetails();
  }
}
