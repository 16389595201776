import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { CardInfo } from '@core/models/card-info';
import { EngagementTypeEnum } from '@core/models/enums/engagement-type-enum';
import { StyleConfig } from '@shared/components/base-overlay-card-message/models/custom-css.model';

@Component({
  selector: 'app-base-card',
  templateUrl: './base-card.component.html',
  styleUrls: ['./base-card.component.scss'],
})
export class BaseCardComponent implements OnInit {
  @Input() cardInfo: CardInfo;
  @Input() type?: string;
  styleConfig: StyleConfig = {
    padding: { padding: '50px 20px' },
    marginTop: { 'margin-top': '15px' },
  };
  isIconAsAttribute: boolean;

  get translatableText() {
    return `${this.type.toUpperCase()}.${this.cardInfo.name}`;
  }

  ngOnInit(): void {
    this.isIconAsAttribute =
      this.cardInfo.type === EngagementTypeEnum.SmartCode || this.cardInfo.type === EngagementTypeEnum.InstantWeb;
  }
}
