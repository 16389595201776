import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import createAuth0Client, { Auth0Client, RedirectLoginResult } from '@auth0/auth0-spa-js';
import { environment } from '@env/environment';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, combineLatest, from, Observable, of, throwError } from 'rxjs';
import { catchError, concatMap, map, shareReplay, switchMap, tap } from 'rxjs/operators';
import { AuthServiceInterface, NO_PAY_PAYMENT_FLOW } from './auth-service-inteface';

@Injectable({
  providedIn: 'root',
})
export class AuthService implements AuthServiceInterface {
  private userProfileSubject$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  private auth0Client$: Observable<Auth0Client> = (from(
    environment.auth0.organization
      ? createAuth0Client({
          domain: environment.auth0.domain,
          client_id: environment.auth0.clientId,
          redirect_uri: `${window.location.origin}${environment.auth0.redirect_uri}`,
          audience: environment.auth0.audience,
          useRefreshTokens: true,
          ui_locales: this.translateService.currentLang,
          organization: environment.auth0.organization,
        })
      : createAuth0Client({
          domain: environment.auth0.domain,
          client_id: environment.auth0.clientId,
          redirect_uri: `${window.location.origin}${environment.auth0.redirect_uri}`,
          audience: environment.auth0.audience,
          useRefreshTokens: true,
          ui_locales: this.translateService.currentLang,
        }),
  ) as Observable<Auth0Client>).pipe(
    shareReplay(1), // Every subscription receives the same shared value
    catchError((err) => throwError(err)),
  );
  handleRedirectCallback$: Observable<RedirectLoginResult> = this.auth0Client$.pipe(
    concatMap((client: Auth0Client) => from(client.handleRedirectCallback())),
  );
  isAuthenticated$ = this.auth0Client$.pipe(
    concatMap((client: Auth0Client) => from(client.isAuthenticated())),
    tap((res) => (this.loggedIn = res)),
  );
  isLoggedIn$ = new BehaviorSubject<boolean>(false);
  loggedIn: boolean = null;
  paymentFlow: string;
  useDigimarc: boolean;
  userProfile$: Observable<any> = this.userProfileSubject$.asObservable();

  constructor(private router: Router, private translateService: TranslateService) {
    this.localAuthSetup();
    this.handleAuthCallback();
  }

  getPaymentFlow$(): Observable<string> {
    if (environment.defferedPayment) {
      return of(NO_PAY_PAYMENT_FLOW);
    }
    return this.userProfile$.pipe(map((user) => user && user['http://localhost:44346/paymentFlow']));
  }

  getTokenSilently$(options?): Observable<string> {
    return this.auth0Client$.pipe(concatMap((client: Auth0Client) => from(client.getTokenSilently(options))));
  }

  getUser$(options?): Observable<any> {
    return this.auth0Client$.pipe(
      concatMap((client: Auth0Client) => from(client.getUser(options))),
      tap((user) => {
        this.userProfileSubject$.next(user);
        this.paymentFlow = user['http://localhost:44346/paymentFlow'];
        this.useDigimarc = !!user['http://localhost:44346/watermarkProvider']
      }),
    );
  }

  login(redirectPath: string = '/', queryParams?: any): void {
    this.auth0Client$.subscribe((client: Auth0Client) => {
      client.loginWithRedirect({
        redirect_uri: `${window.location.origin}${environment.auth0.redirect_uri}`,
        appState: { target: redirectPath },
        language: this.translateService.currentLang,
        ui_locales: this.translateService.currentLang,
        translatedTitle: this.translateService.instant('AUTH0_LOGIN_SCREEN_TITLE'),
        ...queryParams,
      });
    });
  }

  login$(redirectPath: string = '/', queryParams?: any): Observable<void> {
    return this.auth0Client$.pipe(
      switchMap((client) => {
        return this.translateService.get('AUTH0_LOGIN_SCREEN_TITLE').pipe(
          switchMap((translation) => {
            return from(
              client.loginWithRedirect({
                redirect_uri: `${window.location.origin}${environment.auth0.redirect_uri}`,
                appState: { target: redirectPath, queryParams },
                language: this.translateService.currentLang,
                ui_locales: this.translateService.currentLang,
                translatedTitle: translation,
                ...queryParams,
              }),
            );
          }),
        );
      }),
    );
  }

  logout(): void {
    this.auth0Client$.subscribe((client: Auth0Client) => {
      client.logout({
        client_id: environment.auth0.clientId,
        returnTo: `${window.location.origin}`,
      });
      this.isLoggedIn$.next(false);
    });
  }

  private handleAuthCallback(): void {
    const params = window.location.search;
    if (params.includes('code=') && params.includes('state=')) {
      let targetRoute: string;
      let queryParams: any;
      const authComplete$ = this.handleRedirectCallback$.pipe(
        tap((cbRes) => {
          targetRoute = cbRes.appState && cbRes.appState.target ? cbRes.appState.target : '/';
          queryParams = cbRes.appState && cbRes.appState.queryParams;
        }),
        concatMap(() => {
          return combineLatest([this.getUser$(), this.isAuthenticated$]);
        }),
      );
      authComplete$.subscribe(([user, loggedIn]) => {
        this.isLoggedIn$.next(!!user);
        if (targetRoute.indexOf('?') !== -1 && queryParams) {
          const url = targetRoute.split('?')[0];

          this.router.navigate([url], { queryParams });
        } else {
          this.router.navigate([targetRoute]);
        }
      });
    }
  }

  private localAuthSetup(): void {
    const checkAuth$ = this.isAuthenticated$.pipe(
      concatMap((loggedIn: boolean) => {
        if (loggedIn) {
          this.isLoggedIn$.next(true);
          return this.getUser$();
        }
        return of(loggedIn);
      }),
    );
    checkAuth$.subscribe();
  }
}
