import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';
import { MatLegacyCardModule } from '@angular/material/legacy-card';
import { MatLegacyDialogModule } from '@angular/material/legacy-dialog';
import { MatLegacyInputModule } from '@angular/material/legacy-input';
import { MatLegacyTooltipModule } from '@angular/material/legacy-tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { ChipFiltersModule } from '@shared/components/chip-filters/chip-filters.module';
import { ConfirmButtonModule } from '@shared/components/confirm-button/confirm-button.module';
import { PaginationModule } from '@shared/components/pagination/pagination.module';
import { SearchInputModule } from '@shared/components/search-input/search-input.module';
import { TableDropdownGroupsModule } from '@shared/components/table-dropdown-groups/table-dropdown-groups.module';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { TargetPagesCardModule } from '../../../../features/target-pages-dashboard/target-pages-card/target-pages-card.module';
import { ChangeTargetPagePopupComponent } from './change-target-page-popup.component';

@NgModule({
  declarations: [ChangeTargetPagePopupComponent],
  imports: [
    CommonModule,
    MatLegacyDialogModule,
    MatLegacyButtonModule,
    MatIconModule,
    FlexLayoutModule,
    ProgressSpinnerModule,
    ChipFiltersModule,
    SearchInputModule,
    MatLegacyCardModule,
    MatLegacyTooltipModule,
    MatLegacyInputModule,
    FormsModule,
    PaginationModule,
    TranslateModule.forChild(),
    TableDropdownGroupsModule,
    ConfirmButtonModule,
    TargetPagesCardModule,
  ],
  exports: [ChangeTargetPagePopupComponent],
})
export class ChangeTargetPagePopupModule {}
