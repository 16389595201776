import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ProductSetupInfoComponent } from './product-setup-info.component';
import { InformationPanelModule } from '@shared/components/information-panel/information-panel.module';

@NgModule({
  declarations: [ProductSetupInfoComponent],
  imports: [CommonModule, InformationPanelModule],
  exports: [ProductSetupInfoComponent],
})
export class ProductSetupInfoModule {}
