import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Description } from '@core/constants/EngagementTriggerDescription';
import { EngagementViewModel } from '@core/models/view-models/engagement-view-model';

@Component({
  selector: 'base-trigger-container',
  templateUrl: './base-trigger-container.component.html',
  styleUrls: ['./base-trigger-container.component.scss'],
})
export class BaseTriggerContainerComponent implements OnInit {
  @Input() header: string;
  @Input() descriptions: Description[];
  @Input() image: string;
  @Output() proceedToNextStep: EventEmitter<EngagementViewModel> = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}

  navigateNext() {
    this.proceedToNextStep.emit();
  }
}
