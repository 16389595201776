import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ExtendedModule, FlexLayoutModule, GridModule } from '@angular/flex-layout';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { CustomDirectivesModule } from '@core/directives/custom-directives.module';
import { TranslateModule } from '@ngx-translate/core';
import { ConfirmButtonModule } from '@shared/components/confirm-button/confirm-button.module';
import { LinksContainerModule } from '@shared/components/links-container/links-container.module';
import { PoweredByRezolveModule } from '@shared/components/powered-by-rezolve/powered-by-rezolve.module';
import { EngagementCreationComponent } from './engagement-creation.component';
import { EngagementCreationService as EngagementCreationDataService } from './engagement-creation.service';
import { WizardComponent } from './wizard/wizard.component';
import { WizardStepComponent } from './wizzard-step/wizard-step.component';

@NgModule({
  declarations: [EngagementCreationComponent, WizardComponent, WizardStepComponent],
  imports: [
    CommonModule,
    FlexLayoutModule,
    MatIconModule,
    RouterModule,
    GridModule,
    ExtendedModule,
    LinksContainerModule,
    PoweredByRezolveModule,
    CustomDirectivesModule,
    TranslateModule.forChild(),
    ConfirmButtonModule,
    MatDividerModule,
  ],
  providers: [EngagementCreationDataService],
})
export class EngagementCreationModule {}
