import { Component, Inject, ViewChild } from '@angular/core';
import { MatLegacyDialogRef, MAT_LEGACY_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { CropperPosition, ImageCroppedEvent, ImageCropperComponent, LoadedImage } from 'ngx-image-cropper';

@Component({
  selector: 'app-image-cropper-modal',
  templateUrl: './image-cropper-modal.component.html',
  styleUrls: ['./image-cropper-modal.component.scss'],
})
export class ImageCropperModalComponent {
  croppedImage = '';
  baseImage = '';
  isImageLoaded = false;
  dimensions = {};
  maintainAspectRatio = true;
  cropperMinHeight = 64;
  cropperMinWidth = 64;
  resizeCroppedImage: number | undefined;
  cropperPosition: CropperPosition;
  imageName: string;
  isImageEdited: boolean;
  imageType: string;
  imageId: string;

  @ViewChild('imageCropper') imageCropper: ImageCropperComponent;

  constructor(
    @Inject(MAT_LEGACY_DIALOG_DATA)
    public data: {
      croppedImage: string;
      baseImage: string;
      maintainAspectRatio: boolean;
      cropperPosition?: CropperPosition;
      imageName?: string;
      isImageEdited: boolean;
      imageType: string;
      imageId: string;
      cropperMinHeight?: number;
      cropperMinWidth?: number;
      resizeCroppedImage?: number;
    },
    private dialogRef: MatLegacyDialogRef<ImageCropperModalComponent>,
  ) {
    this.croppedImage = data.croppedImage;
    this.baseImage = data.baseImage;
    this.maintainAspectRatio = data.maintainAspectRatio;
    this.cropperPosition = data.cropperPosition;
    this.imageName = data.imageName;
    this.imageType = data.imageType;
    this.imageId = data.imageId;
    this.cropperMinHeight = data.cropperMinHeight;
    this.cropperMinHeight = data.cropperMinHeight;
    this.resizeCroppedImage = data.resizeCroppedImage;
  }

  imageCropped(event: ImageCroppedEvent) {
    if (!this.cropperPosition) {
      this.imageCropper.cropper = event.cropperPosition;
      this.croppedImage = event.base64;
    } else {
      if (this.imageCropper.cropper === this.data.cropperPosition) {
        this.imageCropper.cropper = this.data.cropperPosition;
        this.croppedImage = event.base64;
      } else {
        this.imageCropper.cropper = this.data.cropperPosition;
        this.croppedImage = this.data.croppedImage;
      }
    }
  }

  imageLoadedEvent(image: LoadedImage) {
    if (image.original.objectUrl && image.original.objectUrl !== 'undefined') {
      this.dimensions = image.original;
    }
  }

  cropperReady(event: any) {
    if (event) {
      this.isImageLoaded = true;
    }
  }

  getImageSize() {
    const formattedByteString = this.croppedImage.substring(this.croppedImage.indexOf(',') + 1);
    const byteString = atob(formattedByteString);
    this.imageType = this.croppedImage.split('data:')[1].split(';base64,')[0];
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type: this.imageType });
    const file = new File([blob], `${this.imageName}-${this.imageId}`, { type: this.imageType });
    const fileList = {
      0: file,
      length: 1,
    };

    this.dialogRef.close({
      data: {
        dimensions: this.dimensions,
        croppedImage: this.croppedImage,
        baseImage: this.baseImage,
        cropperPosition: this.imageCropper.cropper,
        imageFile: fileList,
        imageType: this.imageType,
        isImageEdited: true,
      },
    });
    this.isImageLoaded = true;
  }

  onCancel() {
    if (this.data.croppedImage) {
      this.imageCropper.cropper = this.data.cropperPosition;
    }
    this.croppedImage = this.data.croppedImage;
    this.isImageLoaded = false;
    this.dialogRef.close({
      data: {
        dimensions: this.dimensions,
        croppedImage: this.croppedImage,
        baseImage: this.baseImage,
        cropperPosition: undefined,
        isImageEdited: false,
      },
    });
  }
}
