import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';
import { MatLegacyListModule } from '@angular/material/legacy-list';
import { MatLegacyTooltipModule } from '@angular/material/legacy-tooltip';
import { CustomDirectivesModule } from '@core/directives/custom-directives.module';
import { TranslateModule } from '@ngx-translate/core';
import { ProductImagePreviewModule } from '@shared/components/file-preview/product-image-preview/product-image-preview.module';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { ProductImageFileUploadComponent } from './product-image-file-upload.component';

@NgModule({
  declarations: [ProductImageFileUploadComponent],
  imports: [
    CommonModule,
    ProductImagePreviewModule,
    FlexLayoutModule,
    MatIconModule,
    MatLegacyListModule,
    MatLegacyTooltipModule,
    ProgressSpinnerModule,
    MatLegacyButtonModule,
    CustomDirectivesModule,
    TranslateModule.forChild(),
  ],
  exports: [ProductImageFileUploadComponent],
})
export class ProductImageFileUploadModule {}
