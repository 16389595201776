import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { PageBuildingBlockTypeEnum } from '@core/models/enums/page-building-block-type-enum';
import { ActTypeEnum } from '@core/models/enums/act-type-enum';
import { TargetPreviewPopupConfiguration } from './target-preview-popup.configuration';
import { MerchantAPIService } from '@core/services/merchant-api.service';
import { switchMap, takeUntil } from 'rxjs/operators';
import { BaseComponent } from '@shared/components/base-component/base-component';
import { PageBuildingBlock } from '@core/models/page-building-block';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-target-preview-popup',
  templateUrl: './target-preview-popup.component.html',
  styleUrls: ['./target-preview-popup.component.scss'],
})
export class TargetPreviewPopupComponent extends BaseComponent implements OnInit {
  @Input() configuration: TargetPreviewPopupConfiguration;
  PageBuildingBlockTypeEnum = PageBuildingBlockTypeEnum;
  ActTypeEnum = ActTypeEnum;
  businessName: PageBuildingBlock = null;

  constructor(private merchantService: MerchantAPIService, private translateService: TranslateService) {
    super();
  }

  ngOnInit(): void {
    if (this.configuration.showBusinessName) {
      this.merchantService
        .getMerchantInfo()
        .pipe(takeUntil(this.destroy$))
        .subscribe((merchantInfo) => {
          if (merchantInfo) {
            const businessName = merchantInfo?.paymentCustomerInfo?.businessName;
            this.businessName = PageBuildingBlock.createBusinessNameBuildingBlock(businessName);
          }
        });
    }
  }
}
