import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';
import { MatLegacyDialogModule } from '@angular/material/legacy-dialog';
import { TranslateModule } from '@ngx-translate/core';
import { ConfirmButtonModule } from '@shared/components/confirm-button/confirm-button.module';
import { SimpleConfirmPopupComponent } from './simple-confirm-popup.component';

@NgModule({
  declarations: [SimpleConfirmPopupComponent],
  imports: [
    CommonModule,
    FlexLayoutModule,
    MatLegacyDialogModule,
    MatLegacyButtonModule,
    MatIconModule,
    TranslateModule,
    ConfirmButtonModule,
  ],
  exports: [SimpleConfirmPopupComponent],
})
export class SimpleConfirmPopupModule {}
