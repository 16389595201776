import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActViewModel } from '@core/models/view-models/act-view-model';
import { ActAPIService } from '@core/services/act-api.service';
import { TranslateService } from '@ngx-translate/core';
import { BaseComponent } from '@shared/components/base-component/base-component';
import { DashboardBlockComponent } from '@shared/components/dashboard-table/models/dashboard-block-component';
import { PopupService } from '@shared/components/popup/popup.service';
import { saveAs } from 'file-saver';
import { Observable, of, Subject } from 'rxjs';
import { catchError, filter, switchMap, take, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-act-submission-block',
  templateUrl: './act-submission-block.component.html',
  styleUrls: ['./act-submission-block.component.scss'],
})
export class ActSubmissionBlockComponent extends BaseComponent implements OnInit, DashboardBlockComponent {
  rowData: ActViewModel;

  constructor(
    private actService: ActAPIService,
    private popupService: PopupService,
    private translateService: TranslateService,
  ) {
    super();
  }

  ngOnInit(): void {}

  onDownload() {
    exportActs(this.translateService, this.actService, this.popupService, this.rowData.id, this.rowData.title)
      .pipe(takeUntil(this.destroy$))
      .subscribe();
  }
}

export function exportActs(
  translateService: TranslateService,
  actService: ActAPIService,
  popupService: PopupService,
  actId: string,
  actName: string,
): Observable<any> {
  return actService.exportAct(actId).pipe(
    switchMap((data) => {
      if (data) {
        downloadFile(data, actName);
        return of();
      }
    }),
    catchError((err) => showStatusPopup(translateService, popupService, false, 'FAILED_TO_EXPORT')),
  );
}

function showStatusPopup(
  translateService: TranslateService,
  popupService: PopupService,
  isSuccess: boolean,
  message: string,
): Observable<any> {
  return translateService.get(message).pipe(
    switchMap((translated: string) =>
      popupService.successStatusPopup({
        componentConfiguration: { isSuccess, message: translated },
        height: 'auto',
        width: 'auto',
        maxWidth: '430px',
        backdropClass: 'blurred-backdrop',
      }),
    ),
  );
}

function downloadFile(data: any, actName: string) {
  const blob = new Blob([data], { type: 'application/octet-stream' });
  saveAs(blob, getActSubmissionsFileName(actName));
}

function getActSubmissionsFileName(actName: string) {
  const format = '.csv';
  return `${actName}_Responses_${format}`;
}
