import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatLegacyTooltipModule } from '@angular/material/legacy-tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from '@shared/shared.module';
import { StoreImageUploadComponent } from './store-image-upload.component';

@NgModule({
  declarations: [StoreImageUploadComponent],
  imports: [CommonModule, SharedModule, TranslateModule, MatLegacyTooltipModule],
  exports: [StoreImageUploadComponent],
})
export class StoreImageUploadModule {}
