import Route from '@core/constants/route';
import GenericEngagement from '@core/models/engagement/generic-engagement';
import { EngagementTypeEnum } from '@core/models/enums/engagement-type-enum';
import { PriceInfoModel } from '@core/models/price-info.model';
import { AppSettingService } from '@core/services/app-setting.service';
import { PaymentApiService } from '@core/services/payment-api.service';
import { Guid } from '@core/utils/Guid';
import * as moment from 'moment';
import { BehaviorSubject, Observable, of } from 'rxjs';
import DurationData from '../duration-data';

export class BeaconEngagement extends GenericEngagement {
  constructor(paymentApiService: PaymentApiService, appSettingService: AppSettingService) {
    super(paymentApiService, appSettingService, EngagementTypeEnum.Beacon, null, [
      Route.TRIGGERS,
      Route.BEACON,
      Route.TARGETS,
    ]);
    const tempDuration = new DurationData();
    tempDuration.endDate = moment().add(1, 'year').toDate();
    this.duration$ = new BehaviorSubject(tempDuration);
  }

  getDuration$() {
    return this.duration$;
  }

  getTrigger() {
    return {
      imageUrl: 'assets/images/beacon-bg.jpg',
      name: EngagementTypeEnum[EngagementTypeEnum.Beacon],
      type: EngagementTypeEnum.Beacon,
      svgIcon: 'rss_feed',
    };
  }

  getPayload$() {
    return of({
      id: Guid.newGuid(),
      actInformation: this.actInformation,
      activeTrigger: this.activeTrigger,
      activePricingTargetType: this.activePricingTargetType,
      deferredPayment: false,
    });
  }

  getPrice(): Observable<PriceInfoModel> {
    return super.getPrice();
  }
}
