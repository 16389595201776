import { HttpErrorResponse } from '@angular/common/http';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatExpansionPanel } from '@angular/material/expansion';
import { StripePaymentFlowEnum } from '@core/models/enums/stripe-payment-flow-enum';
import { CreditCardViewModel } from '@core/models/view-models/credit-card-view-model';
import { AppSettingService } from '@core/services/app-setting.service';
import { PaymentApiService } from '@core/services/payment-api.service';
import { environment } from '@env/environment';
import { TranslateService } from '@ngx-translate/core';
import { Stripe, StripeCardElement, StripeElementLocale } from '@stripe/stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { switchMap, switchMapTo, take, takeUntil } from 'rxjs/operators';
import { BaseComponent } from '../base-component/base-component';
import { PopupService } from '../popup/popup.service';
import { StripePaymentDetails } from './stripe-payment-details';

const STYLE = {
  base: {
    'color': '#32325d',
    'fontFamily': '"Helvetica Neue", Helvetica, sans-serif',
    'fontSmoothing': 'antialiased',
    'fontSize': '16px',
    '::placeholder': {
      color: '#aab7c4',
    },
  },
  invalid: {
    color: '#fa755a',
    iconColor: '#fa755a',
  },
};

@Component({
  selector: 'app-stripe-payment',
  templateUrl: './stripe-payment.component.html',
  styleUrls: ['./stripe-payment.component.scss'],
})
export class StripePaymentComponent extends BaseComponent implements OnInit {
  private stripe: Stripe;
  private stripeCardElement: StripeCardElement;
  StripePaymentFlowEnum = StripePaymentFlowEnum;
  saveCard: boolean;
  disablePayment: boolean;
  disableCheckbox: boolean;
  cardError: string;
  savedCreditCards: CreditCardViewModel[];
  selectedCreditCard: CreditCardViewModel;

  @Input() price: number;
  @Input() paymentCurrency: string;
  @Input() stripePaymentFlow: StripePaymentFlowEnum;
  @Input() isSubmitButtonDisabled: boolean;
  @Input() shouldNotSkipPayment: boolean;
  @Output() formSubmitEvent: EventEmitter<any>;
  @Output() handlePaymentEvent: EventEmitter<StripePaymentDetails>;
  @ViewChild('cardElement') cardElement: ElementRef;
  @ViewChild('newCardExpansionPanel') newCardExpansionPanel: MatExpansionPanel;

  constructor(
    private paymentService: PaymentApiService,
    private popupService: PopupService,
    private translateService: TranslateService,
    readonly appSettingService: AppSettingService,
  ) {
    super();
    this.price = 0;
    this.paymentCurrency = this.appSettingService.paymentCurrency;
    this.stripePaymentFlow = StripePaymentFlowEnum.Unprocessed;
    this.savedCreditCards = [];
    this.selectedCreditCard = null;
    this.handlePaymentEvent = new EventEmitter();
    this.formSubmitEvent = new EventEmitter();
    this.saveCard = false;
    this.disablePayment = this.isSubmitButtonDisabled;
    this.disableCheckbox = false;
  }

  async ngOnInit() {
    if (this.shouldNotSkipPayment) {
      this.fetchSavedCreditCards(true);
      await this.loadStripe(this.translateService.currentLang);
      this.translateService.onLangChange.pipe(takeUntil(this.destroy$)).subscribe(async (lanInfo) => {
        const locale = lanInfo.lang;
        await this.loadStripe(locale);
      });
    }
  }

  async loadStripe(locale: string) {
    this.stripe = await loadStripe(environment.stripePublicKey, {
      locale: locale as StripeElementLocale,
    });
    const elements = this.stripe.elements();
    this.stripeCardElement = elements.create('card', { style: STYLE, hidePostalCode: true });
    this.stripeCardElement.mount(this.cardElement.nativeElement);
    this.stripeCardElement.on('change', (event) => {
      this.disablePayment = event.error ? true : false;
      this.cardError = event.error?.message;
    });
    this.stripeCardElement.on('focus', () => {
      this.unselectCreditCard();
    });
  }

  async handlePayment(event: any) {
    this.formSubmitEvent.emit();
    if (this.stripePaymentFlow === StripePaymentFlowEnum.InProgress) {
      return;
    }

    if (!this.shouldNotSkipPayment) {
      this.handlePaymentEvent.emit({
        saveCardDetails: false,
        paymentMethodId: this.selectedCreditCard?.paymentMethodId,
        isNonAci: this.shouldNotSkipPayment,
      });
      return;
    }

    if (this.selectedCreditCard) {
      this.handlePaymentEvent.emit({
        saveCardDetails: true,
        paymentMethodId: this.selectedCreditCard.paymentMethodId,
        isNonAci: this.shouldNotSkipPayment,
      });
      return;
    }

    event.preventDefault();

    const paymentMethod = await this.stripe.createPaymentMethod({
      type: 'card',
      card: this.stripeCardElement,
    });

    if (paymentMethod.error) {
      this.cardError = paymentMethod.error.message;
      this.newCardExpansionPanel.expanded = true;
      return;
    }

    this.cardError = null;
    this.handlePaymentEvent.emit({
      saveCardDetails: this.saveCard,
      paymentMethodId: paymentMethod.paymentMethod.id,
      isNonAci: this.shouldNotSkipPayment,
    });
  }

  onSelectedCreditCard(selectionIndex: number): void {
    for (let index = 0; index < this.savedCreditCards.length; index++) {
      const creditCard = this.savedCreditCards[index];
      if (index === selectionIndex) {
        creditCard.isSelected = !creditCard.isSelected;
        this.selectedCreditCard = creditCard.isSelected ? creditCard : null;
        this.clearInputOfCardDetails(creditCard.isSelected);
        this.disablePayment = !creditCard.isSelected;
        continue;
      }
      creditCard.isSelected = false;
    }
  }

  checkTenant() {
    return environment.partner === 'aci' || environment.partner === 'claro' || environment['chinaProvider'] === true;
  }

  onRemovedCreditCard(index: number): void {
    this.translateService
      .get('ARE_YOU_SURE_THAT_YOU_WANT_TO_DELETE_YOUR_SAVED_CREDIT_CARD')
      .pipe(
        switchMap((translated) =>
          this.popupService.simpleConfirmationPopup({
            maxWidth: '300px',
            width: 'auto',
            height: 'auto',
            componentConfiguration: {
              message: `${translated}?`,
              showDeleteIcon: true,
            },
          }),
        ),
        takeUntil(this.destroy$),
      )
      .subscribe((response) => {
        if (response) {
          this.paymentService
            .detachPaymentMethod(this.savedCreditCards[index].paymentMethodId)
            .pipe(takeUntil(this.destroy$))
            .subscribe(
              () => {
                this.fetchSavedCreditCards();
                this.showSuccessStatusPopup(true, 'YOUR_CREDIT_CARD_WAS_SUCCESSFULLY_REMOVED');
                this.selectedCreditCard = null;
              },
              (error: HttpErrorResponse) => {
                this.showSuccessStatusPopup(false, 'YOUR_CREDIT_CARD_COULD_NOT_BE_REMOVED', error.error);
              },
            );
        } else {
          return;
        }
      });
  }

  private clearInputOfCardDetails(disabled: boolean): void {
    this.stripeCardElement.clear();
    this.saveCard = false;
    this.disableCheckbox = disabled;
  }

  private unselectCreditCard(): void {
    const creditCard = this.savedCreditCards.find((savedCard) => savedCard.isSelected);
    if (!creditCard) {
      return;
    }
    creditCard.isSelected = false;
    this.selectedCreditCard = null;
    this.disableCheckbox = false;
  }

  private showSuccessStatusPopup(isSuccess: boolean, message: string, failureReason?: string): void {
    this.translateService
      .get(message)
      .pipe(
        switchMap((translated: string) => {
          return this.popupService.successStatusPopup({
            componentConfiguration: { isSuccess, message: translated, failureReason },
            height: 'auto',
            width: 'auto',
            maxWidth: '430px',
          });
        }),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  private fetchSavedCreditCards(selectFirst: boolean = false): void {
    this.paymentService
      .getSavedCreditCards()
      .pipe(takeUntil(this.destroy$))
      .subscribe((response) => {
        this.savedCreditCards = response;
        if (selectFirst && this.savedCreditCards.length > 0) {
          this.onSelectedCreditCard(0);
        }
      });
  }
}
