import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';
import { MatLegacyCardModule } from '@angular/material/legacy-card';
import { MatLegacyDialogModule } from '@angular/material/legacy-dialog';
import { MatLegacyTooltipModule } from '@angular/material/legacy-tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { PaginationModule } from '@shared/components/pagination/pagination.module';
import { CustomPipeModule } from '@shared/pipes/custom-pipe.module';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { TargetPagesCardModule } from '../../../../features/dashboard/custom-card/custom-card.module';
import { AssociatedEngagementsPopupComponent } from './associated-engagements-popup.component';

@NgModule({
  declarations: [AssociatedEngagementsPopupComponent],
  imports: [
    CommonModule,
    MatLegacyDialogModule,
    MatLegacyButtonModule,
    MatIconModule,
    FlexLayoutModule,
    MatDividerModule,
    MatLegacyCardModule,
    MatLegacyTooltipModule,
    CustomPipeModule,
    TranslateModule.forChild(),
    PaginationModule,
    ProgressSpinnerModule,
    TargetPagesCardModule,
  ],
  exports: [AssociatedEngagementsPopupComponent],
})
export class AssociatedEngagementsPopupModule {}
