import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CardInfo } from '@core/models/card-info';

@Component({
  selector: 'app-navigation-cards',
  templateUrl: './navigation-cards.component.html',
  styleUrls: ['./navigation-cards.component.scss'],
})
export class NavigationCardsComponent implements OnInit {
  @Input() cardTranslationType: string;
  @Input() cards: CardInfo[] = [];
  @Input() navigationRoute: string;
  @Input() header: string;

  constructor(private router: Router) {}

  ngOnInit(): void {}

  async navigateToUrl(card: CardInfo) {
    if (card.hasPassedLimit) {
      return;
    }

    await this.router.navigate([`/${this.navigationRoute}/${card.url}`]);
  }
}
