import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatLegacyDialog, MatLegacyDialogConfig } from '@angular/material/legacy-dialog';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { FileFormats } from '@core/constants/FileFormats';
import { ImageDimensions } from '@core/models/image-dimensions';
import { ThumbnailImage } from '@core/models/thumbnail-image';
import { TranslateService } from '@ngx-translate/core';
import BaseFileUpload from '@shared/components/file-upload/shared/base-file-upload';
import { ImageCropperModalComponent } from '../target-pages/shared/image-cropper-modal/image-cropper-modal.component';

@Component({
  selector: 'app-store-image-upload',
  templateUrl: './store-image-upload.component.html',
  styleUrls: ['./store-image-upload.component.scss'],
})
export class StoreImageUploadComponent extends BaseFileUpload implements OnInit {
  @Input() storeImage: string = null;
  @Input() isEditMode = false;
  @Input() parentForm: FormGroup;
  @Input() controlName = '';
  @Input() isSmallSize: boolean;
  @Input() acceptType: string;
  defaultLogo = 'assets/images/default-logo-image.svg';
  dimensions: ImageDimensions = {} as ImageDimensions;
  storeImgSrc: string = null;
  safeUrlSrc: SafeUrl;
  storeFileName: string = null;
  storeFileSize: string = null;
  storeFileType: string = null;
  imgWidth: string = null;
  imgHeight: string = null;
  storeImageTooltip = '';
  isDropped = true;
  loader = { storeLoader: false, storeHeroLoader: false };

  @ViewChild('storeImageUpload') fileInput: ElementRef<HTMLInputElement>;
  @ViewChild('upload_zone') uploadZone: ElementRef<HTMLElement>;
  @Output() handlestoreImageChange: EventEmitter<ThumbnailImage> = new EventEmitter();

  constructor(
    protected translationService: TranslateService,
    private readonly dialog: MatLegacyDialog,
    readonly sanitizer: DomSanitizer,
  ) {
    super(
      {
        acceptedFileFormats: [FileFormats.JPEG, FileFormats.JPG, FileFormats.PNG, FileFormats.GIF, FileFormats.SVG],
        maxFileSize: 157286400,
        translationSectionName: '',
        minImageDimensions: { height: 200, width: 200 },
      },
      translationService,
    );
  }

  hovered(isHovered: boolean) {
    if (isHovered) {
      this.uploadZone.nativeElement.classList.add('isHovered');
    } else {
      this.uploadZone.nativeElement.classList.remove('isHovered');
    }
  }

  async handleUpload(file: FileList, control: string) {
    if (control === 'storeLogo') {
      this.loader = { storeLoader: true, storeHeroLoader: false };
    }
    if (control === 'storeHeroImage') {
      this.loader = { storeLoader: false, storeHeroLoader: true };
    }
    if (!file) {
      return;
    }

    const fileType = file.item(0).type;
    if (this.isSmallSize === false && fileType !== FileFormats.TIFF && fileType !== FileFormats.TIF) {
      const Img = new Image();
      Img.src = URL.createObjectURL(file.item(0));
      await Img.decode();
      this.dimensions = { width: Img.width, height: Img.height };
    }

    const error = this.getFileValidationErrorsWithParams(file, this.dimensions);
    this.errors = this.getTranslatedErrorMessagesFromKeysAndParams(error);

    if (!error.length) {
      const uploadedFile = file.item(0);
      this.storeImgSrc = (await this.toBase64(uploadedFile)) as string;
      this.safeUrlSrc = this.sanitizer.bypassSecurityTrustResourceUrl(this.storeImgSrc) as string;
      this.storeImage = this.storeImgSrc.split('base64,')[1];
      this.storeFileName = uploadedFile.name;
      this.storeFileSize = Number(uploadedFile.size / (1024 * 1024)).toFixed(2);
      this.storeFileType = uploadedFile.type.split('image/')[1].toUpperCase();
      this.storeImageTooltip = uploadedFile.name.length > 18 ? this.storeFileName : '';
      this.openImageCropperModal();
    } else {
      this.onInitialValidationFailed();
    }
  }

  toBase64 = (uploadedFile: File) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(uploadedFile);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  openImageCropperModal() {
    const dialogConfig = new MatLegacyDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.width = '100%';
    dialogConfig.height = '100%';
    dialogConfig.maxWidth = '100%';
    dialogConfig.panelClass = 'image-cropper';
    dialogConfig.data = {
      storeImage: this.storeImage,
      baseImage: this.storeImgSrc,
      maintainAspectRatio: true,
    };

    this.dialog
      .open(ImageCropperModalComponent, dialogConfig)
      .afterClosed()
      .subscribe((image) => {
        if (image.data.croppedImage !== undefined) {
          this.storeImgSrc = image.data.croppedImage;
          this.safeUrlSrc = this.sanitizer.bypassSecurityTrustResourceUrl(this.storeImgSrc) as string;
          this.storeImage = this.storeImgSrc.split('base64,')[1];
          this.imgHeight = image.data.dimensions.size.height;
          this.imgWidth = image.data.dimensions.size.width;
          this.handlestoreImageChange.emit({
            base64: this.storeImage,
            name: this.storeFileName,
          });
          this.isDropped = false;
          this.loader = { storeLoader: false, storeHeroLoader: false };
          return;
        } else {
          this.handlestoreImageChange.emit({ base64: null, name: null });
        }
        this.storeImage = null;
        this.storeFileName = null;
        this.safeUrlSrc = this.defaultLogo;
        this.storeImageTooltip = null;
        this.fileInput.nativeElement.value = '';
        this.isDropped = true;
        this.loader = { storeLoader: false, storeHeroLoader: false };

        if (!/safari/i.test(navigator.userAgent)) {
          this.fileInput.nativeElement.type = '';
          this.fileInput.nativeElement.type = 'file';
        }
      });
  }
  uploadFile(formData: FormData): void {}
  onInitialValidationFailed(): void {}
  onLoadingCompleted(): void {}

  ngOnInit(): void {
    const storeOnUpdate = !this.storeImage ? this.defaultLogo : this.storeImage;
    this.safeUrlSrc = this.isEditMode ? storeOnUpdate : null;
    this.acceptedFileFormats = this.acceptType.split(',').map((type) => type.replace('.', 'image/'));
  }
}
