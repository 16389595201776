import { Component, OnInit } from '@angular/core';
import { MatLegacyDialogRef } from '@angular/material/legacy-dialog';
import { RceProduct } from '@core/models/dtos/rce-product';
import { RceProductStatusEnum } from '@core/models/enums/rce-product-status-enum';
import { ProductViewModel } from '@core/models/view-models/product-view-model';
import { ProductService } from '@core/services/product.service';
import { TranslateService } from '@ngx-translate/core';
import { BaseComponent } from '@shared/components/base-component/base-component';
import { GenericCreationContainerService } from '@shared/components/generic-creation-container/generic-creation-container.service';
import { combineLatest, Observable, of } from 'rxjs';
import { finalize, map, switchMap, takeUntil } from 'rxjs/operators';
import { PopupService } from '../popup.service';
import { ProductMediaUpload } from './product-media-upload/product-media-upload.component';

@Component({
  selector: 'app-product-creation-popup',
  templateUrl: './product-creation-popup.component.html',
  styleUrls: ['./product-creation-popup.component.scss'],
})
export class ProductCreationPopupComponent extends BaseComponent implements OnInit {
  currentStep: number;
  product: RceProduct;

  constructor(
    public genericCreationContainerService: GenericCreationContainerService,
    private dialogRef: MatLegacyDialogRef<ProductCreationPopupComponent>,
    private translateService: TranslateService,
    private popupService: PopupService,
    private productService: ProductService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.genericCreationContainerService.initSteps(
      [
        {
          step: 0,
          bottomBar: {
            hideLeftButton: true,
            rightButtonLabel: 'IMAGES',
          },
        },
        {
          step: 1,
          bottomBar: {
            leftButtonLabel: 'BACK_TO_DETAILS',
            rightButtonLabel: 'SAVE_PRODUCT',
          },
        },
      ],
      () => this.dialogRef.close(),
    );

    this.genericCreationContainerService
      .getCurrentStep()
      .pipe(takeUntil(this.destroy$))
      .subscribe((currentStep) => {
        this.currentStep = currentStep;
      });

    this.genericCreationContainerService
      .getSumUpStep()
      .pipe(
        switchMap((stepInfo) => {
          this.genericCreationContainerService.isProcessing$.next(true);
          const { product, files } = stepInfo.data;
          return this.productService
            .addProduct({
              description: product.description,
              name: product.name,
              price: product.price,
              quantity: product.quantity,
              shortDescription: product.shortDescription,
              sku: product.sku,
              weight: product.weight,
              categoryLinks: product.productCategoryLinks || [],
              status: product.enabled ? RceProductStatusEnum.Enabled : RceProductStatusEnum.Disabled,
            })
            .pipe(
              switchMap((createProductResponse: ProductViewModel) => {
                const observables = this.uploadImages(product.sku, files);
                if (observables && observables.length > 0) {
                  return combineLatest(observables).pipe(
                    map(() => ({ createProductResponse, stepInfoProduct: product })),
                  );
                }

                return of({ createProductResponse, stepInfoProduct: product });
              }),
            );
        }),
        switchMap((result) => this.productService.getProductById(result.createProductResponse.id)),
        takeUntil(this.destroy$),
        finalize(() => this.genericCreationContainerService.isProcessing$.next(false)),
      )
      .subscribe(
        (response) => {
          this.product = new RceProduct(response);
          this.dialogRef.close();
          this.showProductSuccessPopup();
          this.genericCreationContainerService.reset();
        },
        (error) => {
          console.error(error);
          this.showProductFailurePopup();
        },
      );

    this.dialogRef.afterClosed().subscribe(() => {
      this.genericCreationContainerService.resetCurrentStep();
    });
  }

  private uploadImages(productSku: string, files: ProductMediaUpload[]) {
    let observables: Observable<any>[] = [];
    if (files && files.length >= 1) {
      observables = observables.concat(
        files.map((file) =>
          this.productService.uploadMedia(productSku, {
            media_type: 'image',
            label: 'product image',
            position: 1,
            disabled: false,
            types: file.types,
            content: {
              base64_encoded_data: file.base64Content,
              type: file.type,
              name: file.name,
            },
          }),
        ),
      );
    }

    return observables;
  }

  private showProductSuccessPopup(): void {
    this.translateService
      .get('PRODUCT_CREATED_SUCCESSFULLY')
      .pipe(
        switchMap((translated) =>
          this.popupService.successStatusPopup({
            componentConfiguration: { isSuccess: true, message: translated },
            height: 'auto',
            width: 'auto',
            maxWidth: '430px',
            backdropClass: 'blurred-backdrop',
          }),
        ),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  private showProductFailurePopup(): void {
    this.translateService
      .get('FAILED_TO_CREATE_PRODUCT')
      .pipe(
        switchMap((translated) =>
          this.popupService.successStatusPopup({
            componentConfiguration: { isSuccess: false, message: translated },
            height: 'auto',
            width: 'auto',
            maxWidth: '430px',
            backdropClass: 'blurred-backdrop',
          }),
        ),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }
}
