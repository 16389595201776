import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';
import { TranslateModule } from '@ngx-translate/core';
import { CreditCardViewComponent } from './credit-card-view.component';

@NgModule({
  declarations: [CreditCardViewComponent],
  imports: [CommonModule, FlexLayoutModule, MatIconModule, MatLegacyButtonModule, TranslateModule.forChild()],
  exports: [CreditCardViewComponent],
})
export class CreditCardViewModule {}
