import { AfterViewInit, Directive, ElementRef, Renderer2 } from '@angular/core';

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[autocompleteOff]',
})
export class AutocompleteOffDirective implements AfterViewInit {
  constructor(private readonly el: ElementRef, private readonly renderer: Renderer2) {}

  ngAfterViewInit() {
    const randomString = Math.random().toString(36).slice(-6);
    this.renderer.setAttribute(this.el.nativeElement, 'name', randomString);
    this.renderer.setAttribute(this.el.nativeElement, 'autocomplete', randomString);
    this.el.nativeElement.setAttribute('autocorrect', 'off');
    this.el.nativeElement.setAttribute('autocapitalize', 'none');
    this.el.nativeElement.setAttribute('spellcheck', 'false');
  }
}
