import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Endpoints } from '@core/constants/Endpoints';
import { AciAutoProvisioningRequest } from '@core/models/auto-provisioning/AciAutoProvisioningRequest';
import { AutoProvisioningRequest } from '@core/models/auto-provisioning/AutoProvisioningRequest';
import {
  CreateAciMerchantAccountRequest,
  CreateAciMerchantAccountResponse,
} from '@core/models/auto-provisioning/CreateAciMerchantAccountRequest';
import { SetupAci3DSecureRequest, SkipAci3DSecureRequest } from '@core/models/auto-provisioning/SetupAci3DSecureRequest';
import { AciOnboardingProgressStatusEnum } from '@core/models/get-aci-onboarding-status-response';
import { Observable } from 'rxjs';
import { BaseAPIServiceService } from './http/base-apiservice.service';

@Injectable({
  providedIn: 'root',
})
export class AutoProvisioningService extends BaseAPIServiceService {
  constructor(protected http: HttpClient) {
    super(http);
  }

  resetOnboardingStep(): Observable<any> {
    return this.http.post<void>(Endpoints.RESET_ONBOARDING_STEP, {});
  }

  resetOnboarding(): Observable<any> {
    return this.http.post<void>(Endpoints.RESET_ONBOARDING, {});
  }

  createAciMerchantAccount(payload: CreateAciMerchantAccountRequest): Observable<CreateAciMerchantAccountResponse> {
    return this.http.post<CreateAciMerchantAccountResponse>(Endpoints.CREATE_ACI_MERCHANT_ACCOUNT, payload);
  }

  async updateMerchantStatus(status: AciOnboardingProgressStatusEnum): Promise<any>{
    return this.http.post(Endpoints.UPDATE_ACI_MERCHANT_STATUS.replace("{status}", status.toString()), {}).toPromise();
  }

  setup(payload: AutoProvisioningRequest): Observable<any> {
    return this.http.post<void>(Endpoints.SETUP_AUTO_PROVISIONING, payload);
  }

  aciSetup(payload: AciAutoProvisioningRequest): Observable<any> {
    return this.http.post<void>(Endpoints.SETUP_ACI_AUTO_PROVISIONING, payload);
  }

  setupAci3DSecure(payload: SetupAci3DSecureRequest): Observable<any> {
    return this.http.post<void>(Endpoints.SETUP_ACI_3D_SECURE, payload);
  }

  skipAci3DSecure(payload: SkipAci3DSecureRequest): Observable<any> {
    return this.http.post<void>(Endpoints.SETUP_ACI_3D_SECURE, payload);
  }
}
