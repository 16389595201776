import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UserLocationActionsEnum } from '@core/models/enums/user-location-actions-enum';
import { LocationViewModel } from '@core/models/view-models/location-view-model';
import { environment } from '@env/environment';
import { TranslateService } from '@ngx-translate/core';
import { BaseComponent } from '@shared/components/base-component/base-component';
import { PopupService } from '@shared/components/popup/popup.service';
import { switchMap, takeUntil } from 'rxjs/operators';
import { LocationsService } from '@core/services/locations.service';

@Component({
  selector: 'app-location-table-item',
  templateUrl: './location-table-item.component.html',
  styleUrls: ['./location-table-item.component.scss'],
})
export class LocationTableItemComponent extends BaseComponent implements OnInit {
  @Input() location: LocationViewModel;
  @Output() updatedLocation: EventEmitter<LocationViewModel> = new EventEmitter();
  @Output() deleteLocation: EventEmitter<LocationViewModel> = new EventEmitter();
  @Output() statusChange: EventEmitter<boolean> = new EventEmitter();

  hideEnabled: boolean;
  hideDisabled: boolean;

  constructor(private translateService: TranslateService, private popupService: PopupService, private locationsService: LocationsService) {
    super();
  }

  ngOnInit() {
    this.hideEnabled = !this.location.availableActions.includes(UserLocationActionsEnum.Enable);
    this.hideDisabled = !this.location.availableActions.includes(UserLocationActionsEnum.Disable);
  }

  onDelete() {
    this.translateService
      .get('ARE_YOU_SURE_THAT_YOU_WANT_TO_DELETE_THIS_LOCATION')
      .pipe(
        switchMap((translated) =>
          this.popupService.simpleConfirmationPopup({
            maxWidth: '300px',
            width: 'auto',
            height: 'auto',
            componentConfiguration: {
              message: `${translated}?`,
              showDeleteIcon: true,
            },
          }),
        ),
        takeUntil(this.destroy$),
      )
      .subscribe((ok) => {
        if (!ok) {
          return;
        }
        this.deleteLocation.emit();
      });
  }

  onChangeStatus(status: boolean) {
    this.statusChange.emit(status);
  }

  showLocationPopup() {
    const locationImage = `https://maps.googleapis.com/maps/api/staticmap?size=${environment.mapImageWidth}x
    ${environment.mapImageHeight}&visible=&style=visibility:on&scale=1&markers=color:blue|
    ${this.location.latitude},${this.location.longitude}&key=${environment.googleMapsKey}`;

    this.popupService.showImagePreviewPopup({
      backdropClass: 'light-backdrop',
      maxWidth: '400px',
      height: 'auto',
      panelClass: 'zero-padding-mat-dialog-container',
      componentConfiguration: { url: locationImage },
    });
  }

  onChangeLocation(currentLocation: LocationViewModel) {
    this.locationsService.navigateToEditLocation(currentLocation.id);
  }
}
