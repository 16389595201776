import { GridActionTypeEnum } from '@shared/components/dashboard-table/models/grid-options';
import * as moment from 'moment';
import { ActDTO } from '../dtos/act-dto';
import { ActTypeEnum } from '../enums/act-type-enum';
import { Filters } from '../filters';

export class ActViewModel extends ActDTO {
  isFavorite = false;
  icon: string;
  toggleButtonActions: GridActionTypeEnum[] = [];
  associatedEngagementsNames: string[] = [];

  constructor(actDTO: ActDTO) {
    super(actDTO);
    this.isFavorite = false;
    this.icon = this.getIconName();
    this.toggleButtonActions = this.getToggleButtonActions();
    this.associatedEngagementsNames = this.associatedEngagements.map((e) => e.clientReferenceId);
  }

  static filterPredicate = (data: ActViewModel, filterString: string): boolean => {
    const { dateRange, enumTypesSelection = [], searchTerm = '' }: Filters = JSON.parse(filterString);
    if (dateRange && moment(data.dateCreated).isBefore(dateRange)) {
      return false;
    }

    if (!enumTypesSelection.every((option) => option === data.actType)) {
      return false;
    }

    return data.title.toLowerCase().includes(searchTerm.toLowerCase());
  };

  static sortingDataAccessor = (item, property) => {
    if (item[property]) {
      return item[property].toString();
    }
  };

  get ActDTO(): ActDTO {
    const act = new ActDTO();
    act.actType = this.actType;
    act.customUrl = this.customUrl;
    act.dataFields = this.dataFields;
    act.dateCreated = this.dateCreated;
    act.dateUpdated = this.dateUpdated;
    act.description = this.description;
    act.id = this.id;
    act.images = this.images;
    act.isBuy = this.isBuy;
    act.isInformationPage = this.isInformationPage;
    act.linkedEngagements = this.linkedEngagements;
    act.product = this.product;
    act.submissionCount = this.submissionCount;
    act.subtitle = this.subtitle;
    act.title = this.title;
    act.pageName = this.pageName;
    act.associatedEngagements = this.associatedEngagements;
    act.pageBuildingBlocks = this.pageBuildingBlocks;
    act.thumbnailUrl = this.thumbnailUrl;
    act.thumbnailImage = this.thumbnailImage;
    return act;
  }

  getIconName(): string {
    switch (this.actType) {
      case ActTypeEnum.Act:
        return 'target-act';
      case ActTypeEnum.Buy:
        return 'target-buy';
      case ActTypeEnum.InformationPage:
        return 'target-info';
      case ActTypeEnum.Url:
        return 'target-link';
      default:
        return 'target-buy';
    }
  }

  getToggleButtonActions(): GridActionTypeEnum[] {
    let result: GridActionTypeEnum[] = [];
    switch (this.actType) {
      case ActTypeEnum.Act:
      case ActTypeEnum.InformationPage:
        result.push(GridActionTypeEnum.Update);
        result.push(GridActionTypeEnum.Read);
        break;
      case ActTypeEnum.Buy:
      case ActTypeEnum.Url:
        result.push(GridActionTypeEnum.Update);
        break;
      default:
        break;
    }

    return result;
  }
}
