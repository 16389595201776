// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*@media (max-width: 767px) {*/
/*  !* On small screens, the nav menu spans the full width of the screen. Leave a space for it. *!*/
/*  .body-content {*/
/*    padding-top: 50px;*/
/*  }*/
/*}*/
`, "",{"version":3,"sources":["webpack://./src/app/app.component.css"],"names":[],"mappings":"AAAA,8BAA8B;AAC9B,mGAAmG;AACnG,oBAAoB;AACpB,yBAAyB;AACzB,MAAM;AACN,IAAI","sourcesContent":["/*@media (max-width: 767px) {*/\n/*  !* On small screens, the nav menu spans the full width of the screen. Leave a space for it. *!*/\n/*  .body-content {*/\n/*    padding-top: 50px;*/\n/*  }*/\n/*}*/\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
