import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MediaGalleryEntry } from '@core/models/dtos/rce-product';
import { MediaType } from '@core/models/product/UploadProductMediaRequest';
import { BaseComponent } from '@shared/components/base-component/base-component';
import {
  FileInfo,
  ProductFileUploadState,
} from '@shared/components/file-upload/product-image-file-upload/product-image-file-upload.component';
import { GenericCreationContainerService } from '@shared/components/generic-creation-container/generic-creation-container.service';
import { of } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

export interface ProductMediaUpload {
  base64Content?: any;
  type?: string;
  name?: string;
  id: string;
  state: ProductFileUploadState;
  types: MediaType[];
}
@Component({
  selector: 'app-product-media-upload',
  templateUrl: './product-media-upload.component.html',
  styleUrls: ['./product-media-upload.component.scss'],
})
export class ProductMediaUploadComponent extends BaseComponent implements OnInit {
  files: ProductMediaUpload[] = [];
  passedFiles: FileInfo[] = [];

  constructor(private genericCreationContainerService: GenericCreationContainerService) {
    super();
  }

  ngOnInit(): void {
    const data = this.genericCreationContainerService.getDataPassedFromPrevStep();
    const mediaGalleryEntries: MediaGalleryEntry[] = data?.mediaEntries;
    if (mediaGalleryEntries) {
      this.passedFiles = mediaGalleryEntries.map((mediaGallery) => ({
        url: mediaGallery.url,
        id: mediaGallery.id.toString(),
        state: 'old',
        mediaTypes: mediaGallery.types,
      }));
    }

    this.genericCreationContainerService
      .getNextStepRequest((stepInfo) => {
        const product = stepInfo.data;

        return of({ canGoNext: true, data: { product, files: this.files } });
      })
      .pipe(takeUntil(this.destroy$))
      .subscribe();
  }

  toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve((reader.result as string).split(',')[1]);
      reader.onerror = (error) => reject(error);
    });

  async onUploadResultEvent(fileInfos: FileInfo[]) {
    this.files = [];
    for (const fileInfo of fileInfos) {
      switch (fileInfo.state) {
        case 'new':
          const result = await this.toBase64(fileInfo.file);
          this.files.push({
            base64Content: result,
            type: fileInfo.file.type,
            name: fileInfo.file.name,
            id: fileInfo.id,
            types: fileInfo.mediaTypes,
            state: fileInfo.state,
          });
          break;
        case 'removed':
          this.files.push({
            id: fileInfo.id,
            state: fileInfo.state,
            types: fileInfo.mediaTypes,
          });
          break;

        default:
          break;
      }
    }
  }

  onSubmit(form: NgForm) {}
}
