import { EngagementTypeEnum } from '@core/models/enums/engagement-type-enum';
import { TargetPagesEnum } from '@core/models/enums/target-page-enum';
import { Action, createReducer, on } from '@ngrx/store';
import * as WizardActions from '../actions/wizard.actions';

export interface WizardState {
  step: number | null;
  trigger: EngagementTypeEnum | null;
  target: TargetPagesEnum | null;
}

export const initialState: WizardState = {
  step: null,
  trigger: null,
  target: null,
};

const wizardReducer = createReducer(
  initialState,
  on(WizardActions.selectTrigger, (state, { trigger }) => ({
    ...state,
    step: 1,
    trigger,
  })),
  on(WizardActions.selectTargetPage, (state, { target }) => ({
    ...state,
    step: 1,
    target,
  })),
  on(WizardActions.updateStep, (state, { step }) => ({
    ...state,
    step,
  })),
  on(WizardActions.navigateBack, (state) => ({
    ...state,
    step: state.step > 0 ? state.step - 1 : 0,
  })),
);

export function reducer(state = initialState, action: Action): WizardState {
  return wizardReducer(state, action);
}
