import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
@Pipe({ name: 'orderBy' })
export class OrderByPipe implements PipeTransform {
  constructor(private translateService: TranslateService) {}
  transform(records: Array<any>, args?: any): any {
    return records.sort((a, b) => {
      const translatedA = this.translateService.instant(a[args.property]);
      const translatedB = this.translateService.instant(b[args.property]);
      if (translatedA < translatedB) {
        return -1 * args.direction;
      } else if (translatedA > translatedB) {
        return 1 * args.direction;
      } else {
        return 0;
      }
    });
  }
}
