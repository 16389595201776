import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatLegacyDialogRef } from '@angular/material/legacy-dialog';
import { RegularExpressions } from '@core/constants/RegularExpressions';
import { TINY_MCE_HEADING_INIT } from '@core/constants/TinyMceEditorInits';
import { environment } from '@env/environment';
import { EditorComponent } from '@tinymce/tinymce-angular';
import { overrideDefaultFontFamily, overrideFontFormats } from '../../../../../utils/tiny-mce-utils';
import { BasePageBuilderConfiguration } from '../base-page-builder-configuration';
import { ColorPickingBlockAbstractComponent } from '../color-picking-block-abstract-component';
import { EmphasisOptions, PageBuildingBlockStyle, TextAlignOptions } from '@core/models/page-building-block-style';

const HeadingLimits = {
  MAX_NUM_OF_NEW_LINES: 1,
  MAX_NUM_OF_CHARACTERS: 255,
};

@Component({
  selector: 'app-heading-customisation-popup',
  templateUrl: './heading-customisation-popup.component.html',
  styleUrls: ['./heading-customisation-popup.component.scss'],
})
export class HeadingCustomisationPopupComponent
  extends ColorPickingBlockAbstractComponent<HeadingCustomisationPopupComponent>
  implements OnInit, AfterViewInit {
  get errorMessage(): string | null {
    if (this.form.invalid) {
      if (this.form.get('numberOfNewLines').invalid) {
        this.max = HeadingLimits.MAX_NUM_OF_NEW_LINES;
        return 'MAX_NEW_LINES_ERROR_MESSAGE';
      } else if (this.form.get('numberOfCharacters').invalid) {
        this.max = HeadingLimits.MAX_NUM_OF_CHARACTERS;
        return 'MAX_CHARACTERS_ERROR_MESSAGE';
      }
    }

    return null;
  }

  private static extractStyles(htmlString: string): PageBuildingBlockStyle {
    const styleObj = new PageBuildingBlockStyle();

    const textAlignMatch = htmlString.match(/text-align:\s*(right|left|center);/i);
    console.log('textAlignMatch', htmlString, textAlignMatch);
    if (textAlignMatch) {
      styleObj.textAlign = TextAlignOptions[textAlignMatch[1].toUpperCase()] || TextAlignOptions.LEFT;
    }

    const rgbColorMatch = htmlString.match(/color:\s*rgb\((\d+),\s*(\d+),\s*(\d+)\);/i);
    const hexColorMatch = htmlString.match(/color:\s*(#[0-9a-fA-F]{6}|#[0-9a-fA-F]{3});/i);

    if (rgbColorMatch) {
      styleObj.color = `#${parseInt(rgbColorMatch[1]).toString(16).padStart(2, '0')}${parseInt(rgbColorMatch[2])
        .toString(16)
        .padStart(2, '0')}${parseInt(rgbColorMatch[3]).toString(16).padStart(2, '0')}`;
    } else if (hexColorMatch) {
      styleObj.color = hexColorMatch[1];
    }

    if (htmlString.includes('font-family:')) {
      if (htmlString.match(/'arial black'/i)) {
        styleObj.fontWeight = EmphasisOptions.BOLD;
      }
    }

    if (htmlString.includes('<strong>')) {
      styleObj.fontWeight = EmphasisOptions.BOLD;
    }

    return styleObj;
  }

  private static getContentWithNoLineBreaksAtEnd(content) {
    return content.replace(RegularExpressions.HTML_SINGLE_LINE_BREAKS_AT_END, '');
  }

  private static getNumberOfCharacterMatches(textContent: string) {
    return textContent.replace(RegularExpressions.NEW_LINES, '').trim().length;
  }

  private static getNumberOfNewLineMatches(textContent: string) {
    return (textContent.replace(RegularExpressions.NEW_LINES_AT_END, '').match(RegularExpressions.NEW_LINES) || [])
      .length;
  }
  tinyApiKey: string;
  form: FormGroup;
  editorInit = TINY_MCE_HEADING_INIT;
  max: number;
  @ViewChild('headingTinyMce') tinymce: EditorComponent;
  @Input() configuration: BasePageBuilderConfiguration;
  textContent: string;

  constructor(public dialogRef: MatLegacyDialogRef<HeadingCustomisationPopupComponent>) {
    super(dialogRef);

    if (environment.tinyMceSettings.tinyMceFontsToOverride) {
      this.editorInit = overrideFontFormats(this.editorInit, environment.tinyMceSettings.tinyMceFontsToOverride);
    }
    if (environment.tinyMceSettings.tinyMceDefaultFontToOverride) {
      this.editorInit = overrideDefaultFontFamily(
        this.editorInit,
        environment.tinyMceSettings.tinyMceDefaultFontToOverride,
      );
    }
    this.tinyApiKey = environment.tinyApiKey;
    this.form = new FormGroup({
      content: new FormControl(null, Validators.required),
      backgroundColor: new FormControl(),
      numberOfNewLines: new FormControl(null, Validators.max(HeadingLimits.MAX_NUM_OF_NEW_LINES)),
      numberOfCharacters: new FormControl(null, Validators.max(HeadingLimits.MAX_NUM_OF_CHARACTERS)),
    });
  }

  ngOnInit(): void {
    this.form.patchValue({
      content: this.configuration.data.content,
      backgroundColor: this.configuration.data.style.backgroundColor,
    });
  }

  ngAfterViewInit(): void {
    this.form.get('content').valueChanges.subscribe((htmlContent) => {
      this.textContent = this.getTextContent();

      if (htmlContent === '&nbsp;') {
        this.form.patchValue({ content: '' });
      }

      const numberOfNewLines = HeadingCustomisationPopupComponent.getNumberOfNewLineMatches(this.textContent);
      const numberOfCharacters = HeadingCustomisationPopupComponent.getNumberOfCharacterMatches(this.textContent);
      this.form.patchValue({ numberOfNewLines, numberOfCharacters });
    });
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  onSubmit() {
    this.textContent = this.getTextContent();
    const { content, backgroundColor } = this.form.value;

    this.configuration.data.content = HeadingCustomisationPopupComponent.getContentWithNoLineBreaksAtEnd(content);
    this.configuration.data.text = HeadingCustomisationPopupComponent.getContentWithNoLineBreaksAtEnd(this.textContent);
    this.configuration.data.style = HeadingCustomisationPopupComponent.extractStyles(this.configuration.data.content);
    this.configuration.data.style.backgroundColor = backgroundColor;
    this.dialogRef.close(this.configuration.data);
  }

  private getTextContent() {
    return this.tinymce.editor?.getContent({ format: 'text' }) ?? '';
  }
}
