import { Injectable } from '@angular/core';
import { BehaviorSubject, interval, Observable, of, timer } from 'rxjs';
import { debounce, delay, delayWhen, distinctUntilChanged, map, shareReplay, switchMap } from 'rxjs/operators';

type loadingState = 'idle' | 'inProgress' | 'onHold';
interface loadingInfo {
  loadingState: loadingState;
  canHide: boolean;
  timeout?: any;
}
type loadingDictionary = { [key: string]: loadingInfo };
@Injectable({
  providedIn: 'root',
})
export class LoaderService {
  private _isLoading = new BehaviorSubject<loadingDictionary>({
    default: { loadingState: 'inProgress', canHide: true },
  });

  constructor() {}

  isLoading(key: string = 'default') {
    return this._isLoading.pipe(
      map((loadingInfo) => (loadingInfo[key] === undefined ? true : loadingInfo[key].loadingState !== 'idle')),
      distinctUntilChanged(),
      shareReplay(1),
    );
  }

  show(key: string = 'default', isDebounce: boolean = false) {
    const prevValue = this._isLoading.value;
    if (isDebounce) {
      const timeout = setTimeout(() => {
        const prevValue = this._isLoading.value;
        const val = prevValue[key];
        if (val.loadingState === 'onHold') {
          clearTimeout(timeout);
          this._isLoading.next({ ...prevValue, [key]: { loadingState: 'idle', canHide: true } });
        } else {
          this._isLoading.next({ ...prevValue, [key]: { ...val, canHide: true } });
        }
      }, 1000);
      this._isLoading.next({
        ...prevValue,
        [key]: {
          loadingState: 'inProgress',
          canHide: false,
          timeout,
        },
      });
    } else {
      this._isLoading.next({ ...prevValue, [key]: { loadingState: 'inProgress', canHide: true } });
    }
  }

  hide(key: string = 'default') {
    const prevValue = this._isLoading.value;
    const val = prevValue[key];
    if (val.canHide) {
      clearTimeout(val.timeout);
      this._isLoading.next({ ...prevValue, [key]: { loadingState: 'idle', canHide: true } });
    } else {
      this._isLoading.next({ ...prevValue, [key]: { loadingState: 'onHold', canHide: false } });
    }
  }
}
