import { enableAllowedWizardSteps } from '@core/constants/EngagementCreationWizardSteps';
import Partners from '@core/constants/Partners';
import Route from '@core/constants/route';
import DurationData from '@core/models/duration-data';
import { EngagementTypeEnum } from '@core/models/enums/engagement-type-enum';
import { PricingTargetTypeEnum } from '@core/models/enums/pricing-target-type.enum';
import { PriceInfoModel } from '@core/models/price-info.model';
import BaseTargetPage from '@core/models/target-pages/base-target-page';
import { AppSettingService } from '@core/services/app-setting.service';
import { environment } from '@env/environment';
import { BehaviorSubject, Observable } from 'rxjs';

abstract class BaseEngagement {
  protected constructor(
    private appSettingService: AppSettingService,
    trigger: EngagementTypeEnum,
    activePricingTargetType: PricingTargetTypeEnum,
    creationSteps: string[] = [],
  ) {
    this.activeTrigger = trigger;
    this.activePricingTargetType = activePricingTargetType;
    this.creationSteps = creationSteps;
    this.currentStep = 1;
    enableAllowedWizardSteps(this.creationSteps);
  }
  currentStep: number;
  creationSteps: string[];
  engagementId: string;
  activeTrigger: EngagementTypeEnum;
  activePricingTargetType: PricingTargetTypeEnum;
  price: number;
  actInformation: BaseTargetPage;
  duration$: BehaviorSubject<DurationData>;

  getPathForStep() {
    return this.buildPathForStep(this.currentStep);
  }

  abstract getPayload$(): Observable<any>;
  abstract getTrigger();
  abstract getDuration$(): Observable<any>;
  abstract getPrice(): Observable<PriceInfoModel>;

  getTargetPage() {
    return this.actInformation ? this.actInformation.getTargetPageInfo() : null;
  }

  private buildPathForStep(step: number) {
    return [`${Route.ENGAGEMENT_CREATION}/${this.creationSteps[step]}`];
  }
}

export default BaseEngagement;
