import { Action, createReducer, on } from '@ngrx/store';
import { OnboardingProgress, OnboardingProgressEnum } from '../models/aci-onboarding.models';
import * as AciPaymentOnboardingActions from '../actions/aci-onboarding-progress.actions';

export interface AciOnboardingProgressState {
  merchantAccountStep: OnboardingProgress;
  confirmationStep: OnboardingProgress;
  aci3DSecureStep: OnboardingProgress;
}

export const initialState: AciOnboardingProgressState = {
  merchantAccountStep: {
    state: OnboardingProgressEnum.Idle,
  },
  confirmationStep: {
    state: OnboardingProgressEnum.Idle,
  },
  aci3DSecureStep: {
    state: OnboardingProgressEnum.Idle,
  },
};

const updateAciOnboardingProgressMerchantAccountReducer = createReducer(
  initialState,
  on(AciPaymentOnboardingActions.updateAciOnboardingProgressMerchantAccount, (state, payload) => ({
    ...state,
    merchantAccountStep: {
      ...payload,
    },
  })),
  on(AciPaymentOnboardingActions.updateAciOnboardingProgressSubmit, (state, payload) => ({
    ...state,
    confirmationStep: {
      ...payload,
    },
  })),
  on(AciPaymentOnboardingActions.updateAciOnboardingProgress3DSecure, (state, payload) => ({
    ...state,
    aci3DSecureStep: {
      ...payload,
    },
  })),
  on(AciPaymentOnboardingActions.clearAciOnboardingProgressState, (state, payload) => ({
    ...initialState,
  })),
);

export function reducer(state = initialState, action: Action): AciOnboardingProgressState {
  return updateAciOnboardingProgressMerchantAccountReducer(state, action);
}
