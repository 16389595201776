import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import Route from '@core/constants/route';
import { Observable } from 'rxjs';
import { EngagementCreationService } from 'src/app/features/engagement-creation/engagement-creation.service';

@Injectable({
  providedIn: 'root',
})
export class ConfirmEngagementGuard  {
  constructor(private engagementCreationService: EngagementCreationService, private router: Router) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (!this.engagementCreationService.engagement) {
      this.router.navigateByUrl(Route.DASHBOARD);
      return false;
    }
    return true;
  }
}
