import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, FormGroupDirective, ValidationErrors, Validators } from '@angular/forms';
import { Links } from '@core/constants/Links';
import { RegularExpressions } from '@core/constants/RegularExpressions';
import { ShopType } from '@core/models/update-merchant-product-config-request';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { BaseComponent } from '@shared/components/base-component/base-component';
import { Observable } from 'rxjs';
import { filter, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { Connection, ConnectionFormField } from '../../../products/shop-connection-models';
import { MenuWizardService } from '../menu-wizard/menu-wizard-service/menu-wizard.service';
import { selectSelfOnboardingState, SelfOnboardingState } from '../store';
import { createPlatformDetails } from '../store/actions/platform-details.action';
import { getSelectedEcommercePlatform } from '../store/selectors/ecommerce-platform.select';

@Component({
  selector: 'app-setting-platform-detail',
  templateUrl: './platform-detail.component.html',
  styleUrls: ['./platform-detail.component.scss'],
})
export class PlatformDetailComponent extends BaseComponent implements OnInit {
  platformDetailForm: FormGroup | undefined;
  eCommercePlatform$: Observable<any>;
  hasExtraFields = false;
  fieldDefinition: Array<any> = [];
  title = 'PLATFORM_INTEGRATION_COMING_SOON';
  addingECommerce = Links.ADDING_ECOMMERCE;
  defaultMessage: string;
  @ViewChild('form') ngForm: FormGroupDirective;

  constructor(
    private readonly store: Store<SelfOnboardingState>,
    private readonly menuWizardService: MenuWizardService,
    private translateService: TranslateService,
  ) {
    super();
    this.platformDetailForm = new FormGroup({
      storeId: new FormControl(''),
      storeURL: new FormControl('', Validators.pattern(RegularExpressions.URL)),
    });
  }

  ngOnInit(): void {
    this.eCommercePlatform$ = this.store.select(getSelectedEcommercePlatform).pipe(
      filter((platFormDetails) => !!platFormDetails),
      map((eCommercePlatform: { [type in ShopType]: Connection }) => {
        const name = Object.keys(eCommercePlatform)[0] as ShopType;
        if (eCommercePlatform[name].formFields) {
          for (const element of eCommercePlatform[name].formFields) {
            this.fieldDefinition.push({
              name: element.formControlName,
              isPassword: element.type === 'password',
              currentState: element.type.toString(),
            });
          }
        }
        if (eCommercePlatform[name].bearerAuthFields) {
          for (const element of eCommercePlatform[name].bearerAuthFields) {
            this.fieldDefinition.push({
              name: element.formControlName,
              isPassword: element.type === 'password',
              currentState: element.type.toString(),
            });
          }
        }

        if (eCommercePlatform[name].partialOAuthFields) {
          for (const element of eCommercePlatform[name].partialOAuthFields) {
            this.fieldDefinition.push({
              name: element.formControlName,
              isPassword: element.type === 'password',
              currentState: element.type.toString(),
            });
          }
        }

        if (eCommercePlatform[name].stateFields) {
          for (const element of eCommercePlatform[name].stateFields) {
            this.fieldDefinition.push({
              name: element.formControlName,
              isPassword: element.type === 'password',
              currentState: element.type.toString(),
            });
          }
        }

        if (eCommercePlatform[name].paymentMethodFields) {
          for (const element of eCommercePlatform[name].paymentMethodFields) {
            this.fieldDefinition.push({
              name: element.formControlName,
              isPassword: element.type === 'password',
              currentState: element.type.toString(),
            });
          }
        }

        if (eCommercePlatform[name].shippingFields) {
          for (const element of eCommercePlatform[name].shippingFields) {
            this.fieldDefinition.push({
              name: element.formControlName,
              isPassword: element.type === 'password',
              currentState: element.type.toString(),
            });
          }
        }

        return {
          name,
          connection: eCommercePlatform[name],
          hasExtraFields: Boolean(eCommercePlatform[name].formFields?.length),
        };
      }),
      tap((platform) => {
        this.updateForm(platform?.connection?.formFields);
        if (platform?.connection?.partialOAuthFields) {
          this.updateForm(platform?.connection?.partialOAuthFields);
        }
        if (platform?.connection?.bearerAuthFields) {
          this.updateForm(platform?.connection?.bearerAuthFields);
        }
        if (platform?.connection?.stateFields) {
          this.updateForm(platform?.connection?.stateFields);
        }
        if (platform?.connection?.paymentMethodFields) {
          this.updateForm(platform?.connection?.paymentMethodFields);
        }
        if (platform?.connection?.shippingFields) {
          this.updateForm(platform?.connection?.shippingFields);
        }
        if (platform?.connection?.formFields) {
          this.title = 'ECOMMERCE_CONNECTION';
        }
      }),
    );

    this.platformDetailForm.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((_) => {
      this.menuWizardService.canGoToNextStep$.next(this.platformDetailForm.valid);
    });

    this.store
      .select(selectSelfOnboardingState)
      .pipe(takeUntil(this.destroy$))
      .subscribe((state) => {
        this.platformDetailForm.patchValue(state.platformDetails);
      });

    this.menuWizardService.nextStepRequest$.pipe(takeUntil(this.destroy$)).subscribe((_) => {
      this.ngForm.ngSubmit.emit();
    });
  }

  updateForm(formFields: ConnectionFormField[] | undefined) {
    (formFields || []).forEach((formField) => {
      if (formField.defaultValue) {
        this.translateService.get(formField.defaultValue).subscribe((msg: string) => {
          this.defaultMessage = msg;
          this.platformDetailForm.addControl(
            formField.formControlName,
            new FormControl(this.defaultMessage, [
              ...(formField.isRequired ? [Validators.required] : []),
              ...(formField.validations || []),
            ]),
          );
        });
        this.translateService.onLangChange
          .pipe(
            switchMap(() => this.translateService.get(formField.defaultValue)),
            takeUntil(this.destroy$),
          )
          .subscribe((msg) => {
            this.defaultMessage = msg;
            this.platformDetailForm.setControl(
              formField.formControlName,
              new FormControl(this.defaultMessage, [
                ...(formField.isRequired ? [Validators.required] : []),
                ...(formField.validations || []),
              ]),
            );
          });
      } else {
        this.platformDetailForm.addControl(
          formField.formControlName,
          new FormControl('', [
            ...(formField.isRequired ? [Validators.required] : []),
            ...(formField.validations || []),
          ]),
        );
      }
    });
  }

  getStoreUrlErrors(): ValidationErrors | null {
    if (!this.platformDetailForm || !this.platformDetailForm.controls.storeURL.errors) {
      return null;
    }
    return this.platformDetailForm.controls.storeURL.errors;
  }

  onSubmit() {
    if (this.platformDetailForm.invalid) {
      return;
    }
    this.menuWizardService.goNext(() => {
      this.store.dispatch(createPlatformDetails({ platformDetails: { ...this.platformDetailForm.value } }));
    });
  }
  shouldIconShow(formControlName) {
    const element = this.fieldDefinition.find((x) => x.name === formControlName);
    if (element) {
      return element.isPassword;
    }
    return false;
  }

  changeItemType(formControlName) {
    const element = this.fieldDefinition.find((x) => x.name === formControlName);
    if (element) {
      element.currentState = element.currentState === 'password' ? 'text' : 'password';
    }
  }

  defineElementType(formControlName) {
    const element = this.fieldDefinition.find((x) => x.name === formControlName);
    if (element) {
      return element.currentState;
    }
    return 'text';
  }

  defineErrorMessage(item: any): string {
    if (item.formControlName === 'apiDomain' || item.formControlName === 'baseUrl') {
      if (
        this.platformDetailForm.get(item.formControlName).value === '' ||
        this.platformDetailForm.get(item.formControlName).value === undefined
      ) {
        return 'THIS_FIELD_IS_REQUIRED';
      }
      const data = this.platformDetailForm.get(item.formControlName).errors;
      const message = data == null ? 'THIS_FIELD_IS_REQUIRED' : data.invalidUrl ?? 'THIS_FIELD_IS_REQUIRED';
      return message;
    }
    return 'THIS_FIELD_IS_REQUIRED';
  }

  updateValidators(value) {
    if (this.platformDetailForm.get(value).value === 'bearer_token') {
      this.eCommercePlatform$.subscribe((result) => {
        for (const field of result.connection.partialOAuthFields) {
          this.platformDetailForm.get(field.formControlName).setValidators([]);
          this.platformDetailForm.get(field.formControlName).updateValueAndValidity();
        }
        for (const field of result.connection.bearerAuthFields) {
          this.platformDetailForm.get(field.formControlName).setValidators([Validators.required]);
          this.platformDetailForm.get(field.formControlName).updateValueAndValidity();
        }
      });
    }
    if (this.platformDetailForm.get(value).value === 'partial_oauth') {
      this.eCommercePlatform$.subscribe((result) => {
        for (const field of result.connection.bearerAuthFields) {
          this.platformDetailForm.get(field.formControlName).setValidators([]);
          this.platformDetailForm.get(field.formControlName).updateValueAndValidity();
        }
        for (const field of result.connection.partialOAuthFields) {
          this.platformDetailForm.get(field.formControlName).setValidators([Validators.required]);
          this.platformDetailForm.get(field.formControlName).updateValueAndValidity();
        }
      });
    }
  }
}
