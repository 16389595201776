import { DataFieldDTO } from '@core/models/dtos/data-field-dto';
import { ImageDefinitionDTO } from '@core/models/dtos/image-definition';
import { ActTypeEnum } from '@core/models/enums/act-type-enum';
import { ThumbnailImage } from '@core/models/thumbnail-image';
import { PageBuildingBlock } from '../page-building-block';
import ActTargetPage from '../target-pages/act-target-page';
import BaseTargetPage from '../target-pages/base-target-page';
import BuyTargetPage from '../target-pages/buy-target-page';
import InformationTargetPage from '../target-pages/information-target-page';
import UrlTargetPage from '../target-pages/url-target-page';
import { EngagementDTO } from './engagement-dto';
import { Product } from './product';

export class ActDTO {
  id: string;
  pageName: string;
  title: string;
  subtitle: string;
  thumbnailImage: ThumbnailImage;
  thumbnailUrl: string;
  isInformationPage: boolean;
  isBuy: boolean;
  description: string;
  customUrl: string;
  dateCreated: Date;
  dateUpdated: Date;
  images: ImageDefinitionDTO[] = [];
  dataFields: DataFieldDTO[] = [];
  linkedEngagements: string[] = [];
  associatedEngagements: EngagementDTO[] = [];
  submissionCount: number;
  product: Product;
  actType: ActTypeEnum = 2;
  formBuilderData: string;
  pageBuildingBlocks: PageBuildingBlock[] = [];
  linkedTargets: string[] = [];

  constructor(actDTO: ActDTO = null) {
    if (actDTO != null) {
      this.id = actDTO.id;
      this.title = actDTO.title;
      this.pageName = actDTO.pageName;
      this.subtitle = actDTO.subtitle;
      this.isInformationPage = actDTO.isInformationPage;
      this.isBuy = actDTO.isBuy;
      this.description = actDTO.description;
      this.customUrl = actDTO.customUrl;
      this.dateCreated = actDTO.dateCreated;
      this.dateUpdated = actDTO.dateUpdated;
      this.images = actDTO.images;
      this.dataFields = actDTO.dataFields;
      this.linkedEngagements = actDTO.linkedEngagements;
      this.associatedEngagements = actDTO.associatedEngagements != null ? actDTO.associatedEngagements : [];
      this.submissionCount = actDTO.submissionCount;
      this.product = actDTO.product;
      this.actType = actDTO.actType ? actDTO.actType : this.getActType(actDTO.isInformationPage, actDTO.isBuy);
      this.formBuilderData = actDTO.formBuilderData;
      this.pageBuildingBlocks = actDTO.pageBuildingBlocks;
      this.thumbnailImage = actDTO.thumbnailImage;
      this.thumbnailUrl = actDTO.thumbnailUrl;
      this.linkedTargets = actDTO.linkedTargets;
    }
  }

  getActInformation(): BaseTargetPage {
    switch (this.actType) {
      case ActTypeEnum.Act:
        const actPage = new ActTargetPage();
        actPage.id = this.id;
        actPage.dataFields = this.dataFields;
        actPage.description = this.description;
        actPage.formBuilderData = this.formBuilderData;
        actPage.pageBuildingBlocks = this.pageBuildingBlocks;
        actPage.images = this.images;
        actPage.title = this.title;
        actPage.pageName = this.pageName;
        actPage.subtitle = this.subtitle;
        actPage.type = this.actType;
        actPage.thumbnailUrl = this.thumbnailUrl;
        actPage.thumbnailImage = this.thumbnailImage;
        return actPage;
      case ActTypeEnum.InformationPage:
        const informationPage = new InformationTargetPage();
        informationPage.id = this.id;
        informationPage.dataFields = this.dataFields;
        informationPage.description = this.description;
        informationPage.formBuilderData = this.formBuilderData;
        informationPage.pageBuildingBlocks = this.pageBuildingBlocks;
        informationPage.isInformationPage = this.isInformationPage;
        informationPage.images = this.images;
        informationPage.title = this.title;
        informationPage.pageName = this.pageName;
        informationPage.subtitle = this.subtitle;
        informationPage.type = this.actType;
        informationPage.thumbnailUrl = this.thumbnailUrl;
        informationPage.thumbnailImage = this.thumbnailImage;
        return informationPage;
      case ActTypeEnum.Buy:
        const buyPage = new BuyTargetPage();
        buyPage.id = this.id;
        buyPage.dataFields = this.dataFields;
        buyPage.description = this.description;
        buyPage.isBuy = this.isBuy;
        buyPage.images = this.images;
        buyPage.title = this.title;
        buyPage.pageName = this.pageName;
        buyPage.subtitle = this.subtitle;
        buyPage.customUrl = this.customUrl;
        buyPage.type = this.actType;
        buyPage.thumbnailUrl = this.thumbnailUrl;
        buyPage.product = this.product;
        buyPage.thumbnailImage = this.thumbnailImage;
        return buyPage;
      case ActTypeEnum.Url:
        const urlPage = new UrlTargetPage();
        urlPage.id = this.id;
        urlPage.dataFields = this.dataFields;
        urlPage.description = this.description;
        urlPage.images = this.images;
        urlPage.title = this.title;
        urlPage.pageName = this.pageName;
        urlPage.subtitle = this.subtitle;
        urlPage.customUrl = this.customUrl;
        urlPage.type = this.actType;
        urlPage.thumbnailUrl = this.thumbnailUrl;
        urlPage.thumbnailImage = this.thumbnailImage;
        return urlPage;
      default:
        break;
    }
  }

  getActType(isInformationPage: boolean, isBuy: boolean): ActTypeEnum {
    if (isInformationPage) {
      return ActTypeEnum.InformationPage;
    }

    if (isBuy) {
      return ActTypeEnum.Buy;
    }

    return ActTypeEnum.Act;
  }
}
