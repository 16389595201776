export const Endpoints = {
  GET_ENGAGEMENTS: `/api/engagement/GetUserEngagementsV2`,
  GET_ASSOCIATED_ENGAGEMENTS: `/api/engagement/GetAssociatedEngagements`,
  DELETE_ENGAGEMENT: `/api/Engagement/DeleteEngagement/`,
  BATCH_DELETE_ENGAGEMENT: `/api/Engagement/BatchDeleteEngagements/`,
  BATCH_UPDATE_ENGAGEMENT: `/api/Engagement/BatchUpdateEngagements`,
  SET_ENGAGEMENT_STATUS: `/api/engagement/SetEngagementUserStatus`,
  CREATE_ENGAGEMENT: `/api/order/CreateEngagement`,
  GET_WATERMARKED_FILE: `/api/engagement/GetWaterMarkedFile`,
  GET_ASSETS_PACK: `/api/engagement/GetAssetPack`,
  GET_MERCHANT_NAME: `api/engagement/GetMerchantName`,
  UPLOAD_WATERMARK_IMAGES_URL: `/api/engagement/UploadImageFileForWatermarking`,
  UPLOAD_WATERMARK_AUDIO_URL: `/api/engagement/UploadAudioFileForWatermarking`,
  GET_TARGET_PAGES: `/api/act/GetMyTargetPagesV2`,
  CREATE_ACT: `/api/act/Create`,
  GET_ACT: `/api/act/`,
  GET_ACTS_BY_IDS: `/api/act/GetActByIds`,
  DELETE_ACT: `/api/act/DeleteAct/`,
  EDIT_ACT: `/api/act/EditAct`,
  SEARCH_PRODUCTS: '/api/Search/products',
  GET_IMAGE: '/api/Servicing/GetImage?path=',
  UPLOAD_FILE: '/api/Order/UploadFile',
  GET_ENGAGEMENT: '/api/engagement/GetEngagement',
  MAKE_PAYMENT: '/api/payments/MakePayment',
  GET_PAYMENT_METHODS: '/api/payments/GetPaymentMethods',
  DETACH_PAYMENT_METHOD: '/api/payments/DetachPaymentMethod',
  GET_ENGAGEMENT_PRICE: `api/payments/GetEngagementPrice`,
  GET_MERCHANT_INFO: `/api/merchant`,
  CHECK_RCE_HOST_NAME_ALREADY_EXIST_URL: `/api/merchant/CheckRceHostNameAlreadyExist`,
  UPDATE_CUSTOMER_PAYMENT_INFO: `/api/merchant/UpdateMerchantPaymentInfo`,
  UPDATE_MERCHANT_API2CART_CONFIG_URL: '/api/merchant/UpdateMerchantApi2CartConfig',
  START_ACI_ONBOARDING: '/api/AciOnboarding/StartAciOnboarding',
  GET_ACI_ONBOARDING_STATUS: '/api/AciOnboarding/GetAciOnboardingStatus',
  UPDATE_ENGAGEMENT_ACT: '/api/engagement/UpdateAct/',
  EXPORT_ACTS_URL: '/api/Act/ExportAct/',
  GET_WE_CHAT_QR_CODE: '/api/engagement/weChatQrCode/',
  CREATE_PRODUCT: '/api/products/',
  UPLOAD_PRODUCT_MEDIA: '/api/products/{sku}/media',
  UPDATE_PRODUCT_MEDIA: '/api/products/{sku}/media/{mediaId}',
  REMOVE_PRODUCT_MEDIA: '/api/products/{sku}/media/{mediaId}',
  UPDATE_PRODUCT: '/api/products/{sku}',
  GET_RCE_PRODUCTS: '/api/Products',
  GET_RCE_CATEGORIES: '/api/Products/categories',
  DELETE_RCE_PRODUCT: '/api/Products/',
  UPDATE_PRODUCT_STATUS: '/api/Products/status',
  CONNECT_TO_API_2_CART: '/api/Products/api2cart',
  SETUP_AUTO_PROVISIONING: '/api/AutoProvisioning/SetupAutoProvisioning',
  SETUP_ACI_AUTO_PROVISIONING: '/api/AutoProvisioning/SetupAciAutoProvisioning',
  CREATE_ACI_MERCHANT_ACCOUNT: '/api/AciOnboarding/CreateAciMerchantAccount',
  UPDATE_ACI_MERCHANT_STATUS: '/api/AciOnboarding/UpdateAciMerchantStatus/{status}',
  RESET_ONBOARDING: '/api/AciOnboarding/ResetOnboarding',
  RESET_ONBOARDING_STEP: '/api/AciOnboarding/ResetOnboardingCurrentStep',
  SETUP_ACI_3D_SECURE: '/api/AciOnboarding/SetupAci3DSecure',
  GET_INTEREST_TAGS: '/api/Bitplaces/GetInterestTags',
  GET_ANALYTICS_EMBED_TOKEN: '/api/Reports/token',
  GET_ENGAGEMENTS_USAGE: 'api/Tier/usage/engagements',
  GET_ACTS_USAGE: 'api/Tier/usage/acts',
  LOCATION_TAGS: 'api/MerchantLocations/tags',
  LOCATION: 'api/MerchantLocations',
  SEARCH_LOCATION: 'api/MerchantLocations/search',
  GET_LOCATIONS_WITHIN_RANGE: 'api/MerchantLocations/distance',
  LOCATIONS_TYPES: 'api/MerchantLocations/types',
  LOCATIONS_BY_TYPE: 'api/MerchantLocations/type',
  UPDATE_BRAND_DATA: 'api/merchant/UpdateMerchantBrandInfo',
  GET_BRAND_DATA: 'api/merchant/GetBrandInfo',
};
